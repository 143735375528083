import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Collapse,
  Form,
  InputGroup,
  Modal,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  ADMIN_BADGE,
  CODE_SHOPING_ORDER_STATUS,
  CODE_SHOPPING_ORDER_ITEMS,
  USE_CONDITION,
} from "../../common/constants";
import { formatDate } from "../../common/format";
import {
  MBody,
  MButton,
  MPageTitle,
  MRow,
  MobilePaymentSection,
} from "../../common/mobile-styled";
import { RootUserState } from "../../redux/store";
import { reqAddAdminBadge } from "../../requests/badge";
import { reqMyHaveCoupon, reqUseCoupon } from "../../requests/coupon";
import { reqPaypalPayment, reqPaypalRefund } from "../../requests/paypal";
import { reqShipFEE } from "../../requests/ship";
import {
  reqShop,
  reqShopDetail,
  reqShopDetailUpdate,
  reqShopUpdate,
} from "../../requests/shop";
import {
  reqPostPointlog,
  reqUpdateUserPoint,
  reqUpdateUserPoints,
  reqUpdateUserPointsUSD,
} from "../../requests/user";
import { 구매대행_콜백_처리 } from "../../views/Customer/CPaypalResult";
import CouponList from "../CouponList";
import Icon from "../Icon";
interface ShopOrderModalProps {
  show: boolean;
  onHide: () => void;
  orderId: string;
  refreshData: () => void;
  status?: number;
}

interface PaymentRowProps {
  title: string;
  amount: string;
}
interface OrderDetail {
  order_id: string;
  category: number;
  productId: string;
  name: string;
  option1: string;
  option2: string;
  quantity: number;
  price: number;
  ShippingFee: number;
  status: number;
  url: string;
  fee: number;
  cancellationReason: string;
  isCanceled: boolean;
  user: {
    pointUsd: number;
    point: number;
    id: string;
  };
}

interface ShopResponseData {
  failed: boolean;
  refund: boolean;
  option1: string;
  option2: string;
  url: string;
  price: number;
  paymentDeadline: string;
  fancallNumber: string;
  totalPriceUSD: number;
  paypalTransactionId?: string;
  status: number;
  PaymentMethod: number;
  usePointKrw: number;
  usePointUse: number;
  user: {
    pointUsd: number;
    point: number;
    id: string;
  };
}

interface UpdatedOrderDetails {
  [key: string]: OrderDetail;
}

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const CShopOrderModal = ({
  show,
  onHide,
  orderId,
  refreshData,
  status,
}: ShopOrderModalProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [params, setParmas] = useState<string>(location.search);
  const [orderDetails, setOrderDetails] = useState<OrderDetail[]>([]);
  const [serviceFee, setServiceFee] = useState("");
  const excludeStatuses = [3, 4, 5, 9, 10];
  const [orderData, setOrderData] = useState<ShopResponseData | null>(null);
  const [paymentDeadline, setPaymentDeadline] = useState("");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);
  const [depositorName, setDepositorName] = useState("");
  const [refundMethod, setRefundMethod] = useState<boolean | null>(false);
  const [depositAmount, setDepositAmount] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [refund, setRefund] = useState<boolean | null>(null);
  const [showKRWPModal, setShowKRWPModal] = useState(false);
  const [showUSDModal, setShowUSDModal] = useState(false);
  const [paymentConfirmed, setPaymentConfirmed] = useState<boolean>(false);
  const hasExcludedStatus = orderDetails.some((detail) =>
    excludeStatuses.includes(detail.status)
  );
  const [appliedPoints, setAppliedPoints] = useState(0);
  const [originalPoints, setOriginalPoints] = useState(
    orderDetails[0]?.user.point || 0
  );
  const [pointsApplied, setPointsApplied] = useState(0);
  const [usdPointsApplied, setUsdPointsApplied] = useState(0);
  const [UtotalUSD, setUtotalUSD] = useState(0);
  const [accountHolder, setAccountHolder] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [failed, setFailed] = useState(false);
  const showPaymentButtons = true;
  // const showPaymentButtons = status === CODE_SHOPING_ORDER_STATUS.WAIT_PAY;
  const [updatedOrderDetails, setUpdatedOrderDetails] =
    useState<UpdatedOrderDetails>({});

  useEffect(() => {
    setOriginalPoints(orderDetails[0]?.user.point || 0);
    내가_가진_구매대행_쿠폰_가져오기();
  }, [orderDetails]);

  //! 쿠폰
  const user = useSelector((state: RootUserState) => state.user);
  const [availableCouponList, setAvailableCouponList] = useState<any>();
  const [krwDiscount, setKrwDiscount] = useState<number>(0);
  const [usdDiscount, setUsdDiscount] = useState<number>(0);
  const [couponApplied, setCouponApplied] = useState<boolean>(false);
  const [couponModal, setCouponModal] = useState<boolean>(false);

  const [selectedCouponId, setSelectedCouponId] = useState<number>();

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const 내가_가진_구매대행_쿠폰_가져오기 = async () => {
    const res = await reqMyHaveCoupon({ userId: user.id });
    const myCouponDataList = res.data;
    const thisAvailableCouponList = myCouponDataList.filter(
      (couponData: any) =>
        couponData.coupon.useCondition === USE_CONDITION["SHOP_SERVICE_FEE"]
    );

    setAvailableCouponList(thisAvailableCouponList);
    if (thisAvailableCouponList.length > 0) {
      Toast.fire({
        icon: "success",
        title: "There are coupons you can use!",
      });
    }
  };

  useEffect(() => {
    if (couponApplied) {
      setCouponModal(false);
      // 쿠폰 실제 적용
    } else {
      setKrwDiscount(0);
      setUsdDiscount(0);
    }
  }, [couponApplied]);

  //!
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await reqShop({ order_id: orderId });
        setOrderData(response.data);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    if (orderId) {
      fetchOrderDetails();
    }
  }, [orderId]);

  const handleKRWPayment = () => {
    const originalKRWPoints = orderDetails[0]?.user.point || 0;
    if (pointsApplied > originalKRWPoints) {
      alert("보유 포인트보다 많은 포인트를 적용할 수 없습니다.");
      setPointsApplied(0);
      return;
    }
    setShowKRWPModal(true);
  };

  const closeKRWPModal = () => {
    setShowKRWPModal(false);
  };

  const handleUSDPayment = () => {
    const originalUSDPoints = orderDetails[0]?.user.pointUsd || 0;
    if (pointsApplied > originalUSDPoints) {
      alert("보유 포인트보다 많은 포인트를 적용할 수 없습니다.");
      setPointsApplied(0);
      return;
    }
    setShowUSDModal(true);
  };

  const closeUSDModal = () => {
    setShowUSDModal(false);
  };

  // 포인트 환불 시스템
  const updateUserPoints = async (pointsToAdd: number) => {
    if (!orderData) {
      return false;
    }

    const userId = orderData.user.id;

    try {
      await reqUpdateUserPoints(userId, { pointsToAdd });

      setOrderData((prevOrderData) => {
        if (prevOrderData) {
          return {
            ...prevOrderData,
            user: {
              ...prevOrderData.user,
              point: prevOrderData.user.point + pointsToAdd,
            },
          };
        }
        return prevOrderData;
      });

      return true;
    } catch (error) {
      console.error("Failed to update user points:", error);
      return false;
    }
  };

  const updateUserPointsUSD = async (pointsToAdd: number) => {
    if (!orderData) {
      return false;
    }

    const userId = orderData.user.id;

    try {
      await reqUpdateUserPointsUSD(userId, { pointsToAdd });

      setOrderData((prevOrderData) => {
        if (prevOrderData) {
          return {
            ...prevOrderData,
            user: {
              ...prevOrderData.user,
              pointUsd: prevOrderData.user.pointUsd + pointsToAdd,
            },
          };
        }
        return prevOrderData;
      });

      return true;
    } catch (error) {
      console.error("Failed to update user USD points:", error);
      return false;
    }
  };

  const handleCancelOrderClick = async () => {
    /*
        1. 사용자가 해당 주문에 사용한 결제 수단 파악 (KRW, USD)
        2. 결제 수단에 따라 다른 로직 수행
          2-1. KRW: 상태 변경 -> 관리자가 인지 후 직접 무통장 입금 환불
          2-2. USD: 상태 변경 + Paypal 환불 -> 관리자의 동작 없이 환불 가능
      */
    const paymentCurrency = orderData?.PaymentMethod === 2 ? "USD" : "KRW";
    Swal.fire({
      title: "Are you sure you want to cancel your order?",
      text: "",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "YES",
      cancelButtonText: "BACK",

      reverseButtons: true,
    }).then(async (result) => {
      if (!result.isConfirmed) return;

      // Paypal 자동 환불 프로세스
      // if (paymentCurrency === "USD") {
      //   await refundByPaypal();
      // } else if (paymentCurrency === "KRW" && orderData?.PaymentMethod === 1) {
      //   const pointsToAdd = (orderData?.usePointKrw || 0) + (orderData?.usePointUse || 0);
      //   await updateUserPoints(pointsToAdd);
      // }

      // 모든 통화에 대해 상태 변경 & 관리자에게 뱃지 송신
      await handleReapplyOrder(orderId);
    });
  };

  // 환불 처리
  const refundByPaypal = async () => {
    const { totalPriceUSD, paypalTransactionId } =
      orderData as ShopResponseData;
    if (totalPriceUSD && paypalTransactionId) {
      await reqPaypalRefund({
        storeid: "ontactkorea",
        paytoken: paypalTransactionId,
        currency: "USD",
        amount: totalPriceUSD,
      });
      // USD 포인트 환불 처리
      const pointsToRefundUSD = orderData?.usePointUse || 0;
      if (pointsToRefundUSD > 0) {
        await updateUserPointsUSD(pointsToRefundUSD);
      }
    }
  };

  // 취소 상태 처리
  const handleReapplyOrder = async (orderId: string) => {
    await reqShopUpdate({
      order_id: orderId,
      status: CODE_SHOPING_ORDER_STATUS.CANCELED,
    });
    Swal.fire("Your order has been successfully canceled.", "", "success");
    onHide();
    refreshData();
  };

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (
        response &&
        response.data &&
        response.data[0]?.fixedExchangeRateShop
      ) {
        setFixedExchangeRate(
          parseFloat(response.data[0].fixedExchangeRateShop)
        );
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  const convertToUSD = (amount: number): number => {
    return parseFloat((amount / fixedExchangeRate).toFixed(2));
  };

  const handleRefundMethodChange = (method: boolean) => {
    setRefundMethod(method);
    setRefund(method);
  };

  const handleFailedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFailed(event.target.checked);
  };

  useEffect(() => {
    // fetchExchangeRate();
    fetchFixedExchangeRate();
    if (orderId) {
      fetchOrderDetails(orderId);
    }

    const fetchOrderData = async () => {
      try {
        const response = await reqShop({ order_id: orderId });
        const data = response.data;
        setPaymentDeadline(data.paymentDeadline);
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    fetchOrderData();
  }, [orderId]);

  const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAgreed(e.target.checked);
  };

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]);

  const fetchOrderDetails = async (order_id: string) => {
    try {
      const detailResponse = await reqShopDetail({ order_id });
      const detailsWithStatus: OrderDetail[] = detailResponse.data.map(
        (item: OrderDetail) => ({
          ...item,
          isCanceled: item.status === CODE_SHOPING_ORDER_STATUS.CANCELED,
        })
      );
      setOrderDetails(detailsWithStatus);

      const shopResponse = await reqShop({ order_id });
      setServiceFee(shopResponse.data.fee.toString());
      const paymentDeadline = shopResponse.data.paymentDeadline;
      setPaymentDeadline(paymentDeadline);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const handleSendEstimate = async () => {
    try {
      const updatedDetailsArray = Object.values(updatedOrderDetails);

      await reqShopDetailUpdate(updatedDetailsArray);

      const totals = calculateTotal();
      setUtotalUSD(totals.UtotalUSD);
      const updateData = {
        order_id: orderId,
        status: CODE_SHOPING_ORDER_STATUS.PAID,
        paymentConfirmed: 1,
      };

      await reqShopUpdate(updateData);
      onHide();
      refreshData();
    } catch (error) {
      console.error("견적서 업데이트 실패:", error);
    }
  };

  const handleCloseOrderClick = () => {
    onHide();
  };

  const calculateTotal = () => {
    const excludeStatuses = [3, 4, 5, 9, 10];

    const productTotal = orderDetails.reduce((total, item) => {
      if (!item.isCanceled && !excludeStatuses.includes(item.status)) {
        return total + item.quantity * item.price;
      }
      return total;
    }, 0);

    const domesticShippingFee = orderDetails.reduce((total, item) => {
      if (!item.isCanceled && !excludeStatuses.includes(item.status)) {
        return total + item.ShippingFee;
      }
      return total;
    }, 0);

    const parsedServiceFee = parseFloat(serviceFee);
    let serviceFeeValue = Math.round(
      productTotal * (isNaN(parsedServiceFee) ? 0 : parsedServiceFee / 100)
    );
    serviceFeeValue = Math.max(serviceFeeValue, 5000);

    const paypalFee =
      (productTotal + serviceFeeValue + domesticShippingFee) * 0.05;
    const totalUSD = convertToUSD(
      productTotal + domesticShippingFee + serviceFeeValue + paypalFee
    );
    const UtotalUSDValue = Math.max(
      totalUSD - usdDiscount - usdPointsApplied,
      0
    );

    return {
      productTotal,
      domesticShippingFee,
      serviceFeeValue,
      paypalFee,
      Ktotal: Math.max(productTotal + domesticShippingFee + serviceFeeValue),
      total: Math.max(
        productTotal +
          domesticShippingFee +
          serviceFeeValue -
          pointsApplied -
          krwDiscount,
        0
      ),
      Utotal: UtotalUSDValue,
      totalUSD: totalUSD,
      UtotalUSD: UtotalUSDValue,
    };
  };

  const {
    productTotal,
    domesticShippingFee,
    serviceFeeValue,
    paypalFee,
    total,
    Ktotal,
    totalUSD,
    Utotal,
  } = calculateTotal();

  const getStatusText = (status: number): string => {
    switch (status) {
      case 2:
        return "POSSIBILITY";
      case 3:
        return "EXPIRATION";
      case 4:
        return "RESTRICTION";
      case 5:
        return "SOLD_OUT";
      default:
        return "Unknown Status";
    }
  };

  const handleDepositAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDepositAmount(e.target.value);
  };

  //! 전송
  const handlePaymentSubmit = async (type: string) => {
    const inputDepositAmount = parseFloat(depositAmount);
    const totalAmount = total;

    const paymentData = {
      order_id: orderId,
      failed,
      refund,
      status: refundMethod ? CODE_SHOPING_ORDER_STATUS.PAID : undefined,
      account: refundMethod === true ? accountNumber : undefined,
      option1: refundMethod === true ? bankName : undefined,
      option2: refundMethod === true ? accountHolder : undefined,
      url: depositorName,
    };

    if (pointsApplied > originalPoints) {
      alert("Error: You cannot apply more points than you have.");
      return;
    }

    if (type === "KRW") {
      if (refundMethod) {
        if (!bankName || !accountHolder || !accountNumber) {
          alert(
            "Please fill in your Bank, Account Holder, and Account Number information."
          );
          return;
        }
      } else {
        if (!depositorName || !depositAmount) {
          alert(
            "Please fill in your Depositor Name and Deposit Amount information."
          );
          return;
        }
      }

      if (inputDepositAmount !== totalAmount) {
        alert(
          "The deposit amount you entered does not match the total amount of payment. Please enter the correct amount."
        );
        return;
      }

      await treatKRWPayment(inputDepositAmount, paymentData);
      return;
    }

    if (type === "USD") {
      // paypal 결제 완료 콜백 후 후처리 과정을 객체에 담아서 parse 후 실행
      const customObject = {
        pk: orderId,
        domain: "SHOP",
        reqShopUpdate: {
          order_id: orderId,
          status: CODE_SHOPING_ORDER_STATUS.PRODUCT_PART,
          paymentConfirmed: true,
          PaymentMethod: 2,
          couponLogId: couponApplied ? selectedCouponId : null,
          snKrwShippingFee: domesticShippingFee,
          snKrwFee: serviceFeeValue,
          snKrwTotalFee: Ktotal,
          snKrwRealFee: total,
          snKrwPointApplied: pointsApplied,
          snKrwCouponApplied: krwDiscount,
          snUsdShippingFee: convertToUSD(domesticShippingFee),
          snUsdFee: convertToUSD(serviceFeeValue),
          snUsdTotalFee: totalUSD,
          snUsdRealFee: Utotal,
          snUsdPointApplied: usdPointsApplied,
          snUsdCouponApplied: usdDiscount,
          snUsdPaypalSurcharge: convertToUSD(paypalFee),
          fancallBirthday: new Date(),
        },
        reqAddAdminBadge: {
          badgeName: ADMIN_BADGE.SHOP_PAYMENT_WAITING,
        },
      };

      if (couponApplied) {
        Object.assign(customObject, {
          reqUseCoupon: {
            couponLogId: selectedCouponId,
            krwDiscounted: 0,
            usdDiscounted: usdDiscount,
            orderId: orderId,
          },
        });
      }
      if (usdPointsApplied > 0) {
        Object.assign(customObject, {
          reqUpdateUserPointUSD: {
            userId: orderDetails[0]?.user.id,
            pointUsd: orderDetails[0]?.user.pointUsd - usdPointsApplied,
          },
        });
      }

      const pointLogPayload = {
        userId: orderDetails[0]?.user.id,
        used: true,
        status: 1,
        usdUsedPoint: usdPointsApplied,
      };

      if (usdPointsApplied > 0) {
        try {
          await reqPostPointlog(pointLogPayload);
        } catch (error) {
          console.error("Failed to log points:", error);
        }
      }

      // 쿠폰으로 인해 0원인 경우 Paypal 결제 없이 처리
      if (parseFloat(Utotal.toFixed(2)) === 0) {
        await 구매대행_콜백_처리(JSON.stringify(customObject));
        Swal.fire({
          title: "Payment Success",
          text: "Thank you for using ontact korea!",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Back to previous page",
        }).then(async (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        return;
      }

      const res = await reqPaypalPayment({
        storeid: "koreawave",
        currency: "USD",
        storeorderno: `shop-${new Date().getTime().toString()}`,
        amount: parseFloat(Utotal.toFixed(2)),
        payerid: `koreawave-${user.id}`,
        payeremail: "khh@koreawave.kr",
        returnurl: `${process.env.REACT_APP_BASE_URL}paypal/handle/result`,
        notiurl: `${process.env.REACT_APP_BASE_URL}paypal/handle/notify`,
        // 콜백 후처리 객체
        custom: JSON.stringify(customObject),
        directreturnflag: "Y",
      });

      if (!res) {
        alert("Paypal API Error");
        return;
      }
      const { online_url, mobile_url } = res.data;
      window.location.replace(isMobile ? mobile_url : online_url);
      return;
    }
  };

  const treatKRWPayment = async (inputDepositAmount: any, paymentData: any) => {
    try {
      if (couponApplied) {
        // 쿠폰 사용
        await reqUseCoupon({
          couponLogId: selectedCouponId,
          krwDiscounted: krwDiscount,
          usdDiscounted: usdDiscount,
          orderId: orderId,
        });
      }

      await reqShopUpdate({
        order_id: orderId,
        failed,
        refund,
        paymentConfirmed: true,
        paymentAmountKRW: inputDepositAmount,
        usePointKrw: pointsApplied,
        PaymentMethod: 1,
        couponLogId: selectedCouponId,
        status: CODE_SHOPING_ORDER_STATUS.PAID,
        account: refundMethod === true ? accountNumber : undefined,
        option1: refundMethod === true ? bankName : undefined,
        option2: refundMethod === true ? accountHolder : undefined,
        url: depositorName,
        snKrwShippingFee: domesticShippingFee,
        snKrwFee: serviceFeeValue,
        snKrwTotalFee: Ktotal,
        snKrwRealFee: total,
        snKrwPointApplied: pointsApplied,
        snKrwCouponApplied: krwDiscount,
        snUsdShippingFee: convertToUSD(domesticShippingFee),
        snUsdFee: convertToUSD(serviceFeeValue),
        snUsdTotalFee: totalUSD,
        snUsdRealFee: Utotal,
        snUsdPointApplied: usdPointsApplied,
        snUsdCouponApplied: usdDiscount,
        snUsdPaypalSurcharge: convertToUSD(paypalFee),
        fancallBirthday: new Date(),
      });

      await reqUpdateUserPoint({
        userId: orderDetails[0]?.user.id,
        point: originalPoints - pointsApplied,
      });

      await reqAddAdminBadge({
        badgeName: ADMIN_BADGE.SHOP_PAYMENT_WAITING,
      });

      const pointLogPayload = {
        userId: orderDetails[0]?.user.id,
        used: true,
        status: 1,
        krwUsedPoint: pointsApplied,
      };

      if (pointsApplied > 0) {
        try {
          await reqPostPointlog(pointLogPayload);
        } catch (error) {
          console.error("Failed to log points:", error);
        }
      }

      Swal.fire({
        title: "Success!",
        text: "Payment information updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      closeKRWPModal();
      closeUSDModal();
      onHide();
      refreshData();
      window.location.reload();
    } catch (error) {
      console.error("Update failed:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to update payment information: ",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const applyAllKRWPoints = () => {
    const maxKrwPoints = Math.min(orderDetails[0]?.user.point, Ktotal);
    setPointsApplied(maxKrwPoints);
  };

  const handleKrwPointsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputPoints = parseInt(e.target.value, 10);

    if (isNaN(inputPoints) || inputPoints < 0) {
      inputPoints = 0;
    }

    const availablePoints = orderDetails[0]?.user.point || 0;
    const maxPoints = Math.min(Ktotal, availablePoints);

    setPointsApplied(Math.min(inputPoints, maxPoints));
  };

  const applyAllUSDPoints = () => {
    const maxUsdPoints = Math.min(orderDetails[0]?.user.pointUsd, Utotal);
    setUsdPointsApplied(maxUsdPoints);
  };

  const handleUsdPointsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputPoints = parseFloat(e.target.value);

    if (isNaN(inputPoints) || inputPoints < 0) {
      setUsdPointsApplied(0);
      return;
    }

    const availablePoints = orderDetails[0]?.user.pointUsd || 0;
    const maxPoints = Math.min(totalUSD, availablePoints);
    const roundedPoints = Math.round(inputPoints * 100) / 100;

    setUsdPointsApplied(Math.min(roundedPoints, maxPoints));
  };

  //! 결제
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [agreeCheck, setAgreeCheck] = useState<boolean>(false);

  const getPaymentCardStyle = (method: string) => {
    return method === paymentMethod ? "payment-card selected" : "payment-card";
  };

  const getMobilePaymentCardStyle = (method: string) => {
    return method === paymentMethod
      ? "mobile-payment-card selected"
      : "mobile-payment-card";
  };

  // 탭 처리
  const [activeTab, setActiveTab] = useState("USD");
  const [key, setKey] = useState("USD");
  const handleSelect = (k: string | null) => {
    if (k !== null) {
      setKey(k);
    }
  };

  const [open, setOpen] = useState(new Array(orderDetails.length).fill(false));

  const toggleCollapse = (index: number) => {
    const newOpen = [...open];
    newOpen[index] = !newOpen[index];
    setOpen(newOpen);
  };

  const [bankName, setBankName] = useState("");

  return (
    <div>
      <StyledModal
        show={show}
        onHide={() => {
          onHide();
          setCouponApplied(false);
        }}
        centered
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "var(--color-lightgray)" }}
        >
          <Modal.Title>
            <i
              className="bi bi-currency-exchange"
              style={{ fontSize: "1.7rem", marginRight: "0.5rem" }}
            ></i>
            {orderId}
          </Modal.Title>
        </Modal.Header>

        {isMobile ? (
          <MobileWrapper>
            <Tabs
              defaultActiveKey="productInfo"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="productInfo" title="Product Info">
                <MBody style={{ border: "none" }}>
                  {orderDetails.map((detail, index) => (
                    <Box
                      key={index}
                      style={{
                        borderBottom: "1px solid lightgray",
                        margin: "0",
                      }}
                    >
                      <div
                        onClick={() => toggleCollapse(index)}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Icon icon="caret-down-fill"></Icon>
                        <NoButton>{`NO: ${detail.productId}`}</NoButton>
                      </div>

                      <Collapse in={open[index]}>
                        <div
                          style={{
                            margin: "0 1.5rem",
                          }}
                        >
                          <MRow>
                            <div className="label">Category</div>
                            <div className="data">
                              {CODE_SHOPPING_ORDER_ITEMS[detail.category]}
                            </div>
                          </MRow>

                          <MRow>
                            <div className="label">Product name</div>
                            <div className="data">{detail.name}</div>
                          </MRow>

                          <MRow>
                            <div className="label">Option 1</div>
                            <div className="data">{detail.option1 || "-"}</div>
                          </MRow>

                          <MRow>
                            <div className="label">Option 2</div>
                            <div className="data">{detail.option2 || "-"}</div>
                          </MRow>

                          <MRow>
                            <div className="label">Quantity</div>
                            <div className="data">{detail.quantity}</div>
                          </MRow>

                          <MRow>
                            <div className="label">Unit price</div>
                            <div className="data price">{detail.price}</div>
                          </MRow>

                          <MRow>
                            <div className="label">Shipping Fee</div>
                            <div className="data price">
                              {detail.ShippingFee}
                            </div>
                          </MRow>

                          <MRow>
                            <div className="label">Status</div>
                            <div className="data">{detail.status}</div>
                          </MRow>
                        </div>
                      </Collapse>
                    </Box>
                  ))}
                </MBody>
              </Tab>

              <Tab eventKey="payment" title="Payment">
                <Tabs
                  defaultActiveKey="KRW"
                  id="payment-tabs"
                  className="mb-3"
                  activeKey={key}
                  onSelect={handleSelect}
                >
                  {/* USD Payment 탭 */}
                  {isMobile
                    ? orderData &&
                      orderData.status !== 3 && (
                        <Tab eventKey="USD" title="USD Payment">
                          <MBody style={{ border: "none" }}>
                            <MPageTitle
                              style={{
                                borderBottom: "1px solid lightgray",
                                paddingBottom: "1rem",
                              }}
                            >
                              USD Payment Details
                            </MPageTitle>
                            <MRow>
                              <div className="label">Product Total</div>
                              <div className="data price">{`$ ${convertToUSD(
                                productTotal
                              )}`}</div>
                            </MRow>
                            <MRow>
                              <div className="label">
                                Domestic Shipping Cost
                              </div>
                              <div className="data price">{`$ ${convertToUSD(
                                domesticShippingFee
                              )}`}</div>
                            </MRow>
                            <MRow>
                              <div className="label">Service Cost</div>
                              <div className="data price">{`$ ${convertToUSD(
                                serviceFeeValue
                              )}`}</div>
                            </MRow>
                            <MRow>
                              <div className="label">Paypal Cost</div>
                              <div className="data price">{`$ ${convertToUSD(
                                paypalFee
                              )}`}</div>
                            </MRow>
                            <MRow>
                              <div className="label">Total</div>
                              <div className="data price">{`$ ${Utotal.toFixed(
                                2
                              )}`}</div>
                            </MRow>

                            <InputGroup className="mb-3">
                              <InputGroup.Text>USD Points</InputGroup.Text>
                              <input
                                type="number"
                                placeholder="Enter USD points to apply"
                                value={usdPointsApplied.toString()}
                                onChange={handleUsdPointsChange}
                                step="0.01"
                                min="0"
                                max={Utotal.toFixed(2)}
                                onKeyPress={(e) => {
                                  if (!/[0-9.]$/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                style={{ flex: 1 }}
                              />
                              <Button
                                variant="outline-secondary"
                                onClick={applyAllUSDPoints}
                              >
                                Apply All USD Points
                              </Button>
                            </InputGroup>

                            <MRow>
                              <div className="label">Available USD Points</div>
                              <div className="data">
                                {orderDetails[0]?.user.pointUsd || 0} USD Points
                              </div>
                            </MRow>
                            <MRow>
                              <div className="label">Remaining USD Points</div>
                              <div className="data">
                                {(
                                  orderDetails[0]?.user.pointUsd -
                                  usdPointsApplied
                                ).toFixed(2)}{" "}
                                USD Points
                              </div>
                            </MRow>
                            <MRow>
                              <div className="label">Applied Coupon</div>
                              <div
                                className="data price"
                                style={{ color: "var(--color-main-blue)" }}
                              >
                                {`- $${usdDiscount.toFixed(2)}`}
                              </div>
                            </MRow>
                          </MBody>
                        </Tab>
                      )
                    : orderData &&
                      orderData.status !== 3 && (
                        <Tab eventKey="USD" title="USD Payment">
                          <Col>
                            <h3
                              style={{
                                borderBottom: "1px solid lightgray",
                                paddingTop: "1rem",
                                paddingBottom: "1rem",
                              }}
                            >
                              USD Payment Details
                            </h3>
                            <Label>Product total</Label>
                            <FeeBoxRow>
                              <div>{`$ ${convertToUSD(productTotal)}`}</div>
                            </FeeBoxRow>
                            <Label>Domestic Shipping Cost</Label>
                            <FeeBoxRow>
                              <div>{`$ ${convertToUSD(
                                domesticShippingFee
                              )}`}</div>
                            </FeeBoxRow>
                            <Label>Service Cost</Label>
                            <FeeBoxRow>
                              <div>{`$ ${convertToUSD(serviceFeeValue)}`}</div>
                            </FeeBoxRow>
                            <Label>Paypal Cost</Label>
                            <FeeBoxRow>
                              <div>{`$ ${convertToUSD(paypalFee)}`}</div>
                            </FeeBoxRow>
                            <Label>Total</Label>
                            <FeeBoxRow>
                              <div>{`$ ${Utotal.toFixed(2)}`}</div>
                            </FeeBoxRow>
                            <InputGroup className="mb-3">
                              <InputGroup.Text>USD Points</InputGroup.Text>
                              <input
                                type="number"
                                placeholder="Enter USD points to apply"
                                value={usdPointsApplied.toString()}
                                onChange={handleUsdPointsChange}
                                step="0.01"
                                min="0"
                                max={Utotal.toFixed(2)}
                                onKeyPress={(e) => {
                                  if (!/[0-9.]$/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                style={{ flex: 1 }}
                              />
                              <Button
                                variant="outline-secondary"
                                onClick={applyAllUSDPoints}
                              >
                                Apply All USD Points
                              </Button>
                              <div style={{ marginTop: "1rem", width: "100%" }}>
                                <Label>Available USD Points:</Label>
                                <div>
                                  {orderDetails[0]?.user.pointUsd || 0} USD
                                  Points
                                </div>
                                <Label>Remaining USD Points:</Label>
                                <div>
                                  {orderDetails[0]?.user.pointUsd -
                                    usdPointsApplied}{" "}
                                  USD Points
                                </div>
                              </div>
                            </InputGroup>
                            <Label>Applied Coupon</Label>
                            <FeeBoxRow>
                              <div style={{ color: "var(--color-main-blue)" }}>
                                {`- $${usdDiscount.toFixed(2)}`}
                              </div>
                            </FeeBoxRow>
                          </Col>
                        </Tab>
                      )}

                  {/* KRW Payment 탭 */}
                  {isMobile ? (
                    <Tab eventKey="KRW" title="KRW Payment">
                      <MBody style={{ border: "none" }}>
                        <MPageTitle
                          style={{
                            borderBottom: "1px solid lightgray",
                            paddingBottom: "1rem",
                          }}
                        >
                          KRW Payment Details
                        </MPageTitle>
                        <MRow>
                          <div className="label">Product Total</div>
                          <div className="data price">{`₩ ${productTotal.toLocaleString(
                            "ko-KR"
                          )}`}</div>
                        </MRow>
                        <MRow>
                          <div className="label">Domestic Shipping Fee</div>
                          <div className="data price">{`₩ ${domesticShippingFee.toLocaleString(
                            "ko-KR"
                          )}`}</div>
                        </MRow>
                        <MRow>
                          <div className="label">Service Fee</div>
                          <div className="data price">{`₩ ${serviceFeeValue.toLocaleString(
                            "ko-KR"
                          )}`}</div>
                        </MRow>
                        <MRow>
                          <div className="label">Total</div>
                          <div className="data price">{`₩ ${total.toLocaleString(
                            "ko-KR"
                          )}`}</div>
                        </MRow>

                        <InputGroup className="mb-3">
                          <InputGroup.Text>KRW Points</InputGroup.Text>
                          <Form.Control
                            type="number"
                            placeholder="Enter points to apply"
                            value={pointsApplied.toString()}
                            onChange={handleKrwPointsChange}
                            min="0"
                            max={Math.min(
                              total,
                              orderDetails[0]?.user.point || 0
                            )}
                          />
                          <Button
                            variant="outline-secondary"
                            onClick={applyAllKRWPoints}
                          >
                            Apply All Points
                          </Button>
                        </InputGroup>

                        <MRow>
                          <div className="label">Available Points</div>
                          <div className="data ">
                            {orderDetails[0]?.user.point || 0} KRW Points
                          </div>
                        </MRow>
                        <MRow>
                          <div className="label">Remaining Points</div>
                          <div className="data ">
                            {orderDetails[0]?.user.point - pointsApplied} KRW
                            Points
                          </div>
                        </MRow>
                        <MRow>
                          <div className="label">Applied Coupon</div>
                          <div
                            className="data price"
                            style={{ color: "var(--color-main-blue)" }}
                          >{`- ₩${krwDiscount.toLocaleString("ko-KR")}`}</div>
                        </MRow>
                      </MBody>
                    </Tab>
                  ) : (
                    <Tab eventKey="KRW" title="KRW Payment">
                      <Col>
                        <h3
                          style={{
                            borderBottom: "1px solid lightgray",
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                          }}
                        >
                          KRW Payment Details
                        </h3>
                        <Label>Product total</Label>
                        <FeeBoxRow>
                          <div>{`₩ ${productTotal.toLocaleString(
                            "ko-KR"
                          )}`}</div>
                        </FeeBoxRow>
                        <Label>Domestic Shipping Fee</Label>
                        <FeeBoxRow>
                          <div>{`₩ ${domesticShippingFee.toLocaleString(
                            "ko-KR"
                          )}`}</div>
                        </FeeBoxRow>
                        <Label>Service Fee</Label>
                        <FeeBoxRow>
                          <div>{`₩ ${serviceFeeValue.toLocaleString(
                            "ko-KR"
                          )}`}</div>
                        </FeeBoxRow>
                        <Label>Total</Label>
                        <FeeBoxRow>
                          <div>{`₩ ${total.toLocaleString("ko-KR")}`}</div>
                        </FeeBoxRow>
                        <InputGroup className="mb-3">
                          <InputGroup.Text>KRW Points</InputGroup.Text>
                          <Form.Control
                            type="number"
                            placeholder="Enter points to apply"
                            value={pointsApplied.toString()}
                            onChange={handleKrwPointsChange}
                            min="0"
                            max={Math.min(
                              total,
                              orderDetails[0]?.user.point || 0
                            )}
                          />
                          <Button
                            variant="outline-secondary"
                            onClick={applyAllKRWPoints}
                          >
                            Apply All Points
                          </Button>
                          <div style={{ marginTop: "1rem", width: "100%" }}>
                            <Label>Available Points:</Label>
                            <div>
                              {orderDetails[0]?.user.point || 0} KRW Points
                            </div>
                            <Label>Remaining Points:</Label>
                            <div>
                              {orderDetails[0]?.user.point - pointsApplied} KRW
                              Points
                            </div>
                          </div>
                        </InputGroup>
                        <Label>Applied Coupon</Label>
                        <FeeBoxRow>
                          <div
                            style={{ color: "var(--color-main-blue)" }}
                          >{`- ₩${krwDiscount.toLocaleString("ko-KR")}`}</div>
                        </FeeBoxRow>
                      </Col>
                    </Tab>
                  )}
                </Tabs>

                {showPaymentButtons && orderData && orderData.status !== 3 && (
                  <div style={{ marginTop: "1rem" }}>
                    <div style={{ textAlign: "right" }}>
                      {key === "KRW" && (
                        <div>
                          {isMobile ? (
                            <div
                              style={{
                                borderBottom: "1px solid lightgray",
                                paddingBottom: ".5rem",
                              }}
                            >
                              <MButton
                                onClick={handleKRWPayment}
                                style={{
                                  backgroundColor: "var(--color-main-blue)",
                                  color: "var(--color-white)",
                                  marginBottom: "1rem",
                                }}
                              >
                                KRW Payment
                              </MButton>
                            </div>
                          ) : (
                            <Button
                              variant="success"
                              onClick={handleKRWPayment}
                            >
                              KRW Payment
                            </Button>
                          )}
                        </div>
                      )}
                      {key === "USD" && (
                        <div>
                          {isMobile ? (
                            <div
                              style={{
                                borderBottom: "1px solid lightgray",
                                paddingBottom: ".5rem",
                              }}
                            >
                              <MButton
                                onClick={handleUSDPayment}
                                style={{
                                  backgroundColor: "var(--color-main-blue)",
                                  color: "var(--color-white)",
                                  marginBottom: "1rem",
                                }}
                              >
                                USD Payment
                              </MButton>
                            </div>
                          ) : (
                            <Button
                              variant="success"
                              onClick={handleUSDPayment}
                            >
                              USD Payment
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Tab>
            </Tabs>

            <>
              {/* 관리자 메시지 */}
              {isMobile ? (
                <div style={{ marginTop: "1rem" }}>
                  <b>Administrator Messages</b>
                  <div style={{ margin: "0.5rem 0 1rem 0" }}>
                    {orderData?.fancallNumber ||
                      "Administrator message not present."}
                  </div>
                </div>
              ) : (
                <div className="card" style={{ marginTop: "1rem" }}>
                  <div className="card-header">Administrator Messages</div>
                  <div className="card-body">
                    <blockquote className="blockquote mb-0">
                      <p style={{ fontSize: "1rem" }}>
                        {orderData?.fancallNumber ||
                          "Administrator message not present."}
                      </p>
                    </blockquote>
                  </div>
                </div>
              )}

              {/* 계좌 정보 추가 - status가 3일 때 */}
              {orderData?.status === 3 && isMobile ? (
                <div
                  style={{
                    marginTop: "1rem",
                    borderTop: "1px solid lightgray",
                    paddingTop: "1rem",
                  }}
                >
                  <div style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
                    Payment Information
                  </div>
                  <MRow>
                    <div className="label">Bank</div>
                    <div className="data">KEB HANA bank</div>
                  </MRow>
                  <MRow>
                    <div className="label">Account holder</div>
                    <div className="data">KOREAWAVE</div>
                  </MRow>
                  <MRow>
                    <div className="label">Email address</div>
                    <div className="data">khh@koreawave.kr</div>
                  </MRow>
                  <MRow>
                    <div className="label">Bank account number</div>
                    <div className="data">30291001486304</div>
                  </MRow>
                </div>
              ) : orderData?.status === 3 ? (
                <div className="card" style={{ marginTop: "1rem" }}>
                  <div className="card-header">Payment Information</div>
                  <div className="card-body">
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td className="font-bold-pay">Deposit bank:</td>
                          <td>KEB HANA bank</td>
                        </tr>
                        <tr>
                          <td className="font-bold-pay">Account holder:</td>
                          <td>KOREAWAVE</td>
                        </tr>
                        <tr>
                          <td className="font-bold-pay">Email address:</td>
                          <td>khh@koreawave.kr</td>
                        </tr>
                        <tr>
                          <td className="font-bold-pay">
                            Deposit account number:
                          </td>
                          <td>30291001486304</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              ) : null}

              {isMobile ? (
                <div
                  className="payment-deadline-container"
                  style={{ marginTop: "1rem" }}
                >
                  <div>
                    <b>Payment Deadline</b>
                    <div style={{ margin: "0.5rem 0 1rem 0" }}>
                      {formatDate(paymentDeadline)}
                    </div>
                  </div>

                  <div
                    style={{
                      paddingTop: "1rem",
                      borderTop: "1px solid lightgray",
                    }}
                  >
                    {/* {orderData?.status === 2 && (
                    <Button
                      variant="danger"
                      onClick={handleCancelOrderClick}
                      style={{ marginRight: "0.5rem" }}
                    >
                      Cancel
                    </Button>
                  )} */}

                    <MButton
                      onClick={handleCloseOrderClick}
                      style={{
                        backgroundColor: "var(--color-gray)",
                        color: "var(--color-white)",
                      }}
                    >
                      Close
                    </MButton>
                  </div>
                </div>
              ) : (
                <div
                  className="payment-deadline-container"
                  style={{ marginTop: "1rem" }}
                >
                  <InputGroup>
                    <InputGroup.Text>Payment Deadline</InputGroup.Text>
                    <Form.Control
                      type="text"
                      value={formatDate(paymentDeadline)}
                      readOnly
                    />
                  </InputGroup>
                  <div style={{ textAlign: "right", marginTop: "1rem" }}>
                    {/* {orderData?.status === 2 && (
                    <Button
                      variant="danger"
                      onClick={handleCancelOrderClick}
                      style={{ marginRight: "0.5rem" }}
                    >
                      Cancel
                    </Button>
                  )} */}

                    <Button variant="secondary" onClick={handleCloseOrderClick}>
                      Close
                    </Button>
                  </div>
                </div>
              )}
            </>
          </MobileWrapper>
        ) : (
          <>
            <StyledModal.Body>
              <div className="table-responsive mt-3">
                <Table className="table-custom text-center">
                  <thead className="table-light">
                    <tr>
                      <th>NO</th>
                      <th>category</th>
                      <th>Product name</th>
                      <th>Option 1</th>
                      <th>Option 2</th>
                      <th>Quantity</th>
                      <th>Unit Price</th>
                      <th>Shipping Fee</th>
                      <th>status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderDetails.map((detail, index) => (
                      <tr
                        key={index}
                        style={
                          excludeStatuses.includes(detail.status)
                            ? { textDecoration: "line-through" }
                            : undefined
                        }
                      >
                        <th
                          style={
                            detail.isCanceled
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.productId}
                        </th>
                        <td>{CODE_SHOPPING_ORDER_ITEMS[detail.category]}</td>
                        <td
                          style={
                            detail.isCanceled
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.name}
                        </td>
                        <td
                          style={
                            detail.isCanceled
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.option1}
                        </td>
                        <td
                          style={
                            detail.isCanceled
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.option2}
                        </td>
                        <td
                          style={
                            detail.isCanceled
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.quantity}
                        </td>
                        <td
                          style={
                            detail.isCanceled
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.price}
                        </td>
                        <td
                          style={
                            detail.isCanceled
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.ShippingFee}
                        </td>
                        <td
                          style={
                            detail.isCanceled
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {getStatusText(detail.status)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <Tabs
                defaultActiveKey="KRW"
                id="payment-tabs"
                className="mb-3"
                activeKey={key}
                onSelect={handleSelect}
              >
                {orderData && orderData.status !== 3 && (
                  <Tab eventKey="USD" title="USD Payment">
                    <TableContainer>
                      <div className="table-responsive mt-3">
                        <Table className="table-custom text-center">
                          <thead className="table-light">
                            <tr>
                              <th className="font-large-pay">Title</th>
                              <th className="font-large-pay">Amount (USD)</th>
                            </tr>
                          </thead>
                          <tbody className="font-large-Shop">
                            <PaymentRow
                              title="Product total"
                              amount={`$${convertToUSD(productTotal)}`}
                            />
                            <PaymentRow
                              title="Domestic Shipping Fee"
                              amount={`$${convertToUSD(domesticShippingFee)}`}
                            />
                            <PaymentRow
                              title="Service Fee"
                              amount={`$${convertToUSD(serviceFeeValue)}`}
                            />
                            <PaymentRow
                              title="Paypal Fee"
                              amount={`$${convertToUSD(paypalFee)}`}
                            />
                            <PaymentRow
                              title="USD Points Applied"
                              amount={`-$${usdPointsApplied.toFixed(2)}`}
                            />
                            <PaymentRow
                              title="Applied Coupon"
                              amount={`-$${usdDiscount.toFixed(2)}`}
                            />
                            <PaymentRow
                              title="Total"
                              amount={`$${Utotal.toFixed(2)}`}
                            />

                            <tr>
                              <td className="font-large-Shop">
                                Available Points
                              </td>
                              <td className="font-large-Shop">
                                ${orderDetails[0]?.user.pointUsd || 0}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-large-Shop">Apply Points</td>
                              <td>
                                <InputGroup className="mb-3">
                                  <InputGroup.Text>USD Points</InputGroup.Text>
                                  <StyledInput
                                    type="number"
                                    placeholder="Enter USD points to apply"
                                    value={usdPointsApplied.toString()}
                                    onChange={handleUsdPointsChange}
                                    step="0.01"
                                    min="0"
                                    max={Utotal.toFixed(2)}
                                    onKeyPress={(e) => {
                                      if (!/[0-9.]$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    style={{ flex: 1 }}
                                    onWheel={(e) => e.preventDefault()}
                                  />
                                  <Button
                                    variant="outline-secondary"
                                    onClick={applyAllUSDPoints}
                                  >
                                    Apply All USD Points
                                  </Button>
                                </InputGroup>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-large-Shop">
                                Remaining Points
                              </td>
                              <td className="font-large-Shop">
                                $
                                {(
                                  orderDetails[0]?.user.pointUsd -
                                  usdPointsApplied
                                ).toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </TableContainer>
                  </Tab>
                )}
                <Tab eventKey="KRW" title="KRW Payment">
                  <TableContainer>
                    <div className="table-responsive mt-3">
                      <Table className="table-custom text-center">
                        <thead className="table-light">
                          <tr>
                            <th className="font-large-pay">Title</th>
                            <th className="font-large-pay">Amount (KRW)</th>
                          </tr>
                        </thead>
                        <tbody className="font-large-Shop">
                          <PaymentRow
                            title="Product total"
                            amount={`₩${productTotal.toLocaleString()}`}
                          />
                          <PaymentRow
                            title="Domestic Shipping Fee"
                            amount={`₩${domesticShippingFee.toLocaleString()}`}
                          />
                          <PaymentRow
                            title="Service Fee"
                            amount={`₩${serviceFeeValue.toLocaleString()}`}
                          />
                          <PaymentRow
                            title="Points Applied"
                            amount={`-₩${pointsApplied.toLocaleString()}`}
                          />
                          <PaymentRow
                            title="Applied Coupon"
                            amount={`-₩${krwDiscount.toLocaleString()}`}
                          />
                          <PaymentRow
                            title="Total"
                            amount={`₩${total.toLocaleString()}`}
                          />

                          <tr>
                            <td className="font-large-Shop">
                              Available Points
                            </td>
                            <td className="font-large-Shop">
                              ₩{orderDetails[0]?.user.point || 0}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-large-Shop">Apply Points</td>
                            <td>
                              <InputGroup className="mb-3">
                                <InputGroup.Text>Apply Points</InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  placeholder="Enter points to apply"
                                  value={pointsApplied.toString()}
                                  onChange={handleKrwPointsChange}
                                  min="0"
                                  max={Math.min(
                                    total,
                                    orderDetails[0]?.user.point || 0
                                  )}
                                />
                                <Button
                                  variant="outline-secondary"
                                  onClick={applyAllKRWPoints}
                                >
                                  Apply All KRW Points
                                </Button>
                              </InputGroup>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-large-Shop">
                              Remaining Points
                            </td>
                            <td className="font-large-Shop">
                              ₩{orderDetails[0]?.user.point - pointsApplied}
                            </td>
                          </tr>

                          {orderData?.status === 3 && (
                            <>
                              <tr>
                                <td
                                  colSpan={2}
                                  className="font-large-pay"
                                  style={{
                                    backgroundColor: "#f8f9fa",
                                    paddingTop: "1rem",
                                  }}
                                >
                                  Payment Information
                                </td>
                              </tr>
                              <tr>
                                <td className="font-large-Shop">Bank</td>
                                <td className="font-large-Shop">
                                  KEB HANA bank
                                </td>
                              </tr>
                              <tr>
                                <td className="font-large-Shop">
                                  Account holder
                                </td>
                                <td className="font-large-Shop">KOREAWAVE</td>
                              </tr>
                              <tr>
                                <td className="font-large-Shop">
                                  Email address
                                </td>
                                <td className="font-large-Shop">
                                  khh@koreawave.kr
                                </td>
                              </tr>
                              <tr>
                                <td className="font-large-Shop">
                                  Bank account number
                                </td>
                                <td className="font-large-Shop">
                                  30291001486304
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </TableContainer>
                </Tab>
              </Tabs>
              {/* <div className="container-fluid mb-3">
                <Table bordered className="text-center table-dark table-hover">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>KRW</th>
                      <th>USD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Product total</th>
                      <td>{productTotal}</td>
                      <td>${convertToUSD(productTotal)}</td>
                    </tr>
                    <tr>
                      <th>Domestic Shipping Fee</th>
                      <td>{domesticShippingFee}</td>
                      <td>${convertToUSD(domesticShippingFee)}</td>
                    </tr>
                    <tr>
                      <th>Service Fee</th>
                      <td>{serviceFeeValue}</td>
                      <td>${convertToUSD(serviceFeeValue)}</td>
                    </tr>
                    <tr>
                      <th>Paypal Fee</th>
                      <th>-</th>
                      <td>${convertToUSD(paypalFee)}</td>
                    </tr>
                    <tr>
                      <th>Point</th>
                      <td>
                        <div>
                          Available Points: {orderDetails[0]?.user.point || 0}
                        </div>
                        <InputGroup className="mb-3">
                          <InputGroup.Text>Apply Points</InputGroup.Text>
                          <Form.Control
                            type="number"
                            placeholder="Enter points to apply"
                            value={pointsApplied}
                            onChange={handleKrwPointsChange}
                          />
                          <Button
                            variant="outline-secondary"
                            onClick={applyAllKRWPoints}
                          >
                            Apply All Points
                          </Button>
                        </InputGroup>
                        <div>
                          Remaining Points:{" "}
                          {orderDetails[0]?.user.point - pointsApplied}
                        </div>
                      </td>
                      <td>
                        <div>
                          Available USD Points:{" "}
                          {orderDetails[0]?.user.pointUsd || 0}
                        </div>
                        <InputGroup className="mb-3">
                          <InputGroup.Text>Apply USD Points</InputGroup.Text>
                          <Form.Control
                            type="number"
                            placeholder="Enter USD points to apply"
                            value={usdPointsApplied}
                            onChange={handleUsdPointsChange}
                          />
                          <Button
                            variant="outline-secondary"
                            onClick={applyAllUSDPoints}
                          >
                            Apply All USD Points
                          </Button>
                        </InputGroup>
                        <div>
                          Remaining USD Points:{" "}
                          {orderDetails[0]?.user.pointUsd - usdPointsApplied}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Applied Coupon</th>
                      <td
                        style={{ color: "var(--color-main-blue)" }}
                      >{`- ₩${krwDiscount.toLocaleString("ko-KR")}`}</td>
                      <td style={{ color: "var(--color-main-blue)" }}>
                        {`- $${usdDiscount.toFixed(2)}`}
                      </td>
                    </tr>

                    <tr>
                      <th>Total</th>
                      <td>₩{total.toLocaleString("ko-KR")}</td>
                      <td>${Utotal.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </Table>
              </div> */}

              {/* 사용 가능 쿠폰 리스트 모달 버튼 */}
              {availableCouponList && availableCouponList.length > 0 && (
                <CouponViewButtonRow onClick={() => setCouponModal(true)}>
                  See my coupons
                  <Icon
                    icon="caret-right-fill"
                    color="var(--color-white)"
                    fontSize="1.4rem"
                  />
                </CouponViewButtonRow>
              )}
            </StyledModal.Body>
            <div className="manager-message mb-3">
              <Card>
                <Card.Header className="card-custom-header">
                  Message
                </Card.Header>
                <Card.Body>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Thank you!"
                    defaultValue={orderData?.fancallNumber}
                    readOnly
                  />
                </Card.Body>
              </Card>
            </div>
            <div className="w-100 mb-3">
              <InputGroup>
                <InputGroup.Text className="input-group-text-custom">
                  Payment Deadline
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={formatDate(paymentDeadline)}
                  readOnly
                />
              </InputGroup>
            </div>
          </>
        )}

        {!isMobile && (
          <StyledModal.Footer>
            {showPaymentButtons && (
              <>
                {key === "KRW" && orderData && orderData.status !== 3 && (
                  <Button variant="success" onClick={handleKRWPayment}>
                    KRW
                  </Button>
                )}
                {key === "USD" && orderData && orderData.status !== 3 && (
                  <Button variant="success" onClick={handleUSDPayment}>
                    USD
                  </Button>
                )}
              </>
            )}
            {/* {orderData?.status === 2 && (
              <Button variant="danger" onClick={handleCancelOrderClick}>
                Cancel
              </Button>
            )} */}
            <Button variant="secondary" onClick={handleCloseOrderClick}>
              Close
            </Button>
          </StyledModal.Footer>
        )}
      </StyledModal>

      {/* KRW 결제 모달 */}
      <Modal show={showKRWPModal} onHide={closeKRWPModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>KRW Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderData && (
            <div>
              <div>
                <h5>Cancellation Type</h5>
                <Form.Check
                  className="check-shop-pay"
                  type="radio"
                  label="Total Cancellation"
                  name="cancellationTypeGroup"
                  id="totalCancellation"
                  onChange={() => setFailed(true)}
                  checked={failed === true}
                />
                <Form.Check
                  className="check-shop-pay"
                  type="radio"
                  label="Partial Cancellation"
                  name="cancellationTypeGroup"
                  id="partialCancellation"
                  onChange={() => setFailed(false)}
                  checked={failed === false}
                />
              </div>
              <hr />
              <div className="mb-3">
                <h5>Refund methods</h5>
                <Form.Check
                  className="check-shop-pay"
                  type="radio"
                  label="Regular refund"
                  name="refundMethodGroup"
                  id="refundMethodRegular"
                  onChange={() => handleRefundMethodChange(true)}
                  checked={refundMethod === true}
                />
                <Form.Check
                  className="check-shop-pay"
                  type="radio"
                  label="Refund with points (+5% extra point)"
                  name="refundMethodGroup"
                  id="refundMethodPoints"
                  onChange={() => handleRefundMethodChange(false)}
                  checked={refundMethod === false}
                />
              </div>
              {refundMethod === true && (
                <div className="mb-3">
                  <h5 className="mb-2">Banking Details</h5>
                  <Form.Group className="mb-3">
                    <Form.Label className="check-shop-pay-Bank">
                      Bank *
                    </Form.Label>
                    <Form.Select
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    >
                      <option value="">Select a bank</option>
                      <option value="KB Kookmin Bank">KB Kookmin Bank</option>
                      <option value="Hana Bank">Hana Bank</option>
                      <option value="Shinhan Bank">Shinhan Bank</option>
                      <option value="Woori Bank">Woori Bank</option>
                      <option value="Industrial Bank of Korea(IBK)">
                        Industrial Bank of Korea(IBK)
                      </option>
                      <option value="Nonghyup Bank">Nonghyup Bank</option>
                      <option value="Korea Development Bank(KDB)">
                        Korea Development Bank(KDB)
                      </option>
                      <option value="Standard Chartered Bank Korea(SCB)">
                        Standard Chartered Bank Korea(SCB)
                      </option>
                      <option value="Busan Bank">Busan Bank</option>
                      <option value="Daegu Bank">Daegu Bank</option>
                      <option value="K Bank">K Bank</option>
                      <option value="Kakao Bank">Kakao Bank</option>
                      <option value="Toss Bank">Toss Bank</option>
                      <option value="Sinhyup Bank">Sinhyup Bank</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="check-shop-pay-Bank">
                      Account Holder *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter account holder"
                      value={accountHolder}
                      onChange={(e) => setAccountHolder(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="check-shop-pay-Bank">
                      Account Number *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter account number"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </Form.Group>
                </div>
              )}
              <hr />
              <Form.Group className="mb-3">
                <Form.Label className="check-shop-pay-Bank">
                  Depositor Name *
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter depositor name"
                  value={depositorName}
                  onChange={(e) => setDepositorName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="check-shop-pay-Bank">
                  Amount of Deposit *
                </Form.Label>
                <StyledInput
                  placeholder="Enter deposit amount"
                  value={depositAmount}
                  onChange={handleDepositAmountChange}
                />
                <hr />
                <Form>
                  <Table responsive>
                    <tbody>
                      <tr>
                        <td>
                          <strong className="font-bold-pay">Total :</strong>{" "}
                          {total.toLocaleString("ko-KR")}₩
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold-pay">
                          Deposit bank: KEB HANA bank
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold-pay">
                          Account holder: KOREAWAVE
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold-pay">
                          Email address: khh@koreawave.kr
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold-pay">
                          Deposit account number: 30291001486304
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="text-danger fw-bold">NOTICE</div>
                  <p className="check-shop-pay">
                    KRW deposit will remain pending until confirmed by Ontact
                    Korea staff. Please standby.
                  </p>
                </Form>
              </Form.Group>

              <PayCol>
                <Form.Group className="mb-3">
                  <h5>Cancellation and refund regulations</h5>
                  <div className="f-row">
                    <Form.Check
                      className="font-bold-pay"
                      type="checkbox"
                      name="agree"
                      checked={agreeCheck === true}
                      onChange={() => setAgreeCheck(!agreeCheck)}
                      style={{ marginRight: ".4rem" }}
                    />
                    <div style={{ marginRight: ".4rem" }}>I agree to the</div>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://docs.google.com/document/d/1SuZi6RC7hWQG9aXqoRLTO2HbZuH0wJ6uondJEFs5Z6s/edit"
                    >
                      <div className="text-link">terms and conditions.</div>
                    </a>
                  </div>
                </Form.Group>
              </PayCol>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeKRWPModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handlePaymentSubmit("KRW")}
            disabled={!agreeCheck}
          >
            Submit Payment
          </Button>
        </Modal.Footer>
      </Modal>

      {/* USD 결제 모달 */}
      <Modal show={showUSDModal} onHide={closeUSDModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>USD Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderData && (
            <div>
              <PayCol>
                <h5>Total</h5>
                <SpaceRow>
                  <label className="check-shop-pay">Order Total</label>
                  <p className="order-total">{Utotal.toFixed(2)} USD</p>
                </SpaceRow>
              </PayCol>

              <PayCol>
                <h5>Cancellation Type</h5>
                <Form.Check
                  className="check-shop-pay"
                  type="radio"
                  label="Total Cancellation"
                  name="cancellationTypeGroup"
                  id="totalCancellation"
                  onChange={() => setFailed(true)}
                  checked={failed === true}
                />
                <Form.Check
                  className="check-shop-pay"
                  type="radio"
                  label="Partial Cancellation"
                  name="cancellationTypeGroup"
                  id="partialCancellation"
                  onChange={() => setFailed(false)}
                  checked={failed === false}
                />
              </PayCol>

              <PayCol>
                <h5>Refund methods</h5>
                <Form.Check
                  className="check-shop-pay"
                  type="radio"
                  label="Regular refund"
                  name="refundMethodGroup"
                  id="refundMethodRegular"
                  onChange={() => handleRefundMethodChange(true)}
                  checked={refundMethod === true}
                />
                <Form.Check
                  className="check-shop-pay"
                  type="radio"
                  label="Refund with points (+5% extra point)"
                  name="refundMethodGroup"
                  id="refundMethodPoints"
                  onChange={() => handleRefundMethodChange(false)}
                  checked={refundMethod === false}
                />
              </PayCol>
              {/* {refundMethod === true && (
                <PayCol>
                  <h5>Banking Details</h5>
                  <Form.Group className="mb-3">
                    <Form.Label>Bank *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter bank name"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Account Holder *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter account holder"
                      value={accountHolder}
                      onChange={(e) => setAccountHolder(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Account Number *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter account number"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </Form.Group>
                </PayCol>
              )}
              <hr /> */}

              {isMobile ? (
                <PayCol>
                  <h5>Payment Method</h5>
                  <MobilePaymentSection>
                    <div>
                      <img src="/resources/img/PayPal.png" alt="Paypal" />
                    </div>
                    <div>
                      <img src="/resources/img/VLSA.png" alt="Credit Card" />
                    </div>
                    <div>
                      <img
                        src="/resources/img/express.png"
                        className="express-image"
                        alt="EXPRESS"
                      />
                    </div>
                  </MobilePaymentSection>
                </PayCol>
              ) : (
                <PayCol>
                  <h5>Payment Method</h5>
                  <PaymentMethodSection>
                    <div className="payment-card">
                      <img
                        className="paypal"
                        src="/resources/img/PayPal.png"
                        alt="Paypal"
                      />
                    </div>
                    <div className="payment-card">
                      <img
                        className="visa"
                        src="/resources/img/VLSA.png"
                        alt="Credit Card"
                      />
                    </div>
                    <div className="payment-card">
                      <img
                        src="/resources/img/express.png"
                        className="amex"
                        alt="EXPRESS"
                      />
                    </div>
                  </PaymentMethodSection>
                </PayCol>
              )}

              <PayCol>
                <Form.Group className="mb-3">
                  <h5>Cancellation and refund regulations</h5>
                  <div className="f-row">
                    <Form.Check
                      className="check-shop-pay"
                      type="checkbox"
                      name="agree"
                      checked={agreeCheck === true}
                      onChange={() => setAgreeCheck(!agreeCheck)}
                      style={{ marginRight: ".4rem" }}
                    />
                    <div style={{ marginRight: ".4rem" }}>I agree to the</div>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://docs.google.com/document/d/1SuZi6RC7hWQG9aXqoRLTO2HbZuH0wJ6uondJEFs5Z6s/edit"
                    >
                      <div className="text-link">terms and conditions.</div>
                    </a>
                  </div>
                </Form.Group>
              </PayCol>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeUSDModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handlePaymentSubmit("USD")}
            disabled={!agreeCheck}
          >
            Submit Payment
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 사용 가능한 쿠폰 리스트 모달 */}
      <Modal
        onEnter={() => setCouponApplied(false)}
        show={couponModal}
        onHide={() => {
          setCouponModal(false);
          setCouponApplied(false);
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>My Available Coupons</Modal.Title>
        </Modal.Header>

        <CouponListWrapper>
          <Label>Please select a coupon to apply.</Label>
          <P> Duplicate use is not possible.</P>

          {/* <DiscountPriceBlock>
            <DiscountPriceRow style={{ borderBottom: " 1px solid lightgray" }}>
              <Label>Before</Label>
              <Label>{`${total.toLocaleString("ko-KR")}₩`}</Label>
              <Label>{`${convertToUSD(Utotal)}$`}</Label>
            </DiscountPriceRow>
            <DiscountPriceRow>
              <Label>After</Label>
              {krwDiscount === 0 ? (
                <Label>{`${total.toLocaleString("ko-KR")}₩`}</Label>
              ) : (
                <Label className="after-price">{`${(
                  total - krwDiscount
                ).toLocaleString("ko-KR")}₩`}</Label>
              )}

              {usdDiscount === 0 ? (
                <Label>{`${convertToUSD(Utotal)}$`}</Label>
              ) : (
                <Label className="after-price">{`${(
                  parseFloat(convertToUSD(Utotal)) - usdDiscount
                ).toFixed(2)}$`}</Label>
              )}
            </DiscountPriceRow>
          </DiscountPriceBlock> */}

          <CouponList
            krwInput={total}
            usdInput={Utotal}
            couponList={availableCouponList}
            setKrwDiscount={setKrwDiscount}
            setUsdDiscount={setUsdDiscount}
            couponApplied={setCouponApplied}
            setSelectedCouponId={setSelectedCouponId}
          />
        </CouponListWrapper>
      </Modal>
    </div>
  );
};
export default CShopOrderModal;
//! Payment Start
const SpaceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .order-total {
    color: var(--color-main-blue);
    font-weight: bold;
  }
`;
//! Payment End
const DiscountPriceBlock = styled.div`
  border: 1px solid lightgray;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;
const DiscountPriceRow = styled.div`
  display: flex;
  flex-direction: row;
  & > label {
    flex: 1 1 0;
    text-align: center;
    margin: 0.4rem 0;
    align-items: center;
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      border-right: 1px solid lightgray;
    }
  }
  .after-price {
    color: var(--color-main-blue);
  }
`;

const CouponViewButtonRow = styled.div`
  display: flex;
  background-color: var(--color-main-blue);
  border-radius: 0.4rem;
  padding: 1rem;
  color: var(--color-white);
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;
const CouponListWrapper = styled.div`
  padding: 1rem;
`;

const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;

const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
  padding: 1rem;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0;
  margin: 0.4rem 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0 0 0.4rem 0;
`;
const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 50%;

    @media (max-width: 768px) {
      max-width: 95%;
    }
  }
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;

  th,
  td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
    th,
    td {
      padding: 6px;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.7em;
    th,
    td {
      padding: 4px;
    }
  }
`;

export const PaymentMethodSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .payment-card {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 5px;
    transition: border-color 0.3s;
    flex: 1 1 0;
    width: 100%;

    .paypal,
    .visa {
      padding: 1rem;
      width: 30%;
    }

    .amex {
      width: 10%;
    }
  }
`;

const StyledInput = styled(Form.Control)`
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PayCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  gap: 0.4rem;
  border-bottom: 1px solid lightgray;
`;

const TableContainer = styled.div`
  padding: 20px;
`;

const PaymentRow: React.FC<PaymentRowProps> = ({ title, amount }) => (
  <tr>
    <td className="font-large-Shop">{title}</td>
    <td className="font-large-Shop">{amount}</td>
  </tr>
);
const NoButton = styled.div`
  padding: 0.5rem;
  font-weight: bold;
`;

const ResponsiveTables = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  background-color: #ffffff; /* Pure white background for the table */
  color: #333333; /* Darker text color for better readability */
  border-color: #e0e0e0; /* Very light gray border color for a softer look */

  th,
  td {
    padding: 12px 15px; /* Adjusted padding for better spacing */
    text-align: center;
    vertical-align: middle;
    border: 1px solid #f0f0f0; /* Very light gray borders for table cells */
  }

  th {
    background-color: #f5f5f5; /* Light gray for a subtle, modern header color */
    color: #333333; /* Dark gray text color for headers for better contrast */
    border-bottom: 1px solid #e0e0e0; /* Light gray for the bottom border of headers */
  }

  tbody tr {
    background-color: #ffffff;
    transition: background-color 0.3s ease; /* Smooth transition for hover effects */
  }

  tbody tr:hover {
    background-color: #f9f9f9; /* Very light gray background on hover for better interaction visibility */
    cursor: pointer;
  }

  .table-hover tbody tr:hover {
    background-color: rgba(
      240,
      240,
      240,
      0.5
    ); /* Very subtle gray highlight effect on hover */
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fafafa; /* Slightly different gray for striped rows to maintain visibility */
  }

  @media (max-width: 768px) {
    font-size: 0.9em; /* Slightly larger font size for better readability on small devices */

    th,
    td {
      padding: 10px; /* Slightly reduced padding at this breakpoint */
    }
  }

  @media (max-width: 480px) {
    font-size: 0.8em; /* Smaller font size for very small devices */

    th,
    td {
      padding: 8px; /* Reduced padding for very small screens */
    }
  }
`;
