import { useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import Swal from "sweetalert2";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { reqGetAllUserById, reqUpdateUserById } from "../../../requests/user";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

interface User {
  id: string;
  name: string;
  latestWarehouseId: string;
}

interface Product {
  id: number;
  userId: string;
  status: number;
  warehouseId: string;
}

export default function Awarehouse() {
  const [activeFloor, setActiveFloor] = useState("1F");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUserId, setNewUserId] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const floors = ["1F", "2F", "3F"];

  const columns = Array.from({ length: 26 }, (_, i) => {
    const char = String.fromCharCode(i + 65);
    return char !== "L" && char !== "O" ? char : null;
  }).filter(Boolean);

  const rows = Array.from({ length: 15 }, (_, i) => i + 1);

  const fetchAllUsers = async () => {
    try {
      const response = await reqGetAllUserById();
      setUsers(response.data);
    } catch (error) {
      console.error("전체 사용자 정보 조회 실패:", error);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const handleFloorChange = (floor: string) => {
    setActiveFloor(floor);
  };

  const getLocationButtonStyle = (locationId: string) => {
    const userAtLocation = users.some(
      (user) => user.latestWarehouseId === locationId
    );
    return userAtLocation ? "btn-success" : "btn-secondary";
  };

  const handleLocationClick = (locationId: string) => {
    setSelectedLocationId(locationId);
    const assignedUsers = users
      .filter((user) => user.latestWarehouseId === locationId)
      .map((user) => user.id);
    setSelectedUsers(assignedUsers);
    setIsModalOpen(true);
  };

  const handleSubmit = async () => {
    const updatedSelectedUsers = newUserId
      ? [...selectedUsers, newUserId]
      : selectedUsers;

    if (!selectedLocationId || updatedSelectedUsers.length === 0) {
      alert("창고 위치와 사용자를 선택해주세요.");
      return;
    }

    try {
      // 확인 대화상자 표시
      const willUpdate = await Swal.fire({
        title: "창고 위치 업데이트",
        text: `선택한 사용자들의 창고 위치를 '${selectedLocationId}'로 업데이트하시겠습니까?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "네, 업데이트합니다",
        cancelButtonText: "취소",
      });

      // 확인 버튼을 클릭한 경우에만 처리
      if (willUpdate.isConfirmed) {
        await Promise.all(
          updatedSelectedUsers.map((userId) =>
            reqUpdateUserById({
              id: userId,
              latestWarehouseId: selectedLocationId,
            })
          )
        );

        // 성공 메시지 표시
        Swal.fire({
          title: "업데이트 완료!",
          text: `위치 ${selectedLocationId}에 사용자를 업데이트했습니다.`,
          icon: "success",
          confirmButtonText: "확인",
        });

        setIsModalOpen(false);
        setNewUserId("");
        fetchAllUsers();
      }
    } catch (error) {
      console.error("사용자 위치 업데이트 실패:", error);
      Swal.fire({
        title: "오류 발생!",
        text: "사용자 위치 업데이트에 실패했습니다.",
        icon: "error",
        confirmButtonText: "확인",
      });
    }
  };

  const resetUserWarehouseLocationsIfNeeded = async () => {
    try {
      const willReset = await Swal.fire({
        title: "창고 위치 초기화",
        text: "모든 사용자의 창고 위치를 초기화하시겠습니까? 이 작업은 되돌릴 수 없습니다.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "네, 초기화합니다",
        cancelButtonText: "취소",
      });

      if (willReset.isConfirmed) {
        const promises = users.map((user) =>
          reqUpdateUserById({
            id: user.id,
            latestWarehouseId: "창고위치없음",
          })
        );

        await Promise.all(promises);

        Swal.fire(
          "초기화 완료!",
          "모든 사용자의 창고 위치가 초기화되었습니다.",
          "success"
        );

        fetchAllUsers();
      }
    } catch (error) {
      console.error("창고 위치 초기화 중 오류 발생:", error);
      Swal.fire({
        title: "오류 발생!",
        text: "창고 위치 초기화 중 오류가 발생했습니다.",
        icon: "error",
        confirmButtonText: "확인",
      });
    }
  };

  // 띄어쓰기
  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <>
      <main id="main" className="main">
        <div>
          <AdminHeadTitle
            subtitle1="배송 운임 관리"
            subtitle2="저장 위치 관리"
            title="저장 위치 관리"
          />
          <div className="container-fluid px-2">
            <button onClick={resetUserWarehouseLocationsIfNeeded}>
              창고 위치 초기화
            </button>
            <h3 className="mt-4">창고관리 - 저장 위치 관리</h3>
            <FloorButtonContainer>
              {floors.map((floor) => (
                <button
                  key={floor}
                  className={`btn ${
                    floor === activeFloor ? "btn-primary" : "btn-secondary"
                  }`}
                  onClick={() => handleFloorChange(floor)}
                >
                  {floor}
                </button>
              ))}
            </FloorButtonContainer>
            <div className="scrollable-table">
              <TableContainer>
                <StyledTable className="table text-center table-sm table-hover">
                  <thead>
                    <tr className="bg-light text-dark">
                      <th>#</th>
                      {columns.map((column) => (
                        <th key={column}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row) => (
                      <tr key={row}>
                        <th scope="row">{row}</th>
                        {columns.map((column) => {
                          const locationId = `${activeFloor}-${column}${row}`;
                          return (
                            <td key={column}>
                              <button
                                type="button"
                                className={`btn position-relative btn-sm ${getLocationButtonStyle(
                                  locationId
                                )}`}
                                onClick={() => handleLocationClick(locationId)}
                              >
                                {column}
                                {row}
                              </button>
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </StyledTable>
              </TableContainer>
            </div>
          </div>
        </div>
      </main>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
      >
        <h2>창고 위치 업데이트</h2>
        <select
          multiple
          value={selectedUsers}
          onChange={(e) => {
            const options = e.target.options;
            let value = [];
            for (let i = 0, l = options.length; i < l; i++) {
              if (options[i].selected) {
                value.push(options[i].value);
              }
            }
            setSelectedUsers(value);
          }}
          style={{ width: "100%", minHeight: "100px", marginBottom: "10px" }}
        >
          {users
            .filter((user) => selectedUsers.includes(user.id))
            .map((user) => (
              <option key={user.id} value={user.id}>
                {user.id} - {user.name}
              </option>
            ))}
        </select>
        <input
          type="text"
          placeholder="새 사용자 ID 추가"
          value={newUserId.toUpperCase()}
          onKeyPress={handleKeyPress}
          onChange={(e) => setNewUserId(e.target.value)}
          style={{ width: "100%", marginBottom: "10px" }}
        />
        <div>
          <button onClick={handleSubmit}>업데이트</button>
          <button onClick={() => setIsModalOpen(false)}>취소</button>
        </div>
      </Modal>
    </>
  );
}

const FloorButtonContainer = styled.div`
  margin-bottom: 2rem;
  button {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledTable = styled.table`
  width: 100%;
  th,
  td {
    text-align: center;
    padding: 4px;
  }
  th:first-child,
  td:first-child {
    padding: 4px 2px;
    width: 75px;
  }
  button {
    width: 50%;
    height: 50%;
    padding: 2px 8px;
    box-sizing: border-box;
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;
