import { Provider } from "react-redux";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AdminLayout from "./components/layouts/AdminLayout";
import CustomerLayout from "./components/layouts/CustomerLayout";
import MainLayout from "./components/layouts/MainLayout";
import RequireAuth from "./redux/RequireAuth";
import { store } from "./redux/store";
import Ainvoice from "./views/Admin/admin/Ainvoice";
import Ashipmentfee from "./views/Admin/admin/Ashipmentfee";
import NotFound from "./views/Landing/NotFound";

import GlobalModal from "./components/modal/GlobalModal";
import ADashboard from "./views/Admin/ADashboard";
import ACalculator from "./views/Admin/admin/ACalculator";
import AShipBoxing from "./views/Admin/ship/AShipBoxing";
import AShipDetails from "./views/Admin/ship/AShipDetails";
import AShipExpired from "./views/Admin/ship/AShipExpired";
import AShipOrder from "./views/Admin/ship/AShipOrder";
import AShipPaid from "./views/Admin/ship/AShipPaid";
import AShipPay from "./views/Admin/ship/AShipPay";
import AShipShipment from "./views/Admin/ship/AShipShipment";
import AShopDetails from "./views/Admin/shop/AShopDetails";
import AShopOrder from "./views/Admin/shop/AShopOrder";
import AShopPaid from "./views/Admin/shop/AShopPaid";
import AShopPaypal from "./views/Admin/shop/AShopPaypal";
import AShopWise from "./views/Admin/shop/AShopWise";
import CDashBoard from "./views/Customer/CDashBoard";
import CShipOrder from "./views/Customer/Ship/CShipOrder";
import CShipSendOrder from "./views/Customer/Ship/CShipSendOrder";
import CWarehouse from "./views/Customer/Warehouse/CWarehouse";
import CWarehouseModify from "./views/Customer/Warehouse/CWarehouseModify";
import SignIn from "./views/Landing/SignIn";
import SignUp from "./views/Landing/SignUp";

import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import CMobileNotice from "./components/CMobileNotice";
import CQuestionPage from "./components/CQuestionPage";
import BlankLayout from "./components/layouts/BlankLayout";
import ALogin from "./views/Admin/ALogin";
import ARegister from "./views/Admin/ARegister";
import ACustomerCare from "./views/Admin/admin/ACustomerCare";
import ACustomrSpace from "./views/Admin/admin/ACustomrSpace";
import Amemberships from "./views/Admin/admin/Amemberships";
import Awarehouse from "./views/Admin/admin/Awarehouse";
import Anewuser from "./views/Admin/analysis/Anewuser";
import CustomerDetail from "./views/Admin/analysis/CustomerDetail";
import DailyRevenueStatistics from "./views/Admin/analysis/DailyRevenueStatistics";
import ShipStatistics from "./views/Admin/analysis/ShipStatistics";
import ShopStatistics from "./views/Admin/analysis/ShopStatistics";
import Acoupon from "./views/Admin/customer/Acoupon";
import Alist from "./views/Admin/customer/Alist";
import Amembership from "./views/Admin/customer/Amembership";
import Apoint from "./views/Admin/customer/Apoint";
import AEvent from "./views/Admin/service/AEvent";
import ANotice from "./views/Admin/service/ANotice";
import AReview from "./views/Admin/service/AReview";
import Aqna from "./views/Admin/service/Aqna";
import AShopCancel from "./views/Admin/shop/AShopCancel";
import ASplit from "./views/Admin/warehouse/ASplit";
import AWAll from "./views/Admin/warehouse/AWAll";
import AWBoxReleased from "./views/Admin/warehouse/AWBoxReleased";
import AWBuylnbound from "./views/Admin/warehouse/AWBuylnbound";
import AWCheckContent from "./views/Admin/warehouse/AWCheckContent";
import AWExpired from "./views/Admin/warehouse/AWExpired";
import AWFindLost from "./views/Admin/warehouse/AWFindLost";
import AWHistory from "./views/Admin/warehouse/AWHistory";
import AWInbound from "./views/Admin/warehouse/AWInbound";
import AWPosition from "./views/Admin/warehouse/AWPosition";
import CMembership from "./views/Customer/CMembership";
import CPaypalCallback from "./views/Customer/CPaypalResult";
import CReview from "./views/Customer/CReview";
import CSettings from "./views/Customer/CSettings";
import CShipGuide from "./views/Customer/CShipGuide";
import CShopGuide from "./views/Customer/CShopGuide";
import CStorageGuide from "./views/Customer/CStorageGuide";
import CSupportForm from "./views/Customer/CSupportForm";
import Caculator from "./views/Customer/Caculator";
import EventCoupon from "./views/Customer/EventCoupon";
import CShipDetails from "./views/Customer/Ship/CShipDetails";
import CShipPay from "./views/Customer/Ship/CShipPay";
import CShopDetails from "./views/Customer/Shop/CShopDetails";
import CShopOrder from "./views/Customer/Shop/CShopOrder";
import CShopPaid from "./views/Customer/Shop/CShopPaid";
import CShopPay from "./views/Customer/Shop/CShopPay";
import CShopSendorder from "./views/Customer/Shop/CShopSendorder";
import CCheckcontents from "./views/Customer/Warehouse/CCheckcontents";
import CLost from "./views/Customer/Warehouse/CLost";
import CSplit from "./views/Customer/Warehouse/CSplit";
import Cevent from "./views/Customer/service/Cevent";
import Index from "./views/Landing/0618_renew/Index";
import Pricing from "./views/Landing/0618_renew/Pricing";
import Service from "./views/Landing/0618_renew/Service";
import PasswordResetPage from "./views/Landing/PasswordResetPage";
import InventorySurvey from "./views/Admin/warehouse/AInventorySurvey";
import FindTableDataPage from "./views/Admin/admin/FindTableDataPage";
import AWHistorys from "./views/Admin/warehouse/AWHistorys";
const gaTrackingId: string = process.env.REACT_APP_GA_TRACKING_ID as string;
ReactGA.initialize(gaTrackingId);

const history = createBrowserHistory();
history.listen((response) => {
  console.log(response.location.pathname);
  ReactGA.set({ page: response.location.pathname });
  ReactGA.pageview(response.location.pathname);
});

const router = createBrowserRouter([
  {
    path: "",
    element: <BlankLayout />,
    children: [
      {
        path: "", // landing
        element: <MainLayout />,
        children: [
          {
            path: "", // index
            element: <Index />,
          },
          {
            path: "service", // service
            element: <Service />,
          },
          {
            path: "price", // price
            element: <Pricing />,
          },
          {
            path: "login", // login
            element: <SignIn />,
          },
          {
            path: "signup", // signup
            element: <SignUp />,
          },
          {
            path: "reset", // signup
            element: <PasswordResetPage />,
          },
          // 쿠폰 증정 링크
          {
            path: "event-coupon/:hashCode",
            element: <EventCoupon />,
          },
        ],
      },
      {
        path: "admin", // admin
        element: <AdminLayout />,
        children: [
          {
            path: "", // dashboard
            element: (
              <RequireAuth>
                <ADashboard />
              </RequireAuth>
            ),
          },
          {
            path: "login",
            element: <ALogin />,
          },
          {
            path: "register",
            element: (
              <RequireAuth>
                <ARegister />
              </RequireAuth>
            ),
          },
          {
            path: "invoice", // 최고관리자-배송대행견적서
            element: (
              <RequireAuth>
                <Ainvoice />
              </RequireAuth>
            ),
          },
          {
            path: "shipmentfee", // 최고관리자-배송운임설정
            element: (
              <RequireAuth>
                <Ashipmentfee />
              </RequireAuth>
            ),
          },
          {
            path: "warehouse", // 최고관리자-배송운임설정
            element: (
              <RequireAuth>
                <Awarehouse />
              </RequireAuth>
            ),
          },
          {
            path: "care", // 최고 관리자 고객 관리
            element: (
              <RequireAuth>
                <ACustomerCare />
              </RequireAuth>
            ),
          },
          {
            path: "space", // 최고 관리자 고객 관리
            element: (
              <RequireAuth>
                <ACustomrSpace />
              </RequireAuth>
            ),
          },
          {
            path: "Calculator", // 계산기
            element: (
              <RequireAuth>
                <FindTableDataPage />
              </RequireAuth>
            ),
          },
          {
            path: "memberships", // 멤버쉽관리
            element: (
              <RequireAuth>
                <Amemberships />
              </RequireAuth>
            ),
          },
          {
            path: "shop", // 구매대행서비스
            children: [
              {
                path: "order", // 구매대행요청서
                element: (
                  <RequireAuth>
                    <AShopOrder />
                  </RequireAuth>
                ),
              },
              {
                path: "pay/wise", // 구매대행결제대기중(wise)
                element: (
                  <RequireAuth>
                    <AShopWise />
                  </RequireAuth>
                ),
              },
              {
                path: "pay/paypal", // 구매대행결제대기중(paypal)
                element: (
                  <RequireAuth>
                    <AShopPaypal />
                  </RequireAuth>
                ),
              },
              {
                path: "paid", // 구매대행결제완료
                element: (
                  <RequireAuth>
                    <AShopPaid />
                  </RequireAuth>
                ),
              },
              {
                path: "details", // 구매대행거래내역
                element: (
                  <RequireAuth>
                    <AShopDetails />
                  </RequireAuth>
                ),
              },
              {
                path: "expired", // 구매대행취소내역
                element: (
                  <RequireAuth>
                    <AShopCancel />
                  </RequireAuth>
                ),
              },
            ],
          },
          {
            path: "ship", // 배송대행서비스
            children: [
              {
                path: "order", // 배송대행요청서
                element: (
                  <RequireAuth>
                    <AShipOrder />
                  </RequireAuth>
                ),
              },
              {
                path: "boxing", // 상품포장중
                element: (
                  <RequireAuth>
                    <AShipBoxing />
                  </RequireAuth>
                ),
              },
              {
                path: "pay", // 배송대행결제대기중
                element: (
                  <RequireAuth>
                    <AShipPay />
                  </RequireAuth>
                ),
              },
              {
                path: "paid", // 배송대행결제완료
                element: (
                  <RequireAuth>
                    <AShipPaid />
                  </RequireAuth>
                ),
              },
              {
                path: "expired", // 배송대행결제기한만료
                element: (
                  <RequireAuth>
                    <AShipExpired />
                  </RequireAuth>
                ),
              },
              {
                path: "details", // 배송대행거래내역
                element: (
                  <RequireAuth>
                    <AShipDetails />
                  </RequireAuth>
                ),
              },
              {
                path: "shipment", // 특송사배송상태관리
                element: (
                  <RequireAuth>
                    <AShipShipment />
                  </RequireAuth>
                ),
              },
            ],
          },
          {
            path: "warehouse", // 창고관리
            children: [
              {
                path: "inbound", // 입고등록
                element: (
                  <RequireAuth>
                    <AWInbound />
                  </RequireAuth>
                ),
              },
              {
                path: "Buyinbound", // 입고등록
                element: (
                  <RequireAuth>
                    <AWBuylnbound />
                  </RequireAuth>
                ),
              },
              {
                path: "history", // 입고기록
                element: (
                  <RequireAuth>
                    <AWHistory />
                  </RequireAuth>
                ),
              },
              {
                path: "historys", // 입고기록(신규)
                element: (
                  <RequireAuth>
                    <AWHistorys />
                  </RequireAuth>
                ),
              },
              {
                path: "checkcontents", // 체크컨텐츠요청내역
                element: (
                  <RequireAuth>
                    <AWCheckContent />
                  </RequireAuth>
                ),
              },
              {
                path: "findlost", // 분실물색출요청내역
                element: (
                  <RequireAuth>
                    <AWFindLost />
                  </RequireAuth>
                ),
              },
              {
                path: "expired", // 무료기한만료상품
                element: (
                  <RequireAuth>
                    <AWExpired />
                  </RequireAuth>
                ),
              },
              {
                path: "all", // 전체상품관리
                element: (
                  <RequireAuth>
                    <AWAll />
                  </RequireAuth>
                ),
              },
              {
                path: "position", // 저장위치관리
                element: (
                  <RequireAuth>
                    <AWPosition />
                  </RequireAuth>
                ),
              },
              {
                path: "released", // 박스출고관리
                element: (
                  <RequireAuth>
                    <AWBoxReleased />
                  </RequireAuth>
                ),
              },
              {
                path: "split", // 스플릿
                element: (
                  <RequireAuth>
                    <ASplit />
                  </RequireAuth>
                ),
              },
              {
                path: "survey", // 재고조사
                element: (
                  <RequireAuth>
                    <InventorySurvey />
                  </RequireAuth>
                ),
              },
            ],
          },
          {
            path: "member", // 고객관리
            children: [
              {
                path: "list", // 전체고객리스트
                element: (
                  <RequireAuth>
                    <Alist />
                  </RequireAuth>
                ),
              },
              {
                path: "membership", // 멤버쉽관리
                element: (
                  <RequireAuth>
                    <Amembership />
                  </RequireAuth>
                ),
              },
              {
                path: "coupon", // 쿠폰관리
                element: (
                  <RequireAuth>
                    <Acoupon />
                  </RequireAuth>
                ),
              },
              {
                path: "point", // 포인트관리
                element: (
                  <RequireAuth>
                    <Apoint />
                  </RequireAuth>
                ),
              },
            ],
          },
          {
            path: "service", // 서비스관리
            children: [
              {
                path: "review", // 리뷰관리
                element: (
                  <RequireAuth>
                    <AReview />
                  </RequireAuth>
                ),
              },
              {
                path: "qna", // 질문응답
                element: (
                  <RequireAuth>
                    <Aqna />
                  </RequireAuth>
                ),
              },
              {
                path: "event", // 이벤트팝업
                element: (
                  <RequireAuth>
                    <AEvent />
                  </RequireAuth>
                ),
              },
              {
                path: "notice", // 공지사항등록
                element: (
                  <RequireAuth>
                    <ANotice />
                  </RequireAuth>
                ),
              },
              {
                path: "blog", // 블로그등록
                element: <></>,
              },
            ],
          },
          {
            path: "analysis", // 통계
            children: [
              {
                path: "newuser", // 가입 통계
                element: (
                  <RequireAuth>
                    <Anewuser />
                  </RequireAuth>
                ),
              },
              // {
              //   path: "inflow", // 유입경로
              //   element: <></>,
              // },
              {
                path: "daily", // 고객별
                element: (
                  <RequireAuth>
                    <DailyRevenueStatistics />
                  </RequireAuth>
                ),
              },
              {
                path: "ship", // 배송대행
                element: (
                  <RequireAuth>
                    <ShipStatistics />
                  </RequireAuth>
                ),
              },
              {
                path: "shop", // 구매대행
                element: (
                  <RequireAuth>
                    <ShopStatistics />
                  </RequireAuth>
                ),
              },
              // {
              //   path: "event", // 이벤트성과
              //   element: <></>,
              // },
              // {
              //   path: "warehouse", // 창고관리
              //   element: <></>,
              // },
              {
                path: "member", // 고객별
                element: (
                  <RequireAuth>
                    <CustomerDetail />
                  </RequireAuth>
                ),
              },
              // {
              //   path: "platform", // 모바일/웹사이트
              //   element: <></>,
              // },
              // {
              //   path: "payment", // 결제수단
              //   element: <></>,
              // },
            ],
          },
        ],
      },
      {
        path: "customer", // customer
        element: <CustomerLayout />,
        children: [
          {
            path: "", // dashboard
            element: <CDashBoard />,
          },
          {
            path: "settings",
            element: <CSettings />,
          },
          {
            path: "calculator", // 예상운임계산기
            element: <Caculator />,
          },
          {
            path: "support", // 질문및건의사항
            element: <CSupportForm />,
          },
          {
            path: "support/:questionId", // 내 질문
            element: <CQuestionPage />,
          },
          {
            path: "membership", // 멤버십
            element: <CMembership />,
          },
          {
            path: "paypal-callback", // Paypal 결과
            element: <CPaypalCallback />,
          },
          {
            path: "shop", // 구매대행서비스
            children: [
              {
                path: "sendorder", // 구매대행요청서작성
                element: <CShopSendorder />,
              },
              {
                path: "order", // 고객이작성한구매요청서
                children: [
                  {
                    index: true,
                    element: <CShopOrder />,
                  },
                  {
                    path: ":status",
                    element: <CShopOrder />,
                  },
                ],
              },
              {
                path: "pay", // 구매요청서(결제대기중)
                element: <CShopPay />,
              },
              {
                path: "paid", // 구매요청서(결제완료/구매대기중)
                element: <CShopPaid />,
              },
              {
                path: "details", // 구매대행거래내역
                element: <CShopDetails />,
              },
            ],
          },
          {
            path: "ship", // 배송대행서비스
            children: [
              {
                path: "sendorder", // 배송대행요청서작성
                element: <CShipSendOrder />,
              },
              {
                path: "order", // 고객이요청한배송요청서
                element: <CShipOrder />,
              },
              {
                path: "pay", // 포장이완료된배송요청서(결제대기중)
                element: <CShipPay />,
              },
              {
                path: "details", // 배송대행거래내역
                children: [
                  {
                    index: true,
                    element: <CShipDetails />,
                  },
                  {
                    path: ":status",
                    element: <CShipDetails />,
                  },
                ],
              },
            ],
          },
          {
            path: "warehouse", // 창고관리
            children: [
              {
                path: "", // 전체상품리스트
                element: <CWarehouse />,
              },
              {
                path: "modify", // 내용수정이필요한상품리스트
                element: <CWarehouseModify />,
              },
              {
                path: "checkcontents", // 체크컨텐츠요청상품리스트
                element: <CCheckcontents />,
              },
              {
                path: "lost", // 송장번호분실요청
                element: <CLost />,
              },
              {
                path: "split", // 송장번호분실요청
                element: <CSplit />,
              },
            ],
          },
          {
            path: "service", // 블로그및이벤트
            children: [
              {
                path: "blog", // 블로그
                element: <></>,
              },
              {
                path: "notice", // 공지사항
                element: <Cevent />,
              },
              {
                path: "notice/:noticeId", // 단일 공지사항
                element: <CMobileNotice />,
              },
            ],
          },
          {
            path: "guide", // 가이드
            children: [
              {
                path: "shop", // 구매대행가이드
                element: <CShopGuide></CShopGuide>,
              },
              {
                path: "storage", // 창고이용가이드
                element: <CStorageGuide></CStorageGuide>,
              },
              {
                path: "ship", // 배송대행가이드
                element: <CShipGuide></CShipGuide>,
              },
            ],
          },
          {
            path: "review",
            children: [
              {
                path: "every",
                element: <CReview />,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

function App() {
  return (
    <>
      <div style={{ fontFamily: "NanumSquare" }}>
        <Provider store={store}>
          <GlobalModal />
          <RouterProvider router={router} />
        </Provider>
      </div>
    </>
  );
}

export default App;
