import { useEffect, useState } from "react";
import { Pagination, Table } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  CODE_SHIPPING_ORDER_STATUS,
  CODE_SHOPING_PAYMENT_STATUS,
  DATA_TYPE,
  USER_BADGE,
} from "../../../common/constants";
import { formatDate } from "../../../common/format";
import CustomerPageTitle from "../../../components/CustomerPageTitle";
import FormatData from "../../../components/FormatData";
import CShipPayModal from "../../../components/modal/CShipPayModal";
import { RootUserState } from "../../../redux/store";
import { reqReadBadge } from "../../../requests/badge";
import { requserShip } from "../../../requests/ship";
const ITEMS_PER_PAGE = 10;

export default function CShipPay() {
  const nav = useNavigate();
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state: RootUserState) => state.user);
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [showRepackModal, setShowRepackModal] = useState(false);
  const [data, setData] = useState<OrderData[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const [totalPages, setTotalPages] = useState(0);

  type OrderData = {
    id: string;
    createdAt: string;
    expiredAt: string;
    userId: string;
    adrReceiver: string;
    status: number;
    membershipGrade: string;
    PaymentMethod: number;
  };

  const fetchData = async () => {
    try {
      const response = await requserShip(user.id);
      const filteredData = response.data.filter(
        (item: OrderData) =>
          item.userId === user.id &&
          item.status === CODE_SHIPPING_ORDER_STATUS.PRODUCT_READY
      );
      setData(filteredData);
      setTotalPages(Math.ceil(filteredData.length / itemsPerPage));
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user?.id]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(0);
    setTotalPages(Math.ceil(data.length / newItemsPerPage));
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const displayedData = data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  useEffect(() => {
    fetchData();

    const badgePayload = {
      userId: user.id,
      badgeName: USER_BADGE.SHIP_PAYMENT_WAITING,
    };
    reqReadBadge(badgePayload);
  }, [user?.id]);

  const handleStatusClick = (orderId: string) => {
    setSelectedOrder(orderId);
    setShowModal(true);
  };

  const handleRepackRequest = () => {
    setShowModal(false);
    setShowRepackModal(true);
  };

  const filteredData = search
    ? data.filter((item) =>
        item.id.toLowerCase().includes(search.toLowerCase())
      )
    : data;

  const startIndex = currentPage * ITEMS_PER_PAGE;

  const tableRows = displayedData.map((item, index) => (
    <tr>
      <td className="font-large-page">{index + 1 + startIndex}</td>
      <td
        className="ontactweb-height"
        key={index}
        onClick={() => handleStatusClick(item.id)}
        style={{
          cursor: "pointer",
          color: "var(--color-main-blue)",
          textDecoration: "underline",
          fontSize: "1.3em",
        }}
      >
        {item.id}
      </td>
      <td className="font-large-page">{formatDate(item.createdAt)}</td>
      <td className="font-large-page">{formatDate(item.expiredAt)}</td>
      <td className="font-large-page">
        {item.PaymentMethod > 0
          ? "Confirming deposit"
          : FormatData(DATA_TYPE.CODE_SHIPPING_ORDERS_STATUS, item.status)}
      </td>
    </tr>
  ));

  return (
    <>
      <div style={{ padding: isMobile ? "1.4rem" : "30px" }}>
        {isMobile ? (
          <MobileWrapper>
            <CustomerPageTitle title="Pending Payments" />

            <div>
              {displayedData.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "200px",
                    textAlign: "center",
                    padding: "2rem 0",
                  }}
                >
                  <i
                    className="bi bi-exclamation-circle"
                    style={{
                      fontSize: "2.5rem",
                      color: "#6c757d",
                      marginBottom: "1rem",
                    }}
                  ></i>
                  <p style={{ fontSize: "1.1rem", color: "#666", margin: 0 }}>
                    No data available
                  </p>
                </div>
              ) : (
                displayedData.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid lightgray",
                      marginBottom: "1rem",
                      paddingBottom: "1rem",
                    }}
                    onClick={() => handleStatusClick(item.id)}
                  >
                    <Row>
                      <P>No.</P>
                      <P>{index + 1 + startIndex}</P>
                    </Row>

                    <Row>
                      <P>Order No.</P>
                      <P
                        style={{
                          cursor: "pointer",
                          color: "var(--color-main-blue)",
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        {item.id}
                      </P>
                    </Row>

                    <Row>
                      <P>Order Date</P>
                      <P>{formatDate(item.createdAt)}</P>
                    </Row>

                    <Row>
                      <P>Paid Expire Date</P>
                      <P>{formatDate(item.expiredAt)}</P>
                    </Row>

                    <Row>
                      <P>Status</P>
                      <P>{CODE_SHOPING_PAYMENT_STATUS[item.status]}</P>
                    </Row>
                  </div>
                ))
              )}
              <Pagination className="justify-content-center">
                {[...Array(totalPages)].map((_, idx) => (
                  <Pagination.Item
                    key={idx}
                    active={idx === currentPage}
                    onClick={() => handlePageChange(idx)}
                  >
                    {idx + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          </MobileWrapper>
        ) : (
          <>
            <CustomerPageTitle title="Pending Payments" />
            <div
              style={{
                height: "800px",
                overflowY: "scroll",
                marginBottom: "20px",
              }}
            >
              <section className="container-fluid" id="about">
                {displayedData.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "400px",
                      textAlign: "center",
                    }}
                  >
                    <i
                      className="bi bi-exclamation-circle"
                      style={{
                        fontSize: "3rem",
                        color: "#6c757d",
                        marginBottom: "1rem",
                      }}
                    ></i>
                    <p style={{ fontSize: "1.2rem", color: "#666", margin: 0 }}>
                      No data available
                    </p>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <Table className="table-custom text-center">
                      <thead className="table-light">
                        <tr translate="no">
                          <th className="font-large-page-title">No</th>
                          <th className="font-large-page-title">Order No</th>
                          <th className="font-large-page-title">Order Date</th>
                          <th className="font-large-page-title">
                            Paid Expire Date
                          </th>
                          <th className="font-large-page-title">Status</th>
                        </tr>
                      </thead>
                      <tbody>{tableRows}</tbody>
                    </Table>
                  </div>
                )}
                <Pagination className="justify-content-center">
                  {[...Array(totalPages)].map((_, idx) => (
                    <Pagination.Item
                      key={idx}
                      active={idx === currentPage}
                      onClick={() => handlePageChange(idx)}
                    >
                      {idx + 1}
                    </Pagination.Item>
                  ))}
                </Pagination>
              </section>
            </div>
          </>
        )}

        {showModal && (
          <CShipPayModal
            show={showModal}
            onHide={() => setShowModal(false)}
            orderNumber={selectedOrder ?? ""}
            onRepackRequest={handleRepackRequest}
            refreshData={fetchData}
          />
        )}
      </div>
    </>
  );
}

const Title = styled.h3`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  margin-top: 1rem;
`;
const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;
