import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Pagination, Spinner } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import * as XLSX from "xlsx";
import {
  CODE_COUNTRY_ITEMS,
  CODE_SHOPPING_ORDER_ITEMS,
} from "../../../common/constants";
import {
  reqAShop,
  reqAShopDetil,
  reqAShopStatistics,
} from "../../../requests/shop";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const PAYMENT_METHODS: { [key: number]: string } = {
  1: "KRW",
  2: "USD",
};

const MEMBERSHIP_LEVELS: { [key: number]: string } = {
  1: "Red",
  2: "Blue",
  3: "Green",
};

const FILTER_PERIODS = {
  ALL: "All",
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month",
  YEAR: "Year",
};

interface ShopProductDetail {
  id: number;
  orderId: string;
  userId: string;
  PackageId: string;
  productId: string;
  category: number;
  price: number;
  quantity: number;
  status: number;
  name: string;
  ShippingFee: number;
}

interface Order {
  id: number;
  orderId: string;
  userId: string;
  productId: string;
  PaymentMethod: number;
  ShippingFee: number | null;
  account: string | null;
  cancellationReason: string;
  category: string | null;
  couponLogId: string | null;
  createdAt: string;
  deletedAt: string | null;
  failed: boolean;
  fancall: boolean;
  fancallBirthday: string;
  fee: number;
  notified6Hours: boolean;
  option1: string | null;
  option2: string | null;
  paymentAmountKRW: number | null;
  paymentAmountUSD: number | null;
  paymentConfirmed: boolean;
  paymentDeadline: string;
  paypalPayAmount: number;
  paypalTransactionId: string;
  preorder: boolean;
  preorderEndAt: string | null;
  preorderStartAt: string | null;
  price: number;
  quantity: number | null;
  refund: string | null;
  shopProductDetails: ShopProductDetail[];
  snKrwCouponApplied: number;
  snKrwFee: number;
  snKrwPointApplied: number;
  snKrwRealFee: number;
  snKrwShippingFee: number;
  snKrwTotalFee: number;
  snUsdCouponApplied: number;
  snUsdFee: number;
  snUsdPaypalSurcharge: number;
  snUsdPointApplied: number;
  snUsdRealFee: number;
  snUsdShippingFee: number;
  snUsdTotalFee: number;
  status: number;
  totalPriceKRW: number;
  totalPriceUSD: number;
  updatedAt: string;
  url: string | null;
  user: {
    membershipId: number;
    country: number;
  };
}

interface OrderDetail {
  id: number;
  orderId: string;
  userId: string;
  productId: string;
  category: number;
  name: string;
  option1: string;
  option2: string;
  url: string;
  quantity: number;
  price: number;
  preorder: boolean;
  preorderStartAt: string;
  preorderEndAt: string;
  fancall: boolean;
  fancallName: string;
  fancallBirthday: string;
  fancallNumber: string;
  fancallEmail: string;
  fancallCountry: number;
  fancallKakao: string;
  fancallSnsLine: string;
  status: number;
  fee: number;
  ShippingFee: number;
  cancellationReason: string;
  paymentMethod: number;
  totalPriceKRW: number;
  totalPriceUSD: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  user: {
    id: string;
    membershipId: number;
    country: number;
  };
  shopProduct: {
    PaymentMethod: number;
    totalPriceKRW: number;
    totalPriceUSD: number;
    fancallBirthday: string;
  };
}

interface Statistics {
  requested: number;
  cancelled: number;
  completed: number;
  totalRevenueKRW: number;
  totalRevenueUSD: number;
  totalServiceFeeKRW: number;
  totalServiceFeeUSD: number;
  totalProductCostKRW: number;
  totalProductCostUSD: number;
  categoryCounts: { [key: string]: number };
  top5UsersKRW: [string, number][];
  top5UsersUSD: [string, number][];
  countryCounts: { [key: string]: number };
  monthlyUsageCounts: { [key: string]: number };
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const LoadingText = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin: 0;
`;

const ShopStatistics: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState<Order[]>([]);
  const [orderDetails, setOrderDetails] = useState<OrderDetail[]>([]);
  const [filteredOrderDetails, setFilteredOrderDetails] = useState<
    OrderDetail[]
  >([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterPeriod, setFilterPeriod] = useState<string>(FILTER_PERIODS.ALL);
  const [statistics, setStatistics] = useState<Statistics>({
    requested: 0,
    cancelled: 0,
    completed: 0,
    totalRevenueKRW: 0,
    totalRevenueUSD: 0,
    totalServiceFeeKRW: 0,
    totalServiceFeeUSD: 0,
    totalProductCostKRW: 0,
    totalProductCostUSD: 0,
    categoryCounts: {},
    top5UsersKRW: [],
    top5UsersUSD: [],
    countryCounts: {},
    monthlyUsageCounts: {},
  });

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setIsLoading(true);
        const response = await reqAShop();
        const validOrders = response.data.filter(
          (order: Order) =>
            order.fancallBirthday &&
            order.fancallBirthday !== "1970-01-01T00:00:00.000Z"
        );
        setOrders(validOrders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrders();
    fetchOrderDetails();
  }, []);

  useEffect(() => {
    updateFilteredOrderDetails();
  }, [
    orderDetails,
    itemsPerPage,
    currentPage,
    startDate,
    endDate,
    searchTerm,
    filterPeriod,
  ]);

  useEffect(() => {
    fetchStatistics();
  }, [startDate, endDate]);

  const fetchOrderDetails = async () => {
    try {
      const response = await reqAShopDetil();
      setOrderDetails(response.data);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const fetchStatistics = async () => {
    try {
      const response = await reqAShopStatistics(startDate, endDate);
      setStatistics(response.data);
    } catch (error) {
      console.error("Error fetching statistics:", error);
    }
  };

  const updateFilteredOrderDetails = () => {
    const now = new Date();
    let filtered = orderDetails.filter((detail) => {
      const orderDate =
        detail.shopProduct && detail.shopProduct.fancallBirthday
          ? new Date(detail.shopProduct.fancallBirthday)
          : null;
      const start = startDate ? new Date(startDate) : new Date(0);
      const end = endDate ? new Date(endDate) : new Date();
      let withinPeriod = true;

      if (filterPeriod !== FILTER_PERIODS.ALL) {
        if (orderDate) {
          switch (filterPeriod) {
            case FILTER_PERIODS.DAY:
              withinPeriod =
                now.getTime() - orderDate.getTime() <= 24 * 60 * 60 * 1000;
              break;
            case FILTER_PERIODS.WEEK:
              withinPeriod =
                now.getTime() - orderDate.getTime() <= 7 * 24 * 60 * 60 * 1000;
              break;
            case FILTER_PERIODS.MONTH:
              withinPeriod =
                now.getTime() - orderDate.getTime() <= 30 * 24 * 60 * 60 * 1000;
              break;
            case FILTER_PERIODS.YEAR:
              withinPeriod =
                now.getTime() - orderDate.getTime() <=
                365 * 24 * 60 * 60 * 1000;
              break;
          }
        } else {
          withinPeriod = false;
        }
      }

      return (
        (!startDate || (orderDate && orderDate >= start)) &&
        (!endDate || (orderDate && orderDate <= end)) &&
        detail.userId.toUpperCase().includes(searchTerm.toUpperCase()) &&
        withinPeriod
      );
    });

    setFilteredOrderDetails(filtered);
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(
      newItemsPerPage === -1 ? orderDetails.length : newItemsPerPage
    );
    setCurrentPage(1);
    updateFilteredOrderDetails();
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    updateFilteredOrderDetails();
  };

  const handleDownload = () => {
    const wb = XLSX.utils.book_new();
    const wsData = filteredOrderDetails.map((detail) => {
      if (!detail.shopProduct) {
        return {
          "Order ID": detail.orderId,
          "User ID": detail.userId,
          "Product ID": detail.productId,
          Category: CODE_SHOPPING_ORDER_ITEMS[detail.category] || "Unknown",
          Quantity: detail.quantity,
          "Product Price": "N/A",
          "Total Price": "N/A",
          "Payment Method": "N/A",
          "Paid At": "N/A",
        };
      }

      const productPrice =
        detail.price * detail.quantity + (detail.ShippingFee ?? 0) ?? 0;
      const totalPrice =
        detail.shopProduct.PaymentMethod === 1
          ? detail.shopProduct.totalPriceKRW
          : (detail.shopProduct.totalPriceUSD ?? 0).toFixed(2);

      return {
        "Order ID": detail.orderId,
        "User ID": detail.userId,
        "Product ID": detail.productId,
        Category: CODE_SHOPPING_ORDER_ITEMS[detail.category] || "Unknown",
        Quantity: detail.quantity,
        "Product Price": productPrice,
        "Total Price": totalPrice,
        "Payment Method": PAYMENT_METHODS[detail.shopProduct.PaymentMethod],
        "Paid At": detail.shopProduct.fancallBirthday
          ? new Date(detail.shopProduct.fancallBirthday).toLocaleDateString(
              "en-CA"
            )
          : "N/A",
      };
    });

    const ws = XLSX.utils.json_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, "Orders");
    XLSX.writeFile(wb, "FilteredOrders.xlsx");
  };

  const barOptions: ChartOptions<"bar"> = {
    indexAxis: "x",
    responsive: true,
    maintainAspectRatio: false,
  };

  const lineOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
  };

  const filteredMonths = Object.keys(statistics.monthlyUsageCounts)
    .filter(
      (month) =>
        (!startDate || new Date(month) >= new Date(startDate)) &&
        (!endDate || new Date(month) <= new Date(endDate))
    )
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  const top5Categories = Object.entries(statistics.categoryCounts)
    .sort(([, a], [, b]) => (b as number) - (a as number))
    .slice(0, 5);

  const top5UsersKRW = statistics.top5UsersKRW;
  const top5UsersUSD = statistics.top5UsersUSD;
  const top5Countries = Object.entries(statistics.countryCounts)
    .sort(([, a], [, b]) => (b as number) - (a as number))
    .slice(0, 5);

  return (
    <main id="main" className="main">
      <Header>
        <FilterContainer>
          <label>
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
          <label>
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
          <button onClick={updateFilteredOrderDetails}>Filter</button>
        </FilterContainer>
        <DownloadButton onClick={handleDownload}>Download</DownloadButton>
      </Header>

      {isLoading ? (
        <LoadingContainer>
          <Spinner animation="border" variant="primary" />
          <LoadingText>데이터를 불러오는 중입니다...</LoadingText>
        </LoadingContainer>
      ) : (
        <Content>
          <MainContentContainer>
            <SalesTableSection>
              <CardContainer>
                <Card>
                  <CardHeader>
                    <IconWrapper>
                      <i
                        className="bi bi-truck"
                        style={{ fontSize: "24px" }}
                      ></i>
                    </IconWrapper>
                    <h3>구매대행 매출</h3>
                  </CardHeader>
                  <CardBody>
                    <table>
                      <thead>
                        <tr>
                          <th>구분</th>
                          <th>상품 합계</th>
                          <th>서비스 비용</th>
                          <th>총합</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key="KRW">
                          <td>KRW</td>
                          <td>{statistics.totalProductCostKRW}</td>
                          <td>{statistics.totalServiceFeeKRW}</td>
                          <td>{statistics.totalRevenueKRW}</td>
                        </tr>
                        <tr key="USD">
                          <td>USD</td>
                          <td>{statistics.totalProductCostUSD.toFixed(2)}</td>
                          <td>{statistics.totalServiceFeeUSD.toFixed(2)}</td>
                          <td>{statistics.totalRevenueUSD.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </CardBody>
                  <CardHeader>
                    <IconWrapper>
                      <i
                        className="bi bi-card-list"
                        style={{ fontSize: "24px" }}
                      ></i>
                    </IconWrapper>
                    <h3>주문 상태</h3>
                  </CardHeader>
                  <CardBody>
                    <Statistic>
                      <div>요청(상품기준)</div>
                      <div>{statistics.requested}</div>
                    </Statistic>
                    <Statistic>
                      <div>취소(상품기준)</div>
                      <div>{statistics.cancelled}</div>
                    </Statistic>
                    <Statistic>
                      <div>완료(상품기준)</div>
                      <div>{statistics.completed}</div>
                    </Statistic>
                  </CardBody>
                </Card>
              </CardContainer>
              <ChartContainer>
                <div className="chart-card">
                  <i className="bi bi-box-seam chart-icon"></i>
                  <h3 className="chart-title">선호상품 (수량 기준)</h3>
                  <ul className="chart-list">
                    {top5Categories.map(([key, count], index) => (
                      <li key={key} className="chart-list-item">
                        <strong>
                          {index + 1}. {CODE_SHOPPING_ORDER_ITEMS[+key]}
                        </strong>
                        <span>{count}건</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="chart-card">
                  <i className="bi bi-geo-alt chart-icon"></i>
                  <h3 className="chart-title">국가 TOP 5 (주문기준)</h3>
                  <ul className="chart-list">
                    {top5Countries.map(([key, count], index) => (
                      <li key={key} className="chart-list-item">
                        <strong>
                          {index + 1}. {CODE_COUNTRY_ITEMS[+key]}
                        </strong>
                        <span>{count}건</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="chart-card">
                  <i className="bi bi-person-circle chart-icon"></i>
                  <h3 className="chart-title">고객 TOP 5 (KRW)</h3>
                  <ul className="chart-list">
                    {top5UsersKRW.map(([key, count], index) => (
                      <li key={key} className="chart-list-item">
                        <strong>
                          {index + 1}. {key}
                        </strong>
                        <span>{count.toLocaleString()} KRW</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="chart-card">
                  <i className="bi bi-person-circle chart-icon"></i>
                  <h3 className="chart-title">고객 TOP 5 (USD)</h3>
                  <ul className="chart-list">
                    {top5UsersUSD.map(([key, count], index) => (
                      <li key={key} className="chart-list-item">
                        <strong>
                          {index + 1}. {key}
                        </strong>
                        <span>{count.toLocaleString()} USD</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </ChartContainer>
            </SalesTableSection>

            <Section>
              <GraphContainer>
                <div className="large-chart">
                  <h3>Monthly Order Counts</h3>
                  <Line
                    data={{
                      labels: filteredMonths,
                      datasets: [
                        {
                          label: "Orders",
                          data: filteredMonths.map(
                            (month) => statistics.monthlyUsageCounts[month]
                          ),
                          backgroundColor: "rgba(75, 192, 192, 0.2)",
                          borderColor: "rgba(75, 192, 192, 1)",
                          borderWidth: 2, // Increase border width for better visibility
                          pointRadius: 5, // Increase point size for emphasis
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false, // Ensure the chart expands to fill the container
                      plugins: {
                        legend: {
                          position: "top",
                          labels: {
                            font: {
                              size: 16, // Increase font size for better visibility
                            },
                          },
                        },
                        tooltip: {
                          mode: "index",
                          intersect: false, // Enhance tooltip interaction
                        },
                      },
                      scales: {
                        x: {
                          display: true,
                          title: {
                            display: true,
                            text: "Month",
                            font: {
                              size: 18, // Increase font size for x-axis title
                            },
                          },
                          ticks: {
                            autoSkip: true,
                            maxTicksLimit: 12,
                            font: {
                              size: 14, // Increase font size for x-axis labels
                            },
                          },
                        },
                        y: {
                          display: true,
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: "Order Count",
                            font: {
                              size: 18, // Increase font size for y-axis title
                            },
                          },
                          ticks: {
                            font: {
                              size: 14, // Increase font size for y-axis labels
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </GraphContainer>
            </Section>
          </MainContentContainer>
          <TableContainer>
            <ItemsPerPageContainer>
              <label>Show</label>
              <Dropdown
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={-1}>All</option>
              </Dropdown>
              <label>entries</label>
              <SearchBar
                type="text"
                placeholder="Search by User ID"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(
                    e.target.value.replace(/\s/g, "").toUpperCase()
                  );
                  setCurrentPage(1);
                  updateFilteredOrderDetails();
                }}
              />
            </ItemsPerPageContainer>

            <div
              style={{
                padding: "0.5rem 1rem",
                borderRadius: "0.4rem",
                backgroundColor: "#f8f9fa",
                textAlign: "center",
              }}
            ></div>

            <div className="container-fluid">
              <table className="table text-center">
                <thead>
                  <Row
                    style={{
                      borderBottom: "1px solid lightgray",
                      fontWeight: "bold",
                    }}
                  >
                    <div>주문번호</div>
                    <div>카테고리</div>
                    <div>수량</div>
                    <div>스페이스코드</div>
                    <div>국가</div>
                    <div>멤버십등급</div>
                    <div>상품금액</div>
                    <div>합계금액</div>
                    <div>결제날짜</div>
                    <div>결제수단</div>
                  </Row>
                </thead>

                <tbody>
                  {Array.isArray(filteredOrderDetails) &&
                    filteredOrderDetails.length > 0 &&
                    filteredOrderDetails
                      .slice(
                        (currentPage - 1) * itemsPerPage,
                        currentPage * itemsPerPage
                      )
                      .map((detail, index) => {
                        if (!detail.shopProduct) {
                          return (
                            <Row key={index}>
                              <div>{detail.productId}</div>
                              <div>
                                {CODE_SHOPPING_ORDER_ITEMS[detail.category]}
                              </div>
                              <div>{detail.quantity}</div>
                              <div>{detail.userId}</div>
                              <div>
                                {CODE_COUNTRY_ITEMS[detail.user.country]}
                              </div>
                              <div>
                                {MEMBERSHIP_LEVELS[detail.user.membershipId]}
                              </div>
                              <div>N/A</div>
                              <div>N/A</div>
                              <div>N/A</div>
                              <div>N/A</div>
                            </Row>
                          );
                        }

                        const paymentMethod =
                          PAYMENT_METHODS[detail.shopProduct.PaymentMethod];
                        const totalPrice =
                          paymentMethod === "KRW"
                            ? detail.shopProduct.totalPriceKRW
                            : detail.shopProduct.totalPriceUSD ?? 0;
                        const productPrice =
                          detail.price * detail.quantity +
                            (detail.ShippingFee ?? 0) ?? 0;

                        return (
                          <Row key={index}>
                            <div>{detail.productId}</div>
                            <div>
                              {CODE_SHOPPING_ORDER_ITEMS[detail.category]}
                            </div>
                            <div>{detail.quantity}</div>
                            <div>{detail.userId}</div>
                            <div>{CODE_COUNTRY_ITEMS[detail.user.country]}</div>
                            <div>
                              {MEMBERSHIP_LEVELS[detail.user.membershipId]}
                            </div>
                            <div>
                              {paymentMethod === "USD"
                                ? (productPrice / 1300).toFixed(2)
                                : productPrice}
                            </div>
                            <div>
                              {paymentMethod === "USD"
                                ? totalPrice.toFixed(2)
                                : totalPrice}
                            </div>
                            <div>
                              {detail.shopProduct.fancallBirthday
                                ? new Date(
                                    detail.shopProduct.fancallBirthday
                                  ).toLocaleDateString("en-CA")
                                : "N/A"}
                            </div>
                            <div>{paymentMethod}</div>
                          </Row>
                        );
                      })}
                </tbody>
              </table>
              <p>Total Entries: {filteredOrderDetails.length}</p>
            </div>

            <CenteredPagination style={{ marginTop: "1rem" }}>
              <Pagination.First
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {currentPage > 3 && <Pagination.Ellipsis />}
              {[...Array(totalPages)]
                .map((_, index) => index + 1)
                .filter(
                  (page) =>
                    page === 1 ||
                    page === totalPages ||
                    (page >= currentPage - 2 && page <= currentPage + 2)
                )
                .map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}
              {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              />
            </CenteredPagination>
          </TableContainer>
        </Content>
      )}
    </main>
  );
};

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  label {
    font-weight: bold;
  }

  input {
    margin-left: 0.5rem;
  }

  button {
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    background-color: var(--color-main-blue);
    color: var(--color-white);
    border: none;
    cursor: pointer;
    &:hover {
      background-color: var(--color-main-blue-hover);
    }
  }
`;

const DownloadButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  background-color: var(--color-main-blue);
  color: var(--color-white);
  border: none;
  cursor: pointer;
  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;

const Content = styled.div`
  padding: 1rem;
`;

const StatisticsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const Statistic = styled.div`
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
`;

const SalesTableSection = styled.div`
  width: 38%; /* Adjust width to align to the right */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 1rem;
  max-height: 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
  }
`;

const SalesTable = styled.div`
  flex: 1;
  margin-right: 2rem;
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }
`;

const Section = styled.section`
  width: 60%; /* Adjust width for alignment to the left */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
  overflow: hidden;
  align-items: stretch;

  @media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
`;

const CardBody = styled.div`
  display: flex; /* Use flexbox to center content */
  justify-content: center; /* Center content horizontally */
  width: 100%; /* Use the full width of the card body */
  text-align: center;
  margin-top: 0.5rem;

  table {
    width: 100%; /* Adjust table width as needed */
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 5px;
  }

  th {
    background-color: #f2f2f2;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 50%; /* Ensure it doesn't exceed half the width */
  margin-bottom: 0rem;
`;

const Card = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0.5rem; /* Reduced padding */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0rem; /* Add some margin to separate rows if needed */
`;

const MainContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  flex-wrap: wrap;

  /* For responsiveness */
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ChartContainer = styled.div`
  display: grid; /* Grid 사용으로 레이아웃 설정 */
  grid-template-columns: repeat(2, 1fr); /* 2개의 열로 구성 */
  gap: 2rem; /* 카드들 간의 간격 */
  overflow-y: auto; /* 세로 스크롤 허용 */
  max-height: calc(100vh - 4rem); /* 화면 높이에 맞추어 설정 */

  .chart-card {
    background-color: #fff;
    border-radius: 0.2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0.1rem;
    text-align: center;
    display: flex;
    flex-direction: column; /* 카드 내용을 수직 정렬 */
    justify-content: space-between; /* 카드 내부에 공간을 균등하게 분배 */
    height: 100%; /* 카드 높이를 부모에 맞춤 */
  }

  .chart-icon {
    font-size: 1.5rem;
    color: #63c2de;
    margin-bottom: 0.8rem;
  }

  .chart-title {
    font-size: 1.3rem;
    font-weight: bold;
    color: #1f3c88;
    margin-bottom: 0.5rem;
  }

  .chart-list {
    font-size: 0.8rem;
    list-style: none;
    padding: 0;
    text-align: left;
    flex-grow: 1; /* 리스트가 남은 공간을 차지하도록 */
  }

  .chart-list-item {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem 0;
    font-size: 1rem;
    color: #333;
  }

  .chart-list-item strong {
    color: #1f3c88;
  }
`;

const GraphContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: calc(
    100vh - 8rem
  ); /* This should ensure the container fits within the viewport height */
  overflow-y: auto;
  margin-top: 0;

  .large-chart {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    overflow: hidden;
    margin-top: 0;
    height: calc(
      500vh - 2rem
    ); /* Adjust this value to match the full height needed */
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    .large-chart {
      height: auto; /* Allow full width on mobile */
      margin-bottom: 1rem; /* Adjust bottom margin for smaller screens */
    }
  }
`;

const LargeChartContainer = styled.div`
  flex: 2;

  .large-chart {
    height: 800px; /* 줄여서 바 그래프와 맞추기 */
  }
`;

const TableContainer = styled.div`
  padding: 1rem;
  background-color: var(--color-white);
  border-radius: 0.4rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
`;

const ItemsPerPageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Dropdown = styled.select`
  margin: 0 0.5rem;
`;

const SearchBar = styled.input`
  margin-left: auto;
  padding: 0.5rem;
  border: 1px solid lightgray;
  border-radius: 0.4rem;
`;

const CenteredPagination = styled(Pagination)`
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid lightgray;

  div {
    flex: 1;
  }
`;

export default ShopStatistics;
