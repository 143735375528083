import axios from "axios";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Pagination, Spinner } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import * as XLSX from "xlsx";
import {
  CODE_COUNTRY_ITEMS,
  getAccessTokenFromLocalStorage,
} from "../../../common/constants";
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend
);

const COMPANY_NAMES: { [key: number]: string } = {
  1: "FEDEX",
  2: "UPS",
  3: "EMS",
  4: "K-PACKET",
  5: "CJ",
};

const PAYMENT_METHODS: { [key: number]: string } = {
  1: "KRW",
  2: "USD",
};

const MEMBERSHIP_LEVELS: { [key: number]: string } = {
  1: "Red",
  2: "Blue",
  3: "Green",
};

const FILTER_PERIODS = {
  ALL: "All",
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month",
  YEAR: "Year",
};

interface Shipment {
  id: string;
  userId: string;
  adrReceiver: string;
  adrCountry: number;
  adrCity: string;
  PaymentMethod: number;
  paidAt: string;
  company: number;
  detail: any[];
  user: {
    membershipId: number;
  };
  packagPriceKRW: number;
  packagPriceUSD: number;
  optionPriceKRW: number;
  optionPriceUSD: number;
  warehousePriceKRW: number;
  warehousePriceUSD: number;
  FEDEXPriceKRW: number;
  FEDEXPriceUSD: number;
  UPSPriceKRW: number;
  UPSPriceUSD: number;
  EMSPriceKRW: number;
  EMSPriceUSD: number;
  PACKETPriceKRW: number;
  PACKETPriceUSD: number;
  CJPriceKRW: number;
  CJPriceUSD: number;
  DepositorFee: string;
  expiredAt: string;
  optionSticker: boolean;
  optionAircap: boolean;
  optionBag: boolean;
  useVacuumPackagingBag: number;
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const LoadingText = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin: 0;
`;

const ShipStatistics: React.FC = () => {
  const [data, setData] = useState<Shipment[]>([]);
  const [filteredData, setFilteredData] = useState<Shipment[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterPeriod, setFilterPeriod] = useState<string>(FILTER_PERIODS.ALL);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const accessToken = getAccessTokenFromLocalStorage();
        if (!accessToken) {
          throw new Error("Access token is missing");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}ship/ontact`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setData(response.data);
        setFilteredData(response.data);
        setTotalPages(Math.ceil(response.data.length / itemsPerPage));
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    updateFilteredData();
  }, [itemsPerPage, currentPage, startDate, endDate, searchTerm, filterPeriod]);

  const updateFilteredData = () => {
    const now = new Date();
    let filtered = data.filter((item) => {
      const createdAt = new Date(item.paidAt);
      const start = new Date(startDate);
      const end = new Date(endDate);
      let withinPeriod = true;

      if (filterPeriod !== FILTER_PERIODS.ALL) {
        switch (filterPeriod) {
          case FILTER_PERIODS.DAY:
            withinPeriod =
              now.getTime() - createdAt.getTime() <= 24 * 60 * 60 * 1000;
            break;
          case FILTER_PERIODS.WEEK:
            withinPeriod =
              now.getTime() - createdAt.getTime() <= 7 * 24 * 60 * 60 * 1000;
            break;
          case FILTER_PERIODS.MONTH:
            withinPeriod =
              now.getTime() - createdAt.getTime() <= 30 * 24 * 60 * 60 * 1000;
            break;
          case FILTER_PERIODS.YEAR:
            withinPeriod =
              now.getTime() - createdAt.getTime() <= 365 * 24 * 60 * 60 * 1000;
            break;
        }
      }

      return (
        (!startDate || createdAt >= start) &&
        (!endDate || createdAt <= end) &&
        item.userId.toUpperCase().includes(searchTerm.toUpperCase()) &&
        item.paidAt !== "1970-01-01T00:00:00.000Z" &&
        withinPeriod
      );
    });

    setFilteredData(filtered);
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage === -1 ? data.length : newItemsPerPage);
    setCurrentPage(1);
    updateFilteredData();
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    updateFilteredData();
  };

  const handleDownload = () => {
    const wb = XLSX.utils.book_new();
    const wsData = filteredData.map((item) => ({
      "Order ID": item.id,
      "User ID": item.userId,
      Receiver: item.adrReceiver,
      Country: CODE_COUNTRY_ITEMS[item.adrCountry] || "Unknown",
      City: item.adrCity,
      "Payment Method": PAYMENT_METHODS[item.PaymentMethod],
      "Paid At": new Date(item.paidAt).toLocaleString(),
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, "Shipments");
    XLSX.writeFile(wb, "Shipments.xlsx");
  };

  const calculateStatistics = () => {
    const shippingCompanyCounts: { [key: number]: number } = {
      1: 0, // FEDEX
      2: 0, // UPS
      3: 0, // EMS
      4: 0, // K-PACKET
      5: 0, // CJ
    };

    const userCounts: { [key: string]: number } = {};
    const countryCounts: { [key: string]: number } = {};
    const monthlyOrderCounts: { [key: string]: number } = {};
    const monthlyRevenueKRW: { [key: string]: number } = {};
    const monthlyRevenueUSD: { [key: string]: number } = {};
    const monthlyAircapKRW: { [key: string]: number } = {};
    const monthlyStickerKRW: { [key: string]: number } = {};
    const monthlyInsuranceKRW: { [key: string]: number } = {};
    const monthlyWarehouseKRW: { [key: string]: number } = {};
    const monthlyVacuumPackKRW: { [key: string]: number } = {};
    const monthlyAircapUSD: { [key: string]: number } = {};
    const monthlyStickerUSD: { [key: string]: number } = {};
    const monthlyInsuranceUSD: { [key: string]: number } = {};
    const monthlyWarehouseUSD: { [key: string]: number } = {};
    const monthlyVacuumPackUSD: { [key: string]: number } = {};
    const monthlyShippingCostKRW: { [key: string]: number } = {};
    const monthlyShippingCostUSD: { [key: string]: number } = {};

    filteredData.forEach((item) => {
      if (!item.paidAt) {
        return;
      }

      const company = item.company;
      shippingCompanyCounts[company] =
        (shippingCompanyCounts[company] || 0) + 1;

      const userId = item.userId;
      userCounts[userId] = (userCounts[userId] || 0) + 1;

      const country = item.adrCountry;
      countryCounts[country] = (countryCounts[country] || 0) + 1;

      const month = new Date(item.paidAt).toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
      });
      monthlyOrderCounts[month] = (monthlyOrderCounts[month] || 0) + 1;

      const depositorFee = parseFloat(item.DepositorFee) || 0;
      // 배송비 계산 로직 추가
      let shippingCostKRW = 0;
      let shippingCostUSD = 0;

      if (item.company === 1) {
        shippingCostKRW = item.FEDEXPriceKRW || 0;
        shippingCostUSD = item.FEDEXPriceUSD || 0;
      } else if (item.company === 2) {
        shippingCostKRW = item.UPSPriceKRW || 0;
        shippingCostUSD = item.UPSPriceUSD || 0;
      } else if (item.company === 3) {
        shippingCostKRW = item.EMSPriceKRW || 0;
        shippingCostUSD = item.EMSPriceUSD || 0;
      } else if (item.company === 4) {
        shippingCostKRW = item.PACKETPriceKRW || 0;
        shippingCostUSD = item.PACKETPriceUSD || 0;
      } else if (item.company === 5) {
        shippingCostKRW = item.CJPriceKRW || 0;
        shippingCostUSD = item.CJPriceUSD || 0;
      }

      if (item.PaymentMethod === 1) {
        monthlyRevenueKRW[month] =
          (monthlyRevenueKRW[month] || 0) + depositorFee;
        if (item.optionAircap) {
          monthlyAircapKRW[month] =
            (monthlyAircapKRW[month] || 0) + item.optionPriceKRW;
        }
        if (item.optionSticker) {
          monthlyStickerKRW[month] =
            (monthlyStickerKRW[month] || 0) + item.optionPriceKRW;
        }
        if (item.optionBag) {
          monthlyInsuranceKRW[month] =
            (monthlyInsuranceKRW[month] || 0) + item.optionPriceKRW;
        }
        monthlyWarehouseKRW[month] =
          (monthlyWarehouseKRW[month] || 0) + item.warehousePriceKRW;
        monthlyVacuumPackKRW[month] =
          (monthlyVacuumPackKRW[month] || 0) +
          item.useVacuumPackagingBag * 5000;

        // 배송비 누적
        monthlyShippingCostKRW[month] =
          (monthlyShippingCostKRW[month] || 0) + shippingCostKRW;
      } else {
        monthlyRevenueUSD[month] =
          (monthlyRevenueUSD[month] || 0) + depositorFee;
        if (item.optionAircap) {
          monthlyAircapUSD[month] =
            (monthlyAircapUSD[month] || 0) + item.optionPriceUSD;
        }
        if (item.optionSticker) {
          monthlyStickerUSD[month] =
            (monthlyStickerUSD[month] || 0) + item.optionPriceUSD;
        }
        if (item.optionBag) {
          monthlyInsuranceUSD[month] =
            (monthlyInsuranceUSD[month] || 0) + item.optionPriceUSD;
        }
        monthlyWarehouseUSD[month] =
          (monthlyWarehouseUSD[month] || 0) + item.warehousePriceUSD;
        monthlyVacuumPackUSD[month] =
          (monthlyVacuumPackUSD[month] || 0) + item.useVacuumPackagingBag * 5;

        // 배송비 누적
        monthlyShippingCostUSD[month] =
          (monthlyShippingCostUSD[month] || 0) + shippingCostUSD;
      }
    });

    return {
      shippingCompanyCounts,
      userCounts,
      countryCounts,
      monthlyOrderCounts,
      monthlyRevenueKRW,
      monthlyRevenueUSD,
      monthlyAircapKRW,
      monthlyStickerKRW,
      monthlyInsuranceKRW,
      monthlyWarehouseKRW,
      monthlyVacuumPackKRW,
      monthlyAircapUSD,
      monthlyStickerUSD,
      monthlyInsuranceUSD,
      monthlyWarehouseUSD,
      monthlyVacuumPackUSD,
      monthlyShippingCostKRW,
      monthlyShippingCostUSD,
    };
  };

  const statistics = calculateStatistics();

  const barOptions: ChartOptions<"bar"> = {
    indexAxis: "y",
  };

  const lineOptions: ChartOptions<"line"> = {};

  const filteredMonths = Object.keys(statistics.monthlyRevenueKRW)
    .filter(
      (month) =>
        (!startDate || new Date(month) >= new Date(startDate)) &&
        (!endDate || new Date(month) <= new Date(endDate))
    )
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  const top5ShippingCompanies = Object.entries(statistics.shippingCompanyCounts)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 5);

  const top5Users = Object.entries(statistics.userCounts)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 5);

  const top5Countries = Object.entries(statistics.countryCounts)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 5);

  return (
    <main id="main" className="main">
      <Header>
        <FilterContainer>
          <label>
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
          <label>
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
          <Dropdown
            value={filterPeriod}
            onChange={(e) => setFilterPeriod(e.target.value)}
          >
            <option value={FILTER_PERIODS.ALL}>All</option>
            <option value={FILTER_PERIODS.DAY}>1 Day</option>
            <option value={FILTER_PERIODS.WEEK}>1 Week</option>
            <option value={FILTER_PERIODS.MONTH}>1 Month</option>
            <option value={FILTER_PERIODS.YEAR}>1 Year</option>
          </Dropdown>
          <button onClick={updateFilteredData}>Filter</button>
        </FilterContainer>
        <DownloadButton onClick={handleDownload}>Download</DownloadButton>
      </Header>

      {isLoading ? (
        <LoadingContainer>
          <Spinner animation="border" variant="primary" />
          <LoadingText>데이터를 불러오는 중입니다...</LoadingText>
        </LoadingContainer>
      ) : (
        <Content>
          <Section>
            <SalesTable>
              <div className="table-header">
                <i className="bi bi-truck table-icon"></i>
                <h3>배송대행 매출</h3>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>통화</th>
                    <th>에어캡</th>
                    <th>스티커</th>
                    <th>보험</th>
                    <th>창고비용</th>
                    <th>진공팩</th>
                    <th>배송비</th>
                    <th>합계</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>KRW</td>
                    <td>
                      {Math.floor(
                        filteredMonths.reduce(
                          (sum, month) =>
                            sum + (statistics.monthlyAircapKRW[month] || 0),
                          0
                        )
                      )}
                    </td>
                    <td>
                      {Math.floor(
                        filteredMonths.reduce(
                          (sum, month) =>
                            sum + (statistics.monthlyStickerKRW[month] || 0),
                          0
                        )
                      )}
                    </td>
                    <td>
                      {Math.floor(
                        filteredMonths.reduce(
                          (sum, month) =>
                            sum + (statistics.monthlyInsuranceKRW[month] || 0),
                          0
                        )
                      )}
                    </td>
                    <td>
                      {Math.floor(
                        filteredMonths.reduce(
                          (sum, month) =>
                            sum + (statistics.monthlyWarehouseKRW[month] || 0),
                          0
                        )
                      )}
                    </td>
                    <td>
                      {Math.floor(
                        filteredMonths.reduce(
                          (sum, month) =>
                            sum + (statistics.monthlyVacuumPackKRW[month] || 0),
                          0
                        )
                      )}
                    </td>
                    <td>
                      {Math.floor(
                        filteredMonths.reduce(
                          (sum, month) =>
                            sum +
                            (statistics.monthlyShippingCostKRW[month] || 0),
                          0
                        )
                      )}
                    </td>{" "}
                    {/* 배송비 추가 */}
                    <td>
                      {Math.floor(
                        filteredMonths.reduce(
                          (sum, month) =>
                            sum + (statistics.monthlyRevenueKRW[month] || 0),
                          0
                        )
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>USD</td>
                    <td>
                      {filteredMonths
                        .reduce(
                          (sum, month) =>
                            sum + (statistics.monthlyAircapUSD[month] || 0),
                          0
                        )
                        .toFixed(2)}
                    </td>
                    <td>
                      {filteredMonths
                        .reduce(
                          (sum, month) =>
                            sum + (statistics.monthlyStickerUSD[month] || 0),
                          0
                        )
                        .toFixed(2)}
                    </td>
                    <td>
                      {filteredMonths
                        .reduce(
                          (sum, month) =>
                            sum + (statistics.monthlyInsuranceUSD[month] || 0),
                          0
                        )
                        .toFixed(2)}
                    </td>
                    <td>
                      {filteredMonths
                        .reduce(
                          (sum, month) =>
                            sum + (statistics.monthlyWarehouseUSD[month] || 0),
                          0
                        )
                        .toFixed(2)}
                    </td>
                    <td>
                      {filteredMonths
                        .reduce(
                          (sum, month) =>
                            sum + (statistics.monthlyVacuumPackUSD[month] || 0),
                          0
                        )
                        .toFixed(2)}
                    </td>
                    <td>
                      {filteredMonths
                        .reduce(
                          (sum, month) =>
                            sum +
                            (statistics.monthlyShippingCostUSD[month] || 0),
                          0
                        )
                        .toFixed(2)}
                    </td>{" "}
                    {/* 배송비 추가 */}
                    <td>
                      {filteredMonths
                        .reduce(
                          (sum, month) =>
                            sum + (statistics.monthlyRevenueUSD[month] || 0),
                          0
                        )
                        .toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </SalesTable>
          </Section>
          <Section>
            <ChartContainer>
              <div className="chart-card">
                <i className="bi bi-airplane chart-icon"></i>
                <h4 className="chart-title">배송사 (주문기준)</h4>
                <ul className="chart-list">
                  {top5ShippingCompanies.map(([key, count], index) => (
                    <li key={key} className="chart-list-item">
                      <strong>
                        {index + 1}. {COMPANY_NAMES[+key]}
                      </strong>
                      <span>{count}건</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="chart-card">
                <i className="bi bi-people chart-icon"></i>
                <h4 className="chart-title">고객 TOP 5 (박스기준)</h4>
                <ul className="chart-list">
                  {top5Users.map(([key, count], index) => (
                    <li key={key} className="chart-list-item">
                      <strong>
                        {index + 1}. {key}
                      </strong>
                      <span>{count}건</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="chart-card">
                <i className="bi bi-geo-alt chart-icon"></i>
                <h4 className="chart-title">국가 TOP 5 (주문기준)</h4>
                <ul className="chart-list">
                  {top5Countries.map(([key, count], index) => (
                    <li key={key} className="chart-list-item">
                      <strong>
                        {index + 1}. {CODE_COUNTRY_ITEMS[+key] || "Unknown"}
                      </strong>
                      <span>{count}건</span>
                    </li>
                  ))}
                </ul>
              </div>
            </ChartContainer>
            <LargeChartContainer>
              <div className="large-chart">
                <h3>주문 건수(1년)</h3>
                <Line
                  data={{
                    labels: filteredMonths,
                    datasets: [
                      {
                        label: "Orders",
                        data: filteredMonths.map(
                          (month) => statistics.monthlyOrderCounts[month]
                        ),
                        backgroundColor: "rgba(75, 192, 192, 0.2)",
                        borderColor: "rgba(75, 192, 192, 1)",
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={lineOptions}
                />
              </div>
            </LargeChartContainer>
          </Section>
          <TableContainer>
            <ItemsPerPageContainer>
              <label>Show</label>
              <Dropdown
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={-1}>All</option>
              </Dropdown>
              <label>entries</label>
              <SearchBar
                type="text"
                placeholder="Search by User ID"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(
                    e.target.value.replace(/\s/g, "").toUpperCase()
                  );
                  setCurrentPage(1);
                  updateFilteredData();
                }}
              />
            </ItemsPerPageContainer>

            <div
              style={{
                padding: "0.5rem 1rem",
                borderRadius: "0.4rem",
                backgroundColor: "#f8f9fa",
                textAlign: "center",
              }}
            ></div>

            <div className="container-fluid">
              <table className="table text-center">
                <thead>
                  <Row
                    style={{
                      borderBottom: "1px solid lightgray",
                      fontWeight: "bold",
                    }}
                  >
                    <div>주문번호</div>
                    <div>박스 수량</div>
                    <div>배송국가</div>
                    <div>스페이스코드</div>
                    <div>멤버십</div>
                    <div>포장 비용</div>
                    <div>옵션 비용</div>
                    <div>창고 비용</div>
                    <div>배송비</div>
                    <div>합계금액</div>
                    <div>결제 날짜</div>
                    <div>결제 수단</div>
                    <div>배송사</div>
                  </Row>
                </thead>

                <tbody>
                  {filteredData
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((item, index) => {
                      const paymentMethod = PAYMENT_METHODS[item.PaymentMethod];
                      const packingCost =
                        paymentMethod === "KRW"
                          ? Math.floor(item.packagPriceKRW)
                          : item.packagPriceUSD?.toFixed(2) || "0.00";
                      const optionCost =
                        paymentMethod === "KRW"
                          ? Math.floor(item.optionPriceKRW)
                          : item.optionPriceUSD?.toFixed(2) || "0.00";
                      const warehouseCost =
                        paymentMethod === "KRW"
                          ? Math.floor(item.warehousePriceKRW)
                          : item.warehousePriceUSD?.toFixed(2) || "0.00";
                      const shippingCost =
                        item.company === 1
                          ? paymentMethod === "KRW"
                            ? Math.floor(item.FEDEXPriceKRW)
                            : item.FEDEXPriceUSD?.toFixed(2) || "0.00"
                          : item.company === 2
                          ? paymentMethod === "KRW"
                            ? Math.floor(item.UPSPriceKRW)
                            : item.UPSPriceUSD?.toFixed(2) || "0.00"
                          : item.company === 3
                          ? paymentMethod === "KRW"
                            ? Math.floor(item.EMSPriceKRW)
                            : item.EMSPriceUSD?.toFixed(2) || "0.00"
                          : item.company === 4
                          ? paymentMethod === "KRW"
                            ? Math.floor(item.PACKETPriceKRW)
                            : item.PACKETPriceUSD?.toFixed(2) || "0.00"
                          : paymentMethod === "KRW"
                          ? Math.floor(item.CJPriceKRW)
                          : item.CJPriceUSD?.toFixed(2) || "0.00";

                      return (
                        <Row key={index}>
                          <div>{item.id}</div>
                          <div>{item.detail.length}</div>
                          <div>
                            {CODE_COUNTRY_ITEMS[item.adrCountry] || "Unknown"}
                          </div>
                          <div>{item.userId}</div>
                          <div>{MEMBERSHIP_LEVELS[item.user.membershipId]}</div>
                          <div>{packingCost}</div>
                          <div>{optionCost}</div>
                          <div>{warehouseCost}</div>
                          <div>{shippingCost}</div>
                          {parseFloat(item.DepositorFee) &&
                          parseFloat(item.DepositorFee).toFixed(2) !==
                            "0.00" ? (
                            <div>
                              {(parseFloat(item.DepositorFee) || 0).toFixed(2)}
                            </div>
                          ) : null}
                          <div>
                            {new Date(item.paidAt).toLocaleDateString("en-CA")}
                          </div>
                          <div>{paymentMethod}</div>
                          <div>{COMPANY_NAMES[item.company]}</div>
                        </Row>
                      );
                    })}
                </tbody>
              </table>
              <div>총 {filteredData.length}개의 데이터</div>
            </div>

            <CenteredPagination style={{ marginTop: "1rem" }}>
              <Pagination.First
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {currentPage > 3 && <Pagination.Ellipsis />}
              {[...Array(totalPages)]
                .map((_, index) => index + 1)
                .filter(
                  (page) =>
                    page === 1 ||
                    page === totalPages ||
                    (page >= currentPage - 2 && page <= currentPage + 2)
                )
                .map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}
              {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              />
            </CenteredPagination>
          </TableContainer>
        </Content>
      )}
    </main>
  );
};

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  label {
    font-weight: bold;
  }

  input {
    margin-left: 0.5rem;
  }

  button {
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    background-color: var(--color-main-blue);
    color: var(--color-white);
    border: none;
    cursor: pointer;
    &:hover {
      background-color: var(--color-main-blue-hover);
    }
  }
`;

const SearchBar = styled.input`
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  border: 1px solid lightgray;
  margin-left: auto; /* To position it to the right */
`;

const DownloadButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  background-color: var(--color-main-blue);
  color: var(--color-white);
  border: none;
  cursor: pointer;
  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TableContainer = styled.div`
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ItemsPerPageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  label {
    margin: 0 0.5rem;
  }
`;

const Dropdown = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  border-radius: 0.4rem;
  border: 1px solid lightgray;
  padding: 0.4rem;
`;

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  gap: 1rem;
  & > div {
    flex: 1 1 0;
    word-break: break-all;
    padding: 0.5rem;
    white-space: normal;
    overflow: hidden;
  }
`;

const SalesAndChartContainer = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
`;
const StatisticsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;

  .chart-container {
    flex: 1;
    min-width: 300px;
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .large-chart {
    flex: 2;
  }
`;

const SalesTable = styled.div`
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1rem;

  .table-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .table-icon {
    font-size: 2rem;
    color: #1f3c88;
    margin-right: 0.5rem; /* Adds space between icon and text */
  }

  h3 {
    font-weight: bold;
    color: #1f3c88;
    margin: 0; /* Removes default margin to align properly with the icon */
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
    color: #1f3c88;
  }

  td {
    color: #333;
  }

  tr:hover {
    background-color: #f9f9f9;
  }
`;

const Section = styled.section`
  display: flex;
  gap: 1rem;
`;

const ChartContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;

  .chart-card {
    flex: 1;
    min-width: 250px;
    max-width: 300px;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    text-align: center;
  }

  .chart-icon {
    font-size: 3rem;
    color: #63c2de;
    margin-bottom: 1rem;
  }

  .chart-title {
    font-weight: bold;
    color: #1f3c88;
    margin-bottom: 1rem;
  }

  .chart-list {
    text-align: left;
    padding: 0;
    list-style: none;
  }

  .chart-list-item {
    font-size: 1rem;
    color: #333;
    display: flex;
    justify-content: space-between; /* Distributes space between items */
    padding: 0.3rem 0;
    align-items: center; /* Ensures items are vertically centered */
    width: 100%; /* Ensures the list items take full width */
    gap: 0.5rem; /* Adds some space between the items for readability */
  }

  .chart-list-item strong {
    color: #1f3c88;
  }
`;

const LargeChartContainer = styled.div`
  flex: 2;
`;

export default ShipStatistics;
