import React, { useEffect } from "react";
import styled from "styled-components";
import $ from "jquery";
import { isMobile } from "react-device-detect";

const Pricing = () => {
  // 페이지 초기 세팅
  useEffect(() => {
    $(function () {
      $(".tab_content").hide();
      $(".tab_content:first").show();

      $("ul.tabs li").click(function () {
        $("ul.tabs li").removeClass("active");
        $(this).addClass("active");
        $(".tab_content").hide();
        var activeTab = $(this).attr("rel");
        $("#" + activeTab).fadeIn();
      });

      //모바일용
      $("ul.mobile_tabs li").click(function () {
        $("ul.mobile_tabs li").removeClass("active");
        $(this).addClass("active");
        $(".tab_content").hide();
        var activeTab = $(this).attr("rel");
        $("#" + activeTab).fadeIn();
      });
    });

    $("body").css("background", "#F9F9F9");
  }, []);

  return (
    <LandingWrapper>
      <div id="landing-page">
        <div
          className={
            isMobile ? "pricing_page_mobile_title" : "pricing_page_title"
          }
        >
          <div className="container_wrap po_rel">
            <h2>Pricing info</h2>
            <img src="/landing/images/pricing_img.png" alt="" />
          </div>
        </div>

        <div id="page-body" className="price_info_wrap mar_t_150 pad_b_150">
          <div className="container_wrap after">
            <ul
              className={`${
                isMobile ? "mobile_tabs hide_1080" : "tabs hide_1080"
              }`}
            >
              <li className="active" rel="tab1">
                Storage fee
              </li>
              <li rel="tab2">Custom order fee</li>
              <li rel="tab3">Repack fee</li>
              <li rel="tab4">Check video</li>
              <li rel="tab5">Split</li>
              <li rel="tab6">Membership</li>
            </ul>
            <select className="show_1080 hide wide" name="" id="tab_select">
              <option value="tab1">Storage fee</option>
              <option value="tab2">Custom order fee</option>
              <option value="tab3">Repack fee</option>
              <option value="tab4">Check video</option>
              <option value="tab5">Split</option>
              <option value="tab6">Membership</option>
            </select>
            <div
              className={`${
                isMobile ? "mobile_tab_container" : "tab_container"
              }`}
            >
              <div id="tab1" className="tab_content">
                <div className="title">
                  <h3>Storage fee</h3>
                </div>
                <div className="table_wrap">
                  <table>
                    <tr>
                      <th className="th_25"></th>
                      <th className="th_25">PRICE IN $</th>
                      <th className="th_25">PRICE IN ₩</th>
                      <th className="th_25">PERIOD</th>
                    </tr>
                    <tr>
                      <td className="list">Every new package</td>
                      <td>0 $</td>
                      <td>0 ₩</td>
                      <td>For 30 days</td>
                    </tr>
                    <tr>
                      <td className="list">After 30 days</td>
                      <td>1 $</td>
                      <td>1,000 ₩</td>
                      <td>Per day and per package</td>
                    </tr>
                  </table>
                </div>
                <div className="info_wrap">
                  <span className="info">* KRW = ₩ &nbsp;&nbsp; USD = $</span>
                </div>
              </div>
              <div id="tab2" className="tab_content">
                <div className="title">
                  <h3>Custom order fee</h3>
                </div>
                <div className="table_wrap">
                  <table>
                    <tr>
                      <th className="th_25">Total order price</th>
                      <th className="th_37">CUSTOM ORDER FEE IN ₩</th>
                      <th className="th_37">CUSTOM ORDER FEE IN $</th>
                    </tr>
                    <tr>
                      <td className="list">Order below 50,000₩</td>
                      <td>Fixed fee 5,000₩</td>
                      <td>Fixed fee $5</td>
                    </tr>
                    <tr>
                      <td className="list">50,000₩</td>
                      <td>10% of the order total price</td>
                      <td>10% of the order total price</td>
                    </tr>
                    <tr>
                      <td className="list">100,000₩</td>
                      <td>8% of the order total price</td>
                      <td>8% of the order total price</td>
                    </tr>
                    <tr>
                      <td className="list">200,000₩</td>
                      <td>7% of the order total price</td>
                      <td>7% of the order total price</td>
                    </tr>
                    <tr>
                      <td className="list">300,000₩</td>
                      <td>6% of the order total price</td>
                      <td>6% of the order total price</td>
                    </tr>
                    <tr>
                      <td className="list">400,000₩ & above</td>
                      <td>5% of the order total price</td>
                      <td>5% of the order total price</td>
                    </tr>
                  </table>
                </div>
                <div className="info_wrap">
                  <span className="info">* KRW = ₩ &nbsp;&nbsp; USD = $</span>
                  <span className="info">
                    * For wholesale orders please contact us at
                    ontactkorea@gmail.com
                  </span>
                </div>
              </div>
              <div id="tab3" className="tab_content">
                <div className="title">
                  <h3>Repack fee</h3>
                </div>
                <div className="table_wrap">
                  <table>
                    <tr>
                      <th className="th_20">Services</th>
                      <th className="th_40">Description</th>
                      <th className="th_20">fee in ₩</th>
                      <th className="th_20">fee in $</th>
                    </tr>
                    <tr>
                      <td className="list">Standard Repack fee</td>
                      <td>
                        Includes new consolidated box Wrapping items in bubble
                        wrap
                      </td>
                      <td>1,000₩ / 1 KG</td>
                      <td>$1 / 1kg</td>
                    </tr>
                  </table>
                </div>

                <h3 className="table_title">※ EXTRA SERVICES</h3>
                <div className="table_wrap">
                  <table>
                    <tr>
                      <th className="th_20">Services</th>
                      <th className="th_40">Description</th>
                      <th className="th_20">fee in ₩</th>
                      <th className="th_20">fee in $</th>
                    </tr>
                    <tr>
                      <td className="list">Combine posters</td>
                      <td>Combining all posters in 1 tube</td>
                      <td>0₩</td>
                      <td>$0</td>
                    </tr>
                    <tr>
                      <td className="list">Combine envelopes</td>
                      <td>Combining all envelopes in 1 small box</td>
                      <td>0₩</td>
                      <td>$0</td>
                    </tr>
                    <tr>
                      <td className="list">Air cap</td>
                      <td>Extra air cap protection added in the box</td>
                      <td>3,000₩</td>
                      <td>$3</td>
                    </tr>
                    <tr>
                      <td className="list">Fragile stickers</td>
                      <td>Stick on 4 sides</td>
                      <td>2,000₩</td>
                      <td>$2</td>
                    </tr>
                    <tr>
                      <td className="list">
                        Vaccuum bag
                        <br />
                        <small style={{ fontSize: "0.8em" }}>Size: 50X60</small>
                      </td>
                      <td>Taking out the air perfect for clothes or dolls</td>
                      <td>5,000₩</td>
                      <td>$5</td>
                    </tr>
                    <tr>
                      <td className="list">Ontact Korea Insurance</td>
                      <td>
                        Insurance for full lost box. Does not cover damage
                        items. Please check our{" "}
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          href="https://docs.google.com/document/d/1SuZi6RC7hWQG9aXqoRLTO2HbZuH0wJ6uondJEFs5Z6s/edit"
                        >
                          <div className="text-link">terms and conditions</div>
                        </a>{" "}
                        for the
                        <span> Ontact Korea Insurance</span>
                      </td>
                      <td>5,000₩</td>
                      <td>$5</td>
                    </tr>
                  </table>
                </div>

                <div className="info_wrap">
                  <span className="info">* KRW = ₩ &nbsp;&nbsp; USD = $</span>
                </div>
              </div>
              <div id="tab4" className="tab_content">
                <div className="title">
                  <h3>Check video</h3>
                </div>
                <div className="table_wrap">
                  <table>
                    <tr>
                      <th className="th_20">Services</th>
                      <th className="th_40">Description</th>
                      <th className="th_20">PRICE IN ₩</th>
                      <th className="th_20">PRICE IN $</th>
                    </tr>
                    <tr>
                      <td className="list">Unboxing video</td>
                      <td>Unboxing video sent to your email</td>
                      <td>ONLY IN USD</td>
                      <td>$5</td>
                    </tr>
                  </table>
                </div>
                <div className="info_wrap">
                  <span className="info">* KRW = ₩ &nbsp;&nbsp; USD = $</span>
                </div>
              </div>
              <div id="tab5" className="tab_content">
                <div className="title">
                  <h3>Split</h3>
                </div>
                <div className="table_wrap">
                  <table>
                    <tr>
                      <th className="th_20">Services</th>
                      <th className="th_40">Description</th>
                      <th className="th_20">PRICE IN ₩</th>
                      <th className="th_20">PRICE IN $</th>
                    </tr>
                    <tr>
                      <td className="list">Split</td>
                      <td>
                        Divide a tracking number in as many box as you need.
                      </td>
                      <td>5,000₩ / tracking number</td>
                      <td>$5 / tracking number</td>
                    </tr>
                  </table>
                </div>
                <div className="info_wrap">
                  <span className="info">* KRW = ₩ &nbsp;&nbsp; USD = $</span>
                </div>
              </div>
              <div id="tab6" className="tab_content">
                <div className="title">
                  <h3>Membership</h3>
                </div>
                <div className="table_wrap">
                  <table>
                    <tr>
                      <th className="th_25">Plan</th>
                      <th className="th_50">Benefits</th>
                      <th className="th_25">Price IN $ ONLY</th>
                    </tr>
                    <tr>
                      <td className="list">3 months membership</td>
                      <td>
                        ✔ No storage fee for 90 days<br></br>✔ 3 free check
                        content coupon<br></br>✔ 25% Repack fee discount{" "}
                        <br></br>✔ 7% Custom order fee<br></br>
                      </td>
                      <td>$90</td>
                    </tr>
                    <tr>
                      <td className="list">1 year membership</td>
                      <td>
                        ✔ No storage fee for 365 days<br></br>✔ 12 free check
                        content coupon<br></br>✔ No Repack cost<br></br>✔ 5%
                        Custom order cost<br></br>
                      </td>
                      <td>$360</td>
                    </tr>
                  </table>
                </div>
                <div className="info_wrap">
                  <span className="info">* KRW = ₩ &nbsp;&nbsp; USD = $</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <footer>
          <div className="container_wrap after">
            <div className="part">
              <h3>Korea wave</h3>
              <p>
                CEO : Kim ho hwang &nbsp;|&nbsp; Business Number : 437-88-03231
                <br />
                1F, 36, Jwasuyeong-ro 210beon-gil, Suyeong-gu, Busan, Republic
                of Korea, 48213
              </p>
            </div>
            <div className="part">
              <h3>Contact Us</h3>
              <p>
                Tel : 051-751-9020
                <br />
                Email : ontactkorea@gmail.com
                <br />
                CS : +82 10-2716-9020
                <br />
                Mon-Fri : 10AM ~ 18PM (excluded weekend, holiday)
              </p>
            </div>
          </div>
        </footer>

        {/* Scroller */}
        <a
          href="#"
          className="scroll-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short" />
        </a>
      </div>
    </LandingWrapper>
  );
};

const LandingWrapper = styled.div`
  margin-top: 80px;
  #landing-page > div {
    display: flex;
    justify-content: center;
  }
`;

export default Pricing;
