import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Pagination, Spinner, Table } from "react-bootstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import { getAccessTokenFromLocalStorage } from "../../../common/constants";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { reqPutExpress } from "../../../requests/ship";
const Container = styled.div`
  margin: 20px;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  input {
    margin-left: 10px;
  }
`;

const RequestButton = styled(Button)`
  margin-top: 10px;
  margin-left: 10px;
`;

const ShipDetailsTable = styled(Table)`
  margin-top: 10px;
  overflow-x: auto;
`;

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const RequestButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const LoadingText = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin: 0;
`;

interface PackingDetail {
  id: string;
  orderId: string;
  totalWeightKg: number;
  totalHeightCm: number;
  totalWidthCm: number;
  totalLengthCm: number;
  trackingNumbers: string;
  suspectBattery: boolean;
  express: string;
  status: number;
  warehouseId: string;
  food: boolean;
  imageUrl: string;
  isDeleted: boolean;
  inputBox: string;
  userName: string; // Add user name to the details
}

interface ShipDetail {
  id: string;
  userId: string;
  paypalTransactionId: string | null;
  paypalPayAmount: number;
  adrCity: string;
  adrCountry: number;
  adrEmail: string;
  adrOption1: string;
  adrOption2: string | null;
  adrPhone: string;
  adrReceiver: string;
  adrState: string;
  adrZip: string;
  createdAt: string;
  customerMessage: string | null;
  depositor: string | null;
  detail: PackingDetail[];
  express: string | null;
  totalHeightCm: string;
  totalLengthCm: string;
  totalWeightKg: string;
  totalWidthCm: string;
  trackingNumbers: string;
  updatedAt: string;
  company: number;
  user: {
    name: string;
    membershipId: number;
  };
}

export default function AWBoxReleased() {
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState<ShipDetail[]>([]);
  const [searchBoxNumber, setSearchBoxNumber] = useState("");
  const [searchSpaceCode, setSearchSpaceCode] = useState("");
  const [searchTrackingNumber, setSearchTrackingNumber] = useState("");
  const [selectedDetail, setSelectedDetail] = useState<PackingDetail | null>(
    null
  );
  const [editWarehouseId, setEditWarehouseId] = useState<string | null>(null);
  const [selectedDetails, setSelectedDetails] = useState<Set<string>>(
    new Set()
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [statusFilter, setStatusFilter] = useState<number | null>(0);
  const [searchField, setSearchField] = useState<string>("박스번호");
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = getAccessTokenFromLocalStorage();
      if (!accessToken) {
        console.error("Access token is missing");
        return;
      }

      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}ship/ontact`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setDetails(response.data);
        setTotalPages(Math.ceil(response.data.length / itemsPerPage));
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSaveChanges = async (detail: PackingDetail) => {
    try {
      await reqPutExpress({ id: detail.id, warehouseId: detail.warehouseId });
      setEditWarehouseId(null);
      Swal.fire(
        "저장 완료",
        "창고 위치가 성공적으로 저장되었습니다.",
        "success"
      );
    } catch (error) {
      console.error("Error updating data: ", error);
      Swal.fire("저장 실패", "창고 위치 저장 중 오류가 발생했습니다.", "error");
    }
  };

  const handleCheckboxChange = (id: string) => {
    setSelectedDetails((prevState) => {
      const newState = new Set(prevState);
      if (newState.has(id)) {
        newState.delete(id);
      } else {
        newState.add(id);
      }
      return newState;
    });
  };

  const handleSubmitExpress = async () => {
    const result = await Swal.fire({
      title: "출고신청 확인",
      text: "정말로 출고신청 하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "네, 출고신청합니다!",
      cancelButtonText: "아니오, 취소합니다",
    });

    if (result.isConfirmed) {
      try {
        await Promise.all(
          Array.from(selectedDetails).map((id) =>
            reqPutExpress({ id, status: "1" })
          )
        );
        setSelectedDetails(new Set());
        Swal.fire(
          "출고신청 완료",
          "출고신청이 성공적으로 완료되었습니다.",
          "success"
        ).then(() => {
          handlePrint("출고");
          window.location.reload();
        });
      } catch (error) {
        console.error("Error updating data: ", error);
        Swal.fire("출고신청 실패", "출고신청 중 오류가 발생했습니다.", "error");
      }
    }
  };

  const handleDispose = async () => {
    const result = await Swal.fire({
      title: "폐기신청 확인",
      text: "정말로 폐기신청 하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "네, 폐기신청합니다!",
      cancelButtonText: "아니오, 취소합니다",
    });

    if (result.isConfirmed) {
      try {
        await Promise.all(
          Array.from(selectedDetails).map((id) =>
            reqPutExpress({ id, status: "2" })
          )
        );
        setSelectedDetails(new Set());
        Swal.fire(
          "폐기신청 완료",
          "폐기신청이 성공적으로 완료되었습니다.",
          "success"
        ).then(() => {
          handlePrint("폐기");
          window.location.reload();
        });
      } catch (error) {
        console.error("Error updating data: ", error);
        Swal.fire("폐기신청 실패", "폐기신청 중 오류가 발생했습니다.", "error");
      }
    }
  };

  const handlePrint = (action: string) => {
    const selectedRecords = currentRecords.filter((detail) =>
      selectedDetails.has(detail.id)
    );
    const printWindow = window.open("", "", "height=800,width=800");
    if (printWindow) {
      printWindow.document.write("<html><head><title>Print</title>");
      printWindow.document.write(
        "<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 8px; text-align: left; } th { background-color: #f2f2f2; }</style>"
      );
      printWindow.document.write("</head><body>");
      printWindow.document.write("<table><thead><tr>");
      printWindow.document.write("<th>NO</th>");
      printWindow.document.write("<th>박스 번호</th>");
      printWindow.document.write("<th>스페이스코드</th>");
      printWindow.document.write("<th>이름</th>");
      printWindow.document.write("<th>특송사</th>");
      printWindow.document.write("<th>특송사 송장번호</th>");
      printWindow.document.write("<th>상태</th>");
      printWindow.document.write("<th>저장위치</th>");
      printWindow.document.write("</tr></thead><tbody>");
      selectedRecords.forEach((detail, index) => {
        printWindow.document.write("<tr>");
        printWindow.document.write(`<td>${index + 1}</td>`);
        printWindow.document.write(`<td>${detail.id}</td>`);
        printWindow.document.write(`<td>${detail.userId}</td>`);
        printWindow.document.write(`<td>${detail.userName}</td>`);
        printWindow.document.write(
          `<td>${getStatusLabel(detail.company)}</td>`
        );
        printWindow.document.write(`<td>${detail.express}</td>`);
        printWindow.document.write(`<td>${action}</td>`);
        printWindow.document.write(`<td>${detail.warehouseId || "N/A"}</td>`);
        printWindow.document.write("</tr>");
      });
      printWindow.document.write("</tbody></table></body></html>");
      printWindow.document.close();
      printWindow.print();
    }
  };

  const filteredRecords = details
    .flatMap((detail) =>
      detail.detail
        .filter((d) => statusFilter === null || d.status === statusFilter)
        .map((d) => ({
          ...d,
          userId: detail.userId,
          company: detail.company,
          userName: detail.user ? detail.user.name : "N/A",
        }))
    )
    .filter((detail) => {
      const trimmedTerm = searchTerm.trim();
      if (searchField === "박스번호") {
        return detail.id.includes(trimmedTerm);
      } else if (searchField === "스페이스코드") {
        return detail.userId.includes(trimmedTerm);
      } else if (searchField === "특송사 송장번호") {
        return detail.express && detail.express.includes(trimmedTerm);
      }
      return true;
    });

  useEffect(() => {
    setTotalPages(
      itemsPerPage === -1 ? 1 : Math.ceil(filteredRecords.length / itemsPerPage)
    );
  }, [filteredRecords, itemsPerPage]);

  const currentRecords =
    itemsPerPage === -1
      ? filteredRecords
      : filteredRecords.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    const maxPageItems = 5;
    const isEllipsisStart = currentPage > 3;
    const isEllipsisEnd = currentPage < totalPages - 2;

    // Add first page
    paginationItems.push(
      <Pagination.Item
        key={1}
        active={1 === currentPage}
        onClick={() => handlePageChange(1)}
      >
        1
      </Pagination.Item>
    );

    if (isEllipsisStart) {
      paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    // Determine start and end pages
    let startPage = Math.max(2, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    // Add page numbers
    for (let number = startPage; number <= endPage; number++) {
      paginationItems.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (isEllipsisEnd) {
      paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
    }

    // Add last page
    if (totalPages > 1) {
      paginationItems.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(e.target.value);
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  const handleStatusFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value =
      e.target.value === "null" ? null : parseInt(e.target.value, 10);
    setStatusFilter(value);
  };

  return (
    <Container>
      <AdminHeadTitle
        subtitle1="창고 관리"
        subtitle2="박스 출고 요청 내역"
        title="박스 출고 요청 내역"
      />
      <div className="row mt-3">
        <div className="col-md-3">
          <label htmlFor="statusFilter" className="form-label">
            상태
          </label>
          <select
            id="statusFilter"
            className="form-select"
            value={statusFilter ?? "null"}
            onChange={handleStatusFilterChange}
          >
            <option value="null">전체</option>
            <option value={0}>보관</option>
            <option value={1}>출고</option>
            <option value={2}>폐기</option>
          </select>
        </div>
        <div className="col-md-3">
          <label htmlFor="itemsPerPage" className="form-label">
            페이지 당 항목 수
          </label>
          <select
            id="itemsPerPage"
            className="form-select"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={10}>10개씩 보기</option>
            <option value={20}>20개씩 보기</option>
            <option value={50}>50개씩 보기</option>
            <option value={-1}>전체 보기</option>
          </select>
        </div>
        <div className="col-md-3">
          <label htmlFor="searchField" className="form-label">
            검색 필터링
          </label>
          <Form.Select
            id="searchField"
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
          >
            <option value="박스번호">박스번호</option>
            <option value="스페이스코드">스페이스코드</option>
            <option value="특송사 송장번호">특송사 송장번호</option>
          </Form.Select>
        </div>
        <div className="col-md-3">
          <label htmlFor="searchTerm" className="form-label">
            검색
          </label>
          <input
            id="searchTerm"
            type="text"
            className="form-control"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value.toUpperCase().trim())}
            placeholder={searchField}
          />
        </div>
      </div>

      {isLoading ? (
        <LoadingContainer>
          <Spinner animation="border" variant="primary" />
          <LoadingText>데이터를 불러오는 중입니다...</LoadingText>
        </LoadingContainer>
      ) : (
        <>
          <ResponsiveTable>
            <thead>
              <tr>
                <th>체크박스</th>
                <th>박스 번호</th>
                <th>스페이스코드</th>
                <th>이름</th>
                <th>특송사</th>
                <th>특송사 송장번호</th>
                <th>상태</th>
                <th>인풋박스(출고위치)</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((detail, index) => (
                <tr key={detail.id}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={selectedDetails.has(detail.id)}
                      onChange={() => handleCheckboxChange(detail.id)}
                    />
                  </td>
                  <td>{detail.id}</td>
                  <td>{detail.userId}</td>
                  <td>{detail.userName}</td>
                  <td>{getStatusLabel(detail.company)}</td>
                  <td>{detail.express}</td>
                  <td>
                    {detail.status === 0
                      ? "보관"
                      : detail.status === 1
                      ? "출고"
                      : "폐기"}
                  </td>
                  <td>
                    {editWarehouseId === detail.id ? (
                      <div>
                        <input
                          type="text"
                          value={detail.warehouseId}
                          onChange={(e) => {
                            const updatedWarehouseId = e.target.value;
                            setDetails((prevState) =>
                              prevState.map((shipDetail) => {
                                if (
                                  shipDetail.detail.some(
                                    (d) => d.id === detail.id
                                  )
                                ) {
                                  return {
                                    ...shipDetail,
                                    detail: shipDetail.detail.map((d) =>
                                      d.id === detail.id
                                        ? {
                                            ...d,
                                            warehouseId: updatedWarehouseId,
                                          }
                                        : d
                                    ),
                                  };
                                }
                                return shipDetail;
                              })
                            );
                          }}
                        />
                        <Button
                          variant="link"
                          onClick={() => handleSaveChanges(detail)}
                        >
                          저장
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <span>{detail.warehouseId || "N/A"}</span>
                        <Button
                          variant="link"
                          onClick={() => setEditWarehouseId(detail.id)}
                        >
                          수정
                        </Button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </ResponsiveTable>

          <RequestButtonContainer>
            <Button variant="primary" onClick={handleSubmitExpress}>
              출고신청 버튼
            </Button>
            <Button variant="danger" onClick={handleDispose}>
              폐기 버튼
            </Button>
          </RequestButtonContainer>

          <CenteredPagination style={{ marginTop: "1rem" }}>
            {renderPaginationItems()}
          </CenteredPagination>
        </>
      )}
    </Container>
  );
}

const getStatusLabel = (company: number) => {
  switch (company) {
    case 0:
      return "요청서";
    case 1:
      return "FEDEX";
    case 2:
      return "UPS";
    case 3:
      return "EMS";
    case 4:
      return "PACKET";
    case 5:
      return "CJ";
    default:
      return "미정";
  }
};

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; // Allows the table to adjust width dynamically

  th,
  td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
  }

  // Using media queries to adjust font size and padding for smaller screens
  @media (max-width: 768px) {
    font-size: 0.8em; // Smaller text on smaller screens
    th,
    td {
      padding: 6px;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.7em; // Even smaller text for mobile devices
    th,
    td {
      padding: 4px;
    }
  }
`;
