import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  CODE_SHOPING_PRODUCT_STATUS,
  CODE_SHOP_STATUS,
  USER_BADGE,
} from "../../../common/constants";
import { DATA_TYPE } from "../../../common/constants";
import FormatData from "../../../components/FormatData";
import Icon from "../../../components/Icon";
import CShopOrderModal from "../../../components/modal/CShopOrderModal";
import { RootUserState } from "../../../redux/store";
import { reqReadBadge } from "../../../requests/badge";
import { reqAShop, requserShip } from "../../../requests/shop";
import MTitle from "../MTitle";
import CustomerPageTitle from "../../../components/CustomerPageTitle";
import { Table } from "react-bootstrap";
import { formatDate } from "../../../common/format";
type OrderData = {
  orderId: string;
  userId: string;
  category: number;
  productId: string;
  createdAt: string;
  paymentDeadline: string;
  preorder: boolean;
  fancall: boolean;
  totalPriceKRW: number;
  PaymentMethod: number;
  status: number;
};

const CShopPay: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<OrderData | null>(null);
  const [orders, setOrders] = useState<OrderData[]>([]);
  const user = useSelector((state: RootUserState) => state.user);

  useEffect(() => {
    fetchOrders();

    const badgePayload = {
      userId: user.id,
      badgeName: USER_BADGE.SHOP_PAYMENT_WAITING,
    };
    reqReadBadge(badgePayload);
  }, [user?.id]);

  const fetchOrders = async () => {
    try {
      const response = await requserShip(user.id);
      const statusesToInclude = [CODE_SHOPING_PRODUCT_STATUS.WAIT_PAY];
      const filteredOrders = response.data.filter(
        (order: OrderData) =>
          order.userId === user.id && statusesToInclude.includes(order.status)
      );
      setOrders(filteredOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const handleOrderClick = (order: OrderData) => {
    setCurrentOrder(order);
    setShowModal(true);
  };

  const refreshData = () => {
    fetchOrders();
  };

  const MobileView = () => {
    return (
      <MobileWrapper>
        <CustomerPageTitle title="Pending Payments" />
        <Box>
          {orders.length === 0 ? (
            <div style={{ 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "center", 
              justifyContent: "center", 
              minHeight: "200px",
              textAlign: "center",
              padding: "2rem 0"
            }}>
              <i className="bi bi-exclamation-circle" style={{ fontSize: '2.5rem', color: '#6c757d', marginBottom: '1rem' }}></i>
              <p style={{ fontSize: '1.1rem', color: '#666', margin: 0 }}>No data available</p>
            </div>
          ) : (
            orders.map((order, index) => (
              <div
                key={order.orderId}
                onClick={() => handleOrderClick(order)}
                style={{
                  cursor: "pointer",
                  borderBottom: "1px solid lightgray",
                  paddingBottom: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <Row>
                  <Label>Id</Label>
                  <P>{index + 1}</P>
                </Row>

                <Row>
                  <Label>Order Number</Label>
                  <P>{order.orderId}</P>
                </Row>

                <Row>
                  <Label>Order date</Label>
                  <P>{formatDate(order.createdAt)}</P>
                </Row>

                <Row>
                  <Label>Closing of payment</Label>
                  <P>{formatDate(order.paymentDeadline)}</P>
                </Row>

                <Row>
                  <Label>Status</Label>
                  <P>{CODE_SHOP_STATUS[order.status]}</P>
                </Row>
              </div>
            ))
          )}
        </Box>
      </MobileWrapper>
    );
  };

  return (
    <>
      {isMobile ? (
        <MobileView />
      ) : (
        <>
          <div style={{ padding: "30px" }}>
            <CustomerPageTitle title="Pending Payments" />

            <section className="container-fluid" id="about">
              {orders.length === 0 ? (
                <div style={{ 
                  display: "flex", 
                  flexDirection: "column", 
                  alignItems: "center", 
                  justifyContent: "center", 
                  minHeight: "400px",
                  textAlign: "center"
                }}>
                  <i className="bi bi-exclamation-circle" style={{ fontSize: '3rem', color: '#6c757d', marginBottom: '1rem' }}></i>
                  <p style={{ fontSize: '1.2rem', color: '#666', margin: 0 }}>No data available</p>
                </div>
              ) : (
                <div className="table-responsive">
                  <Table className="table-custom text-center">
                    <thead className="table-light">
                      <tr translate="no">
                        <th scope="col" className="font-large-page-title">
                          NO
                        </th>
                        <th scope="col" className="font-large-page-title">
                          Order Number
                        </th>
                        <th scope="col" className="font-large-page-title">
                          Order date
                        </th>
                        <th scope="col" className="font-large-page-title">
                          Closing of payment
                        </th>
                        <th scope="col" className="font-large-page-title">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((order, index) => (
                        <tr>
                          <td className="font-large-page">{index + 1}</td>
                          <td
                            key={order.orderId}
                            className="ontactweb-height"
                            onClick={() => handleOrderClick(order)}
                            style={{
                              cursor: "pointer",
                              color: "var(--color-main-blue)",
                              textDecoration: "underline",
                              fontSize: "1.3em",
                            }}
                          >
                            {order.orderId}
                          </td>
                          <td className="font-large-page">
                            {formatDate(order.createdAt)}
                          </td>
                          <td className="font-large-page">
                            {formatDate(order.paymentDeadline)}
                          </td>
                          <td className="font-large-page">
                            {FormatData(
                              DATA_TYPE.CODE_SHOPS_STATUS,
                              order.status
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </section>
          </div>
        </>
      )}

      {currentOrder && (
        <CShopOrderModal
          show={showModal}
          onHide={() => setShowModal(false)}
          orderId={currentOrder.orderId}
          refreshData={refreshData}
          status={currentOrder.status}
        />
      )}
    </>
  );
};

export default CShopPay;

const Title = styled.h3`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  margin-top: 1rem;
`;
const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
  padding: 1.4rem;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;
