import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Dropdown,
  Form,
  FormControl,
  Modal,
  Row,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  CODE_COUNTRY_ITEMS,
  CODE_SHOPING_ORDER_STATUS,
  CODE_SHOPPING_ORDER_ITEMS,
} from "../../common/constants";
import { formatDate } from "../../common/format";
import {
  MButton,
  MFooter,
  MRow,
  MTwoButtonRow,
} from "../../common/mobile-styled";
import { RootUserState } from "../../redux/store";
import {
  reqShop,
  reqShopDetail,
  reqShopDetailUpdate,
  reqShopDetailUpdates,
  reqShopUpdate,
  reqShopUpdates,
} from "../../requests/shop";
import Icon from "../Icon";
interface ShopOrderModalProps {
  show: boolean;
  onHide: () => void;
  orderId: string;
  refreshData: () => void;
}
interface OrderDetail {
  order_id: string;
  productId: string;
  category: number;
  status: number;
  name: string;
  option1: string;
  option2: string;
  quantity: number;
  price: number;
  ShippingFee: number;
  url: string;
  fee: number;
  preorder: boolean;
  preorderStartAt: string;
  preorderEndAt: string;
  paymentDeadline: string;
  cancellationReason: string;
  fancallName: string;
  fancall: boolean;
  fancallBirthday: string;
  fancallNumber: string;
  fancallEmail: string;
  fancallCountry: number;
  fancallKakao: string;
  fancallSnsLine: string;
  isCanceled: boolean;
}
interface UpdatedOrderDetails {
  [key: string]: OrderDetail;
}

interface Product {
  id: string;
  name: string;
  category: number;
  option1: string;
  option2: string;
  quantity: number;
  price: number;
  url: string;
  preorder: boolean;
  preorderStartAt: string | null;
  preorderEndAt: string | null;
  fancallName: string;
  fancall: boolean;
  fancallBirthday: string | null;
  fancallNumber: string;
  fancallEmail: string;
  fancallCountry: number;
  fancallKakao: string;
  fancallSnsLine: string;
  isCanceled: boolean;
}

const CShopOrderWiseModal = ({
  show,
  onHide,
  orderId,
  refreshData,
}: ShopOrderModalProps) => {
  const [orderDetails, setOrderDetails] = useState<OrderDetail[]>([]);
  const [products, setProducts] = useState<Product[]>([
    {
      id: Date.now().toString(),
      name: "",
      category: 0,
      option1: "",
      option2: "",
      quantity: 0,
      price: 0,
      url: "",
      preorder: false,
      fancall: false,
      preorderStartAt: null,
      preorderEndAt: null,
      fancallName: "",
      fancallBirthday: null,
      fancallNumber: "",
      fancallEmail: "",
      fancallCountry: 0,
      fancallKakao: "",
      fancallSnsLine: "",
      isCanceled: false,
    },
  ]);
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const user = useSelector((state: RootUserState) => state.user);
  const [serviceFee, setServiceFee] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [confirmCancelModalShow, setConfirmCancelModalShow] = useState(false);
  const canModify =
    orderDetails.length > 0 &&
    orderDetails.every((detail) => detail.status === 1);

  const handleModifyClick = () => {
    if (!canModify) {
      Swal.fire({
        title: "수정 불가",
        text: "현재 상태에서는 상품을 수정할 수 없습니다. 상태가 1인 상품만 수정 가능합니다.",
        icon: "warning",
        confirmButtonText: "확인",
      });
      return;
    }
    setIsEditMode(true);
  };

  const handleClose = () => {
    setIsEditMode(false);
    onHide();
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    setProducts(
      orderDetails.map((detail) => {
        return {
          id: detail.productId,
          name: detail.name,
          category: detail.category,
          option1: detail.option1,
          option2: detail.option2,
          quantity: detail.quantity,
          price: detail.price,
          url: detail.url,
          preorder: detail.preorder,
          preorderStartAt: detail.preorderStartAt || null,
          preorderEndAt: detail.preorderEndAt || null,
          fancall: detail.fancall,
          fancallName: detail.fancallName || "",
          fancallBirthday: detail.fancallBirthday || null,
          fancallNumber: detail.fancallNumber || "",
          fancallEmail: detail.fancallEmail || "",
          fancallCountry: detail.fancallCountry,
          fancallKakao: detail.fancallKakao || "",
          fancallSnsLine: detail.fancallSnsLine || "",
          isCanceled: false,
        };
      })
    );
  }, [orderDetails, isEditMode, show]);

  const handleSaveChanges = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, save it!",
    });

    if (result.isConfirmed) {
      const isAnyFieldMissing = orderDetails.some((detail) => {
        const missing =
          detail.name === "" ||
          detail.category === 0 ||
          detail.quantity === 0 ||
          detail.price === 0 ||
          detail.url === "";
        return missing;
      });

      if (isAnyFieldMissing) {
        Swal.fire(
          "Error!",
          "Please fill in all required fields for every product (Product name, Category, Quantity, Price and Site URL).",
          "error"
        );
        return;
      }

      if (orderDetails.some((detail) => detail.preorder)) {
        await reqShopUpdate({
          order_id: orderId,
          preorder: true,
          fancall: false,
        });
      }

      if (orderDetails.some((detail) => detail.fancall)) {
        await reqShopUpdate({
          order_id: orderId,
          fancall: true,
          preorder: false,
        });
      }

      const updatePromises = products.map((product) => {
        const { id, ...updateData } = product;

        return reqShopDetailUpdates(id, updateData);
      });

      try {
        await Promise.all(updatePromises);

        Swal.fire(
          "Saved!",
          "Your order details have been updated.",
          "success"
        ).then(() => {
          handleClose();
          window.location.reload();
          // refreshData();
        });
      } catch (error) {
        console.error("주문 상세 정보 업데이트 실패:", error);
        Swal.fire("Error!", "Failed to update order details.", "error");
      }
    }
  };

  const [openItems, setOpenItems] = useState<{
    [key: number | string]: boolean;
  }>({});

  useEffect(() => {
    const initialOpenItems: { [key: number | string]: boolean } = {};
    products.forEach((product, index) => {
      initialOpenItems[index] = true;
      initialOpenItems[`fancall-${index}`] = false;
    });
    setOpenItems(initialOpenItems);
  }, [products]);

  const toggleItem = (index: number) => {
    setOpenItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const toggleFancallItem = (index: number) => {
    setOpenItems((prev) => ({
      ...prev,
      [`fancall-${index}`]: !prev[`fancall-${index}`],
    }));
  };

  const handleProductChange = (
    index: number,
    field: keyof Product,
    value: any
  ) => {
    const newValue = field === "fancallCountry" ? parseInt(value, 10) : value;
    setProducts((prevDetails) =>
      prevDetails.map((detail, idx) =>
        idx === index ? { ...detail, [field]: newValue } : detail
      )
    );
  };

  // useEffect(() => {
  //   if (orderId) {
  //     fetchOrderDetails(orderId);
  //   }
  // }, [orderId]);

  // const handleOrderDetailChange = (
  //   index: number,
  //   field: keyof OrderDetail,
  //   value: any
  // ) => {
  //   setOrderDetails((prevDetails) =>
  //     prevDetails.map((detail, idx) =>
  //       idx === index ? { ...detail, [field]: value } : detail
  //     )
  //   );
  // };

  const fetchOrderDetails = async (order_id: string) => {
    try {
      const detailResponse = await reqShopDetail({ order_id });
      const detailsWithStatus: OrderDetail[] = detailResponse.data.map(
        (item: OrderDetail) => ({
          ...item,
          isCanceled: item.status === 10,
        })
      );
      setOrderDetails(detailsWithStatus);

      const shopResponse = await reqShop({ order_id });
      setServiceFee(shopResponse.data.fee.toString());
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const handleCancelOrderClick = () => {
    setConfirmCancelModalShow(true);
  };

  const handleConfirmCancelOrder = async () => {
    try {
      setConfirmCancelModalShow(false);
      await handleCancelOrder();
    } catch (error) {
      console.error("Cancellation failed:", error);
    }
  };

  const handleCancelOrder = async () => {
    try {
      const updateData = {
        order_id: orderId,
        status: CODE_SHOPING_ORDER_STATUS.CANCELED,
        productId: user.name,
      };

      await reqShopUpdates(updateData);

      Swal.fire({
        title: "Success!",
        text: "Your order has been canceled.",
        icon: "success",
        confirmButtonText: "OK",
      });

      onHide();
      refreshData();
    } catch (error) {
      if (error instanceof Error) {
        const response = (error as any).response;

        if (response && response.status === 500) {
          Swal.fire({
            title: "Error!",
            text: "This item is already on order. Please refresh and try again.",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "Order cancellation failed.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } else {
        // Handle unknown errors
        Swal.fire({
          title: "Error!",
          text: "An unknown error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleReapplyOrderClick = async () => {
    await handleReapplyOrder(orderId);
  };

  const handleReapplyOrder = async (orderId: string) => {
    try {
      await reqShopDetailUpdate({
        order_id: orderId,
        isCanceled: false,
        status: CODE_SHOPING_ORDER_STATUS.CANCELED,
      });
      alert("Order has been reapplied successfully.");
      refreshData();
    } catch (error) {
      console.error("Reapply order failed:", error);
      alert("Failed to reapply the order.");
    }
  };

  const handleBack = () => {
    setIsEditMode(false);
  };

  const toggleSection = (index: number, section: "preorder" | "fancall") => {
    const newProducts = products.map((product, idx) => {
      if (idx === index) {
        const updatedSection = { [section]: !product[section] };

        if (updatedSection[section]) {
          if (section === "preorder") {
            return { ...product, ...updatedSection, fancall: false };
          } else {
            return { ...product, ...updatedSection, preorder: false };
          }
        } else {
          return { ...product, ...updatedSection };
        }
      }
      return product;
    });

    setProducts(newProducts);
  };

  const handleFancallCountrySelect = (countryId: number, index: number) => {
    const newProducts = [...products];
    newProducts[index].fancallCountry = countryId;
    setProducts(newProducts);
  };

  const filteredCountries = Object.entries(CODE_COUNTRY_ITEMS).filter(
    ([id, name]) => {
      if (Array.isArray(name)) {
        return name.some((n) =>
          n.toLowerCase().includes(countrySearchTerm.toLowerCase())
        );
      } else {
        return name.toLowerCase().includes(countrySearchTerm.toLowerCase());
      }
    }
  );

  return (
    <Fragment>
      <Modal show={show && !isEditMode} onHide={onHide} size="xl" centered>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "var(--color-lightgray)" }}
        >
          <MTitle id="contained-modal-title-vcenter">{orderId}</MTitle>
        </Modal.Header>

        <Modal.Body>
          <div className="accordion" id="accordionPreview">
            {products.map((product, index) => (
              <div className="accordion-item" key={index}>
                <div
                  className="accordion-header"
                  id={`heading${index}`}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (product.isCanceled) {
                        handleReapplyOrder(product.id);
                      } else {
                        toggleItem(index);
                      }
                    }}
                    style={{
                      padding: ".5rem",
                    }}
                  >
                    <Icon
                      icon={openItems[index] ? "caret-up" : "caret-down-fill"}
                    ></Icon>
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    {product.id} {product.isCanceled ? "(Canceled)" : ""}
                  </div>
                </div>

                <Collapse in={openItems[index] ?? true}>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                  >
                    <div
                      className="accordion-body"
                      style={{ padding: "0", margin: "0 2.2rem" }}
                    >
                      {isMobile ? (
                        <div>
                          <MRow>
                            <div className="label">Category</div>
                            <div className="data">
                              {CODE_SHOPPING_ORDER_ITEMS[product.category]}
                            </div>
                          </MRow>
                          <MRow>
                            <div className="label">Product Name</div>
                            <div className="data">{product.name}</div>
                          </MRow>
                          <MRow>
                            <div className="label">Option 1</div>
                            <div className="data">{product.option1 || "-"}</div>
                          </MRow>
                          <MRow>
                            <div className="label">Option 2</div>
                            <div className="data">{product.option2 || "-"}</div>
                          </MRow>
                          <MRow>
                            <div className="label">Quantity</div>
                            <div className="data">{product.quantity}</div>
                          </MRow>
                          <MRow>
                            <div className="label">Unit Price</div>
                            <div className="data">{product.price}</div>
                          </MRow>
                          <MRow>
                            <div className="label">Link</div>
                            <div
                              className="data"
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                marginLeft: "1rem",
                              }}
                            >
                              <a
                                href={`http${
                                  product.url.includes("://") ? "" : "s://"
                                }${product.url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="d-inline-block text-truncate"
                                style={{ maxWidth: "100%" }}
                              >
                                {product.url}
                              </a>
                            </div>
                          </MRow>
                          {product.preorder && (
                            <MRow>
                              <div className="label">Pre order</div>
                              <div className="data">
                                {formatDate(product.preorderStartAt as string)}{" "}
                                ~ {formatDate(product.preorderEndAt as string)}
                              </div>
                            </MRow>
                          )}
                          {product.fancall && (
                            <>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleFancallItem(index);
                                }}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "0 .5rem .5rem 0",
                                  cursor: "pointer",
                                  gap: ".5rem",
                                }}
                              >
                                <Icon
                                  icon={
                                    openItems[`fancall-${index}`]
                                      ? "caret-up"
                                      : "caret-down-fill"
                                  }
                                ></Icon>
                                <b>Fan Call</b>
                              </div>
                              <Collapse
                                in={openItems[`fancall-${index}`] ?? false}
                              >
                                <div>
                                  <MRow>
                                    <div className="label">Fancall Name</div>
                                    <div className="data">
                                      {product.fancallName}
                                    </div>
                                  </MRow>
                                  <MRow>
                                    <div className="label">
                                      Fancall Birthday
                                    </div>
                                    <div className="data">
                                      {formatDate(
                                        product.fancallBirthday as string
                                      )}
                                    </div>
                                  </MRow>
                                  <MRow>
                                    <div className="label">Fancall Number</div>
                                    <div className="data">
                                      {product.fancallNumber}
                                    </div>
                                  </MRow>
                                  <MRow>
                                    <div className="label">Fancall Email</div>
                                    <div className="data">
                                      {product.fancallEmail}
                                    </div>
                                  </MRow>
                                  <MRow>
                                    <div className="label">Fancall Country</div>
                                    <div className="data">
                                      {
                                        CODE_COUNTRY_ITEMS[
                                          product.fancallCountry
                                        ]
                                      }
                                    </div>
                                  </MRow>
                                  <MRow>
                                    <div className="label">Fancall Kakao</div>
                                    <div className="data">
                                      {product.fancallKakao}
                                    </div>
                                  </MRow>
                                  <MRow>
                                    <div className="label">
                                      Fancall Sns Line
                                    </div>
                                    <div className="data">
                                      {product.fancallSnsLine}
                                    </div>
                                  </MRow>
                                </div>
                              </Collapse>
                            </>
                          )}
                        </div>
                      ) : (
                        <table className="table">
                          <tbody>
                            <tr>
                              <th>Category</th>
                              <td>
                                {CODE_SHOPPING_ORDER_ITEMS[product.category]}
                              </td>
                            </tr>
                            <tr>
                              <th>Product Name</th>
                              <td>{product.name}</td>
                            </tr>
                            <tr>
                              <th>Option 1</th>
                              <td>{product.option1 || "-"}</td>
                            </tr>
                            <tr>
                              <th>Option 2</th>
                              <td>{product.option2 || "-"}</td>
                            </tr>
                            <tr>
                              <th>Quantity</th>
                              <td>{product.quantity}</td>
                            </tr>
                            <tr>
                              <th>Unit Price</th>
                              <td>{product.price}</td>
                            </tr>
                            <tr>
                              <th>Link</th>
                              <td>
                                <a
                                  href={`http${
                                    product.url.includes("://") ? "" : "s://"
                                  }${product.url}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="d-inline-block text-truncate"
                                  style={{ maxWidth: "700px" }}
                                >
                                  {product.url}
                                </a>
                              </td>
                            </tr>
                            {product.preorder && (
                              <tr>
                                <th>Pre order</th>
                                <td>
                                  {formatDate(
                                    product.preorderStartAt as string
                                  )}{" "}
                                  ~{" "}
                                  {formatDate(product.preorderEndAt as string)}
                                </td>
                              </tr>
                            )}
                            {product.fancall && (
                              <>
                                <tr>
                                  <th>Fancall Name</th>
                                  <td>{product.fancallName}</td>
                                </tr>
                                <tr>
                                  <th>Fancall Birthday</th>
                                  <td>
                                    {formatDate(
                                      product.fancallBirthday as string
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Fancall Number</th>
                                  <td>{product.fancallNumber}</td>
                                </tr>
                                <tr>
                                  <th>Fancall Email</th>
                                  <td>{product.fancallEmail}</td>
                                </tr>
                                <tr>
                                  <th>Fancall Country</th>
                                  <td>
                                    {CODE_COUNTRY_ITEMS[product.fancallCountry]}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Fancall Kakao</th>
                                  <td>{product.fancallKakao}</td>
                                </tr>
                                <tr>
                                  <th>Fancall Sns Line</th>
                                  <td>{product.fancallSnsLine}</td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        </Modal.Body>

        {isMobile ? (
          <MFooter
            style={{ padding: "1rem", borderTop: "1px solid lightgray" }}
          >
            <MTwoButtonRow>
              <MButton
                onClick={handleCancelOrderClick}
                style={{
                  backgroundColor: "var(--color-gray)",
                  color: "var(--color-white)",
                }}
              >
                Cancel
              </MButton>
              {canModify ? (
                <MButton
                  onClick={handleModifyClick}
                  style={{
                    backgroundColor: "var(--color-main-blue)",
                    color: "var(--color-white)",
                  }}
                >
                  Modify
                </MButton>
              ) : (
                <MButton
                  style={{
                    backgroundColor: "#cccccc",
                    color: "#666666",
                    cursor: "not-allowed",
                  }}
                  onClick={() =>
                    Swal.fire({
                      title: "수정 불가",
                      text: "현재 상태에서는 상품을 수정할 수 없습니다. 상태가 1인 상품만 수정 가능합니다.",
                      icon: "warning",
                      confirmButtonText: "확인",
                    })
                  }
                >
                  Modify
                </MButton>
              )}
            </MTwoButtonRow>
          </MFooter>
        ) : (
          <Modal.Footer>
            {canModify ? (
              <Button variant="success" onClick={handleModifyClick}>
                Modify
              </Button>
            ) : (
              <Button
                variant="secondary"
                disabled
                onClick={() =>
                  Swal.fire({
                    title: "수정 불가",
                    text: "현재 상태에서는 상품을 수정할 수 없습니다. 상태가 1인 상품만 수정 가능합니다.",
                    icon: "warning",
                    confirmButtonText: "확인",
                  })
                }
              >
                Modify
              </Button>
            )}
            <Button variant="danger" onClick={handleCancelOrderClick}>
              Cancel
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <Modal
        show={show && isEditMode}
        onHide={() => setIsEditMode(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <div style={{ fontSize: "1.3rem" }}>Order Modify</div>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.2rem",
              borderBottom: "1px solid lightgray",
              paddingBottom: "1rem",
              marginBottom: "1rem",
            }}
          >
            {orderId}
          </div>
          {products.map((product, index) => (
            <OrderSepLine>
              <Form key={index} className="mb-3">
                <Form.Group as={Row} className="mb-3">
                  <div style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                    Product {index + 1}
                  </div>

                  <Form.Label className="font-shop-modify" column sm="3">
                    Category *
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      value={product.category}
                      onChange={(e) =>
                        handleProductChange(
                          index,
                          "category",
                          parseInt(e.target.value)
                        )
                      }
                    >
                      {Object.entries(CODE_SHOPPING_ORDER_ITEMS).map(
                        ([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="font-shop-modify" column sm="3">
                    Product Name *
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      type="text"
                      value={product.name}
                      onChange={(e) =>
                        handleProductChange(index, "name", e.target.value)
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="font-shop-modify" column sm="3">
                    Option 1
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      type="text"
                      value={product.option1}
                      onChange={(e) =>
                        handleProductChange(index, "option1", e.target.value)
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="font-shop-modify" column sm="3">
                    Option 2
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      type="text"
                      value={product.option2}
                      onChange={(e) =>
                        handleProductChange(index, "option2", e.target.value)
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="font-shop-modify" column sm="3">
                    Quantity *
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      type="number"
                      value={product.quantity}
                      onChange={(e) =>
                        handleProductChange(
                          index,
                          "quantity",
                          parseInt(e.target.value)
                        )
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="font-shop-modify" column sm="3">
                    Unit Price(KRW) *
                  </Form.Label>
                  <Col sm="9" className="d-flex align-items-center">
                    <Form.Control
                      type="number"
                      value={product.price}
                      onChange={(e) =>
                        handleProductChange(
                          index,
                          "price",
                          parseFloat(e.target.value)
                        )
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="font-shop-modify" column sm="3">
                    Site Url *
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      type="text"
                      value={product.url}
                      onChange={(e) =>
                        handleProductChange(index, "url", e.target.value)
                      }
                    />
                  </Col>
                </Form.Group>

                <div className="col-12" style={{ marginTop: "0.5rem" }}>
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    {/* Pre order */}
                    {product.preorder && (
                      <div
                        className="accordion-item"
                        style={{ padding: "0.5rem 0" }}
                      >
                        {/* <h4 className="accordion-header">
                          <div
                            className="form-check"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "1rem",
                              alignItems: "center",
                              fontSize: "1.1rem",
                            }}
                          > */}
                        {/* <input
                              type="checkbox"
                              className="form-check-input"
                              id={`preorder-${product.id}`}
                              name="preorder"
                              checked={product.preorder}
                              onChange={(e) => {
                                handleProductChange(
                                  index,
                                  "preorder",
                                  e.target.checked
                                );
                                if (e.target.checked) {
                                  handleProductChange(index, "fancall", false);
                                }
                              }}
                            /> */}
                        <label
                          className="form-check-label"
                          htmlFor={`preorder-${product.id}`}
                          style={{ fontWeight: "bold", width: "100%" }}
                        >
                          Pre Order
                        </label>

                        {/* <Step2Dropdown>
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                onClick={() => toggleSection(index, "preorder")}
                                aria-expanded={product.preorder}
                                aria-controls={`flush-collapseOne-${index}`}
                                style={{ padding: "0" }}
                              ></button>
                            </Step2Dropdown> */}
                        {/* </div>
                        </h4> */}
                        <Collapse in={product.preorder}>
                          <div
                            id={`flush-collapseOne-${index}`}
                            className="accordion-body"
                            style={{ padding: "0" }}
                          >
                            <div
                              className="accordion-body"
                              style={{ padding: "0", margin: "1rem 0rem" }}
                            >
                              <div style={{ marginBottom: "0.5rem" }}>
                                Start date ~ End date
                              </div>
                              <div className="input-group mb-3">
                                <input
                                  type="date"
                                  className="form-control"
                                  name="preorderStartAt"
                                  value={formatDate(
                                    product.preorderStartAt as string
                                  )}
                                  onChange={(e) =>
                                    handleProductChange(
                                      index,
                                      "preorderStartAt",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Start date"
                                  aria-label="Start date"
                                />
                                <span className="input-group-text">~</span>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="preorderEndAt"
                                  value={formatDate(
                                    product.preorderEndAt as string
                                  )}
                                  onChange={(e) =>
                                    handleProductChange(
                                      index,
                                      "preorderEndAt",
                                      e.target.value
                                    )
                                  }
                                  placeholder="End date"
                                  aria-label="End date"
                                />
                              </div>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    )}

                    {/* Fan Call */}
                    {product.fancall && (
                      <div
                        className="accordion-item"
                        style={{ padding: "0.5rem 0" }}
                      >
                        {/* <h4 className="accordion-header">
                          <div
                            className="form-check"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "1rem",
                              alignItems: "center",
                              fontSize: "1.1rem",
                            }}
                          > */}
                        {/* <input
                              type="checkbox"
                              className="form-check-input"
                              id={`fancall-${product.id}`}
                              name="fancall"
                              checked={product.fancall}
                              onChange={(e) => {
                                handleProductChange(
                                  index,
                                  "fancall",
                                  e.target.checked
                                );
                                if (e.target.checked) {
                                  handleProductChange(index, "preorder", false);
                                }
                              }}
                            /> */}
                        <label
                          className="form-check-label"
                          htmlFor={`fancall-${product.id}`}
                          style={{ fontWeight: "bold", width: "100%" }}
                        >
                          Fan Call
                        </label>

                        {/* <Step2Dropdown>
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                onClick={() => toggleSection(index, "fancall")}
                                aria-expanded={product.fancall}
                                aria-controls={`flush-collapseTwo-${index}`}
                                style={{ padding: "0" }}
                              ></button>
                            </Step2Dropdown> */}
                        {/* </div>
                        </h4> */}
                        <Collapse in={product.fancall}>
                          <div
                            id={`flush-collapseTwo-${index}`}
                            className="accordion-body"
                          >
                            <div className="accordion-body">
                              {/* Name Input */}
                              <div className="input-group mb-3">
                                <span className="input-group-text">Name</span>
                                <input
                                  type="text"
                                  aria-label="First name"
                                  className="form-control"
                                  name="fancallName"
                                  value={product.fancallName || ""}
                                  onChange={(e) =>
                                    handleProductChange(
                                      index,
                                      "fancallName",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              {/* Birthday Input */}
                              <div className="input-group mb-3">
                                <span className="input-group-text">
                                  Birthday
                                </span>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="fancallBirthday"
                                  value={formatDate(
                                    product.fancallBirthday as string
                                  )}
                                  onChange={(e) =>
                                    handleProductChange(
                                      index,
                                      "fancallBirthday",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>

                              {/* Phone Number Input */}
                              <div className="input-group mb-3">
                                <span className="input-group-text">Phone</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="fancallNumber"
                                  value={product.fancallNumber || ""}
                                  onChange={(e) =>
                                    handleProductChange(
                                      index,
                                      "fancallNumber",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Phone Number"
                                  aria-label="Phone Number"
                                />
                              </div>

                              {/* Email Input */}
                              <div className="input-group mb-3">
                                <span className="input-group-text">E-mail</span>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="fancallEmail"
                                  value={product.fancallEmail || ""}
                                  onChange={(e) =>
                                    handleProductChange(
                                      index,
                                      "fancallEmail",
                                      e.target.value
                                    )
                                  }
                                  placeholder="E-mail"
                                  aria-label="E-mail"
                                />
                              </div>

                              {/* Country Input */}
                              <div className="input-group mb-3">
                                <label
                                  className="input-group-text"
                                  htmlFor="dropdown-basic-fancallCountry"
                                >
                                  Country
                                </label>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic-fancallCountry"
                                  >
                                    {products[index].fancallCountry
                                      ? CODE_COUNTRY_ITEMS[
                                          products[index].fancallCountry
                                        ]
                                      : "Select Country"}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu
                                    style={{
                                      maxHeight: "300px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <FormControl
                                      autoFocus
                                      className="mx-2 my-1 w-auto"
                                      placeholder="Search country..."
                                      onChange={(e) =>
                                        setCountrySearchTerm(e.target.value)
                                      }
                                      value={countrySearchTerm}
                                    />
                                    {filteredCountries.map(([id, name]) => (
                                      <Dropdown.Item
                                        key={id}
                                        onClick={() =>
                                          handleFancallCountrySelect(
                                            Number(id),
                                            index
                                          )
                                        }
                                      >
                                        {name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              {/* KakaoTalk ID Input */}
                              <div className="input-group mb-3">
                                <span className="input-group-text">
                                  KakaoTalk ID
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="fancallKakao"
                                  value={product.fancallKakao || ""}
                                  onChange={(e) =>
                                    handleProductChange(
                                      index,
                                      "fancallKakao",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Kakao"
                                  aria-label="Kakao"
                                />
                              </div>

                              {/* Line ID Input */}
                              <div className="input-group mb-3">
                                <span className="input-group-text">
                                  Line ID
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="fancallSnsLine"
                                  value={product.fancallSnsLine || ""}
                                  onChange={(e) =>
                                    handleProductChange(
                                      index,
                                      "fancallSnsLine",
                                      e.target.value
                                    )
                                  }
                                  placeholder="SnsLine"
                                  aria-label="SnsLine"
                                />
                              </div>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            </OrderSepLine>
          ))}
        </Modal.Body>
        <Modal.Footer style={{ width: "100%" }}>
          {products.some((product) => product.isCanceled) ? (
            <>
              <Button
                variant="primary"
                onClick={() => handleReapplyOrder(orderId)}
              >
                Reapply Order
              </Button>
            </>
          ) : (
            <>
              {isMobile ? (
                <MTwoButtonRow style={{ width: "100%" }}>
                  <MButton
                    onClick={handleBack}
                    style={{
                      backgroundColor: "var(--color-gray)",
                      color: "var(--color-white)",
                    }}
                  >
                    Back
                  </MButton>
                  <MButton
                    onClick={handleSaveChanges}
                    style={{
                      backgroundColor: "var(--color-main-blue)",
                      color: "var(--color-white)",
                    }}
                  >
                    Save
                  </MButton>
                </MTwoButtonRow>
              ) : (
                <>
                  <Button variant="success" onClick={handleSaveChanges}>
                    Save
                  </Button>
                  <Button variant="secondary" onClick={handleBack}>
                    Back
                  </Button>
                  {/* <Button variant="danger" onClick={handleCancelOrderClick}>
                Cancel Order
              </Button> */}
                </>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={confirmCancelModalShow}
        onHide={() => setConfirmCancelModalShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Order Cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to cancel the order?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setConfirmCancelModalShow(false)}
          >
            No
          </Button>
          <Button variant="danger" onClick={handleConfirmCancelOrder}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const Step2Dropdown = styled.div`
  & > button:focus {
    box-shadow: none !important;
  }
`;

const OrderSepLine = styled.div`
  border-bottom: 1px solid #afafaf;
  margin-bottom: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;

export const MTitle = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
`;

export default CShopOrderWiseModal;
