import { useEffect, useState } from "react";
import { Button, Form, Modal, Pagination, Table } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  ADMIN_BADGE,
  CODE_SHOPING_PAYMENT_STATUS,
  DATA_TYPE,
} from "../../../common/constants";
import { formatDate } from "../../../common/format";
import CustomerHeadShipFilterMobile from "../../../components/CustomerHeadShipFilterMobile";
import CustomerHeadShipFilter from "../../../components/CustomerHeadShipFiter";
import CustomerPageTitle from "../../../components/CustomerPageTitle";
import FormatData from "../../../components/FormatData";
import CShipDetailModal from "../../../components/modal/CShipDetailModal";
import CShipPayModal from "../../../components/modal/CShipPayModal";
import { MODAL_TYPE } from "../../../components/modal/GlobalModal";
import { modalOpen } from "../../../redux/modalSlice";
import { RootUserState } from "../../../redux/store";
import { reqSendAlarm } from "../../../requests/alarm";
import { reqAddAdminBadge } from "../../../requests/badge";
import { reqCreateReview } from "../../../requests/review";
import { reqShipOrderUpdate, requserShip } from "../../../requests/ship";
import {
  reqPostPointlog,
  reqUpdateUserPoints,
  reqUpdateUserPointsUSD,
} from "../../../requests/user";
const ITEMS_PER_PAGE = 10;

interface RatingStarsProps {
  rating: number;
  setRating: (rating: number) => void;
}

const RatingStars = ({ rating, setRating }: RatingStarsProps) => {
  return (
    <div>
      {[...Array(5)].map((_, index) => {
        return (
          <button
            type="button"
            key={index}
            className={index < rating ? "text-warning" : "text-muted"}
            onClick={() => setRating(index + 1)}
          >
            <span className="bi bi-star-fill"></span>
          </button>
        );
      })}
    </div>
  );
};

const CustomPagination = ({
  totalPages,
  currentPage,
  onPageChange,
}: {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}) => {
  const maxVisiblePages = isMobile ? 5 : 10;

  const getPageNumbers = () => {
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  return (
    <CenteredPagination>
      {currentPage > 1 && (
        <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} />
      )}

      {getPageNumbers().map((pageNum) => (
        <Pagination.Item
          key={pageNum}
          active={pageNum === currentPage}
          onClick={() => onPageChange(pageNum)}
        >
          {pageNum}
        </Pagination.Item>
      ))}

      {currentPage < totalPages && (
        <Pagination.Next onClick={() => onPageChange(currentPage + 1)} />
      )}
    </CenteredPagination>
  );
};

export default function CShipDetails() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const [search, setSearch] = useState("");
  const [searchOption, setSearchOption] = useState("");
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state: RootUserState) => state.user);
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [showRepackModal, setShowRepackModal] = useState(false);
  const [data, setData] = useState<OrderData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const [showCheck1Modal, setShowCheck1Modal] = useState(false);
  const [showCheck2Modal, setShowCheck2Modal] = useState(false);
  const [showCheck3Modal, setShowCheck3Modal] = useState(false);
  const [rating, setRating] = useState(0);
  const [reviewContent, setReviewContent] = useState("");
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [previewImage, setPreviewImage] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [showPayModal, setShowPayModal] = useState(false);

  const handleCloseCheck2Modal = () => {
    setShowCheck2Modal(false);
    handleShowCheck3Modal();
  };
  const handleShowCheck3Modal = () => setShowCheck3Modal(true);
  const handleCloseCheck3Modal = () => setShowCheck3Modal(false);

  type OrderData = {
    id: string;
    createdAt: string;
    expiredAt: string;
    userId: string;
    adrReceiver: string;
    status: number;
    review: boolean;
    membershipGrade: string;
    PaymentMethod: number;
  };

  const fetchData = async () => {
    try {
      const response = await requserShip(user.id);
      let filteredData = response.data
        .filter(
          (item: OrderData) => item.userId === user.id && item.status !== 10
        )
        .sort(
          (a: OrderData, b: OrderData) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

      if (search) {
        filteredData = filteredData.filter((item: OrderData) =>
          item.id.toLowerCase().includes(search.toLowerCase())
        );
      }

      const statusFilters = selectedStatus.split(",").map(Number);
      filteredData = filteredData.filter((item: OrderData) =>
        selectedStatus ? statusFilters.includes(item.status) : true
      );

      const sortedData = filteredData.sort(
        (a: OrderData, b: OrderData) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setData(sortedData);
      setTotalPages(Math.ceil(sortedData.length / itemsPerPage));
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user?.id, selectedStatus, itemsPerPage, search, searchOption]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitReview = async () => {
    if (!selectedOrder || isLoading) {
      console.error("No order selected or already loading.");
      return;
    }

    // 예외 처리: 별점과 리뷰 내용이 작성되지 않은 경우
    if (rating === 0 || reviewContent.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Review",
        text: "Please provide a rating and review content before submitting.",
      });
      return;
    }

    try {
      const selectedOrderData = data.find(
        (order) => order.id === selectedOrder
      );

      if (!selectedOrderData) {
        console.error("Selected order data not found.");
        return;
      }

      const pointsToAdd = selectedOrderData.PaymentMethod === 1 ? 1000 : 1;

      Swal.fire({
        title: `Are you sure you want to complete the review?`,
        text: `You can get ${pointsToAdd} points`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          const formData = new FormData();
          formData.append("userId", user.id);
          formData.append("orderId", selectedOrder);
          formData.append("rating", String(rating));
          formData.append("content", reviewContent);
          if (selectedImage) {
            formData.append("file", selectedImage);
          }
          await reqCreateReview(formData);

          const updatePayload = {
            id: selectedOrder,
            review: true,
          };
          await reqShipOrderUpdate(updatePayload);

          if (selectedOrderData.PaymentMethod === 1) {
            const pointsResponse = await reqUpdateUserPoints(user.id, {
              pointsToAdd,
            });
            console.log("Points updated successfully", pointsResponse.data);

            const pointLogPayload = {
              userId: user.id,
              grant: true,
              krwGrantPoint: pointsToAdd,
              status: 3,
            };

            if (pointsToAdd > 0) {
              try {
                await reqPostPointlog(pointLogPayload);
              } catch (error) {
                console.error("Failed to log points:", error);
              }
            }
          } else {
            const pointsResponse = await reqUpdateUserPointsUSD(user.id, {
              pointsToAdd,
            });
            console.log("USD points updated successfully", pointsResponse.data);

            const pointLogPayload = {
              userId: user.id,
              grant: true,
              usdGrantPoint: parseFloat(pointsToAdd.toFixed(2)),
              status: 3,
            };

            if (parseFloat(pointsToAdd.toFixed(2)) > 0) {
              try {
                await reqPostPointlog(pointLogPayload);
              } catch (error) {
                console.error("Failed to log points:", error);
              }
            }
          }

          const alarmPayload = {
            userId: user.id,
            read: 0,
            content: `Points have been credited to your profile.`,
            sender: "Admin",
          };
          await reqSendAlarm(alarmPayload);

          const badgePayload = {
            badgeName: ADMIN_BADGE.REVIEW,
          };
          await reqAddAdminBadge(badgePayload);

          setIsLoading(false);

          setShowCheck2Modal(false);
          setRating(0);
          setReviewContent("");
          setPreviewImage("");
          setSelectedImage(null);
          await fetchData();

          Swal.fire("Thank you for your review!", "", "success");
        }
      });
    } catch (error) {
      console.error("Error during the review submission process:", error);
      setIsLoading(false); // Reset loading state on error
    }
  };

  const handleStatusClick = (orderId: string, status: number) => {
    setSelectedOrder(orderId);
    if (status === 4) {
      setShowPayModal(true);
    } else {
      setShowModal(true);
    }
  };

  const handleRepackRequest = () => {
    setShowModal(false);
    setShowRepackModal(true);
  };

  const filteredData = search
    ? data.filter((item) =>
        item.id.toLowerCase().includes(search.toLowerCase())
      )
    : data;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedData = data.slice(startIndex, startIndex + itemsPerPage);

  const tableRows = displayedData.map((item, index) => (
    <tr
      className="ontactweb-height"
      key={index}
      style={{ backgroundColor: "#ffffff" }}
    >
      <td className="font-large-page">{index + 1 + startIndex}</td>
      <td
        className="font-large-page"
        onClick={(e) => {
          e.stopPropagation();
          if ([1, 2, 3].includes(item.status)) {
            dispatch(
              modalOpen({
                modalType: MODAL_TYPE.SHIP_ORDER_DATAIL,
                title: "Order Detail",
                payload: { data: item },
              })
            );
          } else {
            handleStatusClick(item.id, item.status);
          }
        }}
        style={{
          cursor: "pointer",
          color: "#007bff",
          textDecoration: "underline",
          fontSize: "1.3em",
        }}
      >
        {item.id}
      </td>
      <td className="font-large-page">{formatDate(item.createdAt)}</td>
      <td className="font-large-page">
        {item.expiredAt ? formatDate(item.expiredAt) : "Unpaid"}
      </td>
      <td className="font-large-page">
        {FormatData(DATA_TYPE.CODE_SHIPPING_ORDERS_STATUS, item.status)}
      </td>
      <td className="font-large-page">
        {item.review ? (
          <Button variant="secondary" disabled>
            Review Completed
          </Button>
        ) : (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleCreateReviewClick(item.id);
            }}
            variant="primary"
            disabled={item.status !== 8}
          >
            Write Review
          </Button>
        )}
      </td>
    </tr>
  ));

  const handleShowCheck1Modal = () => setShowCheck1Modal(true);
  const handleCloseCheck1Modal = () => setShowCheck1Modal(false);

  const handleShowCheck2Modal = () => {
    setShowCheck1Modal(false);
    setShowCheck2Modal(true);
  };

  const handleCreateReviewClick = (orderId: string) => {
    setSelectedOrder(orderId);
    handleShowCheck1Modal();
  };

  const handleCheck2YesClick = () => {
    handleCloseCheck2Modal();
  };

  const handleCheck3BackClick = () => {
    handleCloseCheck3Modal();
    setShowCheck2Modal(true);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Image size should not exceed 5MB!",
        });
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.readyState === 2) {
          setPreviewImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
      setSelectedImage(file);
    }
  };

  return (
    <>
      <div style={{ padding: "1.4rem" }}>
        {isMobile ? (
          <CustomerPageTitle title="My Shipments" />
        ) : (
          <>
            <CustomerPageTitle title="My Shipments" />

            {/* 옵션 필터 */}
            <CustomerHeadShipFilter
              selectStatus={(value: string) => setSelectedStatus(value)}
              selectViewPage={(value: number) =>
                handleItemsPerPageChange(value)
              }
              enterSearch={(value: string) => setSearch(value)}
              selectSearchOption={(value: string) => setSearchOption(value)}
            />
          </>
        )}

        {isMobile ? (
          <>
            <CustomerHeadShipFilterMobile
              selectStatus={(value: string) => setSelectedStatus(value)}
              selectViewPage={(value: number) =>
                handleItemsPerPageChange(value)
              }
              enterSearch={(value: string) => setSearch(value)}
              selectSearchOption={(value: string) => setSearchOption(value)}
            />
            <MobileWrapper>
              <div>
                {displayedData.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "200px",
                      textAlign: "center",
                      padding: "2rem 0",
                    }}
                  >
                    <i
                      className="bi bi-exclamation-circle"
                      style={{
                        fontSize: "2.5rem",
                        color: "#6c757d",
                        marginBottom: "1rem",
                      }}
                    ></i>
                    <p style={{ fontSize: "1.1rem", color: "#666", margin: 0 }}>
                      No data available
                    </p>
                  </div>
                ) : (
                  displayedData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        cursor: "pointer",
                        borderBottom: "1px solid lightgray",
                        marginBottom: "1rem",
                        paddingBottom: "1rem",
                      }}
                      onClick={() => handleStatusClick(item.id, item.status)}
                    >
                      <Row>
                        <P>No.</P>
                        <P>{index + 1 + startIndex}</P>
                      </Row>

                      <Row>
                        <P>Order No.</P>
                        <P
                          style={{
                            cursor: "pointer",
                            color: "var(--color-main-blue)",
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          {item.id}
                        </P>
                      </Row>

                      <Row>
                        <P>Order Date</P>
                        <P>{formatDate(item.createdAt)}</P>
                      </Row>

                      <Row>
                        <P>Paid Expire Date</P>
                        <P>
                          {item.expiredAt
                            ? formatDate(item.expiredAt)
                            : "Unpaid"}
                        </P>
                      </Row>

                      <Row>
                        <P>Status</P>
                        <P>{CODE_SHOPING_PAYMENT_STATUS[item.status]}</P>
                      </Row>

                      <Row>
                        {item.review ? (
                          <button
                            style={{ width: "100%", marginTop: "0.6rem" }}
                            className="btn btn-secondary"
                            disabled
                          >
                            Review Completed
                          </button>
                        ) : (
                          <button
                            style={{ width: "100%", marginTop: "0.6rem" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCreateReviewClick(item.id);
                            }}
                            className="btn btn-primary"
                            disabled={item.status !== 8}
                          >
                            Write Review
                          </button>
                        )}
                      </Row>
                    </div>
                  ))
                )}
                <CustomPagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </MobileWrapper>
          </>
        ) : (
          <div
            style={{
              height: "800px",
              overflowY: "scroll",
              marginBottom: "20px",
            }}
          >
            {displayedData.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "400px",
                  textAlign: "center",
                }}
              >
                <i
                  className="bi bi-exclamation-circle"
                  style={{
                    fontSize: "3rem",
                    color: "#6c757d",
                    marginBottom: "1rem",
                  }}
                ></i>
                <p style={{ fontSize: "1.2rem", color: "#666", margin: 0 }}>
                  No data available
                </p>
              </div>
            ) : (
              <div className="table-responsive">
                <Table className="table-custom text-center">
                  <thead className="table-light">
                    <tr translate="no">
                      <th className="font-large-page-title">No</th>
                      <th className="font-large-page-title">Order NO</th>
                      <th className="font-large-page-title">Created At</th>
                      <th className="font-large-page-title">Expired At</th>
                      <th className="font-large-page-title">Status</th>
                      <th className="font-large-page-title">Review</th>
                    </tr>
                  </thead>
                  <tbody>{tableRows}</tbody>
                </Table>
              </div>
            )}
            <CustomPagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        )}

        {showModal && (
          <CShipDetailModal
            show={showModal}
            onHide={() => setShowModal(false)}
            orderNumber={selectedOrder ?? ""}
            onRepackRequest={handleRepackRequest}
            refreshData={fetchData}
          />
        )}

        {showPayModal && (
          <CShipPayModal
            show={showPayModal}
            onHide={() => setShowPayModal(false)}
            orderNumber={selectedOrder ?? ""}
            onRepackRequest={handleRepackRequest}
            refreshData={fetchData}
          />
        )}
      </div>

      <Modal show={showCheck1Modal} onHide={handleCloseCheck1Modal}>
        <Modal.Header closeButton>
          <Modal.Title>Have you received the product?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You cannot write a review before receiving the product, and if you
          click YES, the transaction is completed Changes to status.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCheck1Modal}>
            No
          </Button>
          <Button variant="primary" onClick={handleShowCheck2Modal}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCheck2Modal} onHide={handleCloseCheck2Modal}>
        <Modal.Header closeButton>
          <Modal.Title>Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RatingStars rating={rating} setRating={setRating} />
          <Form.Group className="mb-3">
            <Form.Label>Review Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={reviewContent}
              onChange={(e) => setReviewContent(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <FormRow className="form-group">
              <label htmlFor="image">Upload Image</label>
              <input
                type="file"
                className="form-control-file"
                id="image"
                name="image"
                accept="image/*"
                onChange={handleFileChange}
              />
              {imageUrl ? (
                <div className="image-cover">
                  <img
                    src={`https://kr.object.ncloudstorage.com/ontact-bucket/${imageUrl}`}
                    alt="Preview"
                  />
                </div>
              ) : (
                <div className="image-cover">
                  {previewImage && (
                    <img
                      src={previewImage}
                      style={
                        isMobile
                          ? {
                              marginTop: "20px",
                              width: "--webkit-fill-available",
                              objectFit: "scale-down",
                            }
                          : {
                              marginTop: "20px",
                              maxHeight: "300px",
                              maxWidth: "1000px",
                              objectFit: "scale-down",
                            }
                      }
                      alt="Preview"
                    />
                  )}
                </div>
              )}
            </FormRow>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCheck2Modal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmitReview}
            disabled={isLoading}
          >
            Submit Review
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCheck3Modal} onHide={handleCloseCheck3Modal}>
        {isLoading ? (
          <LoadingSection>
            <img src="/resources/img/loading.gif" alt="로딩중" />
          </LoadingSection>
        ) : null}
        <Modal.Header closeButton>
          <Modal.Title>Review Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you do not want to write a review?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCheck3Modal}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCheck3BackClick}>
            Back to Review
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const LoadingSection = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > img {
    width: 100%;
  }
`;
const Title = styled.h3`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  margin-top: 1rem;
`;
const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;

  & > label {
    margin: 20px 0 10px 0;
  }

  .dropdown {
    width: 50%;

    & > button {
      border: 1px solid;
    }
  }

  .image-cover {
    text-align: left;
  }
`;
const MSearchBar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;
const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    margin-bottom: 4rem;
  }
`;
