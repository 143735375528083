import { addDays, differenceInCalendarDays, format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import { USER_BADGE } from "../../../common/constants";
import { FormatTime } from "../../../common/format";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { MODAL_TYPE } from "../../../components/modal/GlobalModal";
import { modalOpen } from "../../../redux/modalSlice";
import { RootState, RootUserState } from "../../../redux/store";
import { reqSendAlarm } from "../../../requests/alarm";
import { reqAddBadge } from "../../../requests/badge";
import { reqShopUserDetail } from "../../../requests/shop";
import { reqGetUserById } from "../../../requests/user";
import { reqGetMembership, reqInboundShip } from "../../../requests/warehouse";

interface FetchedOrder {
  orderId: string;
  userId: string;
  name: string;
  option1: string;
  option2: string;
  quantity: number;
  category: number;
  createdAt: string;
  PackageId: string;
  productId: string;
  Extra: string;
  isCanceled: boolean;
  status: number;
  Addstatus: number;
  warehouse: string;
  user: {
    name: string;
    membershipId: number;
    latestWarehouseId: string;
    membershipEndAt: string;
  };
}

interface MembershipInfo {
  freeStorageDaysRed: number;
  freeStorageDaysGreen: number;
  freeStorageDaysBlue: number;
}

export default function AWInbound() {
  const [orderDetails, setOrderDetails] = useState<FetchedOrder[]>([]);
  const [latestWarehouseId, setLatestWarehouseId] = useState<string>("");
  const [addStatus, setAddStatus] = useState<number[]>([]);
  const user = useSelector((state: RootUserState) => state.user);
  const [userId, setUserId] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [userMembershipId, setUserMembershipId] = useState<number>(3);
  const [membershipInfo, setMembershipInfo] = useState<MembershipInfo | null>(
    null
  );
  const [selectedOrderName, setSelectedOrderName] = useState("");
  const dispatch = useDispatch();
  const admin = useSelector((state: RootState) => state.admin);
  const [data, setData] = useState({
    userId: "",
    tracking: "",
    date: new Date(),
    Extra: 0,
    warehouseId: "",
    warehouse: "",
    file: "",
    Addstatus: 0,
    totalWeightKg: 0,
  });
  const [isValid, setIsValid] = useState({
    userId: true,
    tracking: true,
    warehouseId: true,
    warehouse: true,
    Extra: true,
    productId: true,
    Addstatus: true,
  });

  const [orderList, setOrderList] = useState<FetchedOrder[]>([]);
  const [imageUrl, setImageUrl] = useState<any>("");

  const fetchOrderDetails = async () => {
    if (!userId) {
      return;
    }
    const response = await reqShopUserDetail({ userId });
    if (response.data) {
      const filteredOrderDetails = response.data.filter(
        (order: FetchedOrder) => order.status === 6
      );

      setOrderList(filteredOrderDetails);
    }
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!userId) {
        return;
      }
      const response = await reqShopUserDetail({ userId });
      if (response.data) {
        setOrderDetails(response.data);
      }
    };

    fetchOrderDetails();
  }, [userId]);

  const handleAddStatusChange = (value: number) => {
    setData({ ...data, Addstatus: data.Addstatus === value ? 0 : value });
  };

  const checkValid = () => {
    let [formIsValid, message] = [true, ""];
    const newIsValidState = { ...isValid };

    if (data.userId === "") {
      newIsValidState.userId = false;
      formIsValid = false;
      message = "User ID를 입력해주세요.";
    }
    if (data.tracking === "") {
      newIsValidState.tracking = false;
      formIsValid = false;
      message = "송장번호를 입력해주세요.";
    }
    // if (data.warehouseId === "") {
    //   newIsValidState.warehouseId = false;
    //   formIsValid = false;
    //   message = "창고 위치를 입력해주세요.";
    // }
    if (data.Extra < 0) {
      newIsValidState.Extra = false;
      formIsValid = false;
      message = "입고금액을 올바르게 입력해주세요.";
    }

    setIsValid(newIsValidState);
    return { formIsValid, message };
  };

  useEffect(() => {
    const fetchLatestWarehouseIdAndMembership = async () => {
      if (!userId) return;
      try {
        const response = await reqGetUserById({ id: userId });
        if (response && response.data) {
          const { latestWarehouseId, membershipId, membershipEndAt } =
            response.data;
          setLatestWarehouseId(latestWarehouseId || "");
          setUserMembershipId(membershipId || 3);
          setUserMembershipEndAt(membershipEndAt || null);
        } else {
          setLatestWarehouseId("");
          setUserMembershipId(3);
          setUserMembershipEndAt(null);
        }
      } catch (error) {
        console.error("창고 위치 및 멤버십 ID 조회 실패:", error);
        setLatestWarehouseId("");
        setUserMembershipId(3);
        setUserMembershipEndAt(null);
      }
    };

    fetchLatestWarehouseIdAndMembership();
  }, [userId]);

  useEffect(() => {
    const fetchMembershipInfo = async () => {
      try {
        const response = await reqGetMembership();
        if (response && response.data && response.data.length > 0) {
          setMembershipInfo(response.data[0]);
        }
      } catch (error) {
        console.error("멤버십 정보 조회 실패:", error);
      }
    };

    fetchMembershipInfo();
  }, []);

  const calculateFreeAt = (membershipEndAt: string | null) => {
    let baseDate: Date;

    // membershipEndAt이 있으면 그 날짜를 기준으로, 없으면 현재 날짜를 기준으로 사용
    if (membershipEndAt) {
      baseDate = new Date(membershipEndAt);
    } else {
      baseDate = new Date(); // membershipEndAt이 없으면 현재 날짜 사용
    }

    // 기준 날짜에 30일을 더한 날짜 계산
    const resultDate = addDays(baseDate, 30);
    return resultDate;
  };

  const handleCheckboxChange = (
    productId: string,
    userId: string,
    Extra: string,
    warehouseId: string,
    name: string
  ) => {
    setData({
      ...data,
      userId,
      tracking: productId,
      Extra: Number(Extra),
      warehouseId,
      totalWeightKg: 0,
    });
    setIsValid({
      ...isValid,
      userId: true,
      productId: true,
      Extra: true,
      warehouseId: true,
    });
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.readyState === 2) {
        setImageUrl(reader.result);
      }
    };
    reader.readAsDataURL(file);
    setFile(file);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [userMembershipEndAt, setUserMembershipEndAt] = useState<string | null>(
    null
  );

  const handleFormSubmit = async () => {
    const { formIsValid, message } = checkValid();
    if (!formIsValid) {
      Swal.fire(message, "", "warning");
      return;
    }
    if (!file) {
      Swal.fire("사진을 등록해주세요.", "", "warning");
      return;
    }
    if (file.size > 5 * 1024 * 1024) {
      Swal.fire("파일 용량을 줄여주세요. (최대 5MB)", "", "warning");
      return;
    }

    Swal.fire({
      title: "입고 등록을 진행하시겠습니까?",
      text: "사용자에게 알림을 발송합니다.",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const freeAt = calculateFreeAt(userMembershipEndAt);
        const currentDate = new Date();
        const daysPastFreeAt = differenceInCalendarDays(currentDate, freeAt);

        let warehouseValue = "free";
        if (daysPastFreeAt > 0) {
          warehouseValue = `free +${daysPastFreeAt} days`;
        }

        const formattedDate = format(data.date, "yyyy-MM-dd'T'HH:mm:ss");
        const formattedFreeAt = format(freeAt, "yyyy-MM-dd'T'HH:mm:ss");

        const formData = new FormData();
        formData.append("userId", data.userId);
        formData.append("tracking", data.tracking);
        formData.append("date", formattedDate);
        formData.append("Extra", data.Extra.toString());
        formData.append("warehouseId", data.warehouseId);
        formData.append("Addstatus", data.Addstatus.toString());
        formData.append("warehouse", warehouseValue);
        formData.append("freeAt", formattedFreeAt);
        formData.append("manager", admin.name);
        formData.append("totalWeightKg", (data.totalWeightKg || 0).toString());

        try {
          setIsLoading(true);
          if (file) {
            formData.append("file", file);
          }
          const result = await reqInboundShip(formData);
          setIsLoading(false);

          const alarmPayload = {
            userId: data.userId,
            read: 0,
            content: `New products have arrived. Please input the product details.`,
            sender: admin.name,
          };
          await reqSendAlarm(alarmPayload);

          const badgePayload1 = {
            userId: data.userId,
            badgeName: USER_BADGE.ALL_PRODUCT,
          };
          const badgePayload2 = {
            userId: data.userId,
            badgeName: USER_BADGE.MODIFY_PRODUCT,
          };
          await reqAddBadge(badgePayload1);
          await reqAddBadge(badgePayload2);

          if (result.data) {
            dispatch(
              modalOpen({
                modalType: MODAL_TYPE.DATA_SAVE,
                title: "입고 등록 완료",
              })
            );
            window.location.reload();
          }
        } catch (error) {
          alert("송장번호가 중복됩니다.");
        }
      }
    });
  };

  // 띄어쓰기
  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSection>
          <img src="/resources/img/loading.gif" alt="로딩중" />
        </LoadingSection>
      ) : null}

      <AdminHeadTitle
        subtitle1="창고 관리"
        subtitle2="입고 등록"
        title="입고 등록"
      />

      <main className="mt-3">
        <div className="row">
          <div className="col-4">
            <h5>송장 정보</h5>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                스페이스코드
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="스페이스 코드 입력"
                value={data.userId}
                onKeyPress={handleKeyPress}
                onChange={async (e) => {
                  const newUserId = e.target.value.toUpperCase().trim();
                  setData({ ...data, userId: newUserId });
                  setIsValid({ ...isValid, userId: true });
                  setUserId(newUserId);

                  if (newUserId) {
                    try {
                      const response = await reqGetUserById({ id: newUserId });
                      if (
                        response &&
                        response.data &&
                        response.data.latestWarehouseId
                      ) {
                        setLatestWarehouseId(response.data.latestWarehouseId);
                        setData((prevData) => ({
                          ...prevData,
                          warehouseId: response.data.latestWarehouseId,
                        }));
                      } else {
                        setLatestWarehouseId("");
                        setData((prevData) => ({
                          ...prevData,
                          warehouseId: "",
                        }));
                      }
                    } catch (error) {
                      console.error("창고 위치 조회 실패:", error);
                      setLatestWarehouseId("");
                      setData((prevData) => ({ ...prevData, warehouseId: "" }));
                    }
                  }
                }}
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                송장번호
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="송장번호"
                value={data.tracking}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  const newTracking = e.target.value.toUpperCase().trim();
                  setData({ ...data, tracking: newTracking });
                  setIsValid({ ...isValid, tracking: true });
                }}
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                입고날짜
              </span>
              <input
                type="datetime-local"
                className="form-control"
                placeholder="입고 날짜 선택"
                value={FormatTime(data.date)}
                onChange={(e) => {
                  console.log(e.target.value);
                  setData({ ...data, date: new Date(e.target.value) });
                }}
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                입고금액
              </span>
              <input
                type="number"
                className="form-control"
                aria-describedby="basic-addon1"
                value={data.Extra.toString()}
                onChange={(e) => {
                  setData({ ...data, Extra: Number(e.target.value) });
                  setIsValid({ ...isValid, Extra: true });
                }}
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                창고 위치
              </span>
              <input
                type="text"
                className="form-control"
                aria-describedby="basic-addon1"
                placeholder="창고 위치 입력 (선택사항)"
                onKeyPress={handleKeyPress}
                value={data.warehouseId}
                onChange={(e) => {
                  setData({ ...data, warehouseId: e.target.value });
                  setIsValid({ ...isValid, warehouseId: true });
                }}
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                총 무게 (KG)
              </span>
              <input
                type="number"
                step="0.01"
                min="0"
                className="form-control"
                placeholder="무게를 입력하세요"
                value={data.totalWeightKg || ""}
                onChange={(e) => {
                  // 소수점 2자리까지만 입력 허용
                  const inputValue = e.target.value;
                  let parsedValue = 0;

                  if (inputValue) {
                    // 소수점 2자리까지만 처리
                    const regex = /^\d*\.?\d{0,2}$/;

                    if (regex.test(inputValue)) {
                      parsedValue = parseFloat(inputValue) || 0;
                    } else {
                      // 소수점 2자리 초과 입력 시 자르기
                      const parts = inputValue.split(".");
                      if (parts.length > 1 && parts[1].length > 2) {
                        parsedValue =
                          parseFloat(
                            parts[0] + "." + parts[1].substring(0, 2)
                          ) || 0;
                        e.target.value = parsedValue.toString();
                      } else {
                        parsedValue = parseFloat(inputValue) || 0;
                      }
                    }
                  }

                  setData({
                    ...data,
                    totalWeightKg: parsedValue,
                  });
                }}
              />
            </div>
            <div className="input-group">
              <input type="file" onChange={handleFileChange} />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="inputGroupFileAddon04"
              >
                사진 촬영
              </button>
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tax"
                  checked={data.Addstatus === 1}
                  onChange={() => handleAddStatusChange(1)}
                />
                <label className="form-check-label" htmlFor="tax">
                  세금
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="returnCost"
                  checked={data.Addstatus === 2}
                  onChange={() => handleAddStatusChange(2)}
                />
                <label className="form-check-label" htmlFor="returnCost">
                  반송비용
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="collectDelivery"
                  checked={data.Addstatus === 3}
                  onChange={() => handleAddStatusChange(3)}
                />
                <label className="form-check-label" htmlFor="collectDelivery">
                  착불배송비
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="split"
                  checked={data.Addstatus === 4}
                  onChange={() => handleAddStatusChange(4)}
                />
                <label className="form-check-label" htmlFor="split">
                  스플릿
                </label>
              </div>
            </div>
            <div className="mt-3 text-end">
              {/* <button
                className="btn btn-success w-50"
                onClick={async (e) => {
                  if (!checkValid()) {
                    return;
                  }

                  const freeAt = calculateFreeAt(data.date, userMembershipId);

                  const currentDate = new Date();
                  const daysPastFreeAt = differenceInCalendarDays(
                    currentDate,
                    freeAt
                  );

                  let warehouseValue = "free";
                  if (daysPastFreeAt > 0) {
                    warehouseValue = `free +${daysPastFreeAt} days`;
                  }

                  const formattedDate = format(data.date, "yyyy-MM-dd'T'HH:mm");
                  const formattedFreeAt = format(freeAt, "yyyy-MM-dd'T'HH:mm");

                  const submissionData = {
                    ...data,
                    warehouse: warehouseValue,
                    date: formattedDate,
                    freeAt: formattedFreeAt,
                  };

                  try {
                    const result = await reqInboundShip(submissionData);

                    if (result.data) {
                      setData({
                        userId: "",
                        tracking: "",
                        date: new Date(),
                        Extra: 0,
                        warehouseId: "",
                        file: "",
                        Addstatus: 0,
                        warehouse: "",
                      });

                      dispatch(
                        modalOpen({
                          modalType: MODAL_TYPE.DATA_SAVE,
                          title: "입고 등록 완료",
                        })
                      );
                    }
                  } catch (error) {
                    console.error("Error submitting inbound ship data", error);
                    alert("송장번호가 중복됩니다.");
                  }
                }}
              >
                입고등록
              </button> */}
              <button
                className="btn btn-success w-50"
                onClick={handleFormSubmit}
              >
                입고등록
              </button>
            </div>
          </div>
          {imageUrl && (
            <div className="col-4">
              <ImageTmpSection>
                <img src={imageUrl} alt="Preview" />
              </ImageTmpSection>
            </div>
          )}
        </div>
      </main>
    </>
  );
}

const LoadingSection = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > img {
    width: 100%;
  }
`;

const ImageTmpSection = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  margin-top: 3rem;
  & > img {
    max-width: 60rem;
    max-height: 60rem;
    object-fit: scale-down;
  }
`;
