import { format } from "date-fns";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  FormControl,
  Table,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import Select, { SingleValue } from "react-select";
import makeAnimated from "react-select/animated";
import { CODE_SHOPPING_ORDER_ITEMS, DATE_FORMAT } from "../../common/constants";
import { modalClose, modalOpen } from "../../redux/modalSlice";
import { reqShipProductUpdate } from "../../requests/ship";
import Icon from "../Icon";
import { MODAL_TYPE } from "./GlobalModal";

interface Item {
  category: number | null;
  name: string;
  quantity: number;
  price: number;
}

export default function ModalShipModify() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const data = modal.payload.data;
  const [editedData, setEditedData] = useState({});
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const [image, setImage] = useState(data.storagePhotoUrl ?? "");
  const [showPreview, setShowPreview] = useState(true);
  const [info, setInfo] = useState<Item[]>(data.detail ?? []);
  const [activeKey, setActiveKey] = useState<string | null>("0");
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (data.detail && data.detail.length > 0) {
      setInfo(data.detail.map((item: Item) => ({ ...item })));
      setActiveKey((data.detail.length - 1).toString());
    } else {
      addNewItem();
    }
  }, [data]);

  useEffect(() => {
    // fetchExchangeRate();
  }, []);

  useEffect(() => {
    checkSubmitValidity();
  }, [info]);

  const checkSubmitValidity = () => {
    const isAnyItemValid = info.some(item =>
      item.category !== null &&
      item.name.trim() !== "" &&
      Number.isInteger(item.quantity) &&
      item.quantity > 0 &&
      item.price > 0
    );
    setIsSubmitEnabled(isAnyItemValid);
  };

  const addNewItem = () => {
    const newItem = { category: null, name: "", quantity: 0, price: 0 };
    const newInfo = [...info, newItem];
    setInfo(newInfo);
    setActiveKey((newInfo.length - 1).toString());
  };

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  const categoryOptions = [
    { value: null, label: "Choose Category", isDisabled: true },
    ...Object.entries(CODE_SHOPPING_ORDER_ITEMS).map(([key, value]) => ({
      value: parseInt(key),
      label: value,
    })),
  ];

  const handlePreviewAndSubmit = () => {
    setShowPreview(!showPreview);
  };

  const handleImageClick = () => {
    dispatch(modalOpen({
      modalType: MODAL_TYPE.IMAGE_PREVIEW,
      title: 'Image Preview',
      payload: { data: { imageUrl: data.imageUrl } }
    }));
  };

  const handleCategoryChange = (selectedOption: SingleValue<{ value: number | null; label: string }> | null, actionMeta: any, idx: number) => {
    if (selectedOption) {
      const updatedInfo = info.map((item, index) => index === idx ? { ...item, category: selectedOption.value } : item);
      setInfo(updatedInfo);
    }
  };

  const handleRemoveItem = (index: number) => {
    const updatedInfo = info.filter((_, idx) => idx !== index);
    setInfo(updatedInfo);
    setActiveKey(updatedInfo.length ? (updatedInfo.length - 1).toString() : "0");
  };

  const handleChange = (index: number, field: keyof typeof info[0], value: any) => {
    const updatedInfo = info.map((item, idx) => idx === index ? { ...item, [field]: value } : item);
    setInfo(updatedInfo);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const fileReader = new FileReader();

      fileReader.onload = function (e) {
        setImage(e.target?.result as string);
      };

      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  const convertToKRW = (usd: number) => {
    return Math.round(usd * exchangeRate);
  };

  return (
    <>
      <div
        style={{
          height: "600px",
          marginTop: "20px",
          paddingRight: "15px",
          paddingLeft: "5px",
        }}
      >
        <div style={{ width: "100%", marginBottom: "15px" }}>
          Tracking No.
          <FormControl disabled value={data.tracking} />
        </div>
        <div style={{ width: "100%", marginBottom: "15px" }}>
        Arrival Date
          <FormControl
            disabled
            value={format(new Date(data.inboundAt), DATE_FORMAT.ONLY_DATE)}
          />
        </div>
        <div style={{ flex: 1 }}>
          {data.imageUrl ? (
            <img
              src={data.imageUrl}
              alt="Attached File"
              style={{ width: isMobile ? '100%' : '700px', height: '100%', objectFit: 'cover' }}
              onClick={handleImageClick}
            />
          ) : 'No image available'}
        </div>

        <div style={{ width: "100%", marginBottom: "15px" }}>
          Contents
          <Accordion
            defaultActiveKey="0"
            activeKey={activeKey}
            onSelect={(eventKey) => {
              if (typeof eventKey === "string" || eventKey === null) {
                setActiveKey(eventKey);
              } else {
                console.warn("Unexpected eventKey type:", typeof eventKey);
              }
            }}
            style={{ marginTop: "10px" }}
          >
            {info.map((item, idx) => (
              <AccordionItem key={idx} eventKey={idx.toString()}>
                <AccordionHeader>Item #{idx + 1}</AccordionHeader>
                <AccordionBody>
                  <div style={{ marginBottom: "15px" }}>
                    Category *
                    <Select
                      components={animatedComponents}
                      options={categoryOptions}
                      value={categoryOptions.find(
                        (option) => option.value === item.category
                      )}
                      onChange={(selectedOption, actionMeta) =>
                        handleCategoryChange(
                          selectedOption as SingleValue<{
                            value: number | null;
                            label: string;
                          }>,
                          actionMeta,
                          idx
                        )
                      }
                    />
                  </div>
                  <div style={{ width: "100%", marginBottom: "15px" }}>
                    Name *
                    <FormControl
                      value={item.name}
                      onChange={(e) => {
                        handleChange(idx, 'name', e.target.value);
                      }}
                    />
                  </div>
                  <div style={{ width: "100%", marginBottom: "15px" }}>
                    Quantity *
                    <FormControl
                      type="number"
                      value={item.quantity.toString()}
                      onChange={(e) => {
                        handleChange(idx, 'quantity', Number(e.target.value));
                      }}
                    />
                  </div>
                  <div style={{ width: "100%", marginBottom: "15px" }}>
                    Unit Price($) *
                    <FormControl
                      type="number"
                      step="0.01"
                      value={item.price.toString()}
                      onChange={(e) => {
                        handleChange(idx, 'price', parseFloat(e.target.value));
                      }}
                    />
                  </div>
                  <Button variant="danger" onClick={() => handleRemoveItem(idx)}>
                    <Icon icon="trash" /> Delete Item
                  </Button>
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <Button
          variant="outline-success"
          style={{ width: "100%" }}
          onClick={() => {
            const newItemIndex = info.length;
            setInfo([
              ...info,
              { category: null, name: "", quantity: 0, price: 0 }, // Default category to null
            ]);
            setActiveKey(newItemIndex.toString());
          }}
        >
          <Icon icon="plus" /> Add Product
        </Button>
        <div className="d-flex justify-content-end gap-2 mt-3">
          <Button
            variant="outline-success"
            style={{ width: "50%" }}
            onClick={handlePreviewAndSubmit}
          >
            Preview
          </Button>
          <Button
            variant="success"
            style={{ width: "50%" }}
            onClick={async () => {
              const invalidInputs: string[] = [];
              info.forEach((item, index) => {
                if (item.category === null) invalidInputs.push(`Item #${index + 1}: Category is required.`);
                if (item.name.trim() === "") invalidInputs.push(`Item #${index + 1}: Name is required.`);
                if (!Number.isInteger(item.quantity) || item.quantity <= 0) invalidInputs.push(`Item #${index + 1}: Quantity must be a positive integer.`);
                if (isNaN(item.price) || item.price <= 0) invalidInputs.push(`Item #${index + 1}: Unit Price must be non-negative.`);
              });

              if (invalidInputs.length > 0) {
                Swal.fire({
                  icon: 'error',
                  title: 'Validation Error',
                  html: invalidInputs.join("<br>"),
                });
                return;
              }

              const payload = {
                tracking: data.tracking,
                info: info,
              };

              try {
                await reqShipProductUpdate(payload);
                dispatch(modalClose());
                setTimeout(() => {
                  dispatch(
                    modalOpen({
                      modalType: MODAL_TYPE.INFO,
                      title: "Change Saved",
                      payload: { text: "Changes saved successfully" },
                    })
                  );
                  window.location.reload();
                }, 500);
              } catch (error) {
                Swal.fire({
                  icon: 'error',
                  title: 'An error occurred',
                  text: 'An error occurred while saving changes.',
                });
              }
            }}
            disabled={!isSubmitEnabled}
          >
            Submit
          </Button>
        </div>
        {showPreview && (
          <div style={{ marginTop: "20px", overflowX: isMobile ? "auto" : "visible" }}>
            <h5>Preview</h5>
            <Table className="reduced-size-table" style={{ fontSize: isMobile ? '12px' : 'inherit', padding: isMobile ? '5px' : 'inherit' }}>
              <thead>
                <tr>
                  <th className="reduced-size-header" style={{ padding: isMobile ? '5px' : 'inherit', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: isMobile ? '60px' : 'inherit' }}>Category</th>
                  <th className="reduced-size-header" style={{ padding: isMobile ? '5px' : 'inherit', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: isMobile ? '60px' : 'inherit' }}>Name</th>
                  <th className="reduced-size-header" style={{ padding: isMobile ? '5px' : 'inherit', maxWidth: isMobile ? '60px' : 'inherit' }}>Quantity</th>
                  <th className="reduced-size-header" style={{ padding: isMobile ? '5px' : 'inherit', maxWidth: isMobile ? '60px' : 'inherit' }}>Price($)</th>
                  <th className="reduced-size-header" style={{ padding: isMobile ? '5px' : 'inherit', maxWidth: isMobile ? '60px' : 'inherit' }}>total(Price)</th>
                </tr>
              </thead>
              <tbody>
                {info.map((item, index) => (
                  <tr key={index}>
                    <td style={{ padding: isMobile ? '5px' : 'inherit', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: isMobile ? '60px' : 'inherit' }}>
                      {item.category === null ? "Choose Category" : (
                        CODE_SHOPPING_ORDER_ITEMS[item.category].length > 7
                          ? `${CODE_SHOPPING_ORDER_ITEMS[item.category].substring(0, 7)}...`
                          : CODE_SHOPPING_ORDER_ITEMS[item.category]
                      )}
                    </td>
                    <td style={{ padding: isMobile ? '5px' : 'inherit', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: isMobile ? '60px' : 'inherit' }}>
                      {item.name.length > 7 ? `${item.name.substring(0, 7)}...` : item.name}
                    </td>
                    <td style={{ padding: isMobile ? '5px' : 'inherit' }}>{item.quantity}</td>
                    <td style={{ padding: isMobile ? '5px' : 'inherit' }}>{item.price}</td>
                    <td style={{ padding: isMobile ? '5px' : 'inherit' }}>${item.quantity * item.price}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </>
  );
}
