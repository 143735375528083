import React, { useEffect, useState } from "react";
import { Pagination, Spinner, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import {
  CODE_SHIPPING_PAYMENT_STATUS,
  CODE_SHOPING_ORDER_STATUS,
} from "../../../common/constants";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import ShopPaidModal from "../../../components/modal/ShopPaidModal";
import { reqAShop, reqShopUpdate } from "../../../requests/shop";

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const LoadingText = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin: 0;
`;

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

type OrderData = {
  orderId: string;
  userId: string;
  createdAt: string;
  PaymentMethod: number;
  totalPriceUSD: number;
  totalPriceKRW: number;
  paymentAmountKRW: number;
  snUsdRealFee: number;
  paymentAmountUSD: number;
  status: number;
  productId: string;
};

const AShopPaid: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<string | null>(null);
  const [orders, setOrders] = useState<OrderData[]>([]);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [selectedOrderForUpdate, setSelectedOrderForUpdate] = useState({
    orderId: "",
    newStatus: 0,
  });

  const refreshData = () => {
    fetchOrders();
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      setIsLoading(true);
      const response = await reqAShop();
      const filteredOrders = response.data.filter(
        (order: OrderData) =>
          order.status === CODE_SHOPING_ORDER_STATUS.PRODUCT_PART,
        CODE_SHOPING_ORDER_STATUS.PRODUCT_COMPLETION,
        CODE_SHOPING_ORDER_STATUS.PRODUCT_WAREHOUSING
      );
      setOrders(filteredOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const displayPaymentAmount = (order: OrderData) => {
    switch (order.PaymentMethod) {
      case 1:
        return `${order.paymentAmountKRW} KRW`;
      case 2:
        return `${order.snUsdRealFee} USD`;
      default:
        return "-";
    }
  };

  const handleOrderClick = (orderId: string) => {
    setCurrentOrder(orderId);
    setShowModal(true);
  };

  const handleStatusChange = async (orderId: string, newStatus: number) => {
    try {
      const updateData = {
        order_id: orderId,
        status: newStatus,
      };

      await reqShopUpdate(updateData);
      alert("주문 상태가 업데이트되었습니다.");

      const updatedOrders = orders
        .map((order) => {
          if (order.orderId === orderId) {
            return { ...order, status: newStatus };
          }
          return order;
        })
        .filter((order) => {
          return (
            order.status !== CODE_SHOPING_ORDER_STATUS.PRODUCT_WAREHOUSING,
            CODE_SHOPING_ORDER_STATUS.PRODUCT_COMPLETION
          );
        });

      setOrders(updatedOrders);
    } catch (error) {
      console.error("Error updating order status:", error);
      alert("주문 상태 업데이트에 실패했습니다.");
    }
  };

  const handleSelectClick = (e: React.MouseEvent) => {
    const updateData = {
      status: CODE_SHOPING_ORDER_STATUS.WAIT_PAY,
    };
    e.stopPropagation();
  };

  const getPaymentMethod = (methodCode: number) => {
    return CODE_SHIPPING_PAYMENT_STATUS[methodCode] || "알 수 없음";
  };

  const handleStatusChangeRequest = (orderId: string, newStatus: number) => {
    setSelectedOrderForUpdate({ orderId, newStatus });
    setConfirmModalShow(true);
  };

  const handleStatusChangeConfirm = async () => {
    setConfirmModalShow(false);
    const { orderId, newStatus } = selectedOrderForUpdate;
    await handleStatusChange(orderId, newStatus);
    refreshData();
  };

  //! 필터 시작
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [searchWord, setSearchWord] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const thisFilteredData = orders
      .filter((order: any) =>
        order.orderId.toUpperCase().includes(searchWord.toUpperCase())
      )
      .sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    // const thisFilteredData = orders.filter(
    //   (order) =>
    //     order.orderId.toLowerCase().includes(searchWord.toLowerCase()) &&
    //     (selectedStatus ? order.status.toString() === selectedStatus : true)
    // );
    setFilteredData(thisFilteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredData.length / itemsPerPage));
  }, [orders, searchWord, itemsPerPage, selectedStatus]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  //! 필터 끝

  return (
    <main id="main" className="main">
      <AdminHeadTitle
        subtitle1="구매대행"
        subtitle2="구매대행 결제 완료"
        title="구매대행 결제 완료"
      />

      {/* 옵션 필터 */}
      <AdminHeadFilter
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
        selectSearchOption={(value: string) => setSearchOption(value)}
      />

      {isLoading ? (
        <LoadingContainer>
          <Spinner animation="border" variant="primary" />
          <LoadingText>데이터를 불러오는 중입니다...</LoadingText>
        </LoadingContainer>
      ) : (
        <>
          <div className="table-responsive">
            <Table className="table-custom text-center">
              <thead className="table-light">
                <tr>
                  <th>주문번호</th>
                  <th>담당자</th>
                  <th>스페이스코드</th>
                  <th>결제수단</th>
                  <th>결제금액</th>
                </tr>
              </thead>
              <tbody>
                {displayedOrders.map((order) => (
                  <tr
                    key={order.orderId}
                    onClick={() => handleOrderClick(order.orderId)}
                    style={{ cursor: "pointer" }}
                  >
                    <th>{order.orderId}</th>
                    <th>{order.productId}</th>
                    <td>{order.userId}</td>
                    <td>{getPaymentMethod(order.PaymentMethod)}</td>
                    <td>{displayPaymentAmount(order)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* 페이지 바 */}
          <CenteredPagination style={{ marginTop: "1rem" }}>
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </CenteredPagination>
        </>
      )}

      {currentOrder && (
        <ShopPaidModal
          show={showModal}
          onHide={() => setShowModal(false)}
          orderId={currentOrder}
          refreshData={refreshData}
        />
      )}

      <Modal show={confirmModalShow} onHide={() => setConfirmModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>주문 상태 변경 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>정말로 주문 상태를 변경하시겠습니까?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setConfirmModalShow(false)}
          >
            취소
          </Button>
          <Button variant="primary" onClick={handleStatusChangeConfirm}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default AShopPaid;
