import JsBarcode from "jsbarcode";
import React, { useEffect, useState } from "react";
import { Pagination, Spinner, Table } from "react-bootstrap";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import { ADMIN_BADGE, USER_BADGE } from "../../../common/constants";
import { formatCustomDate } from "../../../common/format";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { RootState } from "../../../redux/store";
import { reqSendAlarm } from "../../../requests/alarm";
import { reqAddBadge, reqReadAdminBadge } from "../../../requests/badge";
import {
  reqAShip,
  reqLostDetail,
  reqShipProductBox,
  reqlostGet,
} from "../../../requests/ship";
import { reqlostUpdates } from "../../../requests/warehouse";

interface LostShipmentRequest {
  id: number;
  site: string;
  orderNumber: string;
  createdAt: Date;
  userId: string;
  productName: string;
  status: string;
  quantity: number;
  manager: string;
  user: {
    name: string;
    membershipId: number;
    latestWarehouseId: string;
    id: string;
  };
  imageUrl?: string;
}

interface DetailedLostShipmentRequest extends LostShipmentRequest {
  products: { name: string; quantity: number }[];
  imageUrl?: string;
}

interface InboundRequest {
  tracking: string;
  createdAt: Date | string;
  status: string;
  userId?: string;
  name?: string;
  warehouseId?: string;
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const LoadingText = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin: 0;
`;

const AWFindLost: React.FC = () => {
  const [lostRequests, setLostRequests] = useState<LostShipmentRequest[]>([]);
  const [selectedRequest, setSelectedRequest] =
    useState<DetailedLostShipmentRequest | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [inboundRequests, setInboundRequests] = useState<InboundRequest[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [spaceCodeFilter, setSpaceCodeFilter] = useState("");
  const [selectedRequests, setSelectedRequests] = useState<InboundRequest[]>(
    []
  );
  const admin = useSelector((state: RootState) => state.admin);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [statusFilter, setStatusFilter] = useState<
    "Not Done" | "CAN NOT TRACK" | "DONE" | ""
  >("Not Done");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [lostResponse, ashipResponse] = await Promise.all([
          reqlostGet(),
          reqAShip(),
        ]);

        const sortedData = lostResponse.data.sort(
          (a: LostShipmentRequest, b: LostShipmentRequest) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        setLostRequests(sortedData);
        setAsShipData(ashipResponse.data);

        const filtered = sortedData.filter((request: any) => {
          const spaceCodeMatch = request.userId.includes(spaceCodeFilter);
          const statusMatch =
            statusFilter === "" || request.status === statusFilter;
          return spaceCodeMatch && statusMatch;
        });
        setFilteredData(filtered);
        setTotalPages(Math.ceil(filtered.length / itemsPerPage));
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const badgePayload = {
      badgeName: ADMIN_BADGE.LOST_PRODUCT,
    };
    reqReadAdminBadge(badgePayload);
  }, [itemsPerPage, spaceCodeFilter, statusFilter]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    itemsPerPage === -1 ? filteredData.length : startIndex + itemsPerPage
  );

  const openImageModal = () => {
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  const handleDetailsClick = async (id: number) => {
    try {
      const response = await reqLostDetail(id);
      setSelectedRequest(response.data);

      const inboundResponse = await reqAShip();
      const allRequests = inboundResponse.data
        .filter((item: any) => item.userId === "NONE" && item.status === 1)
        .map((item: any) => ({
          ...item,
          createdAt: new Date(item.createdAt),
        }));

      setInboundRequests(allRequests);
      setModalIsOpen(true);
    } catch (error) {
      console.error("Failed to fetch details for request:", error);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const fetchLostRequests = async () => {
    try {
      const response = await reqlostGet();
      setLostRequests(response.data);
    } catch (error) {
      console.error("Failed to fetch lost shipment requests:", error);
    }
  };

  const handleCheckboxChange = (
    selectedReq: InboundRequest,
    isChecked: boolean
  ) => {
    if (isChecked) {
      setSelectedRequests((prev) => [...prev, selectedReq]);
    } else {
      setSelectedRequests((prev) =>
        prev.filter((req) => req.tracking !== selectedReq.tracking)
      );
    }
  };

  // 입고 완료
  const handleSubmit = async () => {
    Swal.fire({
      title: "입고완료을 수행하시겠습니까?",
      text: "사용자에게 알림을 발송합니다.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await sendSubmit();
        window.location.reload();
      }
    });
  };

  // 입고 등록
  const handleRegisterSubmit = async () => {
    if (selectedRequests.length === 0) {
      Swal.fire({
        title: "오류",
        text: "등록할 항목을 선택해주세요.",
        icon: "warning",
        confirmButtonText: "확인",
      });
      return;
    }

    Swal.fire({
      title: "입고등록을 수행하시겠습니까?",
      text: "사용자에게 알림을 발송합니다.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await sendRegisterSubmit();
        handlePrintSelected();
        window.location.reload();
      }
    });
  };

  // 입고 등록
  const sendRegisterSubmit = async () => {
    try {
      if (!selectedRequest?.userId) {
        throw new Error("No selected request user ID found.");
      }

      const userId = selectedRequest.userId;
      await Promise.all(
        selectedRequests.map((request) =>
          reqShipProductBox({
            userId: userId,
            tracking: request.tracking,
            warehouseId: selectedRequest.user.latestWarehouseId,
          })
        )
      );

      await reqlostUpdates({
        id: selectedRequest.id,
        userId: selectedRequest.userId,
        orderNumber: selectedRequest.orderNumber,
        manager: admin.name,
        status: "DONE",
      });

      // 선택된 각 항목에 대해 알림 메시지를 생성
      await Promise.all(
        selectedRequests.map(async (req) => {
          let content = `The product number ${req.tracking} has been successfully stored.\n`;

          // membershipId가 3일 경우 추가 메시지 전송
          // if (selectedRequest.user.membershipId === 3) {
          //   content += `If you don't apply for delivery soon, a warehouse usage fee of up to $30 may be charged, and if it exceeds $30, it will be automatically discarded.\nPlease make sure to apply for delivery soon!\n\nFor more details, please refer to the [terms and conditions](https://docs.google.com/document/d/1SuZi6RC7hWQG9aXqoRLTO2HbZuH0wJ6uondJEFs5Z6s/edit#heading=h.r5gfdkjr887q).`;
          // }

          const alarmPayload = {
            userId: selectedRequest.userId,
            read: 0,
            content: content,
            sender: admin.name,
          };

          await reqSendAlarm(alarmPayload);
        })
      );

      const badgePayload = {
        userId: selectedRequest.userId,
        badgeName: USER_BADGE.LOST_PRODUCT,
      };
      await reqAddBadge(badgePayload);

      setModalIsOpen(false);
      setSelectedRequests([]);
    } catch (error) {
      console.error("Failed to register products:", error);
      Swal.fire({
        title: "제품 등록에 실패했습니다",
        icon: "error",
        confirmButtonText: "닫기",
      });
    }
  };

  // 입고 완료
  const sendSubmit = async () => {
    try {
      if (!selectedRequest?.userId) {
        throw new Error("No selected request user ID found.");
      }

      await reqlostUpdates({
        id: selectedRequest.id,
        manager: admin.name,
        status: "DONE",
      });

      const alarmPayload = {
        userId: selectedRequest.userId,
        read: 0,
        content: "Your item is already registered. Please check it again.",
        sender: admin.name,
      };

      await reqSendAlarm(alarmPayload);

      const badgePayload = {
        userId: selectedRequest.userId,
        badgeName: USER_BADGE.LOST_PRODUCT,
      };
      await reqAddBadge(badgePayload);

      setModalIsOpen(false);
      setSelectedRequests([]);
    } catch (error) {
      console.error("Failed to register products:", error);
      Swal.fire({
        title: "제품 등록에 실패했습니다",
        icon: "error",
        confirmButtonText: "닫기",
      });
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleCanNotTrack = async (id: number) => {
    const result = await Swal.fire({
      title: "찾지 못하셨나요??",
      text: "CAN NOT TRACK 상태로 변경하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "No, cancel!",
    });

    if (result.isConfirmed) {
      try {
        await reqlostUpdates({
          id: id,
          manager: admin.name,
          status: "CAN NOT TRACK",
        });

        Swal.fire({
          title: "Updated Successfully",
          text: "The status has been updated to CAN NOT TRACK.",
          icon: "success",
          confirmButtonText: "OK",
        });

        fetchLostRequests();

        const selectedReq = lostRequests.find((req) => req.id === id);

        if (selectedReq) {
          const alarmPayload = {
            userId: selectedReq.userId,
            read: 0,
            content:
              "Your tracking number claim has been updated to CAN NOT TRACK. Please check the details.",
            sender: admin.name,
          };
          await reqSendAlarm(alarmPayload);

          const badgePayload = {
            userId: selectedReq.userId,
            badgeName: USER_BADGE.LOST_PRODUCT,
          };
          await reqAddBadge(badgePayload);
        }
      } catch (error) {
        console.error("Failed to update status:", error);
        Swal.fire({
          title: "Error",
          text: "Failed to update the status or send notifications.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      Swal.fire("Cancelled", "The status update has been cancelled.", "error");
    }
    setModalIsOpen(false);
    window.location.reload();
  };

  const handlePrintSelected = () => {
    const printWindow = window.open("", "", "width=800,height=600");
    if (!printWindow) {
      console.error(
        "Failed to open the print window. Check your browser popup settings."
      );
      return;
    }

    printWindow.document.write("<html><head><title>Print</title><style>");
    printWindow.document.write("@page { size: 8cm 6cm; margin: 0; }");
    printWindow.document.write(
      "body { margin: 0; display: flex; flex-direction: column; align-items: center; }"
    );
    printWindow.document.write(
      ".page { display: flex; justify-content: center; align-items: center; width: 8cm; height: 6cm; page-break-after: always; }"
    );
    printWindow.document.write(
      ".print-container { width: 8cm; height: 6cm; display: flex; flex-direction: column; justify-content: center; align-items: center; border: 1px solid #000; padding: 10px; box-sizing: border-box; }"
    );
    printWindow.document.write("svg { width: 6cm; height: 2cm; }");
    printWindow.document.write(
      "p { margin: 0; padding: 2px 0; text-align: center; }"
    );
    printWindow.document.write("</style></head><body>");

    selectedRequests.forEach((request) => {
      const lostRequest = lostRequests.find(
        (lostReq) => lostReq.id === selectedRequest?.id
      );
      if (lostRequest) {
        const svgBarcode = `<svg id="barcode-${lostRequest.orderNumber}"></svg>`;

        printWindow.document.write('<div class="page">');
        printWindow.document.write('<div class="print-container">');
        printWindow.document.write(svgBarcode);
        printWindow.document.write(`<p>${lostRequest.orderNumber}</p>`);
        printWindow.document.write(`<p>${lostRequest.userId}</p>`);
        printWindow.document.write(
          `<p>${
            lostRequest.user.name ? lostRequest.user.name : "Unknown User"
          }</p>`
        );
        printWindow.document.write(
          `<p>${
            lostRequest.user.latestWarehouseId
              ? lostRequest.user.latestWarehouseId
              : "Unknown Warehouse"
          }/${lostRequest.orderNumber.slice(-8)}</p>`
        );
        printWindow.document.write("</div>");
        printWindow.document.write("</div>");
      }
    });

    printWindow.document.write("</body></html>");
    printWindow.document.close();

    // 바코드 생성
    selectedRequests.forEach((request) => {
      const lostRequest = lostRequests.find(
        (lostReq) => lostReq.id === selectedRequest?.id
      );
      if (lostRequest) {
        const barcodeElement = printWindow.document.getElementById(
          `barcode-${lostRequest.orderNumber}`
        );
        JsBarcode(barcodeElement, lostRequest.orderNumber, {
          format: "CODE128",
          displayValue: false,
          width: 1,
          height: 30,
          margin: 0,
        });
      }
    });

    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const 택배_배송사_링크 = [
    { name: `CJ`, url: `https://www.cjlogistics.com/ko/tool/parcel/tracking` },
    {
      name: `롯데택배`,
      url: `https://www.lotteglogis.com/home/reservation/tracking/index`,
    },
    {
      name: `우체국 택배조회`,
      url: `https://service.epost.go.kr/iservice/trace.jsp`,
    },
    {
      name: `한진`,
      url: `https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillSch.do?mCode=MN038`,
    },
    { name: `로젠`, url: `https://www.ilogen.com/web/personal/tkSearch` },
    { name: `경동`, url: `https://kdexp.com/index.do` },
    { name: `합동`, url: `https://hdexp.co.kr/index.hd` },
  ];

  useEffect(() => {
    const filtered = lostRequests.filter((request) => {
      const spaceCodeMatch = request.userId.includes(spaceCodeFilter);
      const statusMatch =
        statusFilter === "" || request.status === statusFilter;
      return spaceCodeMatch && statusMatch;
    });
    setFilteredData(filtered);
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
  }, [lostRequests, spaceCodeFilter, statusFilter, itemsPerPage]);

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(e.target.value);
    setItemsPerPage(value === -1 ? lostRequests.length : value);
    setCurrentPage(1);
  };

  // 송장번호 비교
  const [asShipData, setAsShipData] = useState<any[]>([]);

  const getInboundStatus = (request: LostShipmentRequest) => {
    if (!asShipData || asShipData.length === 0) {
      return "입고기록 없음";
    }

    const orderNumber = request.orderNumber.trim().toUpperCase();
    const matchingItems = asShipData.filter(
      (item: any) => item.tracking.trim().toUpperCase() === orderNumber
    );

    if (matchingItems.length > 0) {
      // status가 20이면 폐기상태로 표시
      const isDiscarded = matchingItems.some((item: any) => item.status === 20);
      if (isDiscarded) {
        return "폐기상태";
      }

      const hasNoneUserId = matchingItems.some(
        (item: any) => item.userId === "NONE"
      );
      if (hasNoneUserId) {
        return "NONE에 보관";
      } else {
        return "입고완료";
      }
    } else {
      return "입고기록 없음";
    }
  };

  return (
    <>
      <main id="main" className="main">
        <AdminHeadTitle
          subtitle1="창고 관리"
          subtitle2="분실물 색출 요청 내역"
          title="분실물 색출 요청 내역"
        />

        <div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.2rem",
              marginBottom: "1rem",
            }}
          >
            배송사 배송 조회
          </div>

          <div
            className="line f-row"
            style={{ gap: "1rem", paddingBottom: "2rem" }}
          >
            {택배_배송사_링크.map((data: any) => {
              return (
                <div
                  key={data.name}
                  className="text-link"
                  onClick={() =>
                    window.open(data.url, "_blank", "noopener, noreferrer")
                  }
                  style={{
                    borderRight: "1px solid lightgray",
                    paddingRight: "1rem",
                  }}
                >
                  {data.name}
                </div>
              );
            })}
          </div>
        </div>

        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="itemsPerPage" className="form-label">
                페이지 당 항목 수
              </label>
              <select
                id="itemsPerPage"
                className="form-select"
                value={itemsPerPage === lostRequests.length ? -1 : itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option value={10}>10개씩 보기</option>
                <option value={20}>20개씩 보기</option>
                <option value={50}>50개씩 보기</option>
                <option value={-1}>전체 보기</option>
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="statusFilter" className="form-label">
                상태
              </label>
              <select
                id="statusFilter"
                className="form-select"
                value={statusFilter}
                onChange={(e) =>
                  setStatusFilter(
                    e.target.value as "Not Done" | "CAN NOT TRACK" | "DONE" | ""
                  )
                }
              >
                <option value="">모두</option>
                <option value="Not Done">요청</option>
                <option value="CAN NOT TRACK">추적불가</option>
                <option value="DONE">입고완료</option>
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="spaceCodeFilter" className="form-label">
                스페이스코드
              </label>
              <input
                type="text"
                id="spaceCodeFilter"
                className="form-control"
                value={spaceCodeFilter}
                onChange={(e) => setSpaceCodeFilter(e.target.value)}
              />
            </div>
          </div>
        </div>

        {isLoading ? (
          <LoadingContainer>
            <Spinner animation="border" variant="primary" />
            <LoadingText>데이터를 불러오는 중입니다...</LoadingText>
          </LoadingContainer>
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">구매 사이트</th>
                  <th scope="col">송장번호</th>
                  <th scope="col">요청날짜</th>
                  <th scope="col">스페이스코드</th>
                  <th scope="col">상세정보</th>
                  <th scope="col">상태</th>
                  <th scope="col">입고 상태</th>
                  <th scope="col">담당자</th>
                </tr>
              </thead>
              <tbody>
                {displayedOrders.map((request, index) => (
                  <tr key={index}>
                    <th scope="row">{startIndex + index + 1}</th>
                    <td>{request.site}</td>
                    <td>{request.orderNumber}</td>
                    <td>{formatCustomDate(new Date(request.createdAt))}</td>
                    <td>{request.userId}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => handleDetailsClick(request.id)}
                        style={{ backgroundColor: "black", color: "white" }}
                      >
                        <i className="bi bi-book text-primary" />
                      </button>
                    </td>
                    <td>
                      {request.status === "Not Done"
                        ? "요청"
                        : request.status === "CAN NOT TRACK"
                        ? "추적불가"
                        : request.status === "DONE"
                        ? "입고완료"
                        : request.status}
                    </td>
                    <td>{getInboundStatus(request)}</td>
                    <td>{request.manager}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <CenteredPagination style={{ marginTop: "1rem" }}>
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </CenteredPagination>
          </>
        )}
      </main>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Lost Shipment Details"
      >
        {selectedRequest ? (
          <>
            <table className="table">
              <tbody>
                <tr>
                  <th>스페이스코드</th>
                  <td>{selectedRequest.userId}</td>
                </tr>
                <tr>
                  <th>구매사이트</th>
                  <td>{selectedRequest.site}</td>
                </tr>
                <tr>
                  <th>송장번호</th>
                  <td>{selectedRequest.orderNumber}</td>
                </tr>
                <tr>
                  <th>첨부파일</th>
                  <td>
                    {selectedRequest.imageUrl ? (
                      <img
                        src={selectedRequest.imageUrl}
                        alt="Attached File"
                        style={{
                          maxWidth: "200px",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        onClick={openImageModal}
                      />
                    ) : (
                      "No image available"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>상품 목록</h5>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>상품명</th>
                  <th>수량</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>{selectedRequest?.productName}</td>
                  <td>{selectedRequest?.quantity}</td>
                </tr>
              </tbody>
            </table>
            <h2>NONE입고 내역</h2>
            <div>
              <input
                type="text"
                placeholder="송장번호로 검색"
                value={searchTerm.toUpperCase()}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: "20px", width: "100%", padding: "10px" }}
              />
            </div>
            <ResponsiveTable className="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>송장번호</th>
                  <th>입고일</th>
                  <th>상태</th>
                </tr>
              </thead>
              <tbody>
                {inboundRequests
                  .filter((req) => req.tracking.includes(searchTerm))
                  .map((req, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRequests.some(
                            (selectedReq) =>
                              selectedReq.tracking === req.tracking
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(req, e.target.checked)
                          }
                        />
                      </td>
                      <td>{req.tracking}</td>
                      <td>{new Date(req.createdAt).toLocaleDateString()}</td>
                      <td>{req.status === "10" ? "폐기" : "보관"}</td>
                    </tr>
                  ))}
              </tbody>
            </ResponsiveTable>
          </>
        ) : (
          <p>Loading...</p>
        )}
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <button onClick={handleSubmit} style={{ marginRight: "10px" }}>
            입고완료
          </button>
          <button
            onClick={handleRegisterSubmit}
            style={{ marginRight: "10px" }}
          >
            입고등록
          </button>
          <button
            onClick={() => handleCanNotTrack(selectedRequest?.id ?? 0)}
            style={{ marginRight: "10px" }}
          >
            추적 불가
          </button>
          <button onClick={closeModal}>닫기</button>
        </div>
      </Modal>

      <Modal
        isOpen={isImageModalOpen}
        onRequestClose={closeImageModal}
        style={customImageModalStyles}
        contentLabel="Image Preview"
      >
        <img
          src={selectedRequest?.imageUrl}
          alt="Enlarged file"
          style={{ width: "100%", height: "auto" }}
        />
      </Modal>
    </>
  );
};

const customStyles: ReactModal.Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "50%",
    maxHeight: "80vh",
    transform: "translate(-50%, -50%)",
    overflowY: "auto" as React.CSSProperties["overflowY"],
  },
};

const customImageModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "80%",
    maxHeight: "80vh",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
  },
};

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  max-height: 400px;
  overflow-y: auto;

  th,
  td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
  }

  thead th {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
    th,
    td {
      padding: 6px;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.7em;
    th,
    td {
      padding: 4px;
    }
  }
`;

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

export default AWFindLost;
