import React, { useEffect, useState } from "react";
import { Pagination, Table } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  CODE_SHOPING_ORDER_STATUS,
  CODE_SHOP_STATUS,
  DATA_TYPE,
} from "../../../common/constants";
import { formatDate } from "../../../common/format";
import CustomerHeadFilter from "../../../components/CustomerHeadFilter";
import CustomerHeadFilterMobile from "../../../components/CustomerHeadFilterMobile";
import CustomerPageTitle from "../../../components/CustomerPageTitle";
import FormatData from "../../../components/FormatData";
import CShopOrderModal from "../../../components/modal/CShopOrderModal";
import CShopOrderPreorderModal from "../../../components/modal/CShopOrderPreorderModal";
import CShopOrderReapplyModal from "../../../components/modal/CShopOrderReapplyModal";
import CShopOrderWiseModal from "../../../components/modal/CShopOrderWiseModal";
import { RootUserState } from "../../../redux/store";
import {
  reqShop,
  reqShopProductExpired,
  requserShip,
} from "../../../requests/shop";

type OrderData = {
  orderId: string;
  userId: string;
  createdAt: string;
  paymentDeadline: string;
  preorder: boolean;
  fancall: boolean;
  totalPriceKRW: number;
  totalPriceUSD: number;
  PaymentMethod: number;
  status: number;
};

const ITEMS_PER_PAGE = 10;

const CustomPagination = ({
  totalPages,
  currentPage,
  onPageChange,
}: {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}) => {
  const maxVisiblePages = isMobile ? 5 : 10;

  const getPageNumbers = () => {
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  return (
    <CenteredPagination>
      {currentPage > 1 && (
        <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} />
      )}

      {getPageNumbers().map((pageNum) => (
        <Pagination.Item
          key={pageNum}
          active={pageNum === currentPage}
          onClick={() => onPageChange(pageNum)}
        >
          {pageNum}
        </Pagination.Item>
      ))}

      {currentPage < totalPages && (
        <Pagination.Next onClick={() => onPageChange(currentPage + 1)} />
      )}
    </CenteredPagination>
  );
};

const CShopOrder: React.FC = () => {
  const user = useSelector((state: RootUserState) => state.user);
  const [currentOrder, setCurrentOrder] = useState<OrderData | null>(null);
  const [orders, setOrders] = useState<OrderData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState<OrderData[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [showReapplyModal, setShowReapplyModal] = useState(false);
  const [showFancallModal, setShowFancallModal] = useState(false);
  const [showPreorderModal, setShowPreorderModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showWiseModal, setShowWiseModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState<OrderData[]>([]);

  const customOrder = [1, 10, 100, 2, 3, 4, 6, 7];

  const fetchOrders = async () => {
    try {
      const response = await requserShip(user.id);
      const allOrders = response.data
        .filter((order: OrderData) => order.userId === user.id)
        .sort(
          (a: OrderData, b: OrderData) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
        .sort(
          (a: OrderData, b: OrderData) =>
            customOrder.indexOf(a.status) - customOrder.indexOf(b.status)
        );
      setOrders(allOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [user?.id]);

  useEffect(() => {
    const filtered = orders.filter((order) => {
      return (
        order.orderId.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedStatus ? order.status.toString() === selectedStatus : true)
      );
    });
    setFilteredOrders(filtered);
    setCurrentPage(1);
    setTotalPages(Math.ceil(filtered.length / ITEMS_PER_PAGE));
  }, [orders, searchTerm, selectedStatus, ITEMS_PER_PAGE]);

  const handleOrderClick = async (order: OrderData) => {
    try {
      // 현재 주문의 최신 상태 확인 (관리자가 작업 중인지 체크)
      const targetShopOrder = await reqShop({ order_id: order.orderId });

      // 관리자가 작업 중인 경우 (status가 TRANSACTION_OF_1_BETWEEN_2인 경우)
      if (
        Number(targetShopOrder.data.status) ===
        CODE_SHOPING_ORDER_STATUS.TRANSACTION_OF_1_BETWEEN_2
      ) {
        Swal.fire(
          "The administrator is checking in real time.",
          "Please try again in a moment.",
          "info"
        );
        return;
      }

      setCurrentOrder(order);

      if (order.status === CODE_SHOPING_ORDER_STATUS.CANCELED) {
        setShowReapplyModal(true);
      } else if ([2, 3].includes(order.status)) {
        setShowModal(true);
      } else if ([4, 5, 6, 7].includes(order.status)) {
        setShowPreorderModal(true);
      } else {
        setShowWiseModal(true);
      }
    } catch (error) {
      console.error("Error checking order status:", error);
      Swal.fire("오류가 발생했습니다.", "잠시 후 다시 시도해주세요.", "error");
    }
  };

  const handleModalDisplay = (order: OrderData) => {
    setCurrentOrder(order);

    if (order.status === CODE_SHOPING_ORDER_STATUS.CANCELED) {
      setShowReapplyModal(true);
    } else if ([2, 3].includes(order.status)) {
      setShowModal(true);
    } else if ([4, 5, 6, 7].includes(order.status)) {
      setShowPreorderModal(true);
    } else {
      setShowWiseModal(true);
    }
  };

  const 결제_마감일이_지난_후_처리 = (order: OrderData) => {
    const now = new Date();
    const paymentDealine = new Date(order?.paymentDeadline as string);
    if (now.getTime() < paymentDealine.getTime()) {
      setShowModal(true);
      return;
    }

    Swal.fire({
      title:
        "Change the order to cancel status because the payment deadline has passed.",
      text: "Please reorder your order.",
      icon: "info",

      confirmButtonColor: "#3085d6",
      confirmButtonText: "Ok",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await reqShopProductExpired({
          orderId: order?.orderId,
          status: 10,
          fancallNumber: "Payment Deadline Expired",
        });
        window.location.reload();
      }
    });
    return;
  };

  const refreshData = () => {
    fetchOrders();
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredOrders.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    setTotalPages(Math.ceil(filteredOrders.length / newItemsPerPage));
  };

  return (
    <>
      {isMobile ? (
        <MobileWrapper style={{ padding: "1.4rem" }}>
          <CustomerPageTitle title="My Orders" />

          <CustomerHeadFilterMobile
            selectStatus={(value: string) => setSelectedStatus(value)}
            selectViewPage={(value: number) => handleItemsPerPageChange(value)}
            enterSearch={(value: string) => setSearchTerm(value)}
          />

          <Box>
            {displayedOrders.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "200px",
                  textAlign: "center",
                  padding: "2rem 0",
                }}
              >
                <i
                  className="bi bi-exclamation-circle"
                  style={{
                    fontSize: "2.5rem",
                    color: "#6c757d",
                    marginBottom: "1rem",
                  }}
                ></i>
                <p style={{ fontSize: "1.1rem", color: "#666", margin: 0 }}>
                  No data available
                </p>
              </div>
            ) : (
              displayedOrders.map((order, index) => (
                <div
                  key={order.orderId}
                  onClick={() => handleOrderClick(order)}
                  style={{
                    cursor: "pointer",
                    borderBottom: "1px solid lightgray",
                    paddingBottom: "1rem",
                    paddingTop: "1rem",
                  }}
                >
                  <Row>
                    <P>NO</P>
                    <P>{index + 1 + startIndex}</P>
                  </Row>

                  <Row>
                    <P>Order Number</P>
                    <P>{order.orderId}</P>
                  </Row>

                  <Row>
                    <P>Order Date</P>
                    <P>{formatDate(order.createdAt)}</P>
                  </Row>

                  <Row>
                    <P>Closing Of Payment</P>
                    <P>{formatDate(order.paymentDeadline)}</P>
                  </Row>

                  <Row>
                    <P>Status</P>
                    <P>{CODE_SHOP_STATUS[order.status]}</P>
                  </Row>
                </div>
              ))
            )}
          </Box>
          <CustomPagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </MobileWrapper>
      ) : (
        <>
          <div style={{ padding: "30px" }}>
            <CustomerPageTitle title="My Orders" />

            {/* 옵션 필터 */}
            <CustomerHeadFilter
              selectStatus={(value: string) => setSelectedStatus(value)}
              selectViewPage={(value: number) =>
                handleItemsPerPageChange(value)
              }
              enterSearch={(value: string) => setSearchTerm(value)}
            />
            <div
              style={{
                height: "800px",
                overflowY: "scroll",
                marginBottom: "20px",
              }}
            >
              {displayedOrders.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "400px",
                    textAlign: "center",
                  }}
                >
                  <i
                    className="bi bi-exclamation-circle"
                    style={{
                      fontSize: "3rem",
                      color: "#6c757d",
                      marginBottom: "1rem",
                    }}
                  ></i>
                  <p style={{ fontSize: "1.2rem", color: "#666", margin: 0 }}>
                    No data available
                  </p>
                </div>
              ) : (
                <div className="table-responsive">
                  <Table className="table-custom text-center">
                    <thead className="table-light">
                      <tr translate="no">
                        <th className="font-large-page-title">NO</th>
                        <th className="font-large-page-title">Order Number</th>
                        <th className="font-large-page-title">Order Date</th>
                        <th className="font-large-page-title">
                          Payment Deadline
                        </th>
                        <th className="font-large-page-title">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedOrders.map((order, index) => (
                        <tr>
                          <td className="font-large-page">
                            {index + 1 + startIndex}
                          </td>
                          <td
                            key={order.orderId}
                            className="ontactweb-height"
                            onClick={() => handleOrderClick(order)}
                            style={{
                              cursor: "pointer",
                              color: "var(--color-main-blue)",
                              textDecoration: "underline",
                              fontSize: "1.3em",
                            }}
                          >
                            {order.orderId}
                          </td>
                          <td className="font-large-page">
                            {formatDate(order.createdAt)}
                          </td>
                          <td className="font-large-page">
                            {formatDate(order.paymentDeadline)}
                          </td>
                          <td className="font-large-page">
                            {FormatData(
                              DATA_TYPE.CODE_SHOPS_STATUS,
                              order.status
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <CustomPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {currentOrder && (
        <>
          {currentOrder.status === CODE_SHOPING_ORDER_STATUS.CANCELED ? (
            <CShopOrderReapplyModal
              show={showReapplyModal}
              onHide={() => setShowReapplyModal(false)}
              orderId={currentOrder.orderId}
              refreshData={refreshData}
            />
          ) : [2, 3].includes(currentOrder.status) ? (
            <CShopOrderModal
              show={showModal}
              onHide={() => setShowModal(false)}
              orderId={currentOrder.orderId}
              refreshData={refreshData}
            />
          ) : [4, 5, 6, 7].includes(currentOrder.status) ? (
            <CShopOrderPreorderModal
              show={showPreorderModal}
              onHide={() => setShowPreorderModal(false)}
              orderId={currentOrder.orderId}
              refreshData={refreshData}
            />
          ) : (
            <CShopOrderWiseModal
              show={showWiseModal}
              onHide={() => setShowWiseModal(false)}
              orderId={currentOrder.orderId}
              refreshData={refreshData}
            />
          )}
        </>
      )}
    </>
  );
};

export default CShopOrder;

const Title = styled.h3`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  margin-top: 1rem;
`;
const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
  padding: 1rem;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;
const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    margin-bottom: 4rem;
  }
`;

const RightAlignedGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1rem;
`;
const MSearchBar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;
