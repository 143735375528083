import { Button, FormControl, InputGroup, Table } from "react-bootstrap";
import Icon from "../../../components/Icon";

import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DATA_TYPE, USER_BADGE } from "../../../common/constants";
import { MRow, MWrapper } from "../../../common/mobile-styled";
import CustomerPageTitle from "../../../components/CustomerPageTitle";
import FormatData from "../../../components/FormatData";
import { RootUserState } from "../../../redux/store";
import { reqReadBadge } from "../../../requests/badge";
import { reqUserGetShipAllProduct } from "../../../requests/user";

interface IShipItem {
  id: number;
  check: boolean;
  status: number;
}

export default function CCheckcontents() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const user = useSelector((state: RootUserState) => state.user);
  const pageCount = 10;
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const [searchWord, setSearchWord] = useState<string>("");
  const [data, setData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [checked, setChecked] = useState<boolean[]>(
    Array<boolean>(0).fill(false)
  );
  const [totalCheck, setTotalCheck] = useState<boolean>(false);
  const [filteredOrders, setFilteredOrders] = useState<any[]>([]);

  const rf = async () => {
    const result = await reqUserGetShipAllProduct({
      userId: user.id,
      page: 1,
      pageCount,
      searchWord,
    });

    const filteredData = result.data.list
      .filter((item: IShipItem) => item.status === 4)
      .sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

    setData(filteredData);
    setTotalCount(filteredData.length);
    setChecked(Array<boolean>(filteredData.length).fill(false));
  };

  useEffect(() => {
    rf();
    const badgePayload = {
      userId: user.id,
      badgeName: USER_BADGE.CHECK_CONTENT,
    };
    reqReadBadge(badgePayload);
  }, [user?.id]);

  useEffect(() => {
    const filteredData = data.filter((order) =>
      order.tracking.toLowerCase().includes(searchWord.toLowerCase())
    );
    setFilteredOrders(filteredData);
  }, [data, searchWord]);

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  useEffect(() => {
    // fetchExchangeRate();
  }, [data]);

  const convertToKRW = (usd: number) => {
    return Math.floor(usd * exchangeRate).toLocaleString();
  };

  const convertToUSD = (krw: number) => {
    return (krw / exchangeRate).toFixed(2);
  };

  const [itemOpen, setItemOpen] = useState<any>({});

  const toggleItemOpen = (index: any) => {
    setItemOpen((prev: any) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // 띄어쓰기
  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <>
      {isMobile ? (
        <MWrapper style={{ padding: "1.4rem" }}>
          {/* 헤더 */}
          <CustomerPageTitle title="Check Contents" />

          {/* 검색 바 */}
          <MSearchBar>
            <InputGroup>
              <FormControl
                placeholder="Tracking Number"
                value={searchWord.toUpperCase()}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setSearchWord(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "enter") {
                    rf();
                  }
                }}
              />
              <Button
                variant="outline-success"
                style={{ borderColor: "var(--color-main-blue)" }}
                onClick={rf}
              >
                <Icon icon="search" color="var(--color-main-blue)" />
              </Button>
            </InputGroup>
          </MSearchBar>

          {/* 테이블 */}
          <MTableWrapper>
            <MTableSection>
              {filteredOrders.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "200px",
                    textAlign: "center",
                    padding: "2rem 0",
                  }}
                >
                  <i
                    className="bi bi-exclamation-circle"
                    style={{
                      fontSize: "2.5rem",
                      color: "#6c757d",
                      marginBottom: "1rem",
                    }}
                  ></i>
                  <p style={{ fontSize: "1.1rem", color: "#666", margin: 0 }}>
                    No data available
                  </p>
                </div>
              ) : (
                filteredOrders?.map((v: any, index: number) => {
                  let info = "Empty";
                  if (v.detail.length) {
                    console.log(v);
                    info =
                      v.detail[0]?.name + " + " + v.detail.length + " more";
                  }
                  return (
                    <MTableItem key={v.tracking}>
                      <MTableChecker>
                        {/* <FormCheck
                          checked={checked[index]}
                          onChange={() => {
                            const newChecked = [...checked];
                            newChecked[index] = !newChecked[index];
                            setChecked(newChecked);
                          }}
                        /> */}
                        <div style={{ display: "flex" }}>
                          <Icon
                            icon={
                              itemOpen[index] ? "caret-up" : "caret-down-fill"
                            }
                            fontSize="1rem"
                            style={{ marginRight: "0.5rem" }}
                            onClick={() => toggleItemOpen(index)}
                          />
                          <div className="data" style={{ fontWeight: "bold" }}>
                            {v.tracking}
                          </div>
                        </div>
                      </MTableChecker>
                      {!itemOpen[index] && (
                        <div style={{ marginLeft: "1.5rem" }}>
                          <MRow>
                            <div className="label">Request Date</div>
                            <div className="data">
                              {FormatData(DATA_TYPE.DATE, v.requestAt)}
                            </div>
                          </MRow>
                          <MRow>
                            <div className="label">Check Email</div>
                            <div className="data">{v.checkEmail}</div>
                          </MRow>
                          {/* <MTableRow>
                            <div className="label">Extra Charge</div>
                            <div className="data">
                              ${convertToUSD(v.Extra)} / \{v.Extra}{" "}
                            </MTableData>
                          </MTableRow>
                          <MTableRow>
                            <div className="label">Product Info</div>
                            <div className="data">{info}</div>
                          </MTableRow> */}
                          <MRow>
                            <div className="label">Status</div>
                            <div className="data">
                              {v.isSubmitted ? "Completion" : "Processing"}
                            </div>
                          </MRow>
                        </div>
                      )}
                    </MTableItem>
                  );
                })
              )}
            </MTableSection>
          </MTableWrapper>
        </MWrapper>
      ) : (
        <div style={{ padding: "30px" }}>
          <CustomerPageTitle title="Check Contents" />

          <div className="d-flex justify-content-end">
            <InputGroup className="mb-3" style={{ width: "300px" }}>
              <FormControl
                className=""
                placeholder="Tracking Number"
                value={searchWord.toUpperCase()}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setSearchWord(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "enter") {
                    rf();
                  }
                }}
              />
              <Button
                variant="outline-success"
                style={{ borderColor: "var(--color-main-blue)" }}
                onClick={(e) => {
                  rf();
                }}
              >
                <Icon icon="search" color="var(--color-main-blue)" />
              </Button>
            </InputGroup>
          </div>
          <div
            style={{
              marginBottom: "20px",
            }}
          >
            {filteredOrders.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "400px",
                  textAlign: "center",
                }}
              >
                <i
                  className="bi bi-exclamation-circle"
                  style={{
                    fontSize: "3rem",
                    color: "#6c757d",
                    marginBottom: "1rem",
                  }}
                ></i>
                <p style={{ fontSize: "1.2rem", color: "#666", margin: 0 }}>
                  No data available
                </p>
              </div>
            ) : (
              <div className="table-responsive">
                <Table className="table-custom text-center">
                  <thead className="table-light">
                    <tr translate="no">
                      <th
                        className="font-large-page-title"
                        style={{ top: 0, position: "sticky" }}
                      >
                        Tracking No.
                      </th>
                      <th
                        className="font-large-page-title"
                        style={{ top: 0, position: "sticky" }}
                      >
                        Request Date
                      </th>
                      <th
                        className="font-large-page-title"
                        style={{ top: 0, position: "sticky" }}
                      >
                        Check Email
                      </th>
                      <th
                        className="font-large-page-title"
                        style={{ top: 0, position: "sticky" }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredOrders?.map((v: any, index: number) => {
                      let info = "Empty";
                      if (v.detail?.length) {
                        info =
                          v.detail[0]?.name + " + " + v.detail.length + " more";
                      }
                      return (
                        <>
                          <tr className="ontactweb-height">
                            <td className="font-large-page">{v.tracking}</td>
                            <td className="font-large-page">
                              {FormatData(DATA_TYPE.DATE, v.requestAt)}
                            </td>
                            <td className="font-large-page">{v.checkEmail}</td>
                            <td className="font-large-page">
                              {v.isSubmitted ? (
                                <>
                                  <svg
                                    width="8"
                                    height="8"
                                    viewBox="0 0 16 16"
                                    fill="green"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginRight: "5px" }}
                                  >
                                    <circle cx="8" cy="8" r="8" />
                                  </svg>
                                  Completion
                                </>
                              ) : (
                                <>
                                  <svg
                                    width="8"
                                    height="8"
                                    viewBox="0 0 16 16"
                                    fill="red"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginRight: "5px" }}
                                  >
                                    <circle cx="8" cy="8" r="8" />
                                  </svg>
                                  Processing
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

const MobileWrapper = styled.div`
  margin: 0;
  padding: 1rem;
`;

const MHeader = styled.div``;
const MMTitle = styled.div`
  margin: 1rem 0;
  color: var(--color-main-blue);
  border-radius: 30px;
  font-weight: bold;
  font-size: 24px;
`;

const MSearchBar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;

const MTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const MTableSection = styled.div`
  display: flex;
  flex-direction: column;
`;
const MTableChecker = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.4rem 0 0.8rem 0;
`;
const MTableItem = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-of-type) {
    border-bottom: 1px solid lightgray;
    margin-bottom: 1rem;
  }
`;
const MTableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const MTableLabel = styled.label``;
const MTableData = styled.p``;

const MSubmitButtonRow = styled.div`
  margin: 2rem 0 1rem 0;
  display: flex;
  justify-content: space-between;
  & > button {
    flex-grow: 1;
  }
`;
