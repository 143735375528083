import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import {
  CODE_COUNTRY_ITEMS,
  CODE_SHOPING_ORDER_STATUS,
  CODE_SHOPING_PRODUCT_DETAIL_STATUS,
  CODE_SHOPPING_ORDER_ITEMS,
} from "../../common/constants";
import { formatDate } from "../../common/format";
import { RootState } from "../../redux/store";
import { reqSendAlarm } from "../../requests/alarm";
import { reqShipFEE } from "../../requests/ship";
import {
  reqAShop,
  reqShopDetail,
  reqShopDetailUpdatePuts,
  reqShopUpdate,
} from "../../requests/shop";
import { reqShopEmail } from "../../requests/user";
import { reqGetMembership } from "../../requests/warehouse";

interface ShopOrderModalProps {
  show: boolean;
  onHide: () => void;
  orderId: string;
  onReopen: () => void;
  refreshData: () => void;
}
interface OrderDetail {
  order_id: string;
  productId: string;
  userId: string;
  status: number;
  category: number;
  name: string;
  option1: string;
  option2: string;
  quantity: number;
  price: number;
  ShippingFee: number;
  url: string;
  fee: number;
  paymentDeadline: string;
  cancellationReason: string;
  preorderStartAt: string;
  preorderEndAt: string;
  preorder: boolean;
  fancallName: string;
  fancallBirthday: string;
  fancallNumber: string;
  fancallEmail: string;
  fancallCountry: number;
  fancallKakao: string;
  fancallSnsLine: string;
  isCanceled: boolean;
  user: {
    name: string;
    membershipId: number;
    email: string;
  };
}

type OrderData = {
  orderId: string;
  userId: string;
  createdAt: string;
  PaymentMethod: number;
  preorder: boolean;
  fancall: boolean;
  status: number;
  name: string;
  failed: boolean;
  user: {
    name: string;
    membershipId: number;
  };
};
interface UpdatedOrderDetails {
  [key: string]: OrderDetail;
}

interface MembershipInfo {
  serviceFeeRed: string;
  serviceFeeGreen: string;
  serviceFeeBlue: string;
}

const FancallModal = ({
  show,
  onHide,
  orderId,
  refreshData,
  onReopen,
}: ShopOrderModalProps) => {
  const [orderDetails, setOrderDetails] = useState<OrderDetail[]>([]);
  const [serviceFee, setServiceFee] = useState("");
  const [cancellationReason, setCancellationReason] = useState("");
  const [customerMessage, setCustomerMessage] = useState("");
  const getDefaultPaymentDeadlineWithTime = () => {
    const result = new Date();
    result.setDate(result.getDate() + 1);
    return result.toISOString().slice(0, 16);
  };
  const [paymentDeadline, setPaymentDeadline] = useState(
    getDefaultPaymentDeadlineWithTime()
  );
  const [exchangeRate, setExchangeRate] = useState(0);
  const [UtotalUSD, setUtotalUSD] = useState(0);
  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);
  const [orders, setOrders] = useState<OrderData[]>([]);
  const [confirmCancelModalShow, setConfirmCancelModalShow] = useState(false);
  const [updatedOrderDetails, setUpdatedOrderDetails] =
    useState<UpdatedOrderDetails>({});
  const [selectedStatuses, setSelectedStatuses] = useState<{
    [key: string]: number;
  }>({});
  const [showEstimateConfirmationModal, setShowEstimateConfirmationModal] =
    useState(false);
  const [membershipInfo, setMembershipInfo] = useState<MembershipInfo | null>(
    null
  );
  const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});
  const excludeStatuses = [3, 4, 5, 10];
  const toggleItem = (index: number) => {
    setOpenItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const admin = useSelector((state: RootState) => state.admin);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResettingStatus, setIsResettingStatus] = useState(false);

  useEffect(() => {
    const fetchMembershipDetails = async () => {
      try {
        const response = await reqGetMembership();
        if (response && response.data && response.data.length > 0) {
          setMembershipInfo(response.data[0]);
        }
      } catch (error) {
        console.error("Error fetching membership details:", error);
      }
    };

    fetchMembershipDetails();
  }, []);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await reqAShop();
      const filteredOrders = response.data.filter(
        (order: OrderData) =>
          order.status === CODE_SHOPING_ORDER_STATUS.ORDER_CREATED
      );
      setOrders(filteredOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    if (orderDetails.length > 0 && membershipInfo) {
      const membershipId = orderDetails[0].user.membershipId;
      let fee;
      switch (membershipId) {
        case 1:
          fee = membershipInfo.serviceFeeRed;
          break;
        case 2:
          fee = membershipInfo.serviceFeeBlue;
          break;
        default:
          fee = membershipInfo.serviceFeeGreen;
      }
      setServiceFee(fee);
    }
  }, [orderDetails, membershipInfo]);

  const convertToUSD = (amount: number) => {
    return (amount / fixedExchangeRate).toFixed(2);
  };

  useEffect(() => {
    if (show) {
      setCustomerMessage("");
    }
  }, [show]);

  useEffect(() => {
    fetchFixedExchangeRate();
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]);

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (
        response &&
        response.data &&
        response.data[0]?.fixedExchangeRateShop
      ) {
        setFixedExchangeRate(
          parseFloat(response.data[0].fixedExchangeRateShop)
        );
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]);

  const handleStatusChange = (index: number, status: number) => {
    const detail = orderDetails[index];
    setSelectedStatuses((prev) => ({ ...prev, [detail.productId]: status }));
    setOrderDetails((prevDetails) =>
      prevDetails.map((item, idx) =>
        idx === index ? { ...item, status } : item
      )
    );
  };

  const fetchOrderDetails = async (order_id: string) => {
    try {
      const detailResponse = await reqShopDetail({ order_id });
      const detailsWithStatus: OrderDetail[] = detailResponse.data.map(
        (item: OrderDetail) => ({
          ...item,
          isCanceled: item.status === 10,
          ShippingFee: item.ShippingFee || 0,
        })
      );

      setOrderDetails(detailsWithStatus);

      console.log(
        "Fetched membershipId:",
        detailsWithStatus[0]?.user.membershipId
      );
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const calculateTotal = () => {
    if (orderDetails.length === 0) {
      return {
        productTotal: 0,
        domesticShippingFee: 0,
        serviceFeeValue: 0,
        paypalFee: 0,
        total: 0,
        Utotal: 0,
        UtotalUSD: 0,
      };
    }

    const statusesToCheck = [
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.EXPIRATION,
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.RESTRICTION,
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.SOLD_OUT,
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.CANCELED,
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.POINT,
    ];

    const allInSpecifiedStatuses = orderDetails.every((detail) =>
      statusesToCheck.includes(detail.status)
    );

    const productTotal = orderDetails.reduce((total, item) => {
      if (!excludeStatuses.includes(item.status)) {
        return total + item.quantity * item.price;
      }
      return total;
    }, 0);

    const domesticShippingFee = orderDetails.reduce((total, item) => {
      if (!excludeStatuses.includes(item.status)) {
        return total + item.ShippingFee;
      }
      return total;
    }, 0);

    let serviceFeeValue = 0;
    if (!allInSpecifiedStatuses) {
      const parsedServiceFee = parseFloat(serviceFee);
      serviceFeeValue = Math.round(
        productTotal * (isNaN(parsedServiceFee) ? 0 : parsedServiceFee / 100)
      );
      serviceFeeValue = Math.max(serviceFeeValue, 5000);
    }

    const paypalFee =
      (productTotal + serviceFeeValue + domesticShippingFee) * 0.05;
    const UtotalUSDValue = convertToUSD(
      productTotal + domesticShippingFee + serviceFeeValue + paypalFee
    );

    return {
      productTotal,
      domesticShippingFee,
      serviceFeeValue,
      paypalFee,
      total: productTotal + domesticShippingFee + serviceFeeValue,
      Utotal: productTotal + domesticShippingFee + serviceFeeValue + paypalFee,
      UtotalUSD: parseFloat(UtotalUSDValue),
    };
  };

  const {
    productTotal,
    domesticShippingFee,
    serviceFeeValue,
    paypalFee,
    total,
    Utotal,
  } = calculateTotal();

  const updateServiceFee = async () => {
    try {
      const updateData = {
        order_id: orderId,
        fee: parseInt(serviceFee),
      };

      const response = await reqShopUpdate(updateData);
      console.log("Service fee updated:", response);
      alert("서비스 비용이 업데이트되었습니다.");
    } catch (error) {
      console.error("Error updating service fee:", error);
      alert("서비스 비용 업데이트에 실패했습니다.");
    }
  };

  const handleOrderDetailChange = (
    index: any,
    field: keyof OrderDetail,
    value: any
  ) => {
    setOrderDetails((prevDetails) =>
      prevDetails.map((detail, idx) =>
        idx === index ? { ...detail, [field]: value } : detail
      )
    );

    setUpdatedOrderDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails };
      updatedDetails[index] = {
        ...(updatedDetails[index] || orderDetails[index]),
        [field]: value,
      };
      return updatedDetails;
    });
  };

  const handleSendEstimate = async () => {
    Swal.fire({
      title: "견적서를 전송하시겠습니까?",
      text: "사용자에게 알림을 전송합니다.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // 전송 진행 중 로딩 팝업 표시
        const loadingSwal = Swal.fire({
          title: "견적서 전송 중",
          html: `
            <div class="progress mb-3" style="height: 20px;">
              <div id="progress-bar" class="progress-bar progress-bar-striped progress-bar-animated" 
                style="width: 0%"></div>
            </div>
            <div id="progress-status">주문 상세 정보를 준비하는 중...</div>
          `,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        setIsSubmitting(true);

        try {
          // 진행 상태 업데이트 함수
          const updateProgress = (percent: number, statusText: string) => {
            const progressBar = document.getElementById("progress-bar");
            const statusElement = document.getElementById("progress-status");
            if (progressBar) progressBar.style.width = `${percent}%`;
            if (statusElement) statusElement.innerHTML = statusText;
          };

          // handleConfirmSendEstimate 함수에 진행 상태 업데이트 함수 전달
          await handleConfirmSendEstimate(updateProgress);

          // 성공 메시지
          Swal.fire({
            title: "견적서가 전송되었습니다.",
            icon: "success",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          }).then(() => {
            onHide();
            refreshData();
          });
        } catch (error) {
          console.error("견적서 전송 중 오류 발생:", error);
          Swal.fire({
            title: "견적서 전송 실패",
            text: "견적서 전송 중 오류가 발생했습니다.",
            icon: "error",
            confirmButtonText: "확인",
          });
        } finally {
          setIsSubmitting(false);
        }
      }
    });
  };

  const handleConfirmSendEstimate = async (
    updateProgress?: (percent: number, statusText: string) => void
  ) => {
    const originalOrderDetails = [...orderDetails];
    const originalStatuses = { ...selectedStatuses };
    const successfulRequests: Array<{
      type: string;
      productId: string;
      orderId: string;
      originalData: any;
    }> = [];

    try {
      console.log("Updating order details", orderDetails);

      // 주문 상세 업데이트 시작
      updateProgress && updateProgress(10, "주문 상세 정보를 업데이트 중...");

      const totalDetails = orderDetails.filter(
        (detail) => selectedStatuses[detail.productId]
      ).length;
      let completedDetails = 0;

      for (const detail of orderDetails) {
        if (selectedStatuses[detail.productId]) {
          const payload = {
            ...detail,
            status: selectedStatuses[detail.productId],
            ShippingFee: detail.ShippingFee,
            paymentDeadline: paymentDeadline,
            price: detail.price,
            quantity: detail.quantity,
          };

          try {
            const response = await reqShopDetailUpdatePuts(
              detail.productId,
              payload
            );
            successfulRequests.push({
              type: "detail",
              productId: detail.productId,
              orderId: detail.order_id,
              originalData: {
                status: detail.status,
                ShippingFee: detail.ShippingFee,
                paymentDeadline: detail.paymentDeadline,
                price: detail.price,
                quantity: detail.quantity,
              },
            });

            // 진행 상황 업데이트
            completedDetails++;
            const detailProgress = Math.floor(
              10 + (completedDetails / totalDetails) * 30
            );
            updateProgress &&
              updateProgress(
                detailProgress,
                `주문 상세 정보 업데이트 중... (${completedDetails}/${totalDetails})`
              );

            console.log(`Updated order detail ${detail.productId}`, response);
          } catch (error: any) {
            console.error(
              `Error updating order detail ${detail.productId}:`,
              error
            );
            // 에러 발생 시 롤백 실행
            updateProgress &&
              updateProgress(40, "오류 발생! 변경 사항을 되돌리는 중...");
            await rollbackChanges(successfulRequests);
            throw new Error(
              `주문 상세 항목 업데이트 중 오류가 발생했습니다: ${error.message}`
            );
          }
        }
      }

      // 주문 전체 상태 업데이트
      updateProgress && updateProgress(50, "주문 상태를 업데이트 중...");
      console.log("Updating main order data");
      const totals = calculateTotal();
      setUtotalUSD(totals.UtotalUSD);

      const updateData = {
        order_id: orderId,
        status: CODE_SHOPING_ORDER_STATUS.WAIT_PAY,
        cancellationReason: cancellationReason,
        paymentDeadline: paymentDeadline,
        price: serviceFeeValue,
        totalPriceKRW: totals.total,
        totalPriceUSD: totals.UtotalUSD,
        fancallNumber: customerMessage,
        productId: admin.name,
      };

      try {
        const response = await reqShopUpdate(updateData);
        successfulRequests.push({
          type: "order",
          productId: "",
          orderId: orderId,
          originalData: {
            status: CODE_SHOPING_ORDER_STATUS.ORDER_CREATED,
            cancellationReason,
            paymentDeadline,
            totalPriceKRW: 0,
            totalPriceUSD: 0,
            fancallNumber: "",
          },
        });
        console.log("Main order data updated", response);
        updateProgress &&
          updateProgress(70, "주문 상태 업데이트 완료, 이메일 전송 중...");

        // 3. 이메일 전송 API 직접 호출
        try {
          // 사용자 이메일 정보를 API로 전송
          await reqShopEmail({
            to: orderDetails[0]?.user.email,
            orderId: orderId,
            statusMessages: Object.entries(selectedStatuses).map(
              ([productId, status]) => ({
                productId,
                status,
              })
            ),
          });
          console.log("이메일 전송 요청 완료");
          updateProgress &&
            updateProgress(85, "이메일 전송 완료, 알림 전송 중...");

          // 알림 전송
          let completedNotifications = 0;
          const totalNotifications = Object.keys(selectedStatuses).length;

          for (const detail of orderDetails) {
            if (!selectedStatuses[detail.productId]) continue;

            const status = selectedStatuses[detail.productId];
            let contentMessage = "";

            switch (status) {
              case CODE_SHOPING_PRODUCT_DETAIL_STATUS.SOLD_OUT:
                contentMessage =
                  "The item is out of stock. Please check the item that is out of stock.";
                break;
              case CODE_SHOPING_PRODUCT_DETAIL_STATUS.RESTRICTION:
                contentMessage =
                  "Purchase limit has been reached for this item, please check it.";
                break;
              case CODE_SHOPING_PRODUCT_DETAIL_STATUS.EXPIRATION:
                contentMessage =
                  "The purchase deadline for this item has expired, please check it.";
                break;
              default:
                contentMessage =
                  "Your custom order invoice has been issued. Please proceed with payment so we can secure the items.";
                break;
            }

            const alarmPayload = {
              userId: orderDetails[0]?.userId,
              read: 0,
              content: contentMessage,
              sender: admin.name,
            };
            await reqSendAlarm(alarmPayload);

            // 진행 상황 업데이트
            completedNotifications++;
            const notificationProgress = Math.floor(
              85 + (completedNotifications / totalNotifications) * 15
            );
            updateProgress &&
              updateProgress(
                notificationProgress,
                `알림 전송 중... (${completedNotifications}/${totalNotifications})`
              );
          }

          // 최종 완료
          updateProgress && updateProgress(100, "모든 처리가 완료되었습니다!");
        } catch (emailError: any) {
          console.error("이메일 전송 중 오류 발생:", emailError);
          // 이메일 전송 실패는 주문 업데이트 롤백을 트리거하지 않고 경고만 표시
          Swal.fire({
            title: "이메일 전송 실패",
            text: "견적서가 저장되었지만 이메일 전송에 실패했습니다.",
            icon: "warning",
            confirmButtonText: "확인",
          });
        }
      } catch (error: any) {
        console.error("Error updating main order:", error);
        updateProgress &&
          updateProgress(40, "오류 발생! 변경 사항을 되돌리는 중...");
        await rollbackChanges(successfulRequests);
        throw new Error(
          `주문 상태 업데이트 중 오류가 발생했습니다: ${error.message}`
        );
      }

      console.log("Order update process completed successfully");
      onHide();
      setShowEstimateConfirmationModal(false);
      refreshData();
    } catch (error) {
      console.error("Error during sending estimate:", error);
      await rollbackChanges(successfulRequests);
      throw error;
    }
  };

  const rollbackChanges = async (
    successfulRequests: Array<{
      type: string;
      productId: string;
      orderId: string;
      originalData: any;
    }>
  ) => {
    console.log("Rolling back changes...", successfulRequests);

    for (let i = successfulRequests.length - 1; i >= 0; i--) {
      const request = successfulRequests[i];
      try {
        if (request.type === "detail") {
          await reqShopDetailUpdatePuts(request.productId, {
            ...request.originalData,
            productId: request.productId,
          });
        } else if (request.type === "order") {
          await reqShopUpdate({
            ...request.originalData,
            order_id: request.orderId,
          });
        }
      } catch (rollbackError) {
        console.error(
          `Error during rollback of ${request.type}:`,
          rollbackError
        );
      }
    }

    console.log("Rollback completed");
  };

  const handleCancelOrderClick = () => {
    setConfirmCancelModalShow(true);
  };

  const handleConfirmCancelOrder = async () => {
    try {
      setConfirmCancelModalShow(false);
      await handleCancelOrder();

      const alarmPayload = {
        userId: orderDetails[0]?.userId,
        read: 0,
        content: `Your custom order request has been cancelled.`,
        sender: admin.name,
      };
      await reqSendAlarm(alarmPayload);
    } catch (error) {
      console.error("Cancellation failed:", error);
    }
  };

  const handleCancelOrder = async () => {
    try {
      const updateData = {
        order_id: orderId,
        fancallNumber: customerMessage,
        status: CODE_SHOPING_ORDER_STATUS.CANCELED,
        productId: admin.name,
      };

      await reqShopUpdate(updateData);
      alert("주문이 취소되었습니다.");
      onHide();
      refreshData();
    } catch (error) {
      console.error("주문 취소 실패:", error);
      alert("주문 취소에 실패했습니다.");
      handleSafeHide();
    }
  };

  const handleCancelEstimate = () => {
    setShowEstimateConfirmationModal(false);
    setIsResettingStatus(true);

    reqShopUpdate({
      order_id: orderId,
      status: CODE_SHOPING_ORDER_STATUS.ORDER_CREATED,
    })
      .then(() => {
        console.log(
          `주문 ${orderId}의 상태가 1(ORDER_CREATED)로 변경되었습니다.`
        );
        setIsResettingStatus(false);
        onReopen();
      })
      .catch((error) => {
        console.error("Error resetting order status:", error);
        Swal.fire({
          title: "상태 초기화 오류",
          text: "주문 상태를 초기화하는 중 오류가 발생했습니다.",
          icon: "error",
          confirmButtonText: "확인",
        });
        setIsResettingStatus(false);
        onReopen();
      });
  };

  const handlePriceChange = (index: number, newPrice: any) => {
    const price = parseFloat(newPrice);
    if (!isNaN(price)) {
      setOrderDetails((prevDetails) =>
        prevDetails.map((detail, idx) =>
          idx === index ? { ...detail, price: price } : detail
        )
      );
    } else {
      console.error("Invalid price input:", newPrice);
    }
  };

  const handlequantityChange = (index: number, newquantity: any) => {
    const quantity = parseFloat(newquantity);
    if (!isNaN(quantity)) {
      setOrderDetails((prevDetails) =>
        prevDetails.map((detail, idx) =>
          idx === index ? { ...detail, quantity: quantity } : detail
        )
      );
    } else {
      console.error("Invalid price input:", newquantity);
    }
  };

  const copyToClipboardAndNavigate = (url: any) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Copied!",
          text: "URL has been copied to your clipboard and opening in a new tab.",
          timer: 1500,
        });
        window.open(url, "_blank");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "Failed to copy URL.",
        });
      });
  };

  const downloadExcel = () => {
    const data = orderDetails.map((detail, index) => ({
      NAME: orderDetails[0]?.user.name,
      "SPACE CODE": orderDetails[0]?.userId,
      "PRODUCT NAME": detail.name,
      "OPTION 1": detail.option1,
      "OPTION 2": detail.option2,
      LINK: detail.url,
      "KRW TOTAL": total,
      "USD TOTAL": Utotal,
      "ORDER NUMBER": orderId,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${orderId}_OrderDetails.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const hasPurchasableItems = () => {
    return orderDetails.some(
      (detail) =>
        detail.status === CODE_SHOPING_PRODUCT_DETAIL_STATUS.POSSIBILITY
    );
  };

  const handleSafeHide = async () => {
    try {
      setIsResettingStatus(true);

      // 먼저 현재 주문 상태를 확인
      const response = await reqShopDetail({ order_id: orderId });
      const currentOrderStatus = response.data[0]?.status;

      // 이미 견적서가 전송되어 WAIT_PAY 상태이거나 취소된 경우는 상태를 변경하지 않음
      if (
        currentOrderStatus === CODE_SHOPING_ORDER_STATUS.WAIT_PAY ||
        currentOrderStatus === CODE_SHOPING_ORDER_STATUS.CANCELED
      ) {
        console.log(
          `주문이 이미 견적서 전송(${currentOrderStatus}) 상태이므로 상태를 변경하지 않습니다.`
        );
      }
      // TRANSACTION_OF_1_BETWEEN_2 상태이거나 다른 상태인 경우만 ORDER_CREATED로 변경
      else if (
        currentOrderStatus ===
          CODE_SHOPING_ORDER_STATUS.TRANSACTION_OF_1_BETWEEN_2 ||
        currentOrderStatus === CODE_SHOPING_ORDER_STATUS.ORDER_CREATED
      ) {
        await reqShopUpdate({
          order_id: orderId,
          status: CODE_SHOPING_ORDER_STATUS.ORDER_CREATED,
        });
        console.log(
          `주문 ${orderId}의 상태가 1(ORDER_CREATED)로 변경되었습니다.`
        );
      }
    } catch (error) {
      console.error("Error resetting order status:", error);
      Swal.fire({
        title: "상태 초기화 오류",
        text: "주문 상태를 초기화하는 중 오류가 발생했습니다.",
        icon: "error",
        confirmButtonText: "확인",
      });
    } finally {
      setIsResettingStatus(false);
      onHide();
    }
  };

  const handleSendEstimateClick = async () => {
    const everyProductHasStatus = orderDetails.every((detail) =>
      selectedStatuses.hasOwnProperty(detail.productId)
    );
    if (!everyProductHasStatus) {
      alert("제품의 상태를 선택해야 합니다.");
      return;
    }

    const everyProductHasValidShippingFee = orderDetails.every(
      (detail) => !isNaN(detail.ShippingFee) && detail.ShippingFee >= 0
    );
    if (!everyProductHasValidShippingFee) {
      alert("모든 제품에 대한 배송비를 숫자로 입력해야 합니다.");
      return;
    }

    if (!paymentDeadline) {
      alert("결제 마감일을 설정해야 합니다.");
      return;
    }

    if (!hasPurchasableItems()) {
      alert("구매 가능한 상품이 없습니다.");
      return;
    }

    try {
      const updateData = {
        order_id: orderId,
        fee: parseInt(serviceFee),
      };

      await reqShopUpdate(updateData);
    } catch (error) {
      alert("서비스 비용 업데이트에 실패했습니다. 다시 시도해주세요.");
      return;
    }

    setShowEstimateConfirmationModal(true);
  };

  return (
    <Fragment>
      <GlobalStyle />
      <StyledModal show={show} onHide={handleSafeHide} fullscreen centered>
        {isSubmitting && (
          <LoadingSection>
            <div
              className="spinner-border text-primary"
              role="status"
              style={{ width: "3rem", height: "3rem" }}
            >
              <span className="visually-hidden">로딩 중...</span>
            </div>
            <p className="mt-2">
              견적서를 전송 중입니다. 잠시만 기다려주세요...
            </p>
          </LoadingSection>
        )}
        {isResettingStatus && (
          <LoadingSection>
            <div
              className="spinner-border text-warning"
              role="status"
              style={{ width: "3rem", height: "3rem" }}
            >
              <span className="visually-hidden">상태 초기화 중...</span>
            </div>
            <p className="mt-2">
              주문 상태를 초기화하는 중입니다. 잠시만 기다려주세요...
            </p>
          </LoadingSection>
        )}
        <Modal.Header className="bg-dark text-white">
          <Modal.Title>{orderId}</Modal.Title>
          <CloseButton onClick={handleSafeHide}>&#10006;</CloseButton>
        </Modal.Header>
        <StyledModalBody>
          <ResponsiveRow>
            <ResponsiveCol md={6}>
              <ScrollContainer className="container-fluid mb-4">
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <tbody>
                      {orderDetails.map((detail, index) => (
                        <React.Fragment key={index}>
                          <tr
                            className={
                              detail.isCanceled
                                ? "table-secondary"
                                : "table-primary"
                            }
                          >
                            <th>NO</th>
                            <td>{index + 1}</td>
                            <th>품목</th>
                            <td>
                              {CODE_SHOPPING_ORDER_ITEMS[detail.category] ||
                                "Unknown Category"}
                            </td>
                          </tr>
                          <tr>
                            <th>상품명</th>
                            <td colSpan={3} className="text-center">
                              <input
                                type="text"
                                className="form-control"
                                readOnly
                                placeholder={detail.name}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>옵션1</th>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                readOnly
                                value={detail.option1}
                                onClick={() => {
                                  navigator.clipboard.writeText(detail.option1);
                                  alert("옵션1이 복사되었습니다.");
                                }}
                              />
                            </td>
                            <th>옵션2</th>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                readOnly
                                value={detail.option2}
                                onClick={() => {
                                  navigator.clipboard.writeText(detail.option2);
                                  alert("옵션2가 복사되었습니다.");
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>수량</th>
                            <td>
                              <input
                                className="form-control"
                                type="number"
                                placeholder={detail.quantity.toString()}
                                onChange={(e) =>
                                  handlequantityChange(index, e.target.value)
                                }
                              />
                            </td>
                            <th>단가</th>
                            <td>
                              <input
                                className="form-control"
                                type="number"
                                placeholder={detail.price.toString()}
                                onChange={(e) =>
                                  handlePriceChange(index, e.target.value)
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>소계</th>
                            <td>
                              <input
                                className="form-control"
                                type="number"
                                readOnly
                                value={detail.quantity * detail.price}
                              />
                            </td>
                            <th>국내 배송비</th>
                            <td
                              style={
                                detail.isCanceled
                                  ? { textDecoration: "line-through" }
                                  : undefined
                              }
                            >
                              <Form.Control
                                type="number"
                                value={detail.ShippingFee}
                                onChange={(e) =>
                                  handleOrderDetailChange(
                                    index,
                                    "ShippingFee",
                                    parseFloat(e.target.value)
                                  )
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>링크</th>
                            <td colSpan={3} className="text-center">
                              <a
                                href={
                                  detail.url.includes("://")
                                    ? detail.url
                                    : `https://${detail.url}`
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  const fullUrl = detail.url.includes("://")
                                    ? detail.url
                                    : `https://${detail.url}`;
                                  copyToClipboardAndNavigate(fullUrl);
                                }}
                                target="_blank"
                                className="d-inline-block text-truncate"
                                style={{ maxWidth: "100%" }}
                              >
                                {detail.url}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th>팬콜</th>
                            <td colSpan={3}>
                              <Fragment key={index}>
                                <Button
                                  variant={
                                    detail.fancallName ||
                                    detail.fancallNumber ||
                                    detail.fancallEmail ||
                                    detail.fancallKakao ||
                                    detail.fancallSnsLine
                                      ? "primary"
                                      : "secondary"
                                  }
                                  onClick={() => toggleItem(index)}
                                  aria-expanded={openItems[index]}
                                >
                                  인적사항
                                </Button>
                                {openItems[index] && (
                                  <div className="mt-3">
                                    <p>
                                      <strong>이름:</strong>{" "}
                                      {detail.fancallName}
                                    </p>
                                    <p>
                                      <strong>생년월일:</strong>{" "}
                                      {detail.fancallBirthday
                                        ? formatDate(detail.fancallBirthday)
                                        : "-"}
                                    </p>
                                    <p>
                                      <strong>연락처:</strong>{" "}
                                      {detail.fancallNumber}
                                    </p>
                                    <p>
                                      <strong>이메일:</strong>{" "}
                                      {detail.fancallEmail}
                                    </p>
                                    <p>
                                      <strong>국가:</strong>{" "}
                                      {
                                        CODE_COUNTRY_ITEMS[
                                          detail.fancallCountry
                                        ]
                                      }
                                    </p>
                                    <p>
                                      <strong>카카오톡:</strong>{" "}
                                      {detail.fancallKakao}
                                    </p>
                                    <p>
                                      <strong>라인:</strong>{" "}
                                      {detail.fancallSnsLine}
                                    </p>
                                  </div>
                                )}
                              </Fragment>
                            </td>
                          </tr>
                          <tr>
                            <th>프리오더</th>
                            <td colSpan={3} className="text-center">
                              {detail.preorder ? "프리오더" : "일반"}
                            </td>
                          </tr>
                          <tr>
                            <th>상태</th>
                            <td colSpan={3}>
                              <div className="d-flex flex-wrap">
                                <div className="form-check form-check-inline mb-2">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`statusOptions${index}`}
                                    id={`inlineRadioPossibility-${index}`}
                                    value={
                                      CODE_SHOPING_PRODUCT_DETAIL_STATUS.POSSIBILITY
                                    }
                                    checked={
                                      selectedStatuses[detail.productId] ===
                                      CODE_SHOPING_PRODUCT_DETAIL_STATUS.POSSIBILITY
                                    }
                                    onChange={() =>
                                      handleStatusChange(
                                        index,
                                        CODE_SHOPING_PRODUCT_DETAIL_STATUS.POSSIBILITY
                                      )
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`inlineRadioPossibility-${index}`}
                                  >
                                    구매가능
                                  </label>
                                </div>
                                <div className="form-check form-check-inline mb-2">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`statusOptions${index}`}
                                    id={`inlineRadioExpiration-${index}`}
                                    value={
                                      CODE_SHOPING_PRODUCT_DETAIL_STATUS.EXPIRATION
                                    }
                                    checked={
                                      selectedStatuses[detail.productId] ===
                                      CODE_SHOPING_PRODUCT_DETAIL_STATUS.EXPIRATION
                                    }
                                    onChange={() =>
                                      handleStatusChange(
                                        index,
                                        CODE_SHOPING_PRODUCT_DETAIL_STATUS.EXPIRATION
                                      )
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`inlineRadioExpiration-${index}`}
                                  >
                                    구매기한만료
                                  </label>
                                </div>
                                <div className="form-check form-check-inline mb-2">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`statusOptions${index}`}
                                    id={`inlineRadioRestriction-${index}`}
                                    value={
                                      CODE_SHOPING_PRODUCT_DETAIL_STATUS.RESTRICTION
                                    }
                                    checked={
                                      selectedStatuses[detail.productId] ===
                                      CODE_SHOPING_PRODUCT_DETAIL_STATUS.RESTRICTION
                                    }
                                    onChange={() =>
                                      handleStatusChange(
                                        index,
                                        CODE_SHOPING_PRODUCT_DETAIL_STATUS.RESTRICTION
                                      )
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`inlineRadioRestriction-${index}`}
                                  >
                                    구매수량제한
                                  </label>
                                </div>
                                <div className="form-check form-check-inline mb-2">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`statusOptions${index}`}
                                    id={`inlineRadioSoldOut-${index}`}
                                    value={
                                      CODE_SHOPING_PRODUCT_DETAIL_STATUS.SOLD_OUT
                                    }
                                    checked={
                                      selectedStatuses[detail.productId] ===
                                      CODE_SHOPING_PRODUCT_DETAIL_STATUS.SOLD_OUT
                                    }
                                    onChange={() =>
                                      handleStatusChange(
                                        index,
                                        CODE_SHOPING_PRODUCT_DETAIL_STATUS.SOLD_OUT
                                      )
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`inlineRadioSoldOut-${index}`}
                                  >
                                    품절
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          {index < orderDetails.length - 1 && (
                            <tr className="separator-row">
                              <td
                                colSpan={4}
                                style={{ padding: "0.5rem" }}
                              ></td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </ScrollContainer>
            </ResponsiveCol>
            <ResponsiveCol md={6}>
              <div className="container-fluid mb-3">
                <div className="table-responsive">
                  <ResponsiveTable className="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th scope="col">NO</th>
                        <th scope="col">품목</th>
                        <th scope="col">상품명</th>
                        <th scope="col">수량</th>
                        <th scope="col">단가</th>
                        <th scope="col">국내 배송비</th>
                        <th scope="col">소계</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails.map((detail, index) => (
                        <tr
                          key={index}
                          style={
                            excludeStatuses.includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          <td>{index + 1}</td>
                          <td>
                            {CODE_SHOPPING_ORDER_ITEMS[detail.category] ||
                              "Unknown Category"}
                          </td>
                          <td>{detail.name}</td>
                          <td>{detail.quantity}</td>
                          <td>{detail.price}</td>
                          <td>{detail.ShippingFee}</td>
                          <td>
                            {detail.quantity * detail.price +
                              detail.ShippingFee}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </ResponsiveTable>
                </div>
                <Table bordered className="text-center table-dark table-hover">
                  <thead>
                    <tr>
                      <th></th>
                      <th>KRW</th>
                      <th>USD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>상품 합계</th>
                      <td>{productTotal}</td>
                      <td>{convertToUSD(productTotal)}</td>
                    </tr>
                    <tr>
                      <th>배송 비용</th>
                      <td>{domesticShippingFee}</td>
                      <td>{convertToUSD(domesticShippingFee)}</td>
                    </tr>
                    <tr>
                      <th>
                        <StyledInputGroup>
                          <InputGroup.Text className="bg-dark text-white fw-bold">
                            서비스 비용
                          </InputGroup.Text>
                          <Form.Control
                            type="number"
                            placeholder="10"
                            value={serviceFee}
                            onChange={(e) => setServiceFee(e.target.value)}
                          />
                          <InputGroup.Text>%</InputGroup.Text>
                          <Button variant="primary" onClick={updateServiceFee}>
                            Update
                          </Button>
                        </StyledInputGroup>
                      </th>
                      <td>{serviceFeeValue}</td>
                      <td>{convertToUSD(serviceFeeValue)}</td>
                    </tr>
                    <tr>
                      <th>환전 수수료(5%)</th>
                      <th>-</th>
                      <td>{convertToUSD(paypalFee)}</td>
                    </tr>
                    <tr>
                      <th>합계</th>
                      <td>{total}</td>
                      <td>{convertToUSD(Utotal)}</td>
                    </tr>
                    <tr>
                      <th colSpan={3}>
                        <InputGroup>
                          <InputGroup.Text>결제마감일</InputGroup.Text>
                          <Form.Control
                            type="datetime-local"
                            className="form-control"
                            value={paymentDeadline}
                            onChange={(e) => setPaymentDeadline(e.target.value)}
                          />
                        </InputGroup>
                      </th>
                    </tr>
                  </tbody>
                </Table>
                <Col md={12}>
                  <Form.Label style={{ fontSize: "larger", color: "red" }}>
                    고객이 선택한 취소 옵션(전체 취소의 경우 하나라도 구매가
                    불가능하면 전체취소)
                  </Form.Label>

                  {orders.find((order) => order.orderId === orderId)?.failed ? (
                    <div className="alert alert-danger" role="alert">
                      전체취소
                    </div>
                  ) : (
                    <div className="alert alert-warning" role="alert">
                      부분취소
                    </div>
                  )}
                </Col>
                <Form.Group className="mb-3" controlId="formCustomerMessage">
                  <Form.Label>고객 메세지</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="고객에세 보내는 메세지"
                    value={customerMessage}
                    onChange={(e) => setCustomerMessage(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCustomerMessage">
                  <Form.Label className="label-shop-user">고객 정보</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    readOnly
                    style={{ fontWeight: "bold", fontSize: "1.2em" }}
                    value={`고객 이름: ${orderDetails[0]?.user.name}\n스페이스코드: ${orderDetails[0]?.userId}`}
                  />
                </Form.Group>
              </div>
            </ResponsiveCol>
          </ResponsiveRow>
        </StyledModalBody>
        <StyledModalFooter>
          <Button
            variant="primary"
            onClick={downloadExcel}
            className="mobile-action-btn"
          >
            엑셀 다운로드
          </Button>
          <Button
            variant="secondary"
            onClick={handleCancelOrderClick}
            className="mobile-action-btn"
          >
            전체취소
          </Button>
          <Button
            variant="success"
            onClick={handleSendEstimateClick}
            disabled={!hasPurchasableItems()}
            className="mobile-action-btn"
          >
            견적서 전송
          </Button>
        </StyledModalFooter>
      </StyledModal>
      <StyledModal
        show={showEstimateConfirmationModal}
        onHide={() => setShowEstimateConfirmationModal(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>견적서 확인</Modal.Title>
        </Modal.Header>
        <StyledModalBody>
          <div className="container-fluid mb-3">
            <div className="table-responsive">
              <table className="table table-bordered text-center">
                <thead>
                  <tr>
                    <th scope="col">NO</th>
                    <th scope="col">품목</th>
                    <th scope="col">상품명</th>
                    <th scope="col">수량</th>
                    <th scope="col">단가</th>
                    <th scope="col">국내 배송비</th>
                    <th scope="col">소계</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails.map((detail, index) => (
                    <tr
                      key={index}
                      style={
                        excludeStatuses.includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      <td>{detail.productId}</td>
                      <td>
                        {CODE_SHOPPING_ORDER_ITEMS[detail.category] ||
                          "Unknown Category"}
                      </td>
                      <td>{detail.name}</td>
                      <td>{detail.quantity}</td>
                      <td>{detail.price}</td>
                      <td>{detail.ShippingFee}</td>
                      <td>
                        {detail.quantity * detail.price + detail.ShippingFee}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Table bordered className="text-center table-dark table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>KRW</th>
                  <th>USD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>상품 합계</th>
                  <td>{productTotal}</td>
                  <td>{convertToUSD(productTotal)}</td>
                </tr>
                <tr>
                  <th>배송 비용</th>
                  <td>{domesticShippingFee}</td>
                  <td>{convertToUSD(domesticShippingFee)}</td>
                </tr>
                <tr>
                  <th>서비스 비용</th>
                  <td>{serviceFeeValue}</td>
                  <td>{convertToUSD(serviceFeeValue)}</td>
                </tr>
                <tr>
                  <th>환전 수수료(5%)</th>
                  <th>-</th>
                  <td>{convertToUSD(paypalFee)}</td>
                </tr>
                <tr>
                  <th>합계</th>
                  <td>{total}</td>
                  <td>{convertToUSD(Utotal)}</td>
                </tr>
                <tr>
                  <th colSpan={3}>
                    <InputGroup>
                      <InputGroup.Text>결제마감일</InputGroup.Text>
                      <Form.Control
                        type="datetime-local"
                        className="form-control"
                        value={paymentDeadline}
                        onChange={(e) => setPaymentDeadline(e.target.value)}
                      />
                    </InputGroup>
                  </th>
                </tr>
              </tbody>
            </Table>
          </div>
        </StyledModalBody>
        <StyledModalFooter>
          <Button
            variant="secondary"
            onClick={handleCancelEstimate}
            className="mobile-action-btn"
          >
            뒤로
          </Button>
          <Button
            variant="primary"
            onClick={handleSendEstimate}
            className="mobile-action-btn"
          >
            전송
          </Button>
        </StyledModalFooter>
      </StyledModal>
      <StyledModal
        show={confirmCancelModalShow}
        onHide={() => setConfirmCancelModalShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>주문 취소 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>정말로 주문을 취소하시겠습니까?</Modal.Body>
        <Modal.Body>사용자에게 알림을 전송합니다.</Modal.Body>
        <StyledModalFooter>
          <Button
            variant="secondary"
            onClick={() => setConfirmCancelModalShow(false)}
            className="mobile-action-btn"
          >
            아니요
          </Button>
          <Button
            variant="danger"
            onClick={handleConfirmCancelOrder}
            className="mobile-action-btn"
          >
            네, 취소할게요
          </Button>
        </StyledModalFooter>
      </StyledModal>
    </Fragment>
  );
};
export default FancallModal;

const Title = styled.h3`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  margin-top: 1rem;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0.6rem 0;

  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    & > div {
      flex: none;
      width: 100%;
      margin-top: 0.5rem;
    }
  }
`;

const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;

  & > div {
    border-color: #474747;
  }
`;

const MobileWrapper = styled.div`
  margin: 0;
  padding: 1rem;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;

  th,
  td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
    th,
    td {
      padding: 6px;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.7em;
    th,
    td {
      padding: 4px;
    }
  }
`;

const LoadingSection = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > img {
    width: 100%;
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    max-height: 100vh;
  }

  @media (max-width: 768px) {
    .modal-dialog {
      margin: 0.5rem;
    }
  }
`;

const StyledModalBody = styled(Modal.Body)`
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const StyledModalFooter = styled(Modal.Footer)`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const ResponsiveRow = styled(Row)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ResponsiveCol = styled(Col)`
  @media (max-width: 768px) {
    width: 100%;
    padding: 0.5rem;
  }
`;

const ScrollContainer = styled.div`
  overflow-y: scroll;
  max-height: 1000px;

  @media (max-width: 768px) {
    max-height: 60vh;
  }
`;

const StyledInputGroup = styled(InputGroup)`
  @media (max-width: 768px) {
    flex-wrap: wrap;

    .input-group-text,
    .form-control,
    .btn {
      width: 100%;
      margin-top: 0.25rem;
      border-radius: 0.25rem !important;
    }
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  padding: 0;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const GlobalStyle = createGlobalStyle`
  .mobile-action-btn {
    @media (max-width: 768px) {
      flex: 1 0 auto;
      min-width: 120px;
      margin: 0.25rem;
      font-size: 0.9rem;
      padding: 0.5rem 0.75rem;
    }
  }
`;
