import { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import { PAYMENT_METHOD } from "../../common/constants";
import { formatCustomDate } from "../../common/format";
import {
  MBody,
  MButton,
  MFooter,
  MPageTitle,
  MRow,
  MTwoButtonRow,
} from "../../common/mobile-styled";
import { MembershipInfo } from "../../interface/MembershipInfo";
import { ShipProduct } from "../../interface/ShipProduct";
import { User } from "../../interface/User";
import { RootUserState } from "../../redux/store";
import { reqPaypalPayment } from "../../requests/paypal";
import { reqProductOfUser } from "../../requests/ship";
import Icon from "../Icon";

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

interface MembershipProps {
  userData: User;
  selectedMembership: MembershipInfo;
  setPayModal: any;
}

interface ShipProductData {
  userId: string;
  trackingId: string;
  orderId: string;
  inboundAt?: Date | null;
  freeAt?: Date | null;
  fee: number;
  overstoreFee: number;
}

const CMembershipPayModal = ({
  userData,
  selectedMembership,
  setPayModal,
}: MembershipProps) => {
  const user = useSelector((state: RootUserState) => state.user);
  const currentMembershipId: number = userData.membershipId;
  const currentMembershipEndAt: Date = userData.membershipEndAt as Date;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [productDataList, setProductDataList] = useState<ShipProductData[]>(
    [] as ShipProductData[]
  );
  const [overstoreFeeSum, setOverstoreFeeSum] = useState<number>(0);
  const [overstoreFeeDropdown, setOverstoreFeeDropdown] =
    useState<boolean>(false);
  const [leftExpireDay, setLeftExpireDay] = useState<number>(0);

  const getShipProductDataList = async () => {
    const userId = user.id;
    const res = await reqProductOfUser(userId);
    const shipProductDataList: ShipProduct[] = res.data;

    const filteredDataList = shipProductDataList.filter(
      (product) =>
        product.status !== 20 && product.status !== 3 && product.status !== 6
    );

    const transformedDataList: ShipProductData[] = filteredDataList.map(
      (product) => ({
        userId: product.userId,
        trackingId: product.tracking,
        orderId: product.orderId || "",
        inboundAt: product.inboundAt || null,
        freeAt: product.freeAt || null,
        fee: product.price || 0,
        overstoreFee: product.overstoreFee,
      })
    );

    setProductDataList(
      transformedDataList.filter((product) => product.userId === userData.id)
    );
  };

  const trackingNumbersWithFees = productDataList
    .filter((product) => product.overstoreFee > 0)
    .map((product) => product.trackingId);

  useEffect(() => {
    const initializeModal = async () => {
      setLoading(true);
      await getShipProductDataList();
      setIsOverstoreFeeDefault();
      calcLeftMembershipDate();
      setLoading(false);
    };
    initializeModal();
  }, []);

  const setIsOverstoreFeeDefault = async () => {
    setOverstoreFeeSum(
      productDataList.reduce((acc, cur) => {
        acc += cur.overstoreFee;
        return acc;
      }, 0)
    );
  };

  useEffect(() => {
    setIsOverstoreFeeDefault();
  }, [productDataList]);

  //! Event
  const handleMembershipPayButton = async () => {
    if (!agreeCheck) {
      Toast.fire({
        icon: "warning",
        title: "Please agree to the cancellation and refund regulations.",
      });
      return;
    }
    const paypalResult = await executePaypalPayment();
    if (!paypalResult) {
      alert("Paypal API Error");
      return;
    }
    const { online_url, mobile_url } = paypalResult.data;

    window.location.replace(isMobile ? mobile_url : online_url);
  };

  const executePaypalPayment = async () => {
    const customObject = {
      reqUpdateUserMembership: {
        id: user.id,
        membershipId: selectedMembership.id,
      },
      issueCheckContentsCoupon: {
        userId: user.id,
        membershipId: selectedMembership.id,
      },
      reqPayment: {
        type: "membership",
        userId: user.id,
        paymentAmount: (selectedMembership.price + overstoreFeeSum).toFixed(2),
      },
      // reqShipProductBox: trackingNumbersWithFees.map((tracking) => ({
      //   tracking,
      //   overstoreFee: 0,
      // })),
    };

    const paypalResult = await reqPaypalPayment({
      storeid: "koreawave",
      currency: "USD",
      storeorderno: `membership-${new Date().getTime().toString()}`,
      amount: parseFloat(
        (selectedMembership.price + overstoreFeeSum).toFixed(2)
      ),
      payerid: `koreawave-${user.id}`,
      payeremail: "khh@koreawave.kr",
      returnurl: `${
        process.env.REACT_APP_BASE_URL
      }paypal/handle/result?custom=${encodeURIComponent(
        JSON.stringify(customObject)
      )}`,
      notiurl: `${process.env.REACT_APP_BASE_URL}paypal/handle/notify`,
      custom: JSON.stringify(customObject),
      directreturnflag: "Y",
    });

    return paypalResult;
  };

  //! Utils
  const setPaypalCustomObject = () => {
    const nextMembership = selectedMembership.id;
    return {
      pk: user.id,
      domain: "MEMBERSHIP",
      reqUpdateUserMembership: {
        id: user.id,
        membershipId: nextMembership,
      },
    };
  };

  const calcLeftMembershipDate = () => {
    const curTime = new Date().getTime();
    const expiredTime = new Date(currentMembershipEndAt).getTime();

    const timeDifference = expiredTime - curTime;
    if (timeDifference <= 0) {
      setLeftExpireDay(0);
      return;
    }

    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    setLeftExpireDay(dayDifference);
    return;
  };

  const membershipIdToTitle = (membershipId: number) => {
    if (membershipId === 3) return "Green";
    if (membershipId === 2) return "Blue";
    if (membershipId === 1) return "Red";
    return "";
  };

  const generateMembershipInfoText = () => {
    const currentMembershipTitle = membershipIdToTitle(currentMembershipId);
    const selectedMembershipTitle = membershipIdToTitle(selectedMembership.id);
    const currentMembershipEndAtText = currentMembershipEndAt
      ? currentMembershipEndAt
          .toLocaleString("ko-KR", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour12: false,
          })
          .split("T")[0]
      : "Forever";

    let actionText = "";
    if (currentMembershipId === selectedMembership.id) actionText = "renew";
    if (currentMembershipId < selectedMembership.id) actionText = "downgrade";
    if (currentMembershipId > selectedMembership.id) actionText = "upgrade";

    return (
      <div>
        {leftExpireDay === 0 ? (
          // 멤버십 만료시
          <p>Your current membership is expired!</p>
        ) : (
          <p>
            Your current membership is{" "}
            <span style={{ fontWeight: "bold" }}>{currentMembershipTitle}</span>{" "}
            and you can use up to{" "}
            <span style={{ fontWeight: "bold" }}>
              {currentMembershipEndAtText}
            </span>
            .
          </p>
        )}

        <p>
          Do you want to{" "}
          <span style={{ fontWeight: "bold" }}>{actionText}</span> your
          membership{" "}
          {
            <>
              to{" "}
              <span style={{ fontWeight: "bold" }}>
                {selectedMembershipTitle}
              </span>
            </>
          }{" "}
          ?
        </p>
      </div>
    );
  };

  //! 결제
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [agreeCheck, setAgreeCheck] = useState<boolean>(false);

  const getPaymentCardStyle = (method: string) => {
    return method === paymentMethod ? "payment-card selected" : "payment-card";
  };

  const getMobilePaymentCardStyle = (method: string) => {
    return method === paymentMethod
      ? "mobile-payment-card selected"
      : "mobile-payment-card";
  };

  return loading ? (
    <LoadingSection>
      <img src="/resources/img/loading.gif" alt="로딩중" />
      <p>I'm calculating the cost of the warehouse...</p>
    </LoadingSection>
  ) : (
    <>
      <Modal show={true} onHide={() => setPayModal(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>USD Payment</Modal.Title>
        </Modal.Header>

        <MBody style={{ border: "none" }}>
          {selectedMembership && (
            <div style={{ padding: "0 1rem" }}>
              {/* 정보 */}
              <div className="line">
                <MPageTitle>Info</MPageTitle>
                <div>{generateMembershipInfoText()}</div>

                {/* 멤버십 잔여 기간 내 구매시 */}
                {leftExpireDay !== 0 && (
                  <>
                    <p>
                      ⚠ You have{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {leftExpireDay}{" "}
                      </span>{" "}
                      <span>days until your membership expires.</span>
                    </p>
                    <p>Do you still want to proceed?</p>
                  </>
                )}
              </div>

              {/* 창고 수수료가 있는 경우 고지 */}
              {overstoreFeeSum !== 0 && (
                <div className="line">
                  <MPageTitle>Overstore Cost</MPageTitle>
                  <div
                    onClick={() =>
                      setOverstoreFeeDropdown(!overstoreFeeDropdown)
                    }
                    style={{ paddingBottom: "1rem" }}
                  >
                    {overstoreFeeDropdown ? (
                      <Icon
                        icon="caret-down-fill"
                        style={{ marginRight: ".5rem" }}
                      />
                    ) : (
                      <Icon icon="caret-up" style={{ marginRight: ".5rem" }} />
                    )}
                    See My Details
                  </div>
                  {overstoreFeeDropdown && (
                    <>
                      {isMobile ? (
                        <MBody style={{ border: "none", margin: "0 1.5rem" }}>
                          {productDataList
                            .filter(
                              (product: ShipProductData) =>
                                product.userId === userData.id &&
                                product.overstoreFee > 0
                            )
                            .map((product: ShipProductData, index: number) => (
                              <div
                                className="line"
                                style={{ marginTop: ".5rem" }}
                                key={index}
                              >
                                <MRow>
                                  <div className="label">Tracking No.</div>
                                  <div className="data">
                                    {product.trackingId}
                                  </div>
                                </MRow>

                                <MRow>
                                  <div className="label">Storage Start</div>
                                  <div className="data">
                                    {`${formatCustomDate(
                                      new Date(product.inboundAt as Date)
                                    )}`}
                                  </div>
                                </MRow>

                                <MRow>
                                  <div className="label">Storage Expired</div>
                                  <div className="data">
                                    {`${formatCustomDate(
                                      new Date(product.freeAt as Date)
                                    )}`}
                                  </div>
                                </MRow>

                                <MRow>
                                  <div className="label">Overstore Cost</div>
                                  <div className="data price">
                                    $ {product.overstoreFee.toFixed(2)} USD
                                  </div>
                                </MRow>
                              </div>
                            ))}
                        </MBody>
                      ) : (
                        <Table hover responsive>
                          <thead>
                            <tr>
                              <th>Tracking No.</th>
                              <th>Storage Period</th>
                              <th>Overstore Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productDataList
                              .filter(
                                (product: ShipProductData) =>
                                  product.userId === userData.id &&
                                  product.overstoreFee > 0
                              )
                              .map(
                                (product: ShipProductData, index: number) => (
                                  <tr key={index}>
                                    <td>{product.trackingId}</td>
                                    <td>{`${formatCustomDate(
                                      new Date(product.inboundAt as Date)
                                    )} ~ ${formatCustomDate(
                                      new Date(product.freeAt as Date)
                                    )}`}</td>
                                    <td
                                      style={{
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      $ {product.overstoreFee.toFixed(2)} USD
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </Table>
                      )}
                    </>
                  )}
                </div>
              )}

              {/* 총 합계 금액 */}
              <div className="line">
                <MPageTitle>Total</MPageTitle>
                {overstoreFeeSum !== 0 ? (
                  <>
                    <MRow>
                      <div className="label">Membership Price</div>
                      <div className="data price">
                        $ {selectedMembership.price.toFixed(2)} USD
                      </div>
                    </MRow>
                    <MRow>
                      <div className="label">Overstore Cost</div>
                      <div className="data price">
                        $ {overstoreFeeSum.toFixed(2)} USD
                      </div>
                    </MRow>
                    <MRow>
                      <div className="label bold">Order Total</div>
                      <div className="data price">
                        ${" "}
                        {(selectedMembership.price + overstoreFeeSum).toFixed(
                          2
                        )}{" "}
                        USD
                      </div>
                    </MRow>
                  </>
                ) : (
                  <MRow>
                    <div className="label">Order Total</div>
                    <div className="data price">
                      $ {selectedMembership.price.toFixed(2)} USD
                    </div>
                  </MRow>
                )}
              </div>

              {/* 결제 수단 안내 */}
              {isMobile ? (
                <div className="line">
                  <MPageTitle>Payment Method</MPageTitle>
                  <PaymentMethodSection>
                    <div
                      className={getMobilePaymentCardStyle(
                        PAYMENT_METHOD["PAYPAL"]
                      )}
                      onClick={() => setPaymentMethod(PAYMENT_METHOD["PAYPAL"])}
                    >
                      <img src="/resources/img/PayPal.png" alt="Paypal" />
                    </div>
                    <div
                      className={getMobilePaymentCardStyle(
                        PAYMENT_METHOD["CREDIT_CARD"]
                      )}
                      onClick={() =>
                        setPaymentMethod(PAYMENT_METHOD["CREDIT_CARD"])
                      }
                    >
                      <img src="/resources/img/VLSA.png" alt="Credit Card" />
                    </div>
                    <div
                      className={getMobilePaymentCardStyle(
                        PAYMENT_METHOD["EXPRESS"]
                      )}
                      onClick={() =>
                        setPaymentMethod(PAYMENT_METHOD["EXPRESS"])
                      }
                    >
                      <img
                        src="/resources/img/express.png"
                        className="express-image"
                        alt="EXPRESS"
                      />
                    </div>
                  </PaymentMethodSection>
                </div>
              ) : (
                <div className="line">
                  <MPageTitle>Payment Method</MPageTitle>
                  <PaymentMethodSection>
                    <div className="payment-row">
                      <div
                        className={getPaymentCardStyle(
                          PAYMENT_METHOD["PAYPAL"]
                        )}
                        onClick={() =>
                          setPaymentMethod(PAYMENT_METHOD["PAYPAL"])
                        }
                      >
                        <img src="/resources/img/PayPal.png" alt="Paypal" />
                      </div>
                      <div
                        className={getPaymentCardStyle(
                          PAYMENT_METHOD["CREDIT_CARD"]
                        )}
                        onClick={() =>
                          setPaymentMethod(PAYMENT_METHOD["CREDIT_CARD"])
                        }
                      >
                        <img src="/resources/img/VLSA.png" alt="Credit Card" />
                      </div>
                      <div
                        className={getPaymentCardStyle(
                          PAYMENT_METHOD["EXPRESS"]
                        )}
                        onClick={() =>
                          setPaymentMethod(PAYMENT_METHOD["EXPRESS"])
                        }
                      >
                        <img
                          src="/resources/img/express.png"
                          className="express-image"
                          alt="EXPRESS"
                        />
                      </div>
                    </div>
                  </PaymentMethodSection>
                </div>
              )}

              {/* 환불 규정 동의 */}
              <PayCol>
                <Form.Group className="mb-3">
                  <MPageTitle>Cancellation and refund regulations</MPageTitle>
                  <Form.Check
                    type="checkbox"
                    label="I agree should I decide to change my membership plan prior to its expiration, any remaining days or months on my current plan will be forfeited."
                    checked={agreeCheck === true}
                    onChange={() => setAgreeCheck(!agreeCheck)}
                  />
                </Form.Group>
              </PayCol>
            </div>
          )}
        </MBody>

        {/* 버튼 */}
        {isMobile ? (
          <MFooter style={{ padding: "1rem" }}>
            <MTwoButtonRow>
              <MButton
                onClick={() => setPayModal(false)}
                style={{
                  backgroundColor: "var(--color-gray)",
                  color: "var(--color-white)",
                }}
              >
                Close
              </MButton>
              <MButton
                onClick={() => handleMembershipPayButton()}
                style={{
                  backgroundColor: agreeCheck
                    ? "var(--color-main-blue)"
                    : "var(--color-main-blue-hover)",
                  color: "var(--color-white)",
                }}
              >
                Submit Payment
              </MButton>
            </MTwoButtonRow>
          </MFooter>
        ) : (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setPayModal(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => handleMembershipPayButton()}
              disabled={!agreeCheck}
            >
              Submit Payment
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

//! Payment Start
const SpaceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .order-total {
    color: var(--color-main-blue);
    font-weight: bold;
  }
`;
//! Payment End

const PaymentMethodSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .payment-row {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;
  }

  .payment-card {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ccc;
    border-radius: 8px;
    margin: 5px;
    transition: border-color 0.3s;
    flex: 1 1 0;
    width: 30vw;
    height: 10vh;
    overflow: hidden;
    & > img {
      width: 70%;
      height: 70%;
      object-fit: scale-down;
    }
  }

  .mobile-payment-card {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ccc;
    border-radius: 8px;
    margin: 5px;
    transition: border-color 0.3s;
    flex: 1 1 0;
    width: 83vw;
    height: 7vh;
    & > img {
      width: 70%;
      height: 70%;
      object-fit: scale-down;
    }
  }

  .payment-card:hover,
  .mobile-payment-card:hover,
  .selected {
  }

  // Custom flex settings for each card type
  .paypal,
  .visa {
    flex: 1 0 50%;
  }

  .amex {
    flex: 1 0 100%;
  }
`;

const PayCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  gap: 0.4rem;
  border-bottom: 1px solid lightgray;
`;

const LoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed; /* 화면 중앙 고정 */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; /* 뷰포트 전체 높이 */
  background-color: rgba(255, 255, 255, 0.9); /* 반투명 배경 */
  z-index: 1050; /* 다른 요소 위에 표시 */
  text-align: center;

  & img {
    width: 50px;
    height: 50px;
    margin-bottom: 1rem;
  }

  & p {
    font-size: 1.2rem;
    color: #555;
  }
`;

export default CMembershipPayModal;
