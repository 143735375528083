import { AxiosManager } from "../axiosManager";
import { getAccessTokenFromLocalStorage } from "../common/constants";
// 로컬 스토리지에서 토큰을 가져와서 API 요청
export function reqAShop() {
  const accessToken = getAccessTokenFromLocalStorage();
  if (!accessToken) {
    console.error("Access token is missing");
    throw new Error("Access token is missing");
  }

  return AxiosManager.get("shop/products", {
    headers: {
      Authorization: `Bearer ${accessToken}`, // 토큰을 Authorization 헤더에 추가
    },
  });
}

export function reqAShopByStatus(status: number) {
  const accessToken = getAccessTokenFromLocalStorage();
  if (!accessToken) {
    console.error("Access token is missing");
    throw new Error("Access token is missing");
  }

  return AxiosManager.get(`shop/products/status/${status}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export function requserShip(userId: string) {
  return AxiosManager.get(`shop/products/user/${userId}`);
}

// 로컬 스토리지에서 토큰을 가져와서 API 요청
export function reqAShopDetil() {
  const accessToken = getAccessTokenFromLocalStorage();
  if (!accessToken) {
    console.error("Access token is missing");
    throw new Error("Access token is missing");
  }

  return AxiosManager.get("shop/product", {
    headers: {
      Authorization: `Bearer ${accessToken}`, // 토큰을 Authorization 헤더에 추가
    },
  });
}

export function reqAShopDaily(startDate: string) {
  return AxiosManager.get("shop/products/revenue/daily", {
    params: {
      startDate,
    },
  });
}

export function reqAShopStatistics(startDate: any, endDate: any) {
  return AxiosManager.get(`shop/product/statistics`, {
    params: {
      startDate,
      endDate,
    },
  });
}

export function reqShop(payload: any) {
  return AxiosManager.get(`shop/products/${payload.order_id}`);
}

export function reqShopUpdate(payload: any) {
  return AxiosManager.put(`shop/products/update/${payload.order_id}`, payload);
}

export function reqShopUpdates(payload: any) {
  return AxiosManager.put(`shop/products/cancel/${payload.order_id}`, payload);
}

export function reqDropAllTransaction() {
  return AxiosManager.put(`shop/products/drop-transaction`);
}

export function reqShopProductExpired(payload: any) {
  return AxiosManager.put(`shop/products/expire-payment`, payload);
}

export function reqShopDetailUpdate(payload: any) {
  return AxiosManager.put(`shop/product/details/${payload.order_id}`, payload);
}

export function reqShopDetailUpdates(productId: string, updateData: any) {
  return AxiosManager.put(`shop/product/${productId}`, updateData);
}

export function reqShopDetailUpdatePut(payload: any) {
  return AxiosManager.put(`shop/product/detail/${payload.productId}`, payload);
}

export function reqShopDetailUpdatePuts(productId: string, payload: any) {
  return AxiosManager.put(`shop/product/detail/${productId}`, payload);
}

export function reqShopPost(payload: any) {
  return AxiosManager.post("shop/products", payload);
}

export function reqShopPosts(payload: any) {
  return AxiosManager.post("shop/products/create", payload);
}

export function reqShopDetailpPost(payload: any) {
  return AxiosManager.post("shop/product", payload);
}

// 로컬 스토리지에서 토큰을 가져와서 API 요청
export function reqAShopDetail() {
  const accessToken = getAccessTokenFromLocalStorage();
  if (!accessToken) {
    console.error("Access token is missing");
    throw new Error("Access token is missing");
  }

  return AxiosManager.get(`shop/product`, {
    headers: {
      Authorization: `Bearer ${accessToken}`, // 토큰을 Authorization 헤더에 추가
    },
  });
}

export function reqShopProductCount() {
  return AxiosManager.get(`shop/products/count/all`);
}

export function reqShopDetail(payload: any) {
  return AxiosManager.get(`shop/product/details/${payload.order_id}`);
}

export function reqShopSurvery() {
  return AxiosManager.get(`orders/surveys`);
}

export function reqShopSurverys(id: number) {
  return AxiosManager.get(`orders/survey/${id}/barcodes`);
}

export function reqShopUserDetail(payload: any) {
  return AxiosManager.get(`shop/product/details/user/${payload.userId}`);
}

export function reqShopProductIdDetail(payload: any) {
  return AxiosManager.get(`shop/product/detail/user/${payload.productId}`);
}

export function reqFEDEX(payload: any) {
  return AxiosManager.post("fedex/calculate-rates", payload);
}

export function reqUPS(payload: any) {
  return AxiosManager.post("ups/calculate-rates", payload);
}
