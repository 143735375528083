import { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonProps,
  Collapse,
  Form,
  FormControl,
  Modal,
  Tab,
  Table,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import styled from "styled-components";
import Swal from "sweetalert2";
import { CODE_COUNTRY_ITEMS } from "../../common/constants";
import { formatDate } from "../../common/format";
import { MButton } from "../../common/mobile-styled";
import CouponListSetting from "../../components/CouponListSetting";
import Icon from "../../components/Icon";
import ProfileImg from "../../components/ProfileImg";
import ReviewModal from "../../components/modal/ReviewModal";
import { reqLogout, reqResetPassword } from "../../requests/auth";
import { reqMyHaveCoupon, reqRedeemCoupon } from "../../requests/coupon";
import { reqAllReviewByUser } from "../../requests/review";
import {
  reqDeleteUser,
  reqEditUserAddress,
  reqGetLogsByUserId,
  reqGetUserById,
  reqPutUserAddres,
  reqUpdateMarketingAgreement,
  reqUploadImage,
} from "../../requests/user";

interface Address {
  id: number;
  name: string;
  receiver: string;
  country: number;
  city: string;
  state: string;
  zip: string;
  option1: string;
  option2: string;
  option3: string;
  phone: string;
  email: string;
}

interface CountryOption {
  value: number;
  label: string;
}

export default function CSettings() {
  const user = useSelector((state: any) => state.user);
  const nav = useNavigate();
  const [showAddModal, setShowAddModal] = useState(false);
  const [newAddress, setNewAddress] = useState({
    name: "",
    receiver: "",
    country: 0,
    city: "",
    state: "",
    zip: "",
    option1: "",
    option2: "",
    option3: "",
    phone: "",
    email: "",
  });
  const [selectedProfileMenu, setSelectedProfileMenu] = useState<number>(0);
  const [isPasswordChange, setIsPasswordChange] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [newRePassword, setNewRePassword] = useState<string>("");
  const [data, setData] = useState<any>();
  const [addressData, setAddressData] = useState<any>({});
  const [profileImage, setProfileImage] = useState<any>(
    "https://kr.object.ncloudstorage.com/ontact-bucket/user_profile/default_profile.png"
  );
  const [reviewAllCount, setReviewAllCount] = useState<any>();
  const [reviewList, setReviewList] = useState<any>();
  const [reviewModal, setReviewModal] = useState<boolean>(false);
  const [selectedReview, setSelectedReview] = useState<any>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [couponList, setCouponList] = useState<any[]>([]);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [availableCouponList, setAvailableCouponList] = useState<any>();
  const [couponApplied, setCouponApplied] = useState<boolean>(false);
  const [couponModal, setCouponModal] = useState<boolean>(false);
  const [krwDiscount, setKrwDiscount] = useState<number>(0);
  const [usdDiscount, setUsdDiscount] = useState<number>(0);
  const [selectedCouponId, setSelectedCouponId] = useState<number>();
  const fileInput = useRef(null);
  const [pointHistory, setPointHistory] = useState<any[]>([]);
  const [marketingAgreement, setMarketingAgreement] = useState<boolean>(false);

  // 해시쿠폰 입력
  const [couponCode, setCouponCode] = useState<string>("");

  const handleCouponRedeem = async () => {
    if (!couponCode) {
      Swal.fire("Error", "Please enter a coupon code.", "error");
      return;
    }

    try {
      const userId = user.id; // 사용자 ID 가져오기
      const response = await reqRedeemCoupon({ userId, hashCode: couponCode });
      Swal.fire(
        "Success",
        response.data.message || "Coupon redeemed!",
        "success"
      ).then(() => {
        window.location.reload(); // "OK"를 누르면 새로고침
      });
      setCouponCode(""); // 입력 필드 초기화
    } catch (error: any) {
      Swal.fire(
        "Error",
        error.response?.data?.message || "Failed to redeem coupon.",
        "error"
      );
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [user]);

  const fetchPointHistory = async () => {
    try {
      const response = await reqGetLogsByUserId(user.id);
      setPointHistory(response.data);
    } catch (error) {
      console.error("Failed to fetch point history:", error);
    }
  };

  useEffect(() => {
    if (selectedProfileMenu === 4) {
      fetchPointHistory();
    }
  }, [selectedProfileMenu]);

  const fetchUserData = async () => {
    const res = await reqGetUserById({ id: user.id });
    if (res.data) {
      setData(res.data);
      setProfileImage(
        `https://kr.object.ncloudstorage.com/ontact-bucket/${res.data.imageUrl}`
      );
      setAddressData(res.data.address);
      setMarketingAgreement(res.data.agreeMarketing || false);
      setInput({
        name: res.data.address?.[0].receiver,
        receiver: res.data.address?.[0].receiver,
        country: parseInt(res.data.address?.[0].country),
        city: res.data.address?.[0].city,
        state: res.data.address?.[0].state,
        zip: res.data.address?.[0].zip,
        option1: res.data.address?.[0].option1,
        option2: res.data.address?.[0].option2,
        option3: res.data.address?.[0].option3,
        phone: res.data.phone,
        email: res.data.email,
      });

      const reviewRes = await reqAllReviewByUser({ userId: user.id, page: 1 });
      setReviewAllCount(reviewRes.data.reviewCount);
      setReviewList(reviewRes.data.reviewList);

      const couponRes = await reqMyHaveCoupon({ userId: user.id });
      setAvailableCouponList(couponRes.data);
    }
  };

  const [input, setInput] = useState({
    name: "",
    receiver: "",
    country: 0,
    city: "",
    state: "",
    zip: "",
    option1: "",
    option2: "",
    option3: "",
    phone: "",
    email: "",
  });

  const {
    name,
    receiver,
    country,
    city,
    state,
    zip,
    option1,
    option2,
    option3,
    phone,
    email,
  } = input;

  const validateAddress = () => {
    const requiredFields = [
      "name",
      "receiver",
      "country",
      "city",
      "state",
      "zip",
      "phone",
      "email",
      "option1",
    ];
    for (const field of requiredFields) {
      if (!newAddress[field as keyof typeof newAddress]) {
        return false;
      }
    }
    return true;
  };

  const handleAddNewAddress = async () => {
    if (!validateAddress()) {
      await Swal.fire({
        title: "Missing Information",
        text: "Please fill out all required fields.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (user.Check) {
      await Swal.fire({
        title: "Action Blocked",
        text: "Please wait until the space code changes.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    try {
      await reqEditUserAddress({ ...newAddress, userId: user.id });
      await Swal.fire({
        title: "Success",
        text: "New address added successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      setShowAddModal(false);
      fetchUserData();
      window.location.reload();
    } catch (error) {
      console.error("Failed to add new address:", error);
      await Swal.fire({
        title: "Error",
        text: "Failed to add new address.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const removeAccents = (value: string): string => {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleNewPasswordChange = (e: any) => {
    setNewPassword(e.target.value);
  };
  const handleNewRePasswordChange = (e: any) => {
    setNewRePassword(e.target.value);
  };

  const handleNewAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const filteredValue = removeAccents(value);
    setNewAddress((prev) => ({ ...prev, [name]: filteredValue }));
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const filteredValue = removeAccents(value);
    setInput((prev) => ({ ...prev, [name]: filteredValue }));
  };

  const handleNewCountrySelect = (
    selectedOption: SingleValue<CountryOption>
  ) => {
    if (selectedOption) {
      setNewAddress((prev) => ({ ...prev, country: selectedOption.value }));
    }
  };

  const handleCountrySelect = (selectedOption: SingleValue<CountryOption>) => {
    if (selectedOption) {
      setInput((prev) => ({ ...prev, country: selectedOption.value }));
    }
  };

  const handleOpenModal = (address: Address) => {
    setSelectedAddress(address);
    setInput({
      name: address.name,
      receiver: address.receiver,
      country: address.country,
      city: address.city,
      state: address.state,
      zip: address.zip,
      option1: address.option1,
      option2: address.option2,
      option3: address.option3,
      phone: address.phone,
      email: address.email,
    });
    setShowEditModal(true);
  };

  const handleDeleteUser = async () => {
    const initialResult = await Swal.fire({
      title: "Warning",
      text: "Are you sure you want to permanently delete your account? Deleting your account will remove all your storage data, memberships, coupon and points. This action can't be undone. ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (initialResult.isConfirmed) {
      const confirmResult = await Swal.fire({
        title: "Are you sure?",
        text: "Please write 'Yes I agree' to confirm deletion. This process cannot be undone.",
        icon: "warning",
        input: "text",
        inputPlaceholder: "Yes I agree",
        inputValidator: (value) => {
          if (value !== "Yes I agree") {
            return "You need to write 'Yes I agree' to proceed!";
          }
        },
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      });

      if (confirmResult.isConfirmed) {
        try {
          await reqDeleteUser(user.id);
          await Swal.fire(
            "Deleted!",
            "Your account has been deleted.",
            "success"
          );
          await reqLogout();
          window.location.reload();
          nav("/");
        } catch (error) {
          console.error("Failed to delete user:", error);
          await Swal.fire(
            "Error!",
            "There was an error deleting your account. Please try again later.",
            "error"
          );
        }
      }
    }
  };

  const saveUserInfo = async () => {
    if (user.Check) {
      await Swal.fire({
        title: "Action Blocked",
        text: "Please wait until the space code changes.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    if (selectedAddress && selectedAddress.id) {
      try {
        await reqPutUserAddres({
          id: selectedAddress.id,
          userId: String(user.id),
          ...input,
        });
        await Swal.fire({
          title: "Success",
          text: "Profile updated successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
        setShowEditModal(false);
        fetchUserData();
      } catch (error) {
        console.error("Failed to update address info:", error);
        await Swal.fire({
          title: "Error",
          text: "Failed to update address information.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      await Swal.fire({
        title: "No Address Selected",
        text: "No address selected.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };

  const handleReviewModal = ({ target }: any) => {
    const selectedReviewId = Number(
      target.closest(".review-row").id.split("-")[1]
    );
    const targetReview = reviewList.find(
      (review: any) => review.id === selectedReviewId
    );
    setReviewModal(true);
    setSelectedReview({
      reviewId: targetReview.id,
      rating: targetReview.rating,
      content: targetReview.content,
      createdAt: targetReview.createdAt,
      photoUrl: targetReview.photoUrls,
      response: targetReview.response,
    });
  };

  async function verfiyPassword() {
    if (user.Check) {
      await Swal.fire({
        title: "Action Blocked",
        text: "Please wait until the space code changes.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    const isSamePassword = newPassword != newRePassword;
    const isValidPassword =
      !newPassword || newPassword.length < 6 || newPassword.length > 32;
    if (isSamePassword) {
      alert("설정한 비밀번호가 일치하지 않습니다.");
      return;
    }
    if (isValidPassword) {
      alert("비밀번호는 6자리 이상이어야 합니다.");
      return;
    }
    const response = await reqResetPassword({
      id: user.id,
      password: newPassword,
    });

    if (response.data) {
      alert("비밀번호 변경이 완료되었습니다.");
      window.location.reload();
    } else {
      alert("비밀번호 변경이 실패하였습니다.");
    }
  }

  const [isLoading, setIsLoading] = useState(false);
  async function handleImageUpload(event: { target: { files: any[] } }) {
    const file = event.target.files[0];
    setIsLoading(true);
    if (!file) {
      setProfileImage(
        "https://kr.object.ncloudstorage.com/ontact-bucket/user_profile/default_profile.png"
      );
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImage(reader.result);
      }
    };
    reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append("userId", user.id);
    formData.append("file", file);
    const res = await reqUploadImage(formData);
    setIsLoading(false);
    if (res) {
      alert("프로필 사진이 성공적으로 변경되었습니다.");
      window.location.reload();
    }
    return;
  }

  const getMembershipLevel = (membershipId: number) => {
    switch (membershipId) {
      case 1:
        return { level: "Red", color: "red" };
      case 2:
        return { level: "Blue", color: "blue" };
      case 3:
        return { level: "Green", color: "green" };
      default:
        return { level: "Unknown", color: "black" };
    }
  };

  const getSourceText = (status: number) => {
    switch (status) {
      case 1:
        return "Custom Order";
      case 2:
        return "Shipments";
      case 3:
        return "Review";
      case 4:
        return "Manager";
      case 5:
        return "Refund";
      default:
        return "Unknown";
    }
  };

  // 더 보기
  const [visibleLogs, setVisibleLogs] = useState(5);

  const handleShowMore = () => {
    setVisibleLogs((prevVisibleLogs) => prevVisibleLogs + 5);
  };

  const [visibleCoupons, setVisibleCoupons] = useState(5);

  const handleShowMoreCoupons = () => {
    setVisibleCoupons((prevVisibleCoupons) => prevVisibleCoupons + 5);
  };

  const handleMarketingAgreementChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.checked;

    Swal.fire({
      title: "Marketing Agreement",
      text: newValue
        ? "Would you like to receive marketing information?"
        : "Would you like to opt out of marketing information?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await reqUpdateMarketingAgreement(data?.id, newValue);
          setMarketingAgreement(newValue);
          Swal.fire({
            title: "Success",
            text: newValue
              ? "You have subscribed to marketing information."
              : "You have unsubscribed from marketing information.",
            icon: "success",
            confirmButtonText: "OK",
          });
        } catch (error) {
          console.error("Failed to update marketing agreement:", error);
          Swal.fire({
            title: "Error",
            text: "Failed to update marketing agreement.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSection>
          <img src="/resources/img/loading.gif" alt="로딩중" />
        </LoadingSection>
      ) : null}
      <Main>
        {reviewModal && (
          <ReviewModal
            reviewData={selectedReview}
            setViewReviewModal={(flag: boolean) => setReviewModal(flag)}
            isOwner={true}
          />
        )}
        <div className="settings-profile">
          <div
            className="d-flex justify-content-center"
            style={{ position: "relative" }}
          >
            <ProfileImg
              src={profileImage}
              width="300px"
              editable
              uploadImage={handleImageUpload}
            />
          </div>

          <div className="settings-profile-detail">
            <p className="font-large-profile" translate="no">
              SPACE CODE
              <p>{data?.id}</p>
            </p>

            <p
              className="font-large-profile"
              style={{ color: getMembershipLevel(data?.membershipId).color }}
              translate="no"
            >
              Membership Level
              <p> {getMembershipLevel(data?.membershipId).level}</p>
            </p>
            <p className="font-large-profile" translate="no">
              Joined At <p> {formatDate(data?.createdAt)}</p>
            </p>
            <p className="font-large-profile" translate="no">
              Email <p> {data?.email}</p>
            </p>
            <p className="font-large-profile" translate="no">
              Phone <p>{data?.phone}</p>
            </p>
            <div className="d-flex align-items-center mb-2" translate="no">
              <Form.Check
                type="checkbox"
                id="marketing-agreement"
                checked={marketingAgreement}
                onChange={handleMarketingAgreementChange}
                label="Marketing Information Agreement"
                style={{ marginRight: "10px" }}
              />
            </div>
            <td
              style={{ display: "flex", justifyContent: "flex-end" }}
              translate="no"
            >
              <Button
                variant="danger"
                size="sm"
                style={{ padding: "2px 5px", fontSize: "12px" }}
                onClick={handleDeleteUser}
              >
                Delete Account
              </Button>
            </td>
          </div>
        </div>
        <div className="settings-profile-side">
          <div className="settings-profile-detail-side">
            {isMobile ? (
              <Col style={{ marginBottom: "1rem" }}>
                <Label
                  className={selectedProfileMenu === 0 ? "selected" : ""}
                  onClick={(e) => {
                    setSelectedProfileMenu(0);
                  }}
                >
                  {`> Profile`}
                </Label>
                <Label
                  className={selectedProfileMenu === 1 ? "selected" : ""}
                  onClick={(e) => {
                    setSelectedProfileMenu(1);
                  }}
                >
                  {`> Edit Information`}
                </Label>
                <Label
                  className={selectedProfileMenu === 2 ? "selected" : ""}
                  onClick={(e) => {
                    setSelectedProfileMenu(2);
                  }}
                >
                  {`> My Reviews`}
                </Label>
                <Label
                  className={selectedProfileMenu === 3 ? "selected" : ""}
                  onClick={(e) => {
                    setSelectedProfileMenu(3);
                    setCouponModal(true);
                  }}
                >
                  {`> My Coupons`}
                </Label>
                <Label
                  className={selectedProfileMenu === 4 ? "selected" : ""}
                  onClick={(e) => {
                    setSelectedProfileMenu(4);
                  }}
                >
                  {`> Point history`}
                </Label>
              </Col>
            ) : (
              <MenuList>
                <li
                  className={selectedProfileMenu === 0 ? "selected" : ""}
                  onClick={() => setSelectedProfileMenu(0)}
                  translate="no"
                >
                  Profile
                </li>
                <li
                  className={selectedProfileMenu === 1 ? "selected" : ""}
                  onClick={() => setSelectedProfileMenu(1)}
                  translate="no"
                >
                  Edit Information
                </li>
                <li
                  className={selectedProfileMenu === 2 ? "selected" : ""}
                  onClick={() => setSelectedProfileMenu(2)}
                  translate="no"
                >
                  My Reviews
                </li>
                <li
                  className={
                    selectedProfileMenu === 3 ? "selected f-row" : "f-row"
                  }
                  onClick={() => {
                    setSelectedProfileMenu(3);
                    // setCouponModal(true);
                  }}
                  translate="no"
                >
                  My Coupons
                </li>
                <li
                  className={selectedProfileMenu === 4 ? "selected" : ""}
                  onClick={() => setSelectedProfileMenu(4)}
                  translate="no"
                >
                  Point History
                </li>
              </MenuList>
            )}
            {selectedProfileMenu === 0 && (
              <>
                <div className="table-responsive mt-3">
                  <Table>
                    <tbody>
                      <tr translate="no">
                        <th className="label-large-profile-th">Name</th>
                        <td className="font-large-profile-name">
                          {data?.name}
                        </td>
                      </tr>
                      <tr translate="no">
                        <th className="label-large-profile-th">Country</th>
                        <td className="font-large-profile-name">
                          {CODE_COUNTRY_ITEMS[data?.country]}
                        </td>
                      </tr>
                      <tr translate="no">
                        <th className="label-large-profile-th">Address</th>
                        <td className="font-large-profile-name">
                          {data?.address?.[0].city}, {data?.address?.[0].state},{" "}
                          {data?.address?.[0].zip}
                        </td>
                      </tr>
                      <tr translate="no">
                        <th className="label-large-profile-th">
                          Address Option
                        </th>
                        <td
                          className="font-large-profile-name"
                          style={{ wordBreak: "break-word" }}
                        >
                          {data?.address?.[0].option1},{" "}
                          {data?.address?.[0].option2},{" "}
                          {data?.address?.[0].option3}
                        </td>
                      </tr>
                      <tr translate="no">
                        <th className="label-large-profile-th">Phone</th>
                        <td className="font-large-profile-name">
                          {data?.phone}
                        </td>
                      </tr>
                      <tr translate="no">
                        <th className="label-large-profile-th">Email</th>
                        <td
                          className="font-large-profile-name"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.email}
                        </td>
                      </tr>
                      <tr translate="no">
                        <th className="label-large-profile-th">point(KRW)</th>
                        <td className="font-large-profile-name">
                          ₩{data?.point}
                        </td>
                      </tr>
                      <tr translate="no">
                        <th className="label-large-profile-th">point(USD)</th>
                        <td className="font-large-profile-name">
                          ${data?.pointUsd}
                        </td>
                      </tr>
                      {isMobile ? (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <Button
                            style={{
                              width: "200px",
                              marginTop: "10px",
                              marginLeft: "10px",
                            }}
                            variant="dark"
                            onClick={(e) => {
                              setIsPasswordChange(!isPasswordChange);
                            }}
                          >
                            Change Password
                            {!isPasswordChange && (
                              <Icon icon="chevron-down" fontSize="12px" />
                            )}
                            {isPasswordChange && (
                              <Icon icon="chevron-up" fontSize="12px" />
                            )}
                          </Button>

                          <Collapse in={isPasswordChange}>
                            <div>
                              <FormControl
                                style={{
                                  width: "200px",
                                  marginTop: "10px",
                                }}
                                value={newPassword}
                                onChange={handleNewPasswordChange}
                                type="password"
                                placeholder="Enter New Password"
                              />
                              <FormControl
                                style={{
                                  width: "200px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                                value={newRePassword}
                                onChange={handleNewRePasswordChange}
                                type="password"
                                placeholder="Enter New Password again"
                              />

                              <Button
                                style={{ marginRight: "7px", width: "200px" }}
                                variant="outline-dark"
                                onClick={(e) => {
                                  setIsPasswordChange(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                style={{ marginTop: "10px", width: "200px" }}
                                variant="dark"
                                onClick={(e) => {
                                  verfiyPassword();
                                }}
                              >
                                Submit
                              </Button>
                            </div>
                          </Collapse>
                        </div>
                      ) : (
                        <tr translate="no">
                          <th className="label-large-profile-th">Password</th>
                          <td style={{ width: "70%" }}>
                            <Button
                              variant="dark"
                              onClick={(e) => {
                                setIsPasswordChange(!isPasswordChange);
                              }}
                            >
                              Change Password
                              {!isPasswordChange && (
                                <Icon icon="chevron-down" fontSize="12px" />
                              )}
                              {isPasswordChange && (
                                <Icon icon="chevron-up" fontSize="12px" />
                              )}
                            </Button>

                            <Collapse in={isPasswordChange}>
                              <div>
                                <FormControl
                                  style={{
                                    width: "100%",
                                    marginTop: "10px",
                                  }}
                                  value={newPassword}
                                  onChange={handleNewPasswordChange}
                                  type="password"
                                  placeholder="Enter New Password"
                                />
                                <FormControl
                                  style={{
                                    width: "100%",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                  value={newRePassword}
                                  onChange={handleNewRePasswordChange}
                                  type="password"
                                  placeholder="Enter New Password again"
                                />

                                <Button
                                  style={{
                                    marginRight: "7px",
                                    width: "120px",
                                  }}
                                  variant="outline-dark"
                                  onClick={(e) => {
                                    setIsPasswordChange(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  style={{ width: "120px" }}
                                  variant="dark"
                                  onClick={(e) => {
                                    verfiyPassword();
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            </Collapse>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </>
            )}
            {selectedProfileMenu === 1 && addressData && (
              <>
                <div>
                  <ButtonContainer>
                    {addressData.length > 0 ? (
                      addressData.map((address: any, index: number) => (
                        <MyAddressButton
                          key={index}
                          onClick={() => handleOpenModal(address)}
                        >
                          {address.name}
                        </MyAddressButton>
                      ))
                    ) : (
                      <p>No address data available.</p>
                    )}
                    <AddressAddButton onClick={() => setShowAddModal(true)}>
                      Add New Address
                    </AddressAddButton>
                  </ButtonContainer>
                </div>
              </>
            )}
            {selectedProfileMenu === 2 && (
              <>
                {isMobile ? (
                  <>
                    <div translate="no">
                      {reviewList && (
                        <>
                          <p>{`You wrote ${reviewAllCount} reviews :)`}</p>
                          <ReviewListWrapper>
                            {reviewList.map((item: any) => (
                              <MReviewItem
                                id={`review-${item.id}`}
                                className="review-row"
                                onClick={(e) => handleReviewModal(e)}
                              >
                                <MPhotoContainer>
                                  {item.photoUrls ? (
                                    <img
                                      src={`https://kr.object.ncloudstorage.com/ontact-bucket/${item.photoUrls}`}
                                      alt="review"
                                    />
                                  ) : (
                                    <>No Image</>
                                  )}
                                </MPhotoContainer>
                                <ReviewContent>
                                  <ReviewStars>
                                    {[1, 2, 3, 4, 5].map((_, index) => {
                                      return index < Number(item.rating) ? (
                                        <Icon
                                          icon="star-fill"
                                          color="#f2c72c"
                                          key={index}
                                        />
                                      ) : (
                                        <Icon icon="star" key={index} />
                                      );
                                    })}
                                  </ReviewStars>
                                  <div>{formatDate(item.createdAt)}</div>
                                  <MReviewConmment>
                                    {item.content}
                                  </MReviewConmment>
                                </ReviewContent>
                              </MReviewItem>
                            ))}
                          </ReviewListWrapper>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="settings-profile-item" translate="no">
                      {reviewList && (
                        <>
                          <p>{`You wrote ${reviewAllCount} reviews :)`}</p>
                          <ReviewListWrapper>
                            {reviewList.map((item: any) => (
                              <LargeReviewItem
                                id={`review-${item.id}`}
                                className="review-row"
                                onClick={(e) => handleReviewModal(e)}
                              >
                                <LargePhotoContainer>
                                  {item.photoUrls ? (
                                    <img
                                      src={`https://kr.object.ncloudstorage.com/ontact-bucket/${item.photoUrls}`}
                                      alt="review"
                                    />
                                  ) : (
                                    <>No Image</>
                                  )}
                                </LargePhotoContainer>
                                <LargeReviewContent>
                                  <LargeReviewStars>
                                    {[1, 2, 3, 4, 5].map((_, index) => {
                                      return index < Number(item.rating) ? (
                                        <Icon
                                          icon="star-fill"
                                          color="#f2c72c"
                                          key={index}
                                        />
                                      ) : (
                                        <Icon icon="star" key={index} />
                                      );
                                    })}
                                  </LargeReviewStars>
                                  <div>{formatDate(item.createdAt)}</div>
                                  <LargeReviewComment>
                                    {item.content}
                                  </LargeReviewComment>
                                </LargeReviewContent>
                              </LargeReviewItem>
                            ))}
                          </ReviewListWrapper>
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            {selectedProfileMenu === 3 && (
              <>
                {/* 쿠폰 입력 폼 */}
                <div className="coupon-redeem-form">
                  <h5>Redeem Your Coupon</h5>
                  <div className="coupon-input-wrapper">
                    <input
                      type="text"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      placeholder="Enter coupon code"
                      className="coupon-code-input"
                    />
                    <button
                      onClick={handleCouponRedeem}
                      className="redeem-button"
                    >
                      Receive
                    </button>
                  </div>
                </div>
                <div className="table-responsive mt-4 coupon-table d-none d-md-block">
                  <Table>
                    <thead>
                      <tr translate="no">
                        <th>Coupon Name</th>
                        <th style={{ width: "15%" }}>Discount</th>
                        <th style={{ width: "15%" }}>Minimum Purchase</th>
                        <th>Validity</th>
                        <th>available</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {availableCouponList.map((coupon: any, index: any) => (
                        <tr key={index}>
                          <td>{coupon.coupon.name}</td>
                          <td>
                            {coupon.coupon.discountType === "PERCENT"
                              ? `${coupon.coupon.percentDiscountPrice}%`
                              : `₩${coupon.coupon.krwDiscountPrice.toLocaleString(
                                  "ko-KR"
                                )} or $${coupon.coupon.usdDiscountPrice}`}
                          </td>
                          <td>
                            {coupon.coupon.krwLimitOfUsePrice
                              ? `₩${coupon.coupon.krwLimitOfUsePrice.toLocaleString(
                                  "ko-KR"
                                )} or $${coupon.coupon.usdLimitOfUsePrice}`
                              : `-`}
                          </td>
                          <td>{coupon.expiredAt}</td>
                          <td>
                            {coupon.coupon.availableDeliveryCompanies || "ALL"}
                          </td>
                          <td>
                            {coupon.used ? (
                              "Used"
                            ) : (
                              <div className="f-row">
                                <Icon
                                  icon="circle-fill"
                                  fontSize=".5rem"
                                  color="var(--color-main-red)"
                                  style={{
                                    marginRight: ".4rem",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                />
                                Unused
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div
                  className="coupon-list mt-3 d-block d-md-none"
                  translate="no"
                >
                  {availableCouponList
                    .slice(0, visibleCoupons)
                    .map((coupon: any, index: any) => (
                      <div key={index} className="coupon-item">
                        <FeeBoxRow>
                          <div>
                            <Label>Coupon Name:</Label> {coupon.coupon.name}
                          </div>
                        </FeeBoxRow>
                        <FeeBoxRow>
                          <div>
                            <Label>Discount:</Label>{" "}
                            {coupon.coupon.discountType === "percentage"
                              ? `${coupon.coupon.percentDiscountPrice}%`
                              : coupon.coupon.discountType === "krw"
                              ? `₩${coupon.coupon.krwDiscountPrice.toLocaleString(
                                  "ko-KR"
                                )}`
                              : `$${coupon.coupon.usdDiscountPrice}`}
                          </div>
                        </FeeBoxRow>
                        <FeeBoxRow>
                          <div>
                            <Label>Minimum Purchase:</Label>{" "}
                            {coupon.coupon.krwLimitOfUsePrice
                              ? `₩${coupon.coupon.krwLimitOfUsePrice.toLocaleString(
                                  "ko-KR"
                                )}`
                              : `$${coupon.coupon.usdLimitOfUsePrice}`}
                          </div>
                        </FeeBoxRow>
                        <FeeBoxRow>
                          <div>
                            <Label>Validity:</Label> {coupon.expiredAt}
                          </div>
                        </FeeBoxRow>
                        <FeeBoxRow>
                          <div>
                            <Label>available: </Label>{" "}
                            {coupon.coupon.availableDeliveryCompanies || "ALL"}
                          </div>
                        </FeeBoxRow>
                        <FeeBoxRow>
                          <div>
                            <Label>Status:</Label>{" "}
                            {coupon.used ? "Used" : "Unused"}
                          </div>
                        </FeeBoxRow>
                        {index < availableCouponList.length - 1 && (
                          <hr className="coupon-separator" />
                        )}
                      </div>
                    ))}
                  {visibleCoupons < availableCouponList.length && (
                    <div className="text-center">
                      <Button variant="primary" onClick={handleShowMoreCoupons}>
                        more details
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
            {selectedProfileMenu === 4 && (
              <>
                <div className="current-points">
                  <div translate="no">Current Points</div>
                  <p>
                    KRW: ₩{data?.point}, USD: ${data?.pointUsd}
                  </p>
                </div>
                <div className="table-responsive mt-3 point-history-table d-none d-md-block">
                  <Table>
                    <thead>
                      <tr translate="no">
                        <th>Date</th>
                        <th>Type</th>
                        <th>Points</th>
                        <th>Sources</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pointHistory
                        .slice()
                        .sort(
                          (a, b) =>
                            new Date(b.createdAt).getTime() -
                            new Date(a.createdAt).getTime()
                        )
                        .map((log, index) => (
                          <tr key={index}>
                            <td>{formatDate(log.createdAt)}</td>
                            <td>
                              {log.used
                                ? "Used"
                                : log.grant
                                ? "Granted"
                                : "Unknown"}
                            </td>
                            <td>
                              {log.used ? (
                                <>
                                  {log.krwUsedPoint !== 0 &&
                                    `KRW: -${log.krwUsedPoint}`}
                                  {log.krwUsedPoint !== 0 &&
                                    log.usdUsedPoint !== 0 && <br />}
                                  {log.usdUsedPoint !== 0 &&
                                    parseFloat(log.usdUsedPoint).toFixed(2) !==
                                      "0.00" &&
                                    `USD: -${parseFloat(
                                      log.usdUsedPoint
                                    ).toFixed(2)}`}
                                </>
                              ) : log.grant ? (
                                <>
                                  {log.krwGrantPoint !== 0 &&
                                    `KRW: +${log.krwGrantPoint}`}
                                  {log.krwGrantPoint !== 0 &&
                                    log.usdGrantPoint !== 0 && <br />}
                                  {log.usdGrantPoint !== 0 &&
                                    parseFloat(log.usdGrantPoint).toFixed(2) !==
                                      "0.00" &&
                                    `USD: +${parseFloat(
                                      log.usdGrantPoint
                                    ).toFixed(2)}`}
                                </>
                              ) : (
                                "0"
                              )}
                            </td>
                            <td>{getSourceText(log.status)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                <div className="point-history mt-3 d-block d-md-none">
                  <Col>
                    {pointHistory
                      .slice()
                      .sort(
                        (a, b) =>
                          new Date(b.createdAt).getTime() -
                          new Date(a.createdAt).getTime()
                      )
                      .slice(0, visibleLogs)
                      .map((log, index) => (
                        <div key={index} className="point-item">
                          <FeeBoxRow>
                            <div>
                              <Label>Date:</Label> {formatDate(log.createdAt)}
                            </div>
                          </FeeBoxRow>
                          <FeeBoxRow>
                            <div>
                              <Label>Type:</Label>{" "}
                              {log.used
                                ? "Used"
                                : log.grant
                                ? "Granted"
                                : "Unknown"}
                            </div>
                          </FeeBoxRow>
                          <FeeBoxRow>
                            <div>
                              <Label>Points:</Label>
                              {log.used ? (
                                <>
                                  {log.krwUsedPoint !== 0 &&
                                    `KRW: -${log.krwUsedPoint}`}
                                  {log.krwUsedPoint !== 0 &&
                                    log.usdUsedPoint !== 0 && <br />}
                                  {log.usdUsedPoint !== 0 &&
                                    parseFloat(log.usdUsedPoint).toFixed(2) !==
                                      "0.00" &&
                                    `USD: -${parseFloat(
                                      log.usdUsedPoint
                                    ).toFixed(2)}`}
                                </>
                              ) : log.grant ? (
                                <>
                                  {log.krwGrantPoint !== 0 &&
                                    `KRW: +${log.krwGrantPoint}`}
                                  {log.krwGrantPoint !== 0 &&
                                    log.usdGrantPoint !== 0 && <br />}
                                  {log.usdGrantPoint !== 0 &&
                                    parseFloat(log.usdGrantPoint).toFixed(2) !==
                                      "0.00" &&
                                    `USD: +${parseFloat(
                                      log.usdGrantPoint
                                    ).toFixed(2)}`}
                                </>
                              ) : (
                                "0"
                              )}
                            </div>
                          </FeeBoxRow>
                          <FeeBoxRow>
                            <div>
                              <Label>Sources:</Label>{" "}
                              {getSourceText(log.status)}
                            </div>
                          </FeeBoxRow>
                          {/* Separator or Box */}
                          {index !== pointHistory.length - 1 && <hr />}{" "}
                          {/* Separator */}
                          {/* <div className="separator"></div> */} {/* Box */}
                        </div>
                      ))}
                    {visibleLogs < pointHistory.length && (
                      <div className="text-center">
                        <Button variant="primary" onClick={handleShowMore}>
                          more details
                        </Button>
                      </div>
                    )}
                  </Col>
                </div>
              </>
            )}
          </div>
        </div>
      </Main>

      <Modal
        onEnter={() => setCouponApplied(false)}
        show={couponModal}
        onHide={() => {
          setCouponModal(false);
          setCouponApplied(false);
        }}
        centered
        size="lg"
        style={{ zIndex: "99999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>My Available Coupons</Modal.Title>
        </Modal.Header>

        <CouponListWrapper>
          <Label>Please select a coupon to apply.</Label>
          <P> Duplicate use is not possible.</P>

          <CouponListSetting
            krwInput={0}
            usdInput={5}
            couponList={availableCouponList}
            setKrwDiscount={setKrwDiscount}
            setUsdDiscount={setUsdDiscount}
            couponApplied={setCouponApplied}
            setSelectedCouponId={setSelectedCouponId}
          />
        </CouponListWrapper>
      </Modal>

      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MEditSection>
            <Col>
              <Label>name</Label>
              <P>
                <input
                  type="text"
                  name="name"
                  value={input.name}
                  onChange={onChangeInput}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Receiver</Label>
              <P>
                <input
                  type="text"
                  name="receiver"
                  value={input.receiver}
                  onChange={onChangeInput}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Country</Label>
              <P>
                <Select<CountryOption>
                  options={Object.entries(CODE_COUNTRY_ITEMS).map(
                    ([key, value]) => ({
                      value: Number(key),
                      label: value as string,
                    })
                  )}
                  value={{
                    value: input.country,
                    label: CODE_COUNTRY_ITEMS[input.country] as string,
                  }}
                  onChange={handleCountrySelect}
                  isSearchable
                />
              </P>
            </Col>
            <Col>
              <Label>State</Label>
              <P>
                <input
                  type="text"
                  name="state"
                  value={input.state}
                  onChange={onChangeInput}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>City</Label>
              <P>
                <input
                  type="text"
                  name="city"
                  value={input.city}
                  onChange={onChangeInput}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Zip Code</Label>
              <P>
                <input
                  type="text"
                  name="zip"
                  value={input.zip}
                  onChange={onChangeInput}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Address Option1</Label>
              <P>
                <input
                  type="text"
                  name="option1"
                  value={input.option1}
                  onChange={onChangeInput}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Address Option2</Label>
              <P>
                <input
                  type="text"
                  name="option2"
                  value={input.option2}
                  onChange={onChangeInput}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Address Option3</Label>
              <P>
                <input
                  type="text"
                  name="option3"
                  value={input.option3}
                  onChange={onChangeInput}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Phone</Label>
              <P>
                <input
                  type="text"
                  name="phone"
                  value={input.phone}
                  onChange={onChangeInput}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Email</Label>
              <P>
                <input
                  type="text"
                  name="email"
                  value={input.email}
                  onChange={onChangeInput}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
          </MEditSection>
          <MSaveButton onClick={saveUserInfo}>
            <Icon icon="check-circle" color="white" fontSize="18px" />
            <P>Save</P>
          </MSaveButton>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Col>
              <Label>New name *</Label>
              <P>
                <input
                  type="text"
                  name="name"
                  value={newAddress.name}
                  onChange={handleNewAddressChange}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Receiver *</Label>
              <P>
                <input
                  type="text"
                  name="receiver"
                  value={newAddress.receiver}
                  onChange={handleNewAddressChange}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Country *</Label>
              <P>
                <Select<CountryOption>
                  options={Object.entries(CODE_COUNTRY_ITEMS).map(
                    ([key, value]) => ({
                      value: Number(key),
                      label: value as string,
                    })
                  )}
                  value={{
                    value: newAddress.country,
                    label: CODE_COUNTRY_ITEMS[newAddress.country] as string,
                  }}
                  onChange={handleNewCountrySelect}
                  isSearchable
                />
              </P>
            </Col>
            <Col>
              <Label>State *</Label>
              <P>
                <input
                  type="text"
                  name="state"
                  value={newAddress.state}
                  onChange={handleNewAddressChange}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>City *</Label>
              <P>
                <input
                  type="text"
                  name="city"
                  value={newAddress.city}
                  onChange={handleNewAddressChange}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Zip Code *</Label>
              <P>
                <input
                  type="text"
                  name="zip"
                  value={newAddress.zip}
                  onChange={handleNewAddressChange}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Address Option1 *</Label>
              <P>
                <input
                  type="text"
                  name="option1"
                  value={newAddress.option1}
                  onChange={handleNewAddressChange}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Address Option2</Label>
              <P>
                <input
                  type="text"
                  name="option2"
                  value={newAddress.option2}
                  onChange={handleNewAddressChange}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Address Option3</Label>
              <P>
                <input
                  type="text"
                  name="option3"
                  value={newAddress.option3}
                  onChange={handleNewAddressChange}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Phone *</Label>
              <P>
                <input
                  type="text"
                  name="phone"
                  value={newAddress.phone}
                  onChange={handleNewAddressChange}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
            <Col>
              <Label>Email *</Label>
              <P>
                <input
                  type="text"
                  name="email"
                  value={newAddress.email}
                  onChange={handleNewAddressChange}
                  style={{ width: "100%" }}
                />
              </P>
            </Col>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddNewAddress}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const UnusedDot = styled.div`
  position: absolute;
  top: 0%;
  right: 0%;
  color: var(--color-main-red);
`;

const MyAddressButton = styled(MButton)`
  border: 2px solid var(--color-main-blue);
  color: var(--color-main-blue);
  &:hover {
    background-color: var(--color-lightgray);
  }
`;

const AddressAddButton = styled(MButton)`
  background-color: var(--color-main-blue);
  color: var(--color-white);
  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;

const MSaveButton = styled.div`
  background-color: var(--color-main-blue);
  border-radius: 30px;
  box-shadow: 0px 4px 10px #00000023;
  display: flex;
  justify-content: center;
  margin-top: 1.4rem;
  align-items: center;
  color: white;
  padding: 3px 8px 3px 8px;
  transition-duration: 0.3s;
  cursor: pointer;
  & > p {
    margin-left: 0.4rem;
    padding: 0;
  }
`;
const MEditSection = styled.div`
  height: auto;
  & > table > tr {
    margin-bottom: 1rem;
  }
`;

const ReviewListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.4rem;
`;
const ReviewItem = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.4rem 0;
  min-height: 6rem;
  max-height: 6rem;
`;
const MReviewItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  min-height: 6rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid lightgray;
`;
const PhotoContainer = styled.div`
  min-width: 6rem;
  max-width: 6rem;
  min-height: 6rem;
  max-height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1.4rem 0 0;
  & > img {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
`;
const MPhotoContainer = styled.div`
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 1rem 0;
  & > img {
    width: -webkit-fill-available;
    display: block;
  }
`;
const ReviewContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.2rem 0;
`;
const ReviewStars = styled.div``;
const ReviewComment = styled.div`
  width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const MReviewConmment = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Main = styled.div`
  padding: 30px;
  display: flex;
  gap: 30px;

  @media (max-width: 1279px) {
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
  @media (max-width: 575px) {
    flex-wrap: wrap;
  }

  .settings-profile {
    box-shadow: 0px 4px 10px #00000012;
    padding: 30px;
    width: 25%;

    @media (max-width: 1279px) {
      width: 100%;
    }

    .settings-profile-detail {
      position: relative;
      margin-top: 30px;
      ul {
        padding: 0;
        width: 100%;
        border-bottom: 1px solid #eee;
        display: flex;
        gap: 10px;
        flex-direction: row; /* Align items in a row */
        flex-wrap: wrap; /* Allow wrapping on smaller screens */

        li {
          padding: 15px 20px; /* Increase padding */
          border-bottom: 2px solid transparent;
          transition-duration: 0.3s;
          cursor: pointer;
          font-size: 1.2rem; /* Increase font size */
          list-style: none; /* Remove default list styling */
        }

        @media (max-width: 767px) {
          li {
            font-size: 1rem; /* Adjust font size for smaller screens */
            padding: 10px 15px; /* Adjust padding for smaller screens */
          }
        }

        .selected {
          border-bottom: 2px solid var(--color-main-blue);
        }
      }
    }

    .settings-profile-item {
      padding: 5px;
      position: relative;
      padding-bottom: 30px;
      overflow-y: auto;

      th {
        padding: 5px;
        padding-bottom: 13px;
        overflow-y: auto;
      }
    }

    .settings-profile-add {
      position: absolute;
      bottom: 15px;
      right: 30px;
      background-color: var(--color-main-blue);
      border-radius: 30px;
      box-shadow: 0px 4px 10px #00000023;
      display: flex;
      align-items: center;
      color: white;
      padding: 3px 8px 3px 8px;
      transition-duration: 0.3s;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 4px 10px #00000045;
      }

      i {
        margin: 2px 10px 0 2px;
      }

      p {
        margin: 2px 4px 4px 0;
      }
    }
  }

  .settings-side {
    box-shadow: 0px 4px 10px #00000012;
    padding: 30px;
    width: 80%;
    @media (max-width: 1279px) {
      width: 100%;
    }
  }

  .settings-profile-side {
    box-shadow: 0px 4px 10px #00000012;
    padding: 30px;
    width: 100%;

    @media (max-width: 1279px) {
      width: 100%;
    }

    .settings-profile-detail-side {
      position: relative;
      margin-top: 30px;
      ul {
        padding: 0;
        width: 100%;
        border-bottom: 1px solid #eee;
        display: flex;
        gap: 10px;
        flex-direction: row; /* Align items in a row */
        flex-wrap: wrap; /* Allow wrapping on smaller screens */

        li {
          padding: 15px 20px; /* Increase padding */
          border-bottom: 2px solid transparent;
          transition-duration: 0.3s;
          cursor: pointer;
          font-size: 1.2rem; /* Increase font size */
          list-style: none; /* Remove default list styling */
        }

        @media (max-width: 767px) {
          li {
            font-size: 1rem; /* Adjust font size for smaller screens */
            padding: 10px 15px; /* Adjust padding for smaller screens */
          }
        }

        .selected {
          border-bottom: 2px solid var(--color-main-blue);
        }
      }
    }
  }
`;

const MenuList = styled.ul`
  padding: 0;
  width: 100%;
  border-bottom: 1px solid #eee;
  display: flex;
  gap: 10px;
  flex-direction: column;

  li {
    padding: 15px; /* Increase padding */
    border-bottom: 2px solid transparent;
    transition-duration: 0.3s;
    cursor: pointer;
    font-size: 1.5rem; /* Increase font size */
  }

  @media (max-width: 767px) {
    li {
      font-size: 1.2rem; /* Adjust font size for smaller screens */
      padding: 10px; /* Adjust padding for smaller screens */
    }
  }

  .selected {
    border-bottom: 2px solid var(--color-main-blue);
  }
`;

const StyledTab = styled(Tab)`
  font-size: 1.2rem; // Increase font size
  padding: 15px 20px; // Increase padding for larger tabs
  &.nav-link {
    border-bottom: 2px solid transparent;
    transition-duration: 0.3s;
    cursor: pointer;
  }
  &.nav-link.active {
    border-bottom: 2px solid var(--color-main-blue);
  }
`;

const MobileWrapper = styled.div`
  margin: 0;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1.1rem;
  margin: 0.4rem 0 0.4rem 0;
  &.selected {
    color: var(--color-main-blue);
  }

  &:hover {
    color: var(--color-main-blue);
  }
`;
const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;

const LoadingSection = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > img {
    width: 100%;
  }
`;

const CouponListWrapper = styled.div`
  padding: 1rem;
`;

const LargeReviewItem = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.8rem 0;
  min-height: 8rem;
  max-height: 8rem;
  padding: 1rem;
  border: 1px solid lightgray;
  border-radius: 8px;
`;

const LargePhotoContainer = styled.div`
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
`;

const LargeReviewContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.2rem 0;
  flex: 1;
`;

const LargeReviewStars = styled.div`
  display: flex;
  gap: 0.2rem;
`;

const LargeReviewComment = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
`;

const StyledButton = styled(Button)<ButtonProps>`
  flex: 1 1 45%;
  min-width: 150px;
  max-width: 45%;
`;

const StyledComponents = styled.div`
  .coupon-list,
  .point-history {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .coupon-item,
  .point-item {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .coupon-item div,
  .point-item div {
    margin-bottom: 0.5rem;
  }

  .coupon-item strong,
  .point-item strong {
    display: inline-block;
    width: 120px;
  }

  @media (min-width: 768px) {
    .coupon-list,
    .point-history {
      display: block;
    }

    .coupon-item,
    .point-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;
    }

    .coupon-item div,
    .point-item div {
      margin-bottom: 0;
      flex: 1;
      text-align: center;
    }

    .coupon-item strong,
    .point-item strong {
      display: block;
      width: auto;
    }
  }
`;

const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
