import React, { useEffect, useState } from "react";
import { Pagination, Spinner, Table } from "react-bootstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  ADMIN_BADGE,
  CODE_SHOPING_ORDER_STATUS,
} from "../../../common/constants";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import FancallModal from "../../../components/modal/FancallModal";
import { reqReadAdminBadge } from "../../../requests/badge";
import {
  reqAShopByStatus,
  reqDropAllTransaction,
  reqShop,
  reqShopUpdate,
} from "../../../requests/shop";

type OrderData = {
  orderId: string;
  userId: string;
  createdAt: string;
  PaymentMethod: number;
  preorder: boolean;
  fancall: boolean;
  status: number;
  user: {
    name: string;
    membershipId: number;
  };
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const LoadingText = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin: 0;
`;

const AShopOrder: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<OrderData | null>(null);
  const [orders, setOrders] = useState<OrderData[]>([]);
  const [isResettingStatus, setIsResettingStatus] = useState(false);

  const handleOrderClick = async (order: OrderData) => {
    // 해당 주문이 이미 트랜잭션 상태인지 확인
    try {
      const currentStatusResponse = await reqShop({ order_id: order.orderId });
      const currentOrder = currentStatusResponse.data;

      // 이미 다른 관리자가 확인 중인 경우
      if (
        currentOrder.status ===
          CODE_SHOPING_ORDER_STATUS.TRANSACTION_OF_1_BETWEEN_2 ||
        currentOrder.status === CODE_SHOPING_ORDER_STATUS.WAIT_PAY
      ) {
        Swal.fire({
          title: "주문 처리 중",
          text: "이미 다른 관리자가 이 주문을 확인하고 있습니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return;
      }

      // 정상적으로 처리 계속
      setCurrentOrder(order);
      setShowModal(true);
      checkToTransaction(order.orderId);
    } catch (error) {
      console.error("Error checking order status:", error);
      Swal.fire({
        title: "오류",
        text: "주문 상태를 확인하는 중 오류가 발생했습니다.",
        icon: "error",
        confirmButtonText: "확인",
      });
    }
  };

  const handleOrderClickOff = async () => {
    setShowModal(false);

    if (currentOrder?.orderId) {
      try {
        // 현재 주문 상태 확인
        const response = await reqShop({ order_id: currentOrder.orderId });
        const currentStatus = response.data.status;

        // WAIT_PAY 상태(2)면 상태를 변경하지 않음
        if (currentStatus === CODE_SHOPING_ORDER_STATUS.WAIT_PAY) {
          console.log(
            `주문이 이미 결제 대기 상태(${currentStatus})이므로 상태를 변경하지 않습니다.`
          );
          // 주문 목록 새로고침
          fetchOrders();
        }
        // CANCELED 상태(3)면 상태를 변경하지 않음
        else if (currentStatus === CODE_SHOPING_ORDER_STATUS.CANCELED) {
          console.log(
            `주문이 이미 취소 상태(${currentStatus})이므로 상태를 변경하지 않습니다.`
          );
          // 주문 목록 새로고침
          fetchOrders();
        }
        // 그 외 상태일 경우 ORDER_CREATED로 변경
        else {
          await dropToTransaction();
        }
      } catch (error) {
        console.error("주문 상태 확인 중 오류 발생:", error);
        // 오류 발생 시 기본 동작으로 상태 초기화
        await dropToTransaction();
      }
    }
  };

  const checkToTransaction = async (orderId: string) => {
    await reqShopUpdate({
      order_id: orderId,
      status: CODE_SHOPING_ORDER_STATUS.TRANSACTION_OF_1_BETWEEN_2,
    });
  };

  const dropToTransaction = async () => {
    if (currentOrder?.orderId) {
      setIsResettingStatus(true);
      try {
        await reqShopUpdate({
          order_id: currentOrder.orderId,
          status: CODE_SHOPING_ORDER_STATUS.ORDER_CREATED,
        });
        console.log(
          `주문 ${currentOrder.orderId}의 상태가 1(ORDER_CREATED)로 초기화되었습니다.`
        );
        // 주문 상태 변경 후 목록 새로고침
        fetchOrders();
      } catch (error) {
        console.error("주문 상태 초기화 중 오류 발생:", error);
        Swal.fire({
          title: "상태 초기화 오류",
          text: "주문 상태를 초기화하는 중 오류가 발생했습니다.",
          icon: "error",
          confirmButtonText: "확인",
        });
      } finally {
        setIsResettingStatus(false);
      }
    }
  };

  const refreshData = () => {
    fetchOrders();
  };

  const fetchOrders = async () => {
    try {
      setIsLoading(true);
      // 페이지 강제 이탈로 인한 트랜잭션 drop은 이곳에서 제거
      // 개별 주문 상태를 확인/변경하는 방식으로 변경

      const response = await reqAShopByStatus(
        CODE_SHOPING_ORDER_STATUS.ORDER_CREATED
      );
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // 컴포넌트 언마운트 시에만 트랜잭션을 drop하도록 useEffect 추가
  useEffect(() => {
    fetchOrders();
    const badgePayload = {
      badgeName: ADMIN_BADGE.SHOP_REQUEST_FORM,
    };
    reqReadAdminBadge(badgePayload);

    // 페이지 이탈 시 cleanup 함수
    return () => {
      // 다른 페이지로 이동할 때만 모든 트랜잭션 초기화
      reqDropAllTransaction();
    };
  }, []);

  const getMembershipColor = (membershipId: number) => {
    switch (membershipId) {
      case 1:
        return "RED";
      case 2:
        return "BLUE";
      case 3:
        return "GREEN";
      default:
        return "알 수 없음";
    }
  };

  const handleReopenModal = () => {
    setShowModal(true);
  };

  //! 필터 시작
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [searchWord, setSearchWord] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const thisFilteredData = orders
      .filter((order: any) =>
        order.orderId.toUpperCase().includes(searchWord.toUpperCase())
      )
      .sort(
        (a: any, b: any) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    // const thisFilteredData = orders.filter(
    //   (order) =>
    //     order.orderId.toLowerCase().includes(searchWord.toLowerCase()) &&
    //     (selectedStatus ? order.status.toString() === selectedStatus : true)
    // );
    setFilteredData(thisFilteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredData.length / itemsPerPage));
  }, [orders, searchWord, itemsPerPage, selectedStatus]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  //! 필터 끝
  // 한국 시간
  const formatDateKorean = (dateString: string) => {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 9);
    return date.toISOString().slice(0, 16).replace("T", " ");
  };
  return (
    <main id="main" className="main">
      <AdminHeadTitle
        subtitle1="구매대행"
        subtitle2="구매대행 요청서"
        title="구매대행 요청서"
      />

      {/* 옵션 필터 */}
      <AdminHeadFilter
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
        selectSearchOption={(value: string) => setSearchOption(value)}
      />

      {isLoading ? (
        <LoadingContainer>
          <Spinner animation="border" variant="primary" />
          <LoadingText>데이터를 불러오는 중입니다...</LoadingText>
        </LoadingContainer>
      ) : (
        <>
          {displayedOrders.length === 0 ? (
            <LoadingContainer>
              <i
                className="bi bi-exclamation-circle"
                style={{ fontSize: "3rem", color: "#6c757d" }}
              ></i>
              <LoadingText>데이터가 없습니다</LoadingText>
            </LoadingContainer>
          ) : (
            <>
              <div className="table-responsive">
                <Table className="table-custom text-center">
                  <thead className="table-light">
                    <tr>
                      <th>주문번호</th>
                      <th>주문날자</th>
                      <th>스페이스코드</th>
                      <th>이름</th>
                      <th>멤버십</th>
                      <th>특이사항</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedOrders.map((order) => (
                      <tr
                        key={order.orderId}
                        onClick={() => handleOrderClick(order)}
                        style={{ cursor: "pointer" }}
                      >
                        <th>{order.orderId}</th>
                        <td>{formatDateKorean(order.createdAt)}</td>
                        <td>{order.userId}</td>
                        <td>{order.user.name}</td>
                        <td>
                          <span
                            style={{
                              color: getMembershipColor(
                                order.user.membershipId
                              ),
                            }}
                          >
                            {getMembershipColor(order.user.membershipId)}
                          </span>
                        </td>
                        <td>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={order.preorder}
                              readOnly
                            />
                            <label className="form-check-label">Preorder</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={order.fancall}
                              readOnly
                            />
                            <label className="form-check-label">Fancall</label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              {/* 페이지 바 */}
              <CenteredPagination style={{ marginTop: "1rem" }}>
                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </CenteredPagination>
            </>
          )}
        </>
      )}

      {isResettingStatus && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: 9999 }}
        >
          <div className="p-4 bg-white rounded shadow-lg text-center">
            <Spinner animation="border" variant="warning" />
            <p className="mt-2">주문 상태를 초기화하는 중입니다...</p>
          </div>
        </div>
      )}

      {currentOrder && (
        <FancallModal
          show={showModal}
          onHide={() => handleOrderClickOff()}
          onReopen={handleReopenModal}
          orderId={currentOrder.orderId}
          refreshData={refreshData}
        />
      )}
    </main>
  );
};

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

export default AShopOrder;
