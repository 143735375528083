import { format, parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import JsBarcode from "jsbarcode";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import Icon from "../../../components/Icon";
import {
  reqAShipS,
  reqfilterByDateRange,
  reqfilterByTracking,
} from "../../../requests/ship";

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const LoadingText = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin: 0;
`;

interface ShipRecord {
  id: string;
  tracking: string;
  userId: string;
  warehouseId: string;
  createdAt: string;
  user: {
    name: string;
    membershipId: number;
  };
}

const ResponsiveContainer = styled.div`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-x: auto;

  table {
    width: 100%;
    min-width: 800px;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      text-align: center;
      border: 1px solid #ddd;
      white-space: nowrap;

      @media (max-width: 768px) {
        padding: 4px;
        font-size: 14px;
      }
    }

    th {
      background-color: #f2f2f2;
      position: sticky;
      top: 0;
      z-index: 10;
    }

    td {
      button {
        white-space: nowrap;

        @media (max-width: 768px) {
          padding: 4px 8px;
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FilterItem = styled.div`
  flex: 1;
  min-width: 200px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PaginationControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    margin: 0 5px;

    @media (max-width: 768px) {
      padding: 5px 10px;
      font-size: 14px;
    }
  }
`;

const PrintButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const Spacer = styled.div`
  height: 20px; /* 원하는 간격 크기로 설정 */
`;

const Box = styled.div`
  display: flex;
  border: 1px solid lightgray;
  border-radius: 0.4rem;
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 5px;
  .input-reset {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.4rem;
    width: 100%;
    border: none;
    padding: 0.4rem 0.8rem;
  }
  #search-opt-dropdown {
    position: relative;
    flex: 1 1 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid lightgray;
  }
  #search-opt-input {
    flex: 1 1 0;
    margin-left: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  #search-input {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1.4;
    margin-left: 1px;
    border-radius: 4px;
  }
`;
const Input = styled.input`
  flex-grow: 2;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
`;
const SearchButton = styled.div`
  border: 1px solid var(--color-main-blue);
  padding: 0.4rem 1rem;
  align-items: center;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  cursor: pointer;
`;

export default function AWHistory() {
  const [isLoading, setIsLoading] = useState(true);
  const [shipRecords, setShipRecords] = useState<ShipRecord[]>([]);
  const [originalRecords, setOriginalRecords] = useState<ShipRecord[]>([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("23:59");
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTracking, setSearchTracking] = useState("");

  useEffect(() => {
    const fetchShipRecords = async () => {
      try {
        setIsLoading(true);
        const response = await reqAShipS(page, limit);
        const { data, total } = response.data;
        setShipRecords(data);
        setOriginalRecords(data);
        setTotalRecords(total);
      } catch (error) {
        console.error("입고기록 조회 실패:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchShipRecords();
  }, [page, limit]);

  useEffect(() => {
    const fetchFilteredRecords = async () => {
      try {
        if (searchTracking) {
          const response = await reqfilterByTracking(searchTracking);
          const { data } = response.data;
          setShipRecords(data);
        } else {
          setShipRecords(originalRecords);
        }
      } catch (error) {
        console.error("입고기록 조회 실패:", error);
      }
    };

    fetchFilteredRecords();
  }, [searchTracking, originalRecords]);

  const handleSelectAllChange = (isChecked: boolean) => {
    setSelectAll(isChecked);
    setSelectedRecords(isChecked ? shipRecords.map((record) => record.id) : []);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLimit(Number(event.target.value));
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSearch = async () => {
    if (startDate && endDate) {
      const startDateTime = zonedTimeToUtc(
        `${startDate}T${startTime}:00`,
        "Asia/Seoul"
      );
      const endDateTime = zonedTimeToUtc(
        `${endDate}T${endTime}:00`,
        "Asia/Seoul"
      );

      const response = await reqfilterByDateRange({
        startDate: startDateTime.toISOString().split("T")[0],
        endDate: endDateTime.toISOString().split("T")[0],
        startTime: startDateTime.toISOString().split("T")[1].substring(0, 5),
        endTime: endDateTime.toISOString().split("T")[1].substring(0, 5),
      });
      const { data } = response.data;
      setShipRecords(data);
    } else {
      setShipRecords(originalRecords);
    }
  };

  const handlePrintSelected = () => {
    const selectedRecordsToPrint = shipRecords.filter((record) =>
      selectedRecords.includes(record.id)
    );

    const printWindow = window.open("", "", "width=800,height=600");
    if (!printWindow) {
      console.error(
        "Failed to open the print window. Check your browser popup settings."
      );
      return;
    }

    printWindow.document.write("<html><head><title>Print</title><style>");
    printWindow.document.write("@page { size: 8cm 6cm; margin: 0; }");
    printWindow.document.write(
      "body { margin: 0; display: flex; flex-direction: column; align-items: center; }"
    );
    printWindow.document.write(
      ".page { display: flex; justify-content: center; align-items: center; width: 8cm; height: 6cm; page-break-after: always; }"
    );
    printWindow.document.write(
      ".print-container { width: 8cm; height: 6cm; display: flex; flex-direction: column; justify-content: center; align-items: center; border: 1px solid #000; padding: 10px; box-sizing: border-box; }"
    );
    printWindow.document.write(
      "svg { width: 6cm; height: 2cm; margin-bottom: 10px; }"
    );
    printWindow.document.write(
      "p { margin: 0; padding: 2px 0; text-align: center; font-size: 1.2em; }"
    );
    printWindow.document.write("</style></head><body>");

    selectedRecordsToPrint.forEach((record) => {
      const svgBarcode = `<svg id="barcode-${record.id}"></svg>`;
      const lastEightChars = record.tracking.slice(-8);
      const firstFourChars = lastEightChars.slice(0, 4);
      const secondFourChars = lastEightChars.slice(4, 8);

      printWindow.document.write('<div class="page">');
      printWindow.document.write('<div class="print-container">');
      printWindow.document.write(svgBarcode);
      printWindow.document.write(
        `<p style="font-size: 1.5em; font-weight: bold;">${record.tracking}</p>`
      );
      printWindow.document.write(
        `<p style="font-size: 1em; font-weight: bold;">${record.userId} / ${
          record.user ? record.user.name : "Unknown User"
        }</p>`
      );
      printWindow.document.write(
        `<p style="font-size: 1em; font-weight: bold;">${record.warehouseId} / ${firstFourChars}-${secondFourChars}</p>`
      );
      printWindow.document.write("</div>");
      printWindow.document.write("</div>");
    });

    printWindow.document.write("</body></html>");
    printWindow.document.close();

    // 바코드 생성
    selectedRecordsToPrint.forEach((record) => {
      const barcodeElement = printWindow.document.getElementById(
        `barcode-${record.id}`
      );
      JsBarcode(barcodeElement, record.tracking, {
        format: "CODE128",
        displayValue: false,
        width: 1,
        height: 40,
        margin: 0,
      });
    });

    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const toggleRecordSelection = (id: string, isChecked: boolean) => {
    setSelectedRecords((prev) =>
      isChecked ? [...prev, id] : prev.filter((recordId) => recordId !== id)
    );
  };

  return (
    <>
      <ResponsiveContainer>
        <main id="main" className="main">
          <AdminHeadTitle
            subtitle1="창고 관리"
            subtitle2="입고 기록 라벨 출력"
            title="입고 기록 라벨 출력"
          />

          <FilterContainer>
            <FilterItem>
              <select
                className="form-select"
                aria-label="Default select example"
                value={limit}
                onChange={handleLimitChange}
              >
                <option value="10">10개씩 보기</option>
                <option value="50">50개씩 보기</option>
                <option value="100">100개씩 보기</option>
                <option value="999999">전체보기</option>
              </select>
            </FilterItem>
            <FilterItem>
              <div id="search-input" className="d-flex">
                <Input
                  type="text"
                  className="input-reset"
                  placeholder="Tracking Number"
                  value={searchTracking}
                  onChange={(e: any) =>
                    setSearchTracking(e.target.value.toUpperCase())
                  }
                />
                <SearchButton>
                  <Icon icon="search" color="var(--color-main-blue)" />
                </SearchButton>
              </div>
            </FilterItem>
          </FilterContainer>

          <FilterContainer>
            <FilterItem>
              <input
                type="date"
                className="form-control"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FilterItem>
            <FilterItem>
              <input
                type="time"
                className="form-control"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </FilterItem>
            <FilterItem>
              <input
                type="date"
                className="form-control"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FilterItem>
            <FilterItem>
              <input
                type="time"
                className="form-control"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </FilterItem>
            <FilterItem>
              <button className="btn btn-primary w-100" onClick={handleSearch}>
                검색
              </button>
            </FilterItem>
          </FilterContainer>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <PrintButton onClick={handlePrintSelected}>출력</PrintButton>
          </div>

          {isLoading ? (
            <LoadingContainer>
              <Spinner animation="border" variant="primary" />
              <LoadingText>데이터를 불러오는 중입니다...</LoadingText>
            </LoadingContainer>
          ) : (
            <TableContainer>
              <table className="table table-hover text-center">
                <thead>
                  <tr>
                    <th scope="col">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={(e) =>
                          handleSelectAllChange(e.target.checked)
                        }
                      />
                    </th>
                    <th>NO</th>
                    <th scope="col">송장번호</th>
                    <th scope="col">스페이스코드</th>
                    <th scope="col">이름</th>
                    <th scope="col">저장위치</th>
                    <th scope="col">입고시간</th>
                  </tr>
                </thead>
                <tbody>
                  {shipRecords.map((record, index) => (
                    <tr
                      key={record.id}
                      onClick={() =>
                        toggleRecordSelection(
                          record.id,
                          !selectedRecords.includes(record.id)
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <th scope="row">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            toggleRecordSelection(record.id, e.target.checked)
                          }
                          checked={selectedRecords.includes(record.id)}
                          onClick={(e) => e.stopPropagation()} // Prevent the row click event from triggering
                        />
                      </th>
                      <td>{index + 1}</td>
                      <td>{record.tracking}</td>
                      <td>{record.userId}</td>
                      <td>{record.user ? record.user.name : "Unknown User"}</td>
                      <td>{record.warehouseId}</td>
                      <td>
                        {record.createdAt
                          ? format(
                              parseISO(record.createdAt),
                              "yyyy-MM-dd HH:mm:ss"
                            )
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
          )}

          <PaginationControlsContainer>
            {(shipRecords?.length || 0) > 0 && (
              <div>
                <button
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page <= 1}
                  className="btn btn-secondary me-2"
                >
                  이전
                </button>
                <span className="mx-2">
                  페이지 {page} / {Math.ceil(totalRecords / limit) || 1}
                </span>
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page >= (Math.ceil(totalRecords / limit) || 1)}
                  className="btn btn-secondary ms-2"
                >
                  다음
                </button>
              </div>
            )}
          </PaginationControlsContainer>
        </main>
      </ResponsiveContainer>
    </>
  );
}
