import { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  FormCheck,
  FormControl,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  ADMIN_BADGE,
  CODE_COUNTRY_ITEMS,
  CODE_SHOPPING_ORDER_ITEMS,
  DATA_TYPE,
} from "../../common/constants";
import { MBody, MButton, MFooter, MRow } from "../../common/mobile-styled";
import { modalClose, modalRefresh } from "../../redux/modalSlice";
import { RootUserState } from "../../redux/store";
import { reqAddAdminBadge } from "../../requests/badge";
import { reqShipFEE, reqShipSendOrder } from "../../requests/ship";
import { reqGetUserById } from "../../requests/user";
import FormatData from "../FormatData";
import Icon from "../Icon";

interface Address {
  name: string;
  receiver: string;
  email: string;
  phone: string;
  city: string;
  country: number;
  state: string;
  zip: string;
  option1: string;
  option2: string;
  option3: string;
}

export default function ModalShipRequest() {
  const dispatch = useDispatch();
  const [step, setStep] = useState<number>(0);
  const modal = useSelector((state: any) => state.modal);
  const user = useSelector((state: RootUserState) => state.user);
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const [selectedUrgent, setSelectedUrgent] = useState<number[]>([]);
  const [selectedVacuum, setSelectedVacuum] = useState<number[]>([]);
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const [options, setOptions] = useState<{
    box: number;
    letter: boolean;
    poster: boolean;
    aircap: boolean;
    sticker: boolean;
    bag: boolean;
  }>({
    box: 0,
    letter: false,
    poster: false,
    aircap: false,
    sticker: false,
    bag: false,
  });
  const [message, setMessage] = useState<string>();
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [address, setAddress] = useState<{
    receiver: string;
    email: string;
    phone: string;
    country: number;
    pccc: string;
    zip: string;
    state: string;
    city: string;
    address1: string;
    address2: string;
    address3: string;
    userId: string;
  }>({
    receiver: "",
    email: "",
    phone: "",
    country: 0,
    pccc: "",
    zip: "",
    state: "",
    city: "",
    address1: "",
    address2: "",
    address3: "",
    userId: user.id,
  });

  useEffect(() => {
    const modalElement = document.getElementById("modal-box");
    if (modalElement) {
      modalElement.scrollTop = 0;
    }
  }, [step]);

  useEffect(() => {
    validateForm();
  }, [address]);

  const noAccentRegex = /^[\x00-\x7F]*$/;

  const isPCCCRequired = (countryCode: number) => {
    return [119, 195, 26].includes(countryCode); // Mexico, Vietnam, Brazil
  };

  const validateForm = () => {
    const requiredFieldsFilled =
      address.receiver &&
      address.email &&
      address.phone &&
      address.country &&
      address.city &&
      address.state &&
      address.zip &&
      address.address1 &&
      (address.receiver?.length ?? 0) <= 35 &&
      noAccentRegex.test(address.receiver || "") &&
      (address.address1?.length ?? 0) <= 35 &&
      noAccentRegex.test(address.address1 || "") &&
      (address.address2?.length ?? 0) <= 35 &&
      noAccentRegex.test(address.address2 || "") &&
      (address.address3?.length ?? 0) <= 35 &&
      noAccentRegex.test(address.address3 || "") &&
      (!isPCCCRequired(address.country) ||
        (isPCCCRequired(address.country) && address.pccc));

    setIsValid(!!requiredFieldsFilled);
  };

  const handleInputChange = (field: string, value: string) => {
    if (value.length <= 35 && noAccentRegex.test(value)) {
      setAddress((prevAddress) => ({
        ...prevAddress,
        [field]: value,
      }));
    }
  };

  const isSubmitDisabled = () => {
    if (step !== 2) return false;

    return !isValid || isSubmitting;
  };

  useEffect(() => {
    // fetchExchangeRate();
    fetchFixedExchangeRate();
  }, []);

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);
  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (
        response &&
        response.data &&
        response.data[0]?.fixedExchangeRateShop
      ) {
        setFixedExchangeRate(
          parseFloat(response.data[0].fixedExchangeRateShop)
        );
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  const handleCountrySelect = (countryId: any) => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      country: countryId,
    }));

    if ([119, 195, 26].includes(countryId)) {
      if (isMobile) {
        setTimeout(() => {
          alert("Personal Clearance Number is required for this country.");
        }, 100);
      } else {
        Swal.fire({
          title: "Notice",
          text: "Personal Clearance Number is required for this country.",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const filteredCountries = Object.entries(CODE_COUNTRY_ITEMS).filter(
    ([id, name]) => {
      if (Array.isArray(name)) {
        return name.some((n) =>
          n.toLowerCase().includes(countrySearchTerm.toLowerCase())
        );
      } else {
        return name.toLowerCase().includes(countrySearchTerm.toLowerCase());
      }
    }
  );

  const verifySubmission = () => {
    let [isOK, message] = [false, ""];
    const emailRegex = /^[^\s@]+@[^\s@]/;
    if (!address.receiver) {
      message = "Receiver is required.";
    } else if (!address.email) {
      message = "Email is required.";
    } else if (!emailRegex.test(address.email)) {
      message = "Invalid email format.";
    } else if (!address.phone) {
      message = "Phone number is required.";
    } else if (!address.address1) {
      message = "Address Line is required.";
    } else if (!address.state) {
      message = "State is required.";
    } else if (!address.city) {
      message = "City is required.";
    } else if (address.country === 0) {
      message = "Country is required.";
    } else if (!address.zip) {
      message = "ZIP code is required.";
    } else if (isPCCCRequired(address.country) && !address.pccc) {
      message = "Personal Clearance Number is required for this country.";
    } else {
      isOK = true;
    }
    return { isOK, message };
  };

  const convertToKRW = (usd: number) => {
    return Math.floor(usd * 1000).toLocaleString();
  };

  const convertToUSD = (krw: number) => {
    return (krw / fixedExchangeRate).toFixed(2);
  };

  const [addresses, setAddresses] = useState<Address[]>([]);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<
    number | null
  >(null);

  const loadAddresses = async () => {
    try {
      const res = await reqGetUserById({ id: user.id });
      setAddresses(res.data.address || []);
    } catch (error) {
      console.error("Error fetching addresses:", error);
      setAddresses([]);
    }
  };

  useEffect(() => {
    loadAddresses();
  }, []);

  const handleSelectAddress = async (eventKey: string | null) => {
    const index = Number(eventKey);
    if (index >= 0 && index < addresses.length) {
      const selected = addresses[index];
      setAddress({
        receiver: selected.receiver || "",
        email: selected.email || "", // 여기서 기본값 설정
        phone: selected.phone || "",
        city: selected.city || "",
        country: selected.country || 0,
        state: selected.state || "",
        zip: selected.zip || "",
        address1: selected.option1 || "",
        address2: selected.option2 || "",
        address3: selected.option3 || "",
        pccc: address.pccc,
        userId: user.id,
      });
      setSelectedAddressIndex(index);

      if (isMobile) {
        console.log("Mobile device detected, attempting to show alert.");
        setTimeout(() => {
          alert(
            "Do not use accents and ensure the address is within 35 characters. The address book can be modified on My Page."
          );
        }, 0);
      } else {
        Swal.fire({
          title: "Important Notice",
          text: "Do not use accents and ensure the address is within 35 characters. The address book can be modified on My Page.",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const resetState = () => {
    setSelectedUrgent([]);
    setSelectedVacuum([]);
    setAddress({
      receiver: "",
      email: "",
      phone: "",
      country: 0,
      pccc: "",
      zip: "",
      state: "",
      city: "",
      address1: "",
      address2: "",
      address3: "",
      userId: user.id,
    });
  };

  // 아코디언
  const [itemOpen, setItemOpen] = useState<any>({});
  const toggleItemOpen = (index: any) => {
    setItemOpen((prev: any) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      const submissionPayload = {
        ...options,
        ...address,
        ids: modal?.payload?.list.map((v: any) => v.id),
        urgentIds: selectedUrgent,
        vacuumIds: selectedVacuum,
      };

      await reqShipSendOrder(submissionPayload);

      const badgePayload = {
        badgeName: ADMIN_BADGE.SHIP_REQUEST_FORM,
      };
      await reqAddAdminBadge(badgePayload);

      dispatch(modalClose());
      dispatch(modalRefresh());

      Swal.fire("Success", "Shipping request sent successfully!", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error submitting shipping request:", error);
      Swal.fire("Error", "Failed to send shipping request", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const calculateTotalValue = () => {
    if (modal?.payload?.list) {
      return modal.payload.list.reduce((total: number, item: any) => {
        return (
          total +
          item.detail.reduce(
            (subtotal: number, product: any) =>
              subtotal + product.price * product.quantity,
            0
          )
        );
      }, 0);
    }
    return 0;
  };

  // 아이콘 말
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const toggleTooltip = () => {
    setTooltipVisible(!tooltipVisible);
  };

  const TooltipText =
    "The declared total value is calculated with the information you input for each package during the enter data process. This total value will be used to declare your final box's content value. Total value is not the shipping fee.";

  return (
    <>
      {isMobile ? (
        <div
          style={{
            borderTop: "1px solid lightgray",
          }}
        >
          <MBody>
            <div>
              {step === 0 && (
                <>
                  {/** product list */}
                  <div className="step-header">Step 1: Request Info</div>
                  <div>
                    {modal?.payload?.list?.map((v: any, index: number) => {
                      const detail = v.detail;
                      const ExteraKRW = fixedExchangeRate
                        ? (v.Extra * fixedExchangeRate).toFixed(2)
                        : "-";
                      const priceUSD = v.price;
                      const priceKRW = fixedExchangeRate
                        ? (v.price * fixedExchangeRate).toFixed(2)
                        : "-";
                      return (
                        <div className="dropdown-box" key={index}>
                          <div
                            className="dropdown-bar"
                            onClick={() => toggleItemOpen(index)}
                          >
                            <div className="dropdown-icon">
                              {itemOpen[index] ? (
                                <Icon icon="caret-down-fill" />
                              ) : (
                                <Icon icon="caret-up" />
                              )}
                            </div>

                            <div>{v.tracking}</div>
                          </div>

                          <div className="dropdown-data">
                            {itemOpen[index] && (
                              <>
                                {detail.map((s: any, idx: number) => {
                                  const priceUSD = s.price;
                                  const priceKRW = fixedExchangeRate
                                    ? Math.floor(s.price * fixedExchangeRate)
                                    : "-";
                                  return (
                                    <>
                                      <MRow>
                                        <div className="label">
                                          Arrival Date
                                        </div>
                                        <div className="data">
                                          {FormatData(
                                            DATA_TYPE.DATE,
                                            v.inboundAt
                                          )}
                                        </div>
                                      </MRow>

                                      <MRow>
                                        <div className="label">
                                          Warehouse Cost
                                        </div>
                                        <div className="data price">
                                          ${v.overstoreFee} / ₩
                                          {convertToKRW(v.overstoreFee)}
                                        </div>
                                      </MRow>

                                      <MRow>
                                        <div className="label">
                                          Extra Charge
                                        </div>
                                        <div className="data price">
                                          ${convertToUSD(v.Extra)} / ₩
                                          {ExteraKRW}
                                        </div>
                                      </MRow>

                                      <MRow>
                                        <div className="label">No.</div>
                                        <div className="data">{idx}</div>
                                      </MRow>

                                      <MRow>
                                        <div className="label">Category</div>
                                        <div className="data">
                                          {CODE_SHOPPING_ORDER_ITEMS[
                                            s.category
                                          ] || "Unknown Category"}
                                        </div>
                                      </MRow>

                                      <MRow>
                                        <div className="label">Name</div>
                                        <div className="data">
                                          {s.name.length > 10
                                            ? s.name.slice(0, 10) + "..."
                                            : s.name}
                                        </div>
                                      </MRow>

                                      <MRow>
                                        <div className="label">Unit Price</div>
                                        <div className="data price">
                                          ${priceUSD.toFixed(2)}
                                          {/* / ₩{priceKRW.toLocaleString("ko-KR")} */}
                                        </div>
                                      </MRow>

                                      <MRow>
                                        <div className="label">Quantity</div>
                                        <div className="data">{s.quantity}</div>
                                      </MRow>
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Declared Total value: {calculateTotalValue().toFixed(2)}{" "}
                      USD
                      <i
                        className="bi bi-question-circle"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          fontSize: "1.2em",
                        }}
                        onClick={toggleTooltip}
                      ></i>
                      {tooltipVisible && (
                        <div style={{ fontSize: "0.8em" }}>{TooltipText}</div>
                      )}
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Total Weight (kg):{" "}
                      {(() => {
                        try {
                          console.log("List data:", modal?.payload?.list);
                          const total = (modal?.payload?.list || []).reduce(
                            (total: number, v: any) => {
                              console.log(
                                "Item weight:",
                                v.totalWeightKg,
                                typeof v.totalWeightKg
                              );
                              const weight = parseFloat(v.totalWeightKg) || 0;
                              return total + weight;
                            },
                            0
                          );
                          console.log("Total weight calculated:", total);
                          return total > 0
                            ? `${total.toFixed(2)} kg`
                            : "No Data";
                        } catch (error) {
                          console.error("Error calculating weight:", error);
                          return "No Data";
                        }
                      })()}
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                    >
                      * In case you choose the 'Quantity doesn't matter' option,
                      total value will be divided.
                    </div>
                  </div>
                </>
              )}

              {step === 1 && (
                <>
                  {/** urgent */}
                  <div>
                    <div className="line">
                      <div className="step-header">Step 2: Product Option</div>
                      <div>
                        Check if it need vaccum packaging
                        {options.box === 0 && <> or urgent service</>}
                      </div>
                    </div>

                    <div>
                      <div
                        className="sub-header"
                        style={{ padding: "1rem 0 0 0" }}
                      >
                        - Product Info
                      </div>
                      {modal?.payload?.list?.map((v: any) => {
                        const detail = v.detail;
                        const priceUSD = v.price;
                        const priceKRW = fixedExchangeRate
                          ? (v.price * fixedExchangeRate).toFixed(2)
                          : "-";
                        return (
                          <div className="line" style={{ padding: "1rem 0" }}>
                            <MRow>
                              <div className="label">Tracking No</div>
                              <div className="data">{v.tracking}</div>
                            </MRow>

                            {detail?.map((s: any, idx: number) => {
                              const priceUSD = s.price;
                              const priceKRW = fixedExchangeRate
                                ? Math.floor(s.price * fixedExchangeRate)
                                : "-";
                              return (
                                <>
                                  <MRow>
                                    <div className="label">No.</div>
                                    <div className="data">{idx}</div>
                                  </MRow>

                                  <MRow>
                                    <div className="label">Category</div>
                                    <div className="data">
                                      {CODE_SHOPPING_ORDER_ITEMS[s.category] ||
                                        "Unknown Category"}
                                    </div>
                                  </MRow>

                                  <MRow>
                                    <div className="label">Name</div>
                                    <div className="data">
                                      {s.name.length > 10
                                        ? s.name.slice(0, 10) + "..."
                                        : s.name}
                                    </div>
                                  </MRow>

                                  <MRow>
                                    <div className="label">Unit Price</div>
                                    <div className="data price">
                                      ${priceUSD}
                                      {/* / ₩{priceKRW.toLocaleString("ko-KR")} */}
                                    </div>
                                  </MRow>

                                  <MRow>
                                    <div className="label">Quantity</div>
                                    <div className="data">{s?.quantity}</div>
                                  </MRow>
                                </>
                              );
                            })}

                            <div style={{ padding: ".5rem 0 0 0" }}>
                              {options.box === 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    textAlign: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginBottom: "0",
                                      marginLeft: "0.1rem",
                                      marginRight: "0.6rem",
                                    }}
                                    onClick={(e) => {
                                      if (selectedUrgent.includes(v.id)) {
                                        const temp = [...selectedUrgent].filter(
                                          (s) => s !== v.id
                                        );
                                        setSelectedUrgent(temp);
                                      } else {
                                        setSelectedUrgent([
                                          ...selectedUrgent,
                                          v.id,
                                        ]);
                                      }
                                    }}
                                  >
                                    {selectedUrgent.find((s) => s === v.id) !==
                                      undefined && (
                                      <Icon icon="check-circle-fill" />
                                    )}
                                    {!selectedUrgent.find(
                                      (s) => s === v.id
                                    ) && <Icon icon="circle" />}
                                  </p>
                                  <div
                                    className="data"
                                    style={{
                                      textAlign: "left",
                                      fontWeight: "bold",
                                      color: "var(--color-main-blue)",
                                    }}
                                  >
                                    Urgent
                                  </div>
                                </div>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: "0",
                                    marginLeft: "0.1rem",
                                    marginRight: "0.6rem",
                                  }}
                                  onClick={(e) => {
                                    if (selectedVacuum.includes(v.id)) {
                                      const temp = [...selectedVacuum].filter(
                                        (s) => s !== v.id
                                      );
                                      setSelectedVacuum(temp);
                                    } else {
                                      setSelectedVacuum([
                                        ...selectedVacuum,
                                        v.id,
                                      ]);
                                    }
                                  }}
                                >
                                  {selectedVacuum.find((s) => s === v.id) !==
                                    undefined && (
                                    <Icon icon="check-circle-fill" />
                                  )}
                                  {!selectedVacuum.find((s) => s === v.id) && (
                                    <Icon icon="circle" />
                                  )}
                                </p>
                                <div
                                  className="data"
                                  style={{
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    color: "var(--color-main-blue)",
                                  }}
                                >
                                  Vacuum Packaging($5)
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}

              {step === 2 && (
                <>
                  {/** option */}
                  <div>
                    <div className="step-header">Step 3: Enter Address</div>

                    <div className="line" style={{ paddingBottom: "1rem" }}>
                      <div
                        className="sub-header"
                        style={{ paddingTop: ".4rem" }}
                      >
                        - Box Options
                      </div>
                      <div>
                        <div className="bold">Free options</div>
                        {/* <div className="f-row">
                          <div>
                            <FormCheck
                              style={{ marginRight: "0.6rem" }}
                              checked={options.letter}
                              onChange={(e) => {
                                setOptions({
                                  ...options,
                                  letter: e.target.checked,
                                });
                              }}
                            />
                          </div>
                          <div>Collect all letters in 1 box</div>
                        </div> */}

                        <div className="f-row">
                          <div>
                            <FormCheck
                              style={{ marginRight: "0.6rem" }}
                              checked={options.poster}
                              onChange={(e) => {
                                setOptions({
                                  ...options,
                                  poster: e.target.checked,
                                });
                              }}
                            />
                          </div>
                          <div>Collect all posters as minimum</div>
                        </div>
                      </div>

                      <div>
                        <div className="bold">Charge options</div>
                        <div className="f-row">
                          {/* <div>
                            <FormCheck
                              style={{ marginRight: "0.6rem" }}
                              checked={options.aircap}
                              onChange={(e) => {
                                setOptions({
                                  ...options,
                                  aircap: e.target.checked,
                                });
                              }}
                            />
                          </div>
                          <div> Add aircap (1box(2$))</div> */}
                        </div>

                        <div className="f-row">
                          <div>
                            <FormCheck
                              style={{ marginRight: "0.6rem" }}
                              checked={options.sticker}
                              onChange={(e) => {
                                setOptions({
                                  ...options,
                                  sticker: e.target.checked,
                                });
                              }}
                            />
                          </div>
                          <div>Attach fragile sticker on 4 side (1box(1$))</div>
                        </div>

                        <div className="f-row">
                          <div>
                            <FormCheck
                              style={{ marginRight: "0.6rem" }}
                              checked={options.bag}
                              onChange={(e) => {
                                setOptions({
                                  ...options,
                                  bag: e.target.checked,
                                });
                              }}
                            />
                          </div>
                          <div>Package insurance (1box(5$))</div>
                        </div>
                        <div>
                          {" "}
                          * In case of the loss of a package this insurance will
                          cover certain cost. For more details{" "}
                          <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href="https://docs.google.com/document/d/1SuZi6RC7hWQG9aXqoRLTO2HbZuH0wJ6uondJEFs5Z6s/edit"
                          >
                            <div className="text-link">
                              terms and conditions.
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>

                    {/* Address */}
                    <div>
                      <div className="sub-header">- Address</div>
                      <div>
                        <div className="label">Use My Address</div>
                        <div className="data">
                          <Dropdown
                            onSelect={handleSelectAddress}
                            style={{
                              margin: ".5rem 0",
                              border: "1px solid var(--color-black)",
                              borderRadius: ".4rem",
                            }}
                          >
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-basic"
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>
                                <Icon
                                  icon="house-door-fill"
                                  style={{ marginRight: ".4rem" }}
                                />
                                {selectedAddressIndex !== null
                                  ? `${addresses[selectedAddressIndex].name}, ${addresses[selectedAddressIndex].receiver}`
                                  : "Select Address"}
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: "100%" }}>
                              {addresses.map((address, index) => (
                                <Dropdown.Item
                                  key={index}
                                  eventKey={index.toString()}
                                >
                                  {address.name}, {address.receiver}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>

                      <div>
                        <Padder>
                          Receiver *
                          <FormControl
                            value={address.receiver}
                            onChange={(e) => {
                              if (e.target.value.length <= 35) {
                                setAddress({
                                  ...address,
                                  receiver: e.target.value,
                                });
                              }
                            }}
                          />
                        </Padder>
                        <Padder>
                          Email *
                          <FormControl
                            value={address.email}
                            onChange={(e) => {
                              setAddress({
                                ...address,
                                email: e.target.value,
                              });
                            }}
                          />
                        </Padder>
                        <Padder>
                          Phone Number *
                          <FormControl
                            value={address.phone}
                            onChange={(e) => {
                              setAddress({
                                ...address,
                                phone: e.target.value,
                              });
                            }}
                          />
                        </Padder>
                        <Padder>
                          Personal Clearance Number{" "}
                          {isPCCCRequired(address.country) && "*"}
                          <FormControl
                            value={address.pccc}
                            onChange={(e) => {
                              setAddress({
                                ...address,
                                pccc: e.target.value,
                              });
                            }}
                          />
                          {isPCCCRequired(address.country) && (
                            <div
                              style={{
                                color: "var(--color-main-blue)",
                                fontSize: "0.9em",
                                marginTop: "0.2rem",
                              }}
                            >
                              Personal Clearance Number is required for{" "}
                              {CODE_COUNTRY_ITEMS[address.country]}.
                            </div>
                          )}
                        </Padder>
                        <Padder>
                          City *
                          <FormControl
                            value={address.city}
                            onChange={(e) => {
                              setAddress({
                                ...address,
                                city: e.target.value,
                              });
                            }}
                          />
                        </Padder>
                        <div>
                          Country *
                          <Dropdown
                            style={{
                              margin: ".5rem 0",
                              border: "1px solid var(--color-black)",
                              borderRadius: ".4rem",
                            }}
                          >
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-basic"
                              className="d-flex justify-content-between align-items-center"
                              style={{ textAlign: "left", width: "100%" }}
                            >
                              {address.country
                                ? CODE_COUNTRY_ITEMS[address.country]
                                : "Select Country"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ width: "100%" }}>
                              <FormControl
                                autoFocus
                                placeholder="Search country..."
                                onChange={(e) =>
                                  setCountrySearchTerm(e.target.value)
                                }
                                value={countrySearchTerm}
                                style={{ margin: "0" }}
                              />
                              {filteredCountries.map(([id, name]) => (
                                <Dropdown.Item
                                  key={id}
                                  onClick={() =>
                                    handleCountrySelect(Number(id))
                                  }
                                >
                                  {name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <Padder>
                          State Province Code *
                          <FormControl
                            value={address.state}
                            placeholder="NY"
                            onChange={(e) => {
                              setAddress({
                                ...address,
                                state: e.target.value,
                              });
                            }}
                          />
                        </Padder>
                        <Padder>
                          Zip Code *
                          <FormControl
                            value={address.zip}
                            placeholder="10001"
                            onChange={(e) => {
                              setAddress({
                                ...address,
                                zip: e.target.value,
                              });
                            }}
                          />
                        </Padder>
                        <Padder>
                          Address Line 1 (35 letters) *
                          <FormControl
                            value={address.address1}
                            maxLength={35}
                            onChange={(e) => {
                              setAddress({
                                ...address,
                                address1: e.target.value,
                              });
                            }}
                          />
                        </Padder>
                        <Padder>
                          Address Line 2 (35 letters)
                          <FormControl
                            value={address.address2}
                            maxLength={35}
                            onChange={(e) => {
                              setAddress({
                                ...address,
                                address2: e.target.value,
                              });
                            }}
                          />
                        </Padder>
                        <Padder>
                          Address Line 3 (35 letters)
                          <FormControl
                            value={address.address3}
                            maxLength={35}
                            onChange={(e) => {
                              setAddress({
                                ...address,
                                address3: e.target.value,
                              });
                            }}
                          />
                        </Padder>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {step === 3 && (
                <>
                  {/** summary */}
                  <div>
                    <div className="step-header">Step 4: Request Summary</div>
                    <div>
                      <div
                        className="sub-header"
                        style={{ paddingTop: ".4rem" }}
                      >
                        - Product Info
                      </div>
                      <div>
                        {modal?.payload?.list?.map((v: any, idx: number) => {
                          const productRows = v.detail.map(
                            (s: any, detailIdx: number) => (
                              <div
                                key={`${v.tracking}-${detailIdx}`}
                                className="line"
                              >
                                {detailIdx === 0 ? (
                                  <div>
                                    <div className="bold">Product</div>
                                    <MRow>
                                      <div className="label">Tracking No.</div>
                                      <div className="data">{v.tracking}</div>
                                    </MRow>

                                    <MRow>
                                      <div className="label">Arrival Date</div>
                                      <div className="data">
                                        {FormatData(
                                          DATA_TYPE.DATE,
                                          v.inboundAt
                                        )}
                                      </div>
                                    </MRow>

                                    <MRow>
                                      <div className="label">
                                        Warehouse Cost
                                      </div>
                                      <div className="data">
                                        {v.overstoreFee}
                                      </div>
                                    </MRow>
                                  </div>
                                ) : null}
                                <MRow>
                                  <div className="label">Category</div>
                                  <div className="data">
                                    {CODE_SHOPPING_ORDER_ITEMS[s.category] ||
                                      "Unknown Category"}
                                  </div>
                                </MRow>

                                <MRow>
                                  <div className="label">Name</div>
                                  <div className="data">
                                    {s.name.length > 10
                                      ? s.name.slice(0, 10) + "..."
                                      : s.name}
                                  </div>
                                </MRow>

                                <MRow>
                                  <div className="label">Unit Price</div>
                                  <div className="data">
                                    ${s.price}
                                    {/* / ₩
                                    {fixedExchangeRate
                                      ? Math.floor(s.price * fixedExchangeRate)
                                      : "-"} */}
                                  </div>
                                </MRow>

                                <MRow>
                                  <div className="label">Quantity</div>
                                  <div className="data"> {s.quantity}</div>
                                </MRow>

                                <div>
                                  {detailIdx === 0 && options.box === 0 && (
                                    <div style={{ display: "flex" }}>
                                      <td style={{ marginLeft: "0.2rem" }}>
                                        {selectedUrgent.includes(v.id) ? (
                                          <Icon icon="check-circle-fill" />
                                        ) : (
                                          <Icon icon="circle" />
                                        )}
                                      </td>
                                      <div
                                        className="data"
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                          marginLeft: "0.6rem",
                                          color: "var(--color-main-blue)",
                                        }}
                                      >
                                        Urgent
                                      </div>
                                    </div>
                                  )}
                                  {detailIdx === 0 && (
                                    <div style={{ display: "flex" }}>
                                      <td style={{ marginLeft: "0.2rem" }}>
                                        {selectedVacuum.includes(v.id) ? (
                                          <Icon icon="check-circle-fill" />
                                        ) : (
                                          <Icon icon="circle" />
                                        )}
                                      </td>
                                      <div
                                        className="data"
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                          marginLeft: "0.6rem",
                                          color: "var(--color-main-blue)",
                                        }}
                                      >
                                        Vacuum Packaging($5)
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                          );
                          return productRows;
                        })}
                      </div>

                      <div className="line">
                        <div className="sub-header">- Box Options</div>
                        <div>
                          {options.letter && <div>collect all letters</div>}
                          {options.poster && <div>collect all posters</div>}
                          {options.aircap && <div>add aircap</div>}
                          {options.sticker && (
                            <div>attach fragile stickers</div>
                          )}
                          {options.bag && <div>insurance</div>}
                          {options.box === 0 && <div>1Box</div>}
                          {options.box === 1 && (
                            <div>No limit on number of boxes</div>
                          )}
                        </div>
                      </div>

                      <div className="line">
                        <div className="sub-header">- Address</div>
                        <div>
                          <MRow>
                            {address.receiver}, {address.email}, {address.phone}
                          </MRow>
                          <MRow>{address.pccc}</MRow>
                          <MRow>
                            {CODE_COUNTRY_ITEMS[address.country] ||
                              "Select Country"}
                          </MRow>
                          <MRow>
                            {address.state}, {address.city} {address.zip}
                          </MRow>
                          <MRow>{address.address1}</MRow>
                          <MRow>{address.address2}</MRow>
                          <MRow>{address.address3}</MRow>
                        </div>
                        <div
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            marginBottom: "10px",
                          }}
                        >
                          Declared Total value:{" "}
                          {calculateTotalValue().toFixed(2)} USD
                          <i
                            className="bi bi-question-circle"
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                              fontSize: "1.1em",
                            }}
                            onClick={toggleTooltip}
                          ></i>
                          {tooltipVisible && (
                            <div style={{ fontSize: "0.8em" }}>
                              {TooltipText}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div>
              {step === 1 && (
                <div className="line">
                  <div className="sub-header"> - Notice</div>
                  <div style={{ paddingBottom: "1rem" }}>
                    ※ If capacity or weight of box is over the limit,
                    <br />
                    Ontact Korea can exclude products even if you check those as
                    urgent.
                    <br />
                    Excluded one will be return to warehouse.
                  </div>
                </div>
              )}

              {step === 3 && (
                <div>
                  <div className="line">
                    <div className="sub-header"> - Notice</div>
                    <div style={{ paddingBottom: "1rem" }}>
                      ※ You cannot modify information after the request sent
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              {step === 2 && (
                <div>
                  {/* <Button
                variant="outline-success"
                style={{
                  width: "200px",
                }}
                onClick={async (e) => {
                  const res = await reqGetUserById({ id: user.id });
                  const userHomeData = res.data.address[1];
                  setAddress({
                    ...address,
                    receiver: userHomeData.receiver,
                    email: userHomeData.email,
                    phone: userHomeData.phone,
                    city: userHomeData.city,
                    country: userHomeData.country,
                    state: userHomeData.state,
                    zip: userHomeData.zip,
                    address1: userHomeData.option1,
                    address2: userHomeData.option2,
                    address3: userHomeData.option3,
                  });
                }}
              >
                <Icon
                  icon="house-door-fill"
                  style={{ marginRight: "1rem" }}
                />
                Load My Address
              </Button> */}
                  {/* <Button
                    onClick={loadAddresses}
                    variant="outline-success"
                    style={{ width: "200px" }}
                  >
                    <Icon
                      icon="house-door-fill"
                      style={{ marginRight: "1rem" }}
                    />
                    Load My Address
                  </Button> */}
                </div>
              )}

              <MFooter>
                <MButton
                  onClick={(e) => {
                    if (step !== 0) {
                      setStep(step - 1);
                    } else {
                      dispatch(modalClose());
                    }
                  }}
                  style={{
                    backgroundColor: "var(--color-gray)",
                    color: "var(--color-white)",
                    marginBottom: "1rem",
                  }}
                >
                  {step !== 0 ? "Back" : "Cancel"}
                </MButton>

                {(!isSubmitDisabled() || step === 2) && (
                  <MButton
                    style={{
                      backgroundColor:
                        isSubmitting || (step === 2 && !isValid)
                          ? "gray"
                          : "var(--color-main-blue)",
                      color: "var(--color-white)",
                      marginBottom: "1rem",
                      pointerEvents:
                        isSubmitting || (step === 2 && !isValid)
                          ? "none"
                          : "auto",
                      opacity:
                        isSubmitting || (step === 2 && !isValid) ? 0.5 : 1,
                    }}
                    onClick={async (e) => {
                      if (isSubmitting) return;

                      if (step === 0) {
                        setOptions({ ...options, box: 0 });
                      } else if (step === 2) {
                        const { isOK, message } = verifySubmission();
                        if (!isOK) {
                          alert(message);
                          return;
                        }
                      }
                      if (step < 3) {
                        setStep(step + 1);
                      } else {
                        await handleSubmit();
                      }
                    }}
                  >
                    {step === 3
                      ? "Submit"
                      : step === 0
                      ? "Repack in 1 Box only"
                      : "Next"}
                  </MButton>
                )}

                {step === 0 && (
                  <MButton
                    style={{
                      backgroundColor: isSubmitting
                        ? "gray"
                        : "var(--color-main-blue)",
                      color: "var(--color-white)",
                      marginBottom: "1rem",
                      pointerEvents: isSubmitting ? "none" : "auto",
                    }}
                    onClick={(e) => {
                      setOptions({ ...options, box: 1 });
                      setStep(1);
                    }}
                  >
                    Box Quantity doesn't matter
                  </MButton>
                )}
              </MFooter>
            </div>
          </MBody>
        </div>
      ) : (
        <>
          <div>
            {step === 0 && (
              <>
                {/** product list */}
                <h5>Send Shipping Request of Next Products</h5>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    fontSize: "23px",
                    fontWeight: "bold",
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{TooltipText}</Tooltip>}
                  >
                    <i
                      className="bi bi-question-circle"
                      style={{
                        cursor: "pointer",
                        fontSize: "0.8em",
                        position: "absolute",
                        top: "-17px",
                        right: "10px",
                      }}
                    ></i>
                  </OverlayTrigger>
                  <span style={{ marginRight: "10px" }}>
                    Declared Total value : {calculateTotalValue().toFixed(2)}{" "}
                    USD
                  </span>
                </div>
                <div
                  style={{
                    textAlign: "right",
                    fontSize: "23px",
                    fontWeight: "bold",
                    marginTop: "10px",
                  }}
                >
                  Total Weight (kg):{" "}
                  {(() => {
                    try {
                      console.log("List data:", modal?.payload?.list);
                      const total = (modal?.payload?.list || []).reduce(
                        (total: number, v: any) => {
                          console.log(
                            "Item weight:",
                            v.totalWeightKg,
                            typeof v.totalWeightKg
                          );
                          const weight = parseFloat(v.totalWeightKg) || 0;
                          return total + weight;
                        },
                        0
                      );
                      console.log("Total weight calculated:", total);
                      return total > 0 ? `${total.toFixed(2)} kg` : "No Data";
                    } catch (error) {
                      console.error("Error calculating weight:", error);
                      return "No Data";
                    }
                  })()}
                </div>
                <div
                  style={{
                    height: "400px",
                    overflowY: "scroll",
                    marginTop: "20px",
                  }}
                >
                  <Table style={{ position: "relative" }}>
                    <thead>
                      <tr>
                        <th style={{ top: 0, position: "sticky" }}>
                          Tracking No.
                        </th>
                        <th style={{ top: 0, position: "sticky" }}>
                          Arrival Date
                        </th>
                        <th style={{ top: 0, position: "sticky" }}>
                          Warehouse Cost
                        </th>
                        <th style={{ top: 0, position: "sticky" }}>
                          Extra Charge
                        </th>
                        <th
                          style={{ top: 0, position: "sticky", width: "300px" }}
                        >
                          Product Detail
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {modal?.payload?.list?.map((v: any) => {
                        const detail = v.detail;
                        const ExteraKRW = fixedExchangeRate
                          ? (v.Extra * fixedExchangeRate).toFixed(2)
                          : "-";
                        const priceUSD = v.price;
                        const priceKRW = fixedExchangeRate
                          ? (v.price * fixedExchangeRate).toFixed(2)
                          : "-";
                        return (
                          <tr>
                            <td>{v.tracking}</td>
                            <td>{FormatData(DATA_TYPE.DATE, v.inboundAt)}</td>
                            <td>
                              ${v.overstoreFee} / ₩
                              {convertToKRW(v.overstoreFee)}
                            </td>
                            <td>
                              ${convertToUSD(v.Extra)} / ₩{v.Extra}
                            </td>
                            <td>
                              <th style={{ fontSize: "12px" }}>No.</th>
                              <th style={{ fontSize: "12px" }}>Category</th>
                              <th style={{ fontSize: "12px" }}>Name</th>
                              <th style={{ fontSize: "12px" }}>Unit Price</th>
                              <th style={{ fontSize: "12px" }}>Quantity</th>
                              {detail.map((s: any, idx: number) => {
                                const priceUSD = s.price;
                                const priceKRW = fixedExchangeRate
                                  ? Math.floor(s.price * fixedExchangeRate)
                                  : "-";
                                return (
                                  <>
                                    <tr>
                                      <td style={{ padding: "2px" }}>{idx}</td>
                                      <td style={{ padding: "2px" }}>
                                        {CODE_SHOPPING_ORDER_ITEMS[
                                          s.category
                                        ] || "Unknown Category"}
                                      </td>
                                      <td style={{ padding: "2px" }}>
                                        {s.name.length > 10
                                          ? s.name.slice(0, 10) + "..."
                                          : s.name}
                                      </td>
                                      <td style={{ padding: "2px" }}>
                                        ${priceUSD}
                                        {/* / ₩{priceKRW} */}
                                      </td>
                                      <td style={{ padding: "2px" }}>
                                        {s.quantity}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </>
            )}
            {step === 1 && (
              <>
                {/** urgent */}

                <div>
                  <h5>
                    Product Option (Check if it need vaccum packaging
                    {options.box === 0 && <> or urgent service</>})
                  </h5>
                  <div
                    style={{
                      height: "400px",
                      overflowY: "scroll",
                      marginTop: "20px",
                    }}
                  >
                    <Table style={{ position: "relative" }}>
                      <thead>
                        <tr>
                          <th style={{ top: 0, position: "sticky" }}>
                            Tracking No.
                          </th>

                          <th
                            style={{
                              top: 0,
                              position: "sticky",
                              width: "300px",
                            }}
                          >
                            Product Detail
                          </th>
                          {options.box === 0 && (
                            <th
                              style={{
                                top: 0,
                                position: "sticky",
                                width: "200px",
                                textAlign: "center",
                                color: "var(--color-main-blue)",
                              }}
                            >
                              Urgent
                            </th>
                          )}
                          <th
                            style={{
                              top: 0,
                              position: "sticky",
                              width: "200px",
                              textAlign: "center",
                              color: "var(--color-main-blue)",
                            }}
                          >
                            Vacuum Packaging($5)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {modal?.payload?.list?.map((v: any) => {
                          const detail = v.detail;
                          const priceUSD = v.price;
                          const priceKRW = fixedExchangeRate
                            ? (v.price / fixedExchangeRate).toFixed(2)
                            : "-";
                          return (
                            <tr style={{ cursor: "pointer" }}>
                              <td>{v.tracking}</td>
                              <td>
                                <th style={{ fontSize: "12px" }}>No.</th>
                                <th style={{ fontSize: "12px" }}>Category</th>
                                <th style={{ fontSize: "12px" }}>Name</th>
                                <th style={{ fontSize: "12px" }}>Unit Price</th>
                                <th style={{ fontSize: "12px" }}>Quantity</th>
                                {detail?.map((s: any, idx: number) => {
                                  const priceUSD = s.price;
                                  const priceKRW = fixedExchangeRate
                                    ? Math.floor(s.price * fixedExchangeRate)
                                    : "-";
                                  return (
                                    <>
                                      <tr>
                                        <td style={{ padding: "2px" }}>
                                          {idx}
                                        </td>
                                        <td style={{ padding: "2px" }}>
                                          {CODE_SHOPPING_ORDER_ITEMS[
                                            s.category
                                          ] || "Unknown Category"}
                                        </td>
                                        <td style={{ padding: "2px" }}>
                                          {s.name.length > 10
                                            ? s.name.slice(0, 10) + "..."
                                            : s?.name}
                                        </td>
                                        <td style={{ padding: "2px" }}>
                                          ${priceUSD}
                                          {/* / ₩{priceKRW} */}
                                        </td>
                                        <td style={{ padding: "2px" }}>
                                          {s?.quantity}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </td>
                              {options.box === 0 && (
                                <td
                                  style={{ textAlign: "center" }}
                                  onClick={(e) => {
                                    if (selectedUrgent.includes(v.id)) {
                                      const temp = [...selectedUrgent].filter(
                                        (s) => s !== v.id
                                      );
                                      setSelectedUrgent(temp);
                                    } else {
                                      setSelectedUrgent([
                                        ...selectedUrgent,
                                        v.id,
                                      ]);
                                    }
                                  }}
                                >
                                  {selectedUrgent.find((s) => s === v.id) !==
                                    undefined && (
                                    <Icon icon="check-circle-fill" />
                                  )}
                                  {!selectedUrgent.find((s) => s === v.id) && (
                                    <Icon icon="circle" />
                                  )}
                                </td>
                              )}
                              <td
                                style={{ textAlign: "center" }}
                                onClick={(e) => {
                                  if (selectedVacuum.includes(v.id)) {
                                    const temp = [...selectedVacuum].filter(
                                      (s) => s !== v.id
                                    );
                                    setSelectedVacuum(temp);
                                  } else {
                                    setSelectedVacuum([
                                      ...selectedVacuum,
                                      v.id,
                                    ]);
                                  }
                                }}
                              >
                                {selectedVacuum.find((s) => s === v.id) !==
                                  undefined && (
                                  <Icon icon="check-circle-fill" />
                                )}
                                {!selectedVacuum.find((s) => s === v.id) && (
                                  <Icon icon="circle" />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                {/** option */}
                <OptionContainer className="d-flex gap-4">
                  <div
                    style={{
                      borderRight: "1px solid #eee",
                      paddingRight: "15px",
                      width: "80%",
                    }}
                  >
                    <h5>Box Options</h5>
                    <div className="d-flex gap-2  mt-3">
                      <div style={{ width: "120px" }}>Free options</div>
                      <div>
                        {/* <label className="d-flex gap-2">
                          <FormCheck
                            checked={options.letter}
                            onChange={(e) => {
                              setOptions({
                                ...options,
                                letter: e.target.checked,
                              });
                            }}
                          />
                          Collect all letters in 1 box
                        </label> */}
                        <label className="d-flex gap-2">
                          <FormCheck
                            checked={options.poster}
                            onChange={(e) => {
                              setOptions({
                                ...options,
                                poster: e.target.checked,
                              });
                            }}
                          />
                          Collect all posters as minimum
                        </label>
                      </div>
                    </div>
                    <div className="d-flex gap-2  mt-3">
                      <div style={{ width: "120px" }}>Charge options</div>
                      <div>
                        {/* <label className="d-flex gap-2">
                          <FormCheck
                            checked={options.aircap}
                            onChange={(e) => {
                              setOptions({
                                ...options,
                                aircap: e.target.checked,
                              });
                            }}
                          />
                          Add aircap (1box(2$))
                        </label> */}
                        <label className="d-flex gap-2">
                          <FormCheck
                            checked={options.sticker}
                            onChange={(e) => {
                              setOptions({
                                ...options,
                                sticker: e.target.checked,
                              });
                            }}
                          />
                          Attach fragile sticker on 4 side (1box(1$))
                        </label>
                        <label className="d-flex gap-2">
                          <FormCheck
                            checked={options.bag}
                            onChange={(e) => {
                              setOptions({ ...options, bag: e.target.checked });
                            }}
                          />
                          package insurance (1box(5$))
                        </label>
                        * In case of the loss of a package this insurance will
                        cover certain cost. For more details{" "}
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          href="https://docs.google.com/document/d/1SuZi6RC7hWQG9aXqoRLTO2HbZuH0wJ6uondJEFs5Z6s/edit"
                        >
                          <div className="text-link">terms and conditions.</div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <h5>Address</h5>
                    <div
                      style={{
                        height: "400px",
                        overflowY: "scroll",
                        marginTop: "20px",
                        paddingRight: "20px",
                        paddingLeft: "4px",
                        paddingBottom: "20px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div className="d-flex gap-2">
                          <div style={{ width: "100%" }}>
                            Receiver *
                            <FormControl
                              value={address.receiver}
                              onChange={(e) => {
                                if (e.target.value.length <= 35) {
                                  setAddress({
                                    ...address,
                                    receiver: e.target.value,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            Email *
                            <FormControl
                              value={address.email}
                              onChange={(e) => {
                                setAddress({
                                  ...address,
                                  email: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div style={{ marginTop: "10px" }}>
                          Phone Number *
                          <FormControl
                            value={address.phone}
                            onChange={(e) => {
                              setAddress({
                                ...address,
                                phone: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          Personal Clearance Number{" "}
                          {isPCCCRequired(address.country) && "*"}
                          <FormControl
                            value={address.pccc}
                            onChange={(e) => {
                              setAddress({
                                ...address,
                                pccc: e.target.value,
                              });
                            }}
                          />
                          {isPCCCRequired(address.country) && (
                            <div
                              style={{
                                color: "var(--color-main-blue)",
                                fontSize: "0.9em",
                                marginTop: "0.2rem",
                              }}
                            >
                              Personal Clearance Number is required for{" "}
                              {CODE_COUNTRY_ITEMS[address.country]}.
                            </div>
                          )}
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div style={{ marginTop: "10px" }}>
                          City *
                          <FormControl
                            value={address.city}
                            onChange={(e) => {
                              setAddress({
                                ...address,
                                city: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div
                          className="d-flex gap-2"
                          style={{ marginTop: "10px" }}
                        >
                          <div style={{ marginTop: "1px" }}>
                            Country *
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                                className="d-flex justify-content-between align-items-center"
                                style={{ width: "200px", textAlign: "left" }}
                              >
                                {address.country
                                  ? CODE_COUNTRY_ITEMS[address.country]
                                  : "Select Country"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <FormControl
                                  autoFocus
                                  placeholder="Search country..."
                                  onChange={(e) =>
                                    setCountrySearchTerm(e.target.value)
                                  }
                                  value={countrySearchTerm}
                                  style={{ margin: "10px" }}
                                />
                                {filteredCountries.map(([id, name]) => (
                                  <Dropdown.Item
                                    key={id}
                                    onClick={() =>
                                      handleCountrySelect(Number(id))
                                    }
                                  >
                                    {name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div style={{ width: "100%" }}>
                            State Province Code *
                            <FormControl
                              value={address.state}
                              placeholder="NY"
                              onChange={(e) => {
                                setAddress({
                                  ...address,
                                  state: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            Zip Code *
                            <FormControl
                              value={address.zip}
                              placeholder="10001"
                              onChange={(e) => {
                                setAddress({
                                  ...address,
                                  zip: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          Address Line 1 (35 letters) *
                          <FormControl
                            value={address.address1}
                            onChange={(e) => {
                              if (e.target.value.length <= 35) {
                                setAddress({
                                  ...address,
                                  address1: e.target.value,
                                });
                              }
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          Address Line 2 (35 letters)
                          <FormControl
                            value={address.address2}
                            onChange={(e) => {
                              if (e.target.value.length <= 35) {
                                setAddress({
                                  ...address,
                                  address2: e.target.value,
                                });
                              }
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          Address Line 3 (35 letters)
                          <FormControl
                            value={address.address3}
                            onChange={(e) => {
                              if (e.target.value.length <= 35) {
                                setAddress({
                                  ...address,
                                  address3: e.target.value,
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </OptionContainer>
              </>
            )}
            {step === 3 && (
              <>
                {/** summary */}
                <div>
                  <div>
                    <h5>Request Summary</h5>
                    <div
                      style={{
                        marginTop: "20px",
                        borderRight: "1px solid #eee",
                        paddingRight: "15px",
                        width: "100%",
                        height: "500px",
                        overflowY: "scroll",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>Products</div>
                      <div
                        style={{
                          marginBottom: "30px",
                        }}
                      >
                        <Table
                          striped
                          bordered
                          hover
                          style={{ position: "relative" }}
                        >
                          <thead>
                            <tr>
                              <th>Tracking No.</th>
                              <th>Arrival Date</th>
                              <th>Warehouse Cost</th>
                              <th>Product Detail</th>
                              {options.box === 0 && (
                                <th
                                  style={{
                                    textAlign: "center",
                                    color: "var(--color-main-blue)",
                                  }}
                                >
                                  Urgent
                                </th>
                              )}
                              <th
                                style={{
                                  textAlign: "center",
                                  color: "var(--color-main-blue)",
                                }}
                              >
                                Vacuum Packaging($5)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {modal?.payload?.list?.map(
                              (v: any, idx: number) => {
                                const productRows = v.detail.map(
                                  (s: any, detailIdx: number) => (
                                    <tr key={`${v.tracking}-${detailIdx}`}>
                                      {detailIdx === 0 ? (
                                        <>
                                          <td rowSpan={v.detail.length}>
                                            {v.tracking}
                                          </td>
                                          <td rowSpan={v.detail.length}>
                                            {FormatData(
                                              DATA_TYPE.DATE,
                                              v.inboundAt
                                            )}
                                          </td>
                                          <td rowSpan={v.detail.length}>
                                            {v.overstoreFee}
                                          </td>
                                        </>
                                      ) : null}
                                      <td>
                                        <tr>
                                          <span>
                                            Category:{" "}
                                            {CODE_SHOPPING_ORDER_ITEMS[
                                              s.category
                                            ] || "Unknown Category"}
                                          </span>
                                          ,
                                        </tr>
                                        <tr>
                                          <span>
                                            Name:{" "}
                                            {s.name.length > 10
                                              ? s.name.slice(0, 10) + "..."
                                              : s.name}
                                          </span>
                                          ,
                                        </tr>
                                        <span>
                                          Unit Price: ${s.price}
                                          {/* / ₩
                                          {fixedExchangeRate
                                            ? Math.floor(s.price * fixedExchangeRate)
                                            : "-"} */}
                                        </span>
                                        <br />
                                        <span>Quantity: {s.quantity}</span>
                                      </td>
                                      {detailIdx === 0 && options.box === 0 && (
                                        <td
                                          rowSpan={v.detail.length}
                                          style={{ textAlign: "center" }}
                                        >
                                          {selectedUrgent.includes(v.id) ? (
                                            <Icon icon="check-circle-fill" />
                                          ) : (
                                            <Icon icon="circle" />
                                          )}
                                        </td>
                                      )}
                                      {detailIdx === 0 && (
                                        <td
                                          rowSpan={v.detail.length}
                                          style={{ textAlign: "center" }}
                                        >
                                          {selectedVacuum.includes(v.id) ? (
                                            <Icon icon="check-circle-fill" />
                                          ) : (
                                            <Icon icon="circle" />
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  )
                                );

                                return productRows;
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-flex mb-3">
                        <div style={{ fontWeight: "bold", width: "200px" }}>
                          Box Options
                        </div>
                        <div>
                          {options.letter && <div>collect all letters</div>}
                          {options.poster && <div>collect all posters</div>}
                          {options.aircap && <div>add aircap</div>}
                          {options.sticker && (
                            <div>attach fragile stickers</div>
                          )}
                          {options.bag && <div>insurance</div>}
                          {options.box === 0 && <div>1Box</div>}
                          {options.box === 1 && (
                            <div>No limit on number of boxes</div>
                          )}
                        </div>
                      </div>
                      <div className="d-flex mb-3">
                        <div style={{ fontWeight: "bold", width: "200px" }}>
                          Address
                        </div>
                        <div>
                          <div>
                            {address.receiver}, {address.email}, {address.phone}
                          </div>
                          <div>{address.pccc}</div>
                          <div style={{ width: "100%" }}>
                            {CODE_COUNTRY_ITEMS[address.country] ||
                              "Select Country"}
                          </div>
                          <div>
                            {address.state}, {address.city} {address.zip}
                          </div>
                          <div>{address.address1}</div>
                          <div>{address.address2}</div>
                          <div>{address.address3}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <label className="mt-3" style={{ marginTop: "15px", width: "100%" }}>
            {step === 1 && (
              <>
                ※ If capacity or weight of box is over the limit,
                <br />
                Ontact Korea can exclude products even if you check those as
                urgent.
                <br />
                Excluded one will be return to warehouse.
              </>
            )}
            {step === 3 && (
              <>※ You cannot modify information after the request sent</>
            )}
          </label>
          <div className="d-flex justify-content-end gap-2 mt-2">
            {step === 2 && (
              <div>
                {/* <Button
                  variant="outline-success"
                  style={{
                    width: "200px",
                  }}
                  onClick={async (e) => {
                    const res = await reqGetUserById({ id: user.id });
                    const userHomeData = res.data.address[1];
                    setAddress({
                      ...address,
                      receiver: userHomeData.receiver,
                      email: userHomeData.email,
                      phone: userHomeData.phone,
                      city: userHomeData.city,
                      country: userHomeData.country,
                      state: userHomeData.state,
                      zip: userHomeData.zip,
                      address1: userHomeData.option1,
                      address2: userHomeData.option2,
                      address3: userHomeData.option3,
                    });
                  }}
                >
                  <Icon
                    icon="house-door-fill"
                    style={{ marginRight: "1rem" }}
                  />
                  Load My Address
                </Button> */}
                {/* <Button onClick={loadAddresses} variant="outline-success" style={{ width: "200px" }}>
                  <Icon
                    icon="house-door-fill"
                    style={{ marginRight: "1rem" }}
                  />
                  Load My Address
                </Button> */}
                <Dropdown onSelect={handleSelectAddress}>
                  <Dropdown.Toggle
                    variant="outline-success"
                    id="dropdown-basic"
                    style={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      {selectedAddressIndex !== null
                        ? `${addresses[selectedAddressIndex].name}, ${addresses[selectedAddressIndex].receiver}`
                        : "Select Address"}
                    </span>
                    <Icon
                      icon="house-door-fill"
                      style={{ marginLeft: "1rem" }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {addresses.map((address, index) => (
                      <Dropdown.Item key={index} eventKey={index.toString()}>
                        {address.name}, {address.receiver}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            <Button
              variant="outline-success"
              style={{ width: "200px" }}
              onClick={(e) => {
                if (step !== 0) {
                  setStep(step - 1);
                  // resetState();
                } else {
                  dispatch(modalClose());
                }
              }}
            >
              {step !== 0 ? "Back" : "Cancel"}
            </Button>

            <Button
              variant="success"
              style={{ width: "200px" }}
              onClick={async (e) => {
                if (isSubmitting) return;

                if (step === 0) {
                  setOptions({ ...options, box: 0 });
                } else if (step === 2) {
                  const { isOK, message } = verifySubmission();
                  if (!isOK) {
                    alert(message);
                    return;
                  }
                }
                if (step < 3) {
                  setStep(step + 1);
                } else {
                  await handleSubmit();
                }
              }}
              disabled={isSubmitting || isSubmitDisabled()}
            >
              {step === 3
                ? "Submit"
                : step === 0
                ? "Repack in 1 Box only"
                : "Next"}
            </Button>

            {step === 0 && (
              <Button
                style={{
                  width: "200px",
                }}
                variant="success"
                onClick={async (e) => {
                  setOptions({ ...options, box: 1 });
                  setStep(1);
                }}
              >
                Box Quantity doesn't matter
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
}

const Padder = styled.div`
  padding: 0.4rem 0 0.4rem 0;
`;

const OptionContainer = styled.div`
  @media (max-width: 1279px) {
    flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  @media (max-width: 575px) {
    flex-wrap: wrap;
  }
`;
