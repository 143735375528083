import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import { CODE_SHOPPING_ORDER_ITEMS } from "../../common/constants";
import {
  reqShipProductBox,
  reqproduct,
  reqShipProductDelete,
  reqReactivateExpiredProduct,
} from "../../requests/ship";

interface ProductDetails {
  tracking: string;
  createdAt: string;
  userId: string;
  Addstatus: number;
  Extra: number;
  name: string;
  quantity: number;
  status: number;
  imageUrl: string;
  price: number;
  category: number;
  detail?: {
    price: number;
    name: string;
    quantity: number;
    category: number;
  }[];
}

interface AWarehouseModelProps {
  trackingNumber: string;
  onClose: () => void;
}

export default function AWarehouseModel({
  trackingNumber,
  onClose,
}: AWarehouseModelProps) {
  const [productDetails, setProductDetails] = useState<ProductDetails | null>(
    null
  );
  const firstDetailItem = productDetails?.detail?.[0];
  const [exchangeRate, setExchangeRate] = useState<number>(1234.56);
  const [showImageModal, setShowImageModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await reqproduct(trackingNumber);
        setProductDetails(response.data);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };
    fetchData();
  }, [trackingNumber]);

  const handleInputChange = (
    field: keyof ProductDetails,
    value: string | number
  ) => {
    setProductDetails((prev) => {
      const updatedValues = {
        imageUrl: "",
        tracking: "",
        createdAt: "",
        userId: "",
        Addstatus: 0,
        status: 0,
        Extra: 0,
        name: "",
        quantity: 0,
        price: 0,
        category: 0,
        ...prev,
        [field]: value,
      };
      return updatedValues;
    });
  };

  const handleSubmit = async () => {
    if (!productDetails) return;

    Swal.fire({
      title: "제품 정보를 업데이트하시겠습니까?",
      text: "제품 정보를 수정합니다.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "업데이트",
      cancelButtonText: "취소",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const payload = {
            ...productDetails,
          };
          const response = await reqShipProductBox(payload);
          console.log("Update successful", response);
          Swal.fire(
            "업데이트 완료!",
            "제품 정보가 성공적으로 업데이트되었습니다.",
            "success"
          );
          onClose();
          window.location.reload();
        } catch (error) {
          console.error("Error updating product details:", error);
          Swal.fire(
            "업데이트 실패!",
            "제품 정보 업데이트 중 문제가 발생했습니다.",
            "error"
          );
        }
      }
    });
  };

  const handleReactivate = async () => {
    if (!productDetails) return;

    if (productDetails.status !== 20) {
      Swal.fire({
        title: "복구 불가",
        text: "폐기된 상품만 복구할 수 있습니다.",
        icon: "error",
        confirmButtonText: "확인",
      });
      return;
    }

    Swal.fire({
      title: "제품을 복구하시겠습니까?",
      text: "폐기된 제품을 복구하고 14일의 보관 기간이 추가됩니다.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "복구",
      cancelButtonText: "취소",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await reqReactivateExpiredProduct(productDetails.tracking);
          Swal.fire(
            "복구 완료!",
            "제품이 성공적으로 복구되었습니다.",
            "success"
          );
          onClose();
          window.location.reload();
        } catch (error) {
          console.error("Error reactivating product:", error);
          Swal.fire("복구 실패!", "제품 복구 중 문제가 발생했습니다.", "error");
        }
      }
    });
  };

  const handleDelete = async () => {
    if (!productDetails) return;

    if (![1, 2, 20].includes(productDetails.status)) {
      Swal.fire({
        title: "삭제 불가",
        text: "현재 상태에서는 제품을 삭제할 수 없습니다.",
        icon: "error",
        confirmButtonText: "확인",
      });
      return;
    }

    Swal.fire({
      title: "제품을 삭제하시겠습니까?",
      text: "이 작업은 되돌릴 수 없습니다!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const payload = { tracking: productDetails.tracking };
          const response = await reqShipProductDelete(payload);
          console.log("Delete successful", response);
          Swal.fire(
            "삭제 완료!",
            "제품이 성공적으로 삭제되었습니다.",
            "success"
          );
          onClose();
          window.location.reload();
        } catch (error) {
          console.error("Error deleting product:", error);
          Swal.fire("삭제 실패!", "삭제가 가능한 상태가 아닙니다.", "error");
        }
      }
    });
  };

  const handleImageClick = () => {
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
  };

  return (
    <div style={{ display: "flex", marginTop: "20px", gap: "20px" }}>
      <div style={{ flex: 1 }}>
        {productDetails && productDetails.imageUrl ? (
          <img
            src={productDetails.imageUrl}
            alt="Attached File"
            style={{ maxWidth: "200px", height: "auto", cursor: "pointer" }}
            onClick={handleImageClick}
          />
        ) : (
          "No image available"
        )}
      </div>
      <div style={{ flex: 3, display: "flex", flexDirection: "column" }}>
        {productDetails && (
          <Inner>
            <h5>Order Info</h5>
            <Form>
              <div className="detail-container">
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>주문 번호</Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        value={productDetails.tracking}
                        onChange={(e) =>
                          handleInputChange("tracking", e.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>입고 날짜</Form.Label>
                      <Form.Control
                        type="date"
                        value={format(
                          new Date(productDetails.createdAt),
                          "yyyy-MM-dd"
                        )}
                        onChange={(e) =>
                          handleInputChange("createdAt", e.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>스페이스 코드</Form.Label>
                      <Form.Control
                        type="text"
                        value={productDetails.userId}
                        onChange={(e) =>
                          handleInputChange("userId", e.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>입고 금액</Form.Label>
                      <Form.Control
                        type="number"
                        value={productDetails.Extra.toString()}
                        onChange={(e) =>
                          handleInputChange("Extra", Number(e.target.value))
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>입고 금액 상태</Form.Label>
                      <Form.Select
                        value={productDetails.Addstatus}
                        onChange={(e) =>
                          handleInputChange("Addstatus", Number(e.target.value))
                        }
                      >
                        <option value="">Select Status</option>
                        <option value={1}>세금</option>
                        <option value={2}>반송비용</option>
                        <option value={3}>착불배송비</option>
                        <option value={4}>스플릿</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      카테고리
                      <FormControl
                        disabled
                        value={
                          firstDetailItem
                            ? CODE_SHOPPING_ORDER_ITEMS[
                                firstDetailItem.category
                              ] || "Unknown Category"
                            : "No Detail Available"
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    <div>
                      상품명
                      <FormControl
                        disabled
                        value={
                          firstDetailItem ? firstDetailItem.name : "No Name"
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      수량
                      <FormControl
                        disabled
                        value={
                          firstDetailItem
                            ? firstDetailItem.quantity
                            : "No Quantity"
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    <div>
                      단가
                      <FormControl
                        disabled
                        value={
                          firstDetailItem
                            ? `${firstDetailItem.price} USD / ${(
                                firstDetailItem.price * exchangeRate
                              ).toFixed(2)} KRW`
                            : "No Price"
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="d-flex justify-content-end gap-2">
                {productDetails.status === 20 && (
                  <Button variant="danger" onClick={handleReactivate}>
                    복구
                  </Button>
                )}
                <Button variant="danger" onClick={handleDelete}>
                  삭제
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  수정
                </Button>
                <Button variant="secondary" onClick={onClose}>
                  Close
                </Button>
              </div>
            </Form>
          </Inner>
        )}
      </div>
      <Modal show={showImageModal} onHide={closeImageModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={productDetails?.imageUrl}
            alt="Enlarged"
            style={{ width: "100%", maxHeight: "80vh", objectFit: "contain" }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

const Inner = styled.div`
  .detail-container {
    padding: 15px;
    overflow-y: auto;
  }
  h5 {
    margin-bottom: 20px;
  }
  .row {
    margin-bottom: 5px;
  }
  .row > .col {
    padding: 1px;
  }
  div {
    margin-bottom: 1px;
  }
`;
