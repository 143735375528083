import { AxiosManager } from "../axiosManager";

export const reqEveryRate = async (payload: any) => {
  const result = await AxiosManager.get("/rate/get-every", { params: payload });
  return result;
};

// 결제 정보 업데이트 API 추가
export const reqUpdatePaymentInfo = async (orderId: string) => {
  return await AxiosManager.put(`/shop/products/payment-info/${orderId}`);
};

// 배송 결제 정보 업데이트 API 추가
export const reqUpdateShipPaymentInfo = async (orderId: string) => {
  return await AxiosManager.put(`/ship/payment-info/${orderId}`);
};