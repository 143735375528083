import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Form,
  InputGroup,
  Modal,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Select, { SingleValue } from "react-select";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  ACODE_SHOPING_ORDER_STATUS,
  CODE_COUNTRY,
  CODE_COUNTRY_ITEMS,
  CODE_SHIPPING_ORDER_STATUS,
  USER_BADGE,
} from "../../common/constants";
import { RootState } from "../../redux/store";
import { reqSendAlarm } from "../../requests/alarm";
import { reqAddBadge } from "../../requests/badge";
import { reqEveryRate } from "../../requests/rate";
import {
  reqPutExpress,
  reqShip,
  reqShipDetail,
  reqShipFEE,
  reqShipOrderUpdate,
  reqShipProductBox,
  reqShipProductDetail,
  reqShipTrackingGet,
  reqShipUpdateOrder,
} from "../../requests/ship";
import { reqShipEmail } from "../../requests/user";
import { reqGetMembership } from "../../requests/warehouse";
interface CountryOption {
  value: number;
  label: string;
}
interface RepackModalEM {
  show: boolean;
  onHide: () => void;
  orderNumber: string;
  onRepackRequest: () => void;
  refreshData: () => void;
}
interface Product {
  tracking: string;
  warehouseId: string;
  order_id: string;
  isUrgent: boolean;
  bag: boolean;
  name: string;
  warehouse: string;
  price: number;
  overstoreFee: number;
  boxed: number;
  Extra: number;
}

interface PackingDetail {
  id: string;
  order_id: string;
  totalWeightKg: number;
  totalHeightCm: number;
  totalWidthCm: number;
  totalLengthCm: number;
  trackingNumbers: string;
  suspectBattery: boolean;
  food: boolean;
  imageUrl: string;
  isDeleted: boolean;
}

interface OrderDetails {
  user: User;
  products: Product[];
  optionBox: number;
  optionLetter: boolean;
  optionPoster: boolean;
  optionAircap: boolean;
  optionBag: boolean;
  optionSticker: boolean;
  useVacuumPackagingBag: number;
  adrReceiver: string;
  adrEmail: string;
  adrPhone: string;
  adrCountry: number;
  adrPCCC: string;
  adrZip: string;
  adrState: string;
  adrCity: string;
  adrOption1: string;
  adrOption2: string;
  adrOption3: string;
  estimatedAt: string;
  expiredAt: string;
  weightKg: number;
  heightCm: number;
  widthCm: number;
  lengthCm: number;
  suspectBattery: boolean;
  food: boolean;
  packingStatus: number;
  customerMessage: string;
  managerMessage: string;
}
interface TrackingInfo {
  id: number;
  orderId: string;
  orderIds: string;
  trackingNumbers: string;
}

interface ShippingSelections {
  FEDEX: boolean;
  UPS: boolean;
  EMS: boolean;
  K_PACKET: boolean;
  CJ: boolean;
}

interface User {
  id: string;
  email: string;
  membershipId: number;
}

interface ShipmentRateDetail {
  totalSurcharges: number;
}

interface RatedShipmentDetail {
  rateType: string;
  shipmentRateDetail: ShipmentRateDetail;
}

interface RateReplyDetail {
  ratedShipmentDetails: RatedShipmentDetail[];
}

interface FedExApiResponse {
  data: {
    output: {
      rateReplyDetails: RateReplyDetail[];
    };
  };
}

export default function RepackModalEM({
  show,
  onHide,
  orderNumber,
  onRepackRequest,
  refreshData,
}: RepackModalEM) {
  const defaultUser: User = {
    id: "",
    email: "",
    membershipId: 0,
  };
  // 전역 스타일 추가
  useEffect(() => {
    // 전역 스타일을 추가하는 함수
    const addGlobalStyle = () => {
      const styleElement = document.createElement("style");
      styleElement.id = "print-styles";
      styleElement.textContent = `
        .print-modal-wide {
          max-width: 90% !important;
        }
        .print-table-container {
          overflow-x: auto;
          width: 100%;
        }
        @media (max-width: 992px) {
          .print-table-container table {
            min-width: 800px;
          }
        }
        @media print {
          .modal, .modal-dialog, .modal-content, .modal-body {
            position: static !important;
            display: block !important;
            width: 100% !important;
            height: auto !important;
            overflow: visible !important;
          }
          .modal-backdrop, .modal-header, .modal-footer, .no-print {
            display: none !important;
          }
        }
      `;
      document.head.appendChild(styleElement);
    };

    // 컴포넌트 마운트 시 스타일 추가
    addGlobalStyle();

    // 컴포넌트 언마운트 시 스타일 제거
    return () => {
      const styleElement = document.getElementById("print-styles");
      if (styleElement) {
        document.head.removeChild(styleElement);
      }
    };
  }, []);

  const getDefaultPaymentDeadline = () => {
    const today = new Date();
    const thirtyDaysLater = new Date(today.setDate(today.getDate() + 30));
    const isoString = thirtyDaysLater.toISOString().split("T")[0];
    return `${isoString}T00:00`;
  };
  const [paymentDeadline, setPaymentDeadline] = useState(
    getDefaultPaymentDeadline()
  );
  const admin = useSelector((state: RootState) => state.admin);

  const [exchangeRate, setExchangeRate] = useState(0);
  const [key, setKey] = useState("요청정보");
  const [excludedProducts, setExcludedProducts] = useState<Product[]>([]);
  const [customerMessage, setCustomerMessage] = useState("");
  const [fedexKrw, setFedexKrw] = useState("");
  const [fedexUsd, setFedexUsd] = useState("");
  const [upsKrw, setUpsKrw] = useState("");
  const [upsUsd, setUpsUsd] = useState("");
  const [EMSKrw, setEMSKrw] = useState("");
  const [EMSUsd, setEMSUsd] = useState("");
  const [PACKETKrw, setPACKETKrw] = useState("");
  const [PACKETUsd, setPACKETUsd] = useState("");
  const [CJKrw, setCJKrw] = useState("");
  const [CJUsd, setCJUsd] = useState("");
  const [showRepackConfirmModal, setShowRepackConfirmModal] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const printComponentRef = useRef<HTMLDivElement>(null);
  const [expiredAt, setExpiredAt] = useState("");
  const [adrOption1, setAdrOption1] = useState("");
  const [adrOption2, setAdrOption2] = useState("");
  const [adrOption3, setAdrOption3] = useState("");
  const [adrReceiver, setadrReceiver] = useState("");
  const [adrEmail, setadrEmail] = useState("");
  const [adrPhone, setadrPhone] = useState("");
  const [adrCountry, setadrCountry] = useState("");
  const [adrZip, setAdrZip] = useState("");
  const [adrPCCC, setadrPCCC] = useState("");
  const [adrState, setadrState] = useState("");
  const [adrCity, setadrCity] = useState("");
  const [exchangeRateN, setExchangeRateN] = useState<number>(0);
  const [packingDetails, setPackingDetails] = useState<PackingDetail[]>([]);
  const [trackingInfo, setTrackingInfo] = useState<TrackingInfo[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [imageToShow, setImageToShow] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);

  const handleImageClick = (imageUrl: string) => {
    setImageToShow(imageUrl);
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
  };

  const [orderDetails, setOrderDetails] = useState<OrderDetails>({
    products: [],
    user: defaultUser,
    optionBox: 0,
    optionLetter: false,
    optionPoster: false,
    optionAircap: false,
    optionBag: false,
    optionSticker: false,
    adrReceiver: "",
    adrEmail: "",
    adrPhone: "",
    adrCountry: 0,
    adrPCCC: "",
    adrZip: "",
    adrState: "",
    adrCity: "",
    adrOption1: "",
    adrOption2: "",
    adrOption3: "",
    estimatedAt: "",
    expiredAt: "",
    useVacuumPackagingBag: 0,
    weightKg: 0,
    heightCm: 0,
    widthCm: 0,
    lengthCm: 0,
    suspectBattery: false,
    food: false,
    packingStatus: 0,
    customerMessage: "",
    managerMessage: "",
  });

  const [isZoomed, setIsZoomed] = useState(false);

  // const fetchExchangeRateN = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRateN(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);
  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (
        response &&
        response.data &&
        response.data[0]?.fixedExchangeRateShop
      ) {
        setFixedExchangeRate(
          parseFloat(response.data[0].fixedExchangeRateShop)
        );
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    // fetchExchangeRateN();
    fetchFixedExchangeRate();
  });

  const convertToKRW = (usd: number) => {
    return Math.floor(usd * 1000).toLocaleString();
  };

  const convertToUSD = (krw: number) => {
    return (krw / exchangeRate).toFixed(2);
  };

  const [shippingSelections, setShippingSelections] =
    useState<ShippingSelections>({
      FEDEX: false,
      UPS: false,
      EMS: false,
      K_PACKET: false,
      CJ: false,
    });
  const [everyRate, setEveryRate] = useState<any>({});

  const handleShippingSelectionChange = (carrier: keyof ShippingSelections) => {
    setShippingSelections((prev) => ({ ...prev, [carrier]: !prev[carrier] }));
  };

  const handleSelect = (k: any) => {
    setKey(k);
  };

  const handleRepackageRequest = () => {
    setShowPrintModal(true);
  };

  const fetchTrackingInfo = async () => {
    try {
      const response = await reqShipTrackingGet({ id: orderNumber });
      setTrackingInfo(response.data);
    } catch (error) {
      console.error("Error fetching tracking information: ", error);
    }
  };

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  const fetchExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      const fixedExchangeRate = response.data[0]?.fixedExchangeRate;
      if (fixedExchangeRate) {
        setExchangeRate(parseFloat(fixedExchangeRate));
      } else {
        console.error("Failed to fetch fixed exchange rate: No data");
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  const fetchOrderDetails = async () => {
    try {
      const response = await reqShip({ id: orderNumber });
      const products = response.data.products || [];
      setOrderDetails(response.data);

      const excluded = products.filter((p: Product) => p.boxed === 0);
      setExcludedProducts(excluded);

      const responseShip = await reqShipDetail({ order_id: orderNumber });
      setPackingDetails(responseShip.data);

      await getRates(response.data, responseShip.data);
    } catch (error) {
      console.error("Error fetching order details: ", error);
    }
  };

  // const fetchPackingDetails = async () => {
  //   try {

  //   } catch (error) {
  //     console.error("Error fetching packing details: ", error);
  //   }
  // };

  useEffect(() => {
    fetchExchangeRate();
    if (orderNumber) {
      fetchOrderDetails();
      fetchTrackingInfo();
      // fetchPackingDetails();
    }
  }, [orderNumber]);

  // useEffect(() => {
  //   if (packingDetails.length > 0) {

  //   }
  // }, [orderNumber, packingDetails]);

  const isShipperSelected = () => {
    return (
      shippingSelections.FEDEX ||
      shippingSelections.UPS ||
      shippingSelections.EMS ||
      shippingSelections.K_PACKET ||
      shippingSelections.CJ
    );
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleUpdateEstimate = async () => {
    if (!isShipperSelected()) {
      Toast.fire({
        icon: "success",
        title: "최소 1개 이상의 배송사를 선택해야 합니다.",
      });
      return;
    }

    Swal.fire({
      title: "견적서를 전송하시겠습니까?",
      text: "사용자에게 알림을 발송합니다.",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await updateEstimate();

        const alarmPayload = {
          userId: orderDetails.user.id,
          read: 0,
          content: `${orderNumber}'s is completed. Please check the invoice and proceed with the payment from the pending payment page.`,
          sender: admin.name,
        };
        await reqSendAlarm(alarmPayload);

        const badgePayload = {
          userId: orderDetails.user.id,
          badgeName: USER_BADGE.ALL_PRODUCT,
        };
        await reqAddBadge(badgePayload);

        const shipBadgePayload = {
          userId: orderDetails.user.id,
          badgeName: USER_BADGE.SHIP_PAYMENT_WAITING,
        };
        await reqAddBadge(shipBadgePayload);

        Swal.fire("견적서를 전송하였습니다.", "", "success");
      }
    });
  };

  const calculateVolumeWeight5000 = (
    width: number,
    length: number,
    height: number
  ) => {
    return (width * length * height) / 5000;
  };

  const calculateVolumeWeight6000 = (
    width: number,
    length: number,
    height: number
  ) => {
    return (width * length * height) / 6000;
  };

  const roundToFirstDecimal = (num: any) => {
    const integerPart = Math.floor(num);
    const decimalPart = num - integerPart;

    if (decimalPart <= 0.5) {
      return integerPart + 0.5;
    } else {
      return integerPart + 1;
    }
  };

  const roundToTwo = (num: number) =>
    Math.round((num + Number.EPSILON) * 100) / 100;

  const updateEstimate = async () => {
    const { totalCostKRW: OptionCostKRW, totalCostUSD: OptionCostUSD } =
      calculateOptionCosts();

    const PackingCostKRW = 0;
    const PackingCostUSD = PackingCostKRW / exchangeRate;

    const WarehouseCostKRW = orderDetails.products
      .filter((product) => product.boxed !== 0)
      .reduce(
        (acc, product) => acc + product.overstoreFee * 1000 + product.Extra,
        0
      );

    const WarehouseCostUSD = orderDetails.products
      .filter((product) => product.boxed !== 0)
      .reduce(
        (acc, product) =>
          acc + product.overstoreFee + product.Extra / exchangeRate,
        0
      );

    const now = new Date();
    const estimatedAtISO = now.toISOString();
    let expiredAtISO;

    if (paymentDeadline) {
      const deadlineDate = new Date(paymentDeadline);
      expiredAtISO = deadlineDate.toISOString();
    }

    const shippingCostsPayload = {
      FEDEXPriceKRW: shippingSelections.FEDEX
        ? parseFloat(fedexKrw.toString().replace(/₩|,/g, ""))
        : null,
      FEDEXPriceUSD: shippingSelections.FEDEX
        ? parseFloat(fedexUsd.toString().replace(/\$|,/g, ""))
        : null,
      UPSPriceKRW: shippingSelections.UPS
        ? parseFloat(upsKrw.toString().replace(/₩|,/g, ""))
        : null,
      UPSPriceUSD: shippingSelections.UPS
        ? parseFloat(upsUsd.toString().replace(/\$|,/g, ""))
        : null,
      EMSPriceKRW: shippingSelections.EMS
        ? parseFloat(EMSKrw.toString().replace(/₩|,/g, ""))
        : null,
      EMSPriceUSD: shippingSelections.EMS
        ? parseFloat(EMSUsd.toString().replace(/\$|,/g, ""))
        : null,
      PACKETPriceKRW: shippingSelections.K_PACKET
        ? parseFloat(PACKETKrw.toString().replace(/₩|,/g, ""))
        : null,
      PACKETPriceUSD: shippingSelections.K_PACKET
        ? parseFloat(PACKETUsd.toString().replace(/\$|,/g, ""))
        : null,
      CJPriceKRW: shippingSelections.CJ
        ? parseFloat(CJKrw.toString().replace(/₩|,/g, ""))
        : null,
      CJPriceUSD: shippingSelections.CJ
        ? parseFloat(CJUsd.toString().replace(/\$|,/g, ""))
        : null,
      packagPriceKRW: parseFloat(repackFeeKRW.toString().replace(/₩|,/g, "")),
      packagPriceUSD: parseFloat(repackFeeUSD.toString().replace(/\$|,/g, "")),
      optionPriceKRW: OptionCostKRW,
      optionPriceUSD: roundToTwo(OptionCostUSD),
      warehousePriceKRW: WarehouseCostKRW,
      warehousePriceUSD: roundToTwo(WarehouseCostUSD),
    };

    try {
      for (const product of excludedProducts) {
        await reqShipProductBox({
          tracking: product.tracking,
          status: 2,
        });
      }

      await reqShipUpdateOrder({
        id: orderNumber,
        status: ACODE_SHOPING_ORDER_STATUS.PAID,
      });

      await reqShipProductDetail({
        id: orderNumber,
      });

      await reqShipOrderUpdate({
        id: orderNumber,
        estimatedAt: estimatedAtISO,
        expiredAt: expiredAtISO,
        customerMessage: customerMessage,
        ...shippingCostsPayload,
      });

      // 추가: 이메일 전송
      await reqShipEmail({
        to: orderDetails.adrEmail,
      });

      onRepackRequest();
      onHide();
      refreshData();
      alert("주문 업데이트 성공");
    } catch (error) {
      console.error("주문 업데이트 실패:", error);
      alert("주문 업데이트 실패");
    }
  };

  const handleRepackageClick = async () => {
    try {
      await reqShipUpdateOrder({
        id: orderNumber,
        status: CODE_SHIPPING_ORDER_STATUS.WAIT_PAY,
      });

      for (const detail of packingDetails) {
        await reqPutExpress({
          id: detail.id,
          isDeleted: true,
        });
      }

      const updateBoxedPromises = orderDetails.products.map((product) =>
        reqShipProductBox({
          tracking: product.tracking,
          boxed: 0,
        })
      );
      await Promise.all(updateBoxedPromises);

      refreshData();
      onHide();
      alert("재포장 요청이 성공적으로 처리되었습니다.");
    } catch (error) {
      console.error("재포장 요청 처리에 실패했습니다: ", error);
      alert("재포장 요청 처리에 실패했습니다.");
    }
  };

  function getCountryCodeFromName(countryName: any) {
    const country = CODE_COUNTRY.find((c) => c.name === countryName);
    return country ? country.code : "KR";
  }

  const getRates = async (orderDetails_: any, packingDetails_: any) => {
    const validPackingDetails = packingDetails_.filter(
      (detail: any) => !detail.isDeleted
    );

    if (validPackingDetails.length === 0) {
      console.log("필요한 정보가 부족합니다.");
      setIsLoading(false);
      return;
    }

    const countryCode = getCountryCodeFromName(
      CODE_COUNTRY_ITEMS[orderDetails_.adrCountry]
    );

    if (!countryCode) {
      console.log("필요한 정보가 부족합니다.");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    let totalRates = {
      fedex: { krw: 0, usd: 0 },
      ups: { krw: 0, usd: 0 },
      ems: { krw: 0, usd: 0 },
      kpacket: { krw: 0, usd: 0 },
    };

    for (const detail of validPackingDetails) {
      const dimensions = {
        totalLengthCm: detail.totalLengthCm || 0,
        totalWidthCm: detail.totalWidthCm || 0,
        totalHeightCm: detail.totalHeightCm || 0,
      };

      const res = await reqEveryRate({
        countryCode: countryCode,
        stateCode: orderDetails_.adrState,
        postalCode: orderDetails_.adrZip,
        totalWeight: Number(detail.totalWeightKg) * 1000,
        boxWidth: dimensions.totalWidthCm,
        boxHeight: dimensions.totalHeightCm,
        boxLength: dimensions.totalLengthCm,
      });

      if (res.data) {
        const rateData = res.data;

        totalRates.fedex.krw += rateData.fedex.krw
          ? parseFloat(rateData.fedex.krw)
          : 0;
        totalRates.fedex.usd += rateData.fedex.usd
          ? parseFloat(rateData.fedex.usd)
          : 0;
        totalRates.ups.krw += rateData.ups.krw
          ? parseFloat(rateData.ups.krw)
          : 0;
        totalRates.ups.usd += rateData.ups.usd
          ? parseFloat(rateData.ups.usd)
          : 0;
        totalRates.ems.krw += rateData.ems.krw
          ? parseFloat(rateData.ems.krw)
          : 0;
        totalRates.ems.usd += rateData.ems.usd
          ? parseFloat(rateData.ems.usd)
          : 0;
        totalRates.kpacket.krw += rateData.kpacket.krw
          ? parseFloat(rateData.kpacket.krw)
          : 0;
        totalRates.kpacket.usd += rateData.kpacket.usd
          ? parseFloat(rateData.kpacket.usd)
          : 0;
      }
    }

    setFedexKrw(totalRates.fedex.krw.toFixed(0));
    setFedexUsd(totalRates.fedex.usd.toFixed(2));
    setUpsKrw(totalRates.ups.krw.toFixed(0));
    setUpsUsd(totalRates.ups.usd.toFixed(2));
    setEMSKrw(totalRates.ems.krw.toFixed(0));
    setEMSUsd(totalRates.ems.usd.toFixed(2));
    setPACKETKrw(totalRates.kpacket.krw.toFixed(0));
    setPACKETUsd(totalRates.kpacket.usd.toFixed(2));

    setIsLoading(false);
  };

  const getPackingStatusText = (status: number) => {
    switch (status) {
      case 1:
        return "전체포장완료";
      case 2:
        return "공간 부족";
      case 3:
        return "30kg 초과";
      default:
        return "정보 없음";
    }
  };

  const calculateOptionCosts = () => {
    const costs = {
      stickerPerBox: 2000,
      aircapPerBox: 3000,
      insurancePerBox: 5000,
      vacuumPackPerTracking: 5000,
    };

    const actualBoxCount = packingDetails.filter(
      (detail) => !detail.isDeleted
    ).length;

    let totalCostKRW = 0;
    let totalCostUSD = 0;

    if (orderDetails.optionSticker) {
      totalCostKRW += costs.stickerPerBox * actualBoxCount;
      totalCostUSD += (costs.stickerPerBox * actualBoxCount) / exchangeRate;
    }
    if (orderDetails.optionAircap) {
      totalCostKRW += costs.aircapPerBox * actualBoxCount;
      totalCostUSD += (costs.aircapPerBox * actualBoxCount) / exchangeRate;
    }
    if (orderDetails.optionBag) {
      totalCostKRW += costs.insurancePerBox * actualBoxCount;
      totalCostUSD += (costs.insurancePerBox * actualBoxCount) / exchangeRate;
    }

    const productsUsingVacuumPack = orderDetails.products.filter(
      (product) => product.bag === true && product.boxed !== 0
    );
    totalCostKRW +=
      costs.vacuumPackPerTracking * orderDetails.useVacuumPackagingBag;
    totalCostUSD +=
      (costs.vacuumPackPerTracking * orderDetails.useVacuumPackagingBag) /
      exchangeRate;

    return { totalCostKRW, totalCostUSD };
  };

  const { totalCostKRW, totalCostUSD } = calculateOptionCosts();

  // 무게측정

  const [membershipData, setMembershipData] = useState<any[]>([]);
  const [repurchaseDiscounts, setRepurchaseDiscounts] = useState({
    blue: 0,
    green: 0,
    red: 0,
  });

  // API 호출 함수
  const fetchMembershipData = async () => {
    try {
      const response = await reqGetMembership(); // API 호출
      const apiData = response.data;

      setMembershipData(apiData);

      // id === 1인 항목에서 repurchaseDiscountBlue, Green, Red 값을 추출
      const targetMembership = apiData.find((item: any) => item.id === 1);
      if (targetMembership) {
        setRepurchaseDiscounts({
          blue: targetMembership.repurchaseDiscountBule,
          green: targetMembership.repurchaseDiscountGreen,
          red: targetMembership.repurchaseDiscountRed,
        });
      }
    } catch (error) {
      console.error("Error fetching membership data:", error);
    }
  };

  useEffect(() => {
    fetchMembershipData();
  }, []);

  const totalWeightSum = packingDetails
    .filter((detail) => !detail.isDeleted)
    .reduce((sum, detail) => {
      const weight = Number(detail.totalWeightKg);
      return sum + (isNaN(weight) ? 0 : weight);
    }, 0);

  const membershipDiscounts = {
    1: repurchaseDiscounts.red / 100,
    2: repurchaseDiscounts.blue / 100,
    3: repurchaseDiscounts.green / 100,
  };

  const calculateMembershipDiscountRate = () => {
    const membershipDiscountRates = {
      1: repurchaseDiscounts.red,
      2: repurchaseDiscounts.blue,
      3: repurchaseDiscounts.green,
    };

    const discountRate =
      membershipDiscountRates[
        orderDetails.user.membershipId as keyof typeof membershipDiscountRates
      ] || 0;
    return discountRate;
  };

  const calculateRepackFee = () => {
    const boxCounts = new Map();
    orderDetails.products.forEach((product) => {
      boxCounts.set(product.boxed, (boxCounts.get(product.boxed) || 0) + 1);
    });

    let totalRepackFeeKRW = 0;
    let totalRepackFeeUSD = 0;

    boxCounts.forEach((count, boxNumber) => {
      if (count > 1) {
        const packingDetail = packingDetails[boxNumber - 1];
        if (packingDetail) {
          const repackFeePerBox = packingDetail.totalWeightKg * 1000;
          totalRepackFeeKRW += repackFeePerBox;
          totalRepackFeeUSD += repackFeePerBox / 1000;
        }
      }
    });

    let discountRate =
      membershipDiscounts[
        orderDetails.user.membershipId as keyof typeof membershipDiscounts
      ] || 0;
    totalRepackFeeKRW *= 1 - discountRate;
    totalRepackFeeUSD *= 1 - discountRate;

    return {
      repackFeeKRW: totalRepackFeeKRW,
      repackFeeUSD: totalRepackFeeUSD,
    };
  };

  // const { repackFeeKRW, repackFeeUSD } = calculateRepackFee();

  const [repackFeeKRW, setRepackFeeKRW] = useState("");
  const [repackFeeUSD, setRepackFeeUSD] = useState("");

  useEffect(() => {
    const { repackFeeKRW: initialKRW, repackFeeUSD: initialUSD } =
      calculateRepackFee();
    setRepackFeeKRW(initialKRW.toLocaleString("ko-KR"));
    setRepackFeeUSD(initialUSD.toFixed(2));
  }, [packingDetails]);

  const handleRepackFeeKRWChange = (e: any) => {
    setRepackFeeKRW(e.target.value);
  };

  const handleRepackFeeUSDChange = (e: any) => {
    setRepackFeeUSD(e.target.value);
  };
  // 주소 업데이트
  const handleAdrOption1Change = (e: any) => setAdrOption1(e.target.value);
  const handleAdrOption2Change = (e: any) => setAdrOption2(e.target.value);
  const handleAdrOption3Change = (e: any) => setAdrOption3(e.target.value);
  const handleAdrZipChange = (e: any) => setAdrZip(e.target.value);
  const handleadrReceiver = (e: any) => setadrReceiver(e.target.value);
  const handleAdradrEmail = (e: any) => setadrEmail(e.target.value);
  const handleadrPhone = (e: any) => setadrPhone(e.target.value);
  const handleadrCountry = (e: any) => setadrCountry(e.target.value);
  const handleadrPCCC = (e: any) => setadrPCCC(e.target.value);
  const handleadrCity = (e: any) => setadrCity(e.target.value);
  const handleadrState = (e: any) => setadrState(e.target.value);
  const handleCountrySelect = (selectedOption: SingleValue<CountryOption>) => {
    if (selectedOption) {
      setadrCountry(String(selectedOption.value));
      setOrderDetails((prev) => ({
        ...prev,
        adrCountry: selectedOption.value,
      }));
    }
  };

  const countryOptions = Object.entries(CODE_COUNTRY_ITEMS).map(
    ([key, value]) => ({
      value: Number(key),
      label: value as string,
    })
  );

  const defaultCountryOption = countryOptions.find(
    (option) => option.value === Number(orderDetails.adrCountry)
  );

  const handleAddressUpdate = async () => {
    const updatedFields = {
      id: orderNumber,
      ...(adrOption1 && { adrOption1 }),
      ...(adrOption2 && { adrOption2 }),
      ...(adrOption3 && { adrOption3 }),
      ...(adrZip && { adrZip }),
      ...(adrReceiver && { adrReceiver }),
      ...(adrEmail && { adrEmail }),
      ...(adrPhone && { adrPhone }),
      ...(adrCountry && { adrCountry }),
      ...(adrPCCC && { adrPCCC }),
      ...(adrState && { adrState }),
      ...(adrCity && { adrCity }),
    };

    try {
      await reqShipOrderUpdate(updatedFields);
      alert("주소 업데이트 성공");
      refreshData();
      onHide();
    } catch (error) {
      console.error("주소 업데이트 실패:", error);
    }
  };

  const [warehouseTotalKRW, warehouseTotalUSD] = [
    orderDetails.products
      .filter((product) => product.boxed !== 0)
      .reduce(
        (acc, product) =>
          acc +
          (isNaN(Number(product.overstoreFee))
            ? 0
            : Math.round(Number(product.overstoreFee) * 1000)) +
          product.Extra,
        0
      )
      .toLocaleString("ko-KR"),
    orderDetails.products
      .filter((product) => product.boxed !== 0)
      .reduce(
        (acc, product) =>
          acc +
          (isNaN(Number(product.overstoreFee))
            ? 0
            : Number(product.overstoreFee)) +
          product.Extra / exchangeRate,
        0
      )
      .toFixed(2),
  ];

  const handleFedexKrwChange = (e: any) => {
    setFedexKrw(e.target.value);
  };

  const handleFedexUsdChange = (e: any) => {
    setFedexUsd(e.target.value);
  };

  const handleUpsKrwChange = (e: any) => {
    setUpsKrw(e.target.value);
  };

  const handleUpsUsdChange = (e: any) => {
    setUpsUsd(e.target.value);
  };

  const handleEmsKrwChange = (e: any) => {
    setEMSKrw(e.target.value);
  };

  const handleEmsUsdChange = (e: any) => {
    setEMSUsd(e.target.value);
  };

  const handlePacketKrwChange = (e: any) => {
    setPACKETKrw(e.target.value);
  };

  const handlePacketUsdChange = (e: any) => {
    setPACKETUsd(e.target.value);
  };

  const handleCjKrwChange = (e: any) => {
    setCJKrw(e.target.value);
  };

  const handleCjUsdChange = (e: any) => {
    setCJUsd(e.target.value);
  };

  // 포장 정보 수정
  const [editablePackingDetails, setEditablePackingDetails] = useState<
    PackingDetail[]
  >([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setEditablePackingDetails(packingDetails);
  }, [packingDetails]);

  const handleInputChange = (
    id: string,
    field: keyof PackingDetail,
    value: string
  ) => {
    setEditablePackingDetails((prevDetails) =>
      prevDetails.map((detail) =>
        detail.id === id ? { ...detail, [field]: parseFloat(value) } : detail
      )
    );
  };

  const handleUpdateWeights = async () => {
    try {
      await Promise.all(
        editablePackingDetails.map((detail) =>
          reqPutExpress({
            id: detail.id,
            totalWeightKg: detail.totalWeightKg,
            totalHeightCm: detail.totalHeightCm,
            totalWidthCm: detail.totalWidthCm,
            totalLengthCm: detail.totalLengthCm,
          })
        )
      );
      alert("무게, 가로, 세로, 높이가 성공적으로 업데이트되었습니다.");
      onHide();
    } catch (error) {
      console.error("업데이트 실패:", error);
      alert("업데이트에 실패했습니다.");
    }
  };

  const handlePrint = () => {
    const printContent = printComponentRef.current;

    if (printContent) {
      // 새로운 창 열기
      const printWindow = window.open("", "_blank", "width=800,height=600");

      if (!printWindow) {
        alert("팝업이 차단되었습니다. 팝업 차단을 해제해주세요.");
        return;
      }

      // 인쇄 스타일 정의
      const printStyles = `
        @media print {
          body { font-size: 12pt; margin: 0; padding: 20px; }
          table { width: 100%; border-collapse: collapse; }
          th, td { border: 1px solid #000; padding: 8px; text-align: center; }
          th { background-color: #f2f2f2; }
          .no-print { display: none; }
          h2 { text-align: center; margin-bottom: 20px; }
        }
        body { font-family: Arial, sans-serif; }
        .print-table-container {
          width: 100%;
        }
        table { width: 100%; border-collapse: collapse; }
        th, td { border: 1px solid #000; padding: 8px; text-align: center; }
        th { background-color: #f2f2f2; }
        h2 { text-align: center; margin-bottom: 20px; }
      `;

      // 새 창에 내용 채우기
      printWindow.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <title>재포장 포장정보 인쇄</title>
            <style>${printStyles}</style>
          </head>
          <body>
            <div class="print-table-container">
              ${printContent.innerHTML}
            </div>
            <script>
              // 문서 로드 완료 후 자동으로 인쇄 시작
              window.onload = function() {
                setTimeout(function() {
                  window.print();
                  setTimeout(function() {
                    window.close();
                  }, 100);
                }, 500);
              };
            </script>
          </body>
        </html>
      `);

      printWindow.document.close();

      // 인쇄 작업이 시작되면 모달 상태 변경
      setTimeout(() => {
        setShowPrintModal(false);
        setShowRepackConfirmModal(true);
      }, 1000);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSection>
          <img src="/resources/img/loading.gif" alt="로딩중" />
          <p>배송 운임 API를 불러오는 중입니다...</p>
        </LoadingSection>
      ) : (
        <Modal
          show={show}
          onHide={onHide}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              견적서 - {orderNumber}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs activeKey={key} onSelect={handleSelect} className="mb-3">
              <Tab eventKey="요청정보" title="요청정보">
                <div className="request-info">
                  <div>
                    <strong>서비스 옵션:</strong>
                    <ul>
                      <Form.Check
                        label="한박스만 포장"
                        checked={orderDetails.optionBox === 0}
                        disabled
                        onChange={(e) =>
                          setOrderDetails({
                            ...orderDetails,
                            optionBox: e.target.checked ? 1 : 0,
                          })
                        }
                      />
                      <Form.Check
                        label="편지봉투 박스 포장"
                        checked={orderDetails.optionLetter}
                        disabled
                        onChange={(e) =>
                          setOrderDetails({
                            ...orderDetails,
                            optionLetter: e.target.checked,
                          })
                        }
                      />
                      <Form.Check
                        label="포스터 튜브 최소화"
                        checked={orderDetails.optionPoster}
                        disabled
                        onChange={(e) =>
                          setOrderDetails({
                            ...orderDetails,
                            optionPoster: e.target.checked,
                          })
                        }
                      />
                      <Form.Check
                        label="에어캡 추가"
                        checked={orderDetails.optionAircap}
                        disabled
                        onChange={(e) =>
                          setOrderDetails({
                            ...orderDetails,
                            optionAircap: e.target.checked,
                          })
                        }
                      />
                      <Form.Check
                        label="스티커 4면 부착"
                        checked={orderDetails.optionSticker}
                        disabled
                        onChange={(e) =>
                          setOrderDetails({
                            ...orderDetails,
                            optionSticker: e.target.checked,
                          })
                        }
                      />
                      <Form.Check
                        label="보험가입 유무"
                        checked={orderDetails.optionBag}
                        disabled
                        onChange={(e) =>
                          setOrderDetails({
                            ...orderDetails,
                            optionBag: e.target.checked,
                          })
                        }
                      />
                    </ul>
                  </div>
                </div>
                <table className="table text-center table-hover">
                  <thead>
                    <tr>
                      <th scope="col">NO</th>
                      <th scope="col">운송장번호</th>
                      <th scope="col">창고비용</th>
                      <th scope="col">추가비용</th>
                      <th scope="col">필수포함</th>
                      <th scope="col">진공포장백</th>
                      <th scope="col">BOX</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderDetails.products.map((product, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td
                          style={{
                            textDecoration:
                              product.boxed === 0 ? "line-through" : "none",
                          }}
                        >
                          {product.tracking}
                        </td>
                        <td>
                          ${product.overstoreFee} / ₩
                          {convertToKRW(product.overstoreFee)}
                        </td>
                        <td>
                          ${convertToUSD(product.Extra)} / ₩{product.Extra}
                        </td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            checked={product.isUrgent}
                            disabled
                          />
                        </td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            checked={product.bag}
                            disabled
                          />
                        </td>
                        <td>{product.boxed}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Tab>
              <Tab eventKey="미포함상품" title="미포함상품">
                <div>
                  <Form.Check
                    type="checkbox"
                    id="batteryCheck"
                    label="배터리 포함 의심상품"
                    checked={packingDetails.some(
                      (detail) => detail.suspectBattery
                    )}
                    disabled
                  />
                  <Form.Check
                    type="checkbox"
                    id="foodCheck"
                    label="음식"
                    checked={packingDetails.some((detail) => detail.food)}
                    disabled
                  />
                  <div>
                    포장 상태:{" "}
                    {getPackingStatusText(orderDetails.packingStatus)}
                  </div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>NO</th>
                        <th>운송장번호</th>
                        <th>창고비용</th>
                        <th>추가비용</th>
                        <th>필수포함</th>
                        <th>진공포장백</th>
                        <th>BOX</th>
                      </tr>
                    </thead>
                    <tbody>
                      {excludedProducts.map((product, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{product.tracking}</td>
                          <td>
                            ${product.overstoreFee} / ₩
                            {convertToKRW(product.overstoreFee)}
                          </td>
                          <td>
                            ${convertToUSD(product.Extra)} / ₩{product.Extra}
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={product.isUrgent}
                              disabled
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={product.bag}
                              disabled
                            />
                          </td>
                          <td>{product.boxed}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Tab>
              <Tab eventKey="주소" title="주소">
                <div className="shipping-address">
                  <h4>배송 주소</h4>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="formAddressReceiver"
                    >
                      <Form.Label>받는 사람</Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={35}
                        placeholder="받는 사람 이름"
                        defaultValue={orderDetails.adrReceiver}
                        onChange={handleadrReceiver}
                      />
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>이메일</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={orderDetails.adrEmail}
                        onChange={handleAdradrEmail}
                      />
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>폰번호</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={orderDetails.adrPhone}
                        onChange={handleadrPhone}
                      />
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>국가</Form.Label>
                      <Select<CountryOption>
                        options={countryOptions}
                        defaultValue={defaultCountryOption}
                        onChange={handleCountrySelect}
                        isSearchable
                      />
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>개인통관부호</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={orderDetails.adrPCCC}
                        onChange={handleadrPCCC}
                      />
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>Zipcode</Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={10}
                        defaultValue={orderDetails.adrZip}
                        onChange={handleAdrZipChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>State Province Code</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={orderDetails.adrState}
                        onChange={handleadrState}
                      />
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={orderDetails.adrCity}
                        onChange={handleadrCity}
                      />
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>Address-1(35)</Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={35}
                        defaultValue={orderDetails.adrOption1}
                        onChange={handleAdrOption1Change}
                      />
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>Address-2(35)</Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={35}
                        defaultValue={orderDetails.adrOption2}
                        onChange={handleAdrOption2Change}
                      />
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>Address-3(35)</Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={35}
                        defaultValue={orderDetails.adrOption3}
                        onChange={handleAdrOption3Change}
                      />
                    </Form.Group>
                  </Form>
                </div>
              </Tab>
              <Tab eventKey="포장정보" title="포장정보">
                <div className="packing-info">
                  <h4>포장 정보</h4>
                  <div className="table-responsive">
                    <Table bordered className="table-custom text-center">
                      <thead className="table-light">
                        <tr>
                          <th rowSpan={2} style={{ width: "15%" }}>
                            택배 사진
                          </th>
                          <th rowSpan={2} style={{ width: "10%" }}>
                            상품 ID
                          </th>
                          <th rowSpan={2} style={{ width: "10%" }}>
                            무게 (KG)
                          </th>
                          <th rowSpan={2} style={{ width: "10%" }}>
                            가로 (CM)
                          </th>
                          <th rowSpan={2} style={{ width: "10%" }}>
                            세로 (CM)
                          </th>
                          <th rowSpan={2} style={{ width: "10%" }}>
                            높이 (CM)
                          </th>
                          <th colSpan={2} style={{ width: "20%" }}>
                            적용 중량
                          </th>
                        </tr>
                        <tr>
                          <th style={{ width: "10%" }}>FedEx, UPS</th>
                          <th style={{ width: "10%" }}>EMS, K-Packet</th>
                        </tr>
                      </thead>
                      <tbody>
                        {editablePackingDetails
                          .filter((detail) => !detail.isDeleted)
                          .map((detail, index) => {
                            const volumeWeight5000 = parseFloat(
                              calculateVolumeWeight5000(
                                detail.totalWidthCm,
                                detail.totalLengthCm,
                                detail.totalHeightCm
                              ).toFixed(2)
                            );
                            const volumeWeight6000 = parseFloat(
                              calculateVolumeWeight6000(
                                detail.totalWidthCm,
                                detail.totalLengthCm,
                                detail.totalHeightCm
                              ).toFixed(2)
                            );

                            const roundedWeight5000 =
                              roundToFirstDecimal(volumeWeight5000);
                            const roundedWeight6000 =
                              roundToFirstDecimal(volumeWeight6000);

                            const displayWeight5000 = Math.max(
                              detail.totalWeightKg,
                              roundedWeight5000
                            );
                            const displayWeight6000 = Math.max(
                              detail.totalWeightKg,
                              roundedWeight6000
                            );

                            return (
                              <tr key={index}>
                                <td>
                                  {detail.imageUrl ? (
                                    <img
                                      src={detail.imageUrl}
                                      alt="Attached File"
                                      style={{
                                        maxWidth: "10rem",
                                        height: "auto",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleImageClick(detail.imageUrl)
                                      }
                                    />
                                  ) : (
                                    "No image available"
                                  )}
                                </td>
                                <td>{detail.id}</td>
                                <td>
                                  {isEditing ? (
                                    <Form.Control
                                      type="number"
                                      value={detail.totalWeightKg}
                                      onChange={(e) =>
                                        handleInputChange(
                                          detail.id,
                                          "totalWeightKg",
                                          e.target.value
                                        )
                                      }
                                    />
                                  ) : (
                                    detail.totalWeightKg
                                  )}
                                </td>
                                <td>
                                  {isEditing ? (
                                    <Form.Control
                                      type="number"
                                      value={detail.totalWidthCm}
                                      onChange={(e) =>
                                        handleInputChange(
                                          detail.id,
                                          "totalWidthCm",
                                          e.target.value
                                        )
                                      }
                                    />
                                  ) : (
                                    detail.totalWidthCm
                                  )}
                                </td>
                                <td>
                                  {isEditing ? (
                                    <Form.Control
                                      type="number"
                                      value={detail.totalLengthCm}
                                      onChange={(e) =>
                                        handleInputChange(
                                          detail.id,
                                          "totalLengthCm",
                                          e.target.value
                                        )
                                      }
                                    />
                                  ) : (
                                    detail.totalLengthCm
                                  )}
                                </td>
                                <td>
                                  {isEditing ? (
                                    <Form.Control
                                      type="number"
                                      value={detail.totalHeightCm}
                                      onChange={(e) =>
                                        handleInputChange(
                                          detail.id,
                                          "totalHeightCm",
                                          e.target.value
                                        )
                                      }
                                    />
                                  ) : (
                                    detail.totalHeightCm
                                  )}
                                </td>
                                <td>{displayWeight5000}</td>
                                <td>{displayWeight6000}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="견적서" title="견적서">
                <div className="invoice-info">
                  <h2>견적서</h2>
                  <div className="summary">
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th>배송국가</th>
                          <th>요청수량</th>
                          <th>미포함수량</th>
                          <th>미포함사유</th>
                          <th>박스수량</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {CODE_COUNTRY_ITEMS[
                              Number(orderDetails.adrCountry)
                            ] || "Country not selected"}
                          </td>
                          <td>{orderDetails.products.length}</td>
                          <td>
                            {
                              orderDetails.products.filter(
                                (product) => !product.boxed
                              ).length
                            }
                          </td>
                          <td>
                            {getPackingStatusText(orderDetails.packingStatus)}
                          </td>
                          <td>
                            {
                              packingDetails.filter(
                                (detail) => !detail.isDeleted
                              ).length
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* 포장비용 */}
                  <div className="repack-fee mt-4">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan={3} className="text-center">
                            포장 비용
                          </th>
                        </tr>
                        <tr>
                          <td>박스수량</td>
                          <td colSpan={2} className="text-center">
                            {
                              packingDetails.filter(
                                (detail) => !detail.isDeleted
                              ).length
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>무게합계</td>
                          <td colSpan={2} className="text-center">
                            {Number(totalWeightSum).toFixed(2)} KG
                          </td>
                        </tr>
                        <tr>
                          <td>멤버쉽 할인율 (%)</td>
                          <td colSpan={2} className="text-center">
                            {calculateMembershipDiscountRate()}%
                          </td>
                        </tr>
                        <tr className="table-primary">
                          <th>Total</th>
                          <td className="text-center">
                            <input
                              type="text"
                              value={repackFeeKRW}
                              onChange={handleRepackFeeKRWChange}
                              className="form-control text-center"
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="text"
                              value={repackFeeUSD}
                              onChange={handleRepackFeeUSDChange}
                              className="form-control text-center"
                            />
                          </td>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </Table>
                  </div>
                  {/* 옵션비용 */}
                  <div className="option-fee">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan={3} className="text-center">
                            옵션 비용
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>스티커 부착</td>
                          <td colSpan={2} className="text-center">
                            {orderDetails.optionSticker
                              ? `${packingDetails.length}개`
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>에어캡 추가</td>
                          <td colSpan={2} className="text-center">
                            {orderDetails.optionAircap
                              ? `${packingDetails.length}개`
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>보험</td>
                          <td colSpan={2} className="text-center">
                            {orderDetails.optionBag
                              ? `${packingDetails.length}개`
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>진공포장백 사용</td>
                          <td colSpan={2} className="text-center">
                            {orderDetails.useVacuumPackagingBag
                              ? `${orderDetails.useVacuumPackagingBag}개`
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr className="table-primary">
                          <th>Total</th>
                          <td className="text-center">
                            KRW {totalCostKRW.toLocaleString("ko-KR")}
                          </td>
                          <td className="text-center">
                            USD {totalCostUSD.toFixed(2)}
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                  {/* 웨어하우스 비용 */}
                  <div className="warehouse-fee">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan={3} className="text-center">
                            웨어하우스 비용
                          </th>
                        </tr>
                        <tr>
                          <th className="text-center">비용</th>
                          <th className="text-center">KRW</th>
                          <th className="text-center">USD</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center">웨어하우스 수수료</td>
                          <td className="text-center">
                            ₩
                            {orderDetails.products
                              .filter((product) => product.boxed !== 0)
                              .reduce(
                                (acc, product) =>
                                  acc + Math.round(product.overstoreFee * 1000),
                                0
                              )
                              .toLocaleString("ko-KR")}
                          </td>
                          <td className="text-center">
                            $
                            {orderDetails.products
                              .filter((product) => product.boxed !== 0)
                              .reduce(
                                (acc, product) => acc + product.overstoreFee,
                                0
                              )
                              .toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center">엑스트라 차지</td>
                          <td className="text-center">
                            ₩
                            {orderDetails.products
                              .filter((product) => product.boxed !== 0)
                              .reduce((acc, product) => acc + product.Extra, 0)
                              .toLocaleString("ko-KR")}
                          </td>
                          <td className="text-center">
                            $
                            {orderDetails.products
                              .filter((product) => product.boxed !== 0)
                              .reduce(
                                (acc, product) =>
                                  acc + product.Extra / exchangeRate,
                                0
                              )
                              .toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr className="table-primary">
                          <th className="text-center">Total</th>
                          <td className="text-center">₩{warehouseTotalKRW}</td>
                          <td className="text-center">${warehouseTotalUSD}</td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                  {/* 배송비 */}
                  <div className="shipping-fee">
                    <h5 className="mb-3">배송비</h5>
                    <div style={{ marginBottom: "1rem" }}>
                      주의: 박스가 여러 박스인 주문의 경우, EMS, K-Packet
                      운임사는 API 정책상 각 박스를 서로 다른 주문으로 취급하기
                      때문에 가격이 비쌉니다.{" "}
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" />
                          </th>
                          <th>배송사</th>
                          <th>KRW</th>
                          <th>USD</th>
                        </tr>
                      </thead>
                      {everyRate && (
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={shippingSelections.FEDEX}
                                onChange={() =>
                                  handleShippingSelectionChange("FEDEX")
                                }
                              />
                            </td>
                            <td>FEDEX</td>
                            <td>
                              <input
                                type="text"
                                value={fedexKrw}
                                onChange={handleFedexKrwChange}
                                className="form-control text-center"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={fedexUsd}
                                onChange={handleFedexUsdChange}
                                className="form-control text-center"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={shippingSelections.UPS}
                                onChange={() =>
                                  handleShippingSelectionChange("UPS")
                                }
                              />
                            </td>
                            <td>UPS</td>
                            <td>
                              <input
                                type="text"
                                value={upsKrw}
                                onChange={handleUpsKrwChange}
                                className="form-control text-center"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={upsUsd}
                                onChange={handleUpsUsdChange}
                                className="form-control text-center"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={shippingSelections.EMS}
                                onChange={() =>
                                  handleShippingSelectionChange("EMS")
                                }
                              />
                            </td>
                            <td>EMS</td>
                            <td>
                              <input
                                type="text"
                                value={EMSKrw}
                                onChange={handleEmsKrwChange}
                                className="form-control text-center"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={EMSUsd}
                                onChange={handleEmsUsdChange}
                                className="form-control text-center"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={shippingSelections.K_PACKET}
                                onChange={() =>
                                  handleShippingSelectionChange("K_PACKET")
                                }
                              />
                            </td>
                            <td>K-PACKET</td>
                            <td>
                              <input
                                type="text"
                                value={PACKETKrw}
                                onChange={handlePacketKrwChange}
                                className="form-control text-center"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={PACKETUsd}
                                onChange={handlePacketUsdChange}
                                className="form-control text-center"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={shippingSelections.CJ}
                                onChange={() =>
                                  handleShippingSelectionChange("CJ")
                                }
                              />
                            </td>
                            <td>CJ</td>
                            <td>
                              <input
                                type="text"
                                value={CJKrw}
                                onChange={handleCjKrwChange}
                                className="form-control text-center"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={CJUsd}
                                onChange={handleCjUsdChange}
                                className="form-control text-center"
                              />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                  <RatingInfo>
                    ⏺ K-PACKET은 2kg 이하의 상품만 취급합니다.
                  </RatingInfo>
                  <RatingInfo>
                    ⏺ 운임료 값이 `NONE`일 경우, 정확한 값을 측정하기 위해 각
                    운임사가 제공하는 아래 계산기를 사용하실 수 있습니다.
                    <ul>
                      <li>
                        -{" "}
                        <a
                          href="https://www.fedex.com/en-us/online/rating.html#"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          FEDEX 운임료 계산기
                        </a>
                      </li>
                      <li>
                        -{" "}
                        <a
                          href="https://wwwapps.ups.com/time"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          UPS 운임료 계산기
                        </a>
                      </li>
                      <li>
                        -{" "}
                        <a
                          href="https://ems.epost.go.kr/front.EmsDeliveryDelivery09.postal"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          EMS 운임료 계산기
                        </a>
                      </li>
                      <li>
                        -{" "}
                        <a
                          href="https://www.koreapost.go.kr/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          K-PACKET 운임료 계산기
                        </a>
                      </li>
                    </ul>
                  </RatingInfo>
                  {/* 담당자 메시지 */}
                  <div className="manager-message mb-3">
                    <Card>
                      <Card.Header>담당자가 보내는 메시지</Card.Header>
                      <Card.Body>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="담당자 메시지 입력"
                          defaultValue={orderDetails.managerMessage}
                          readOnly
                        />
                      </Card.Body>
                    </Card>
                  </div>
                  {/* 고객 메시지 입력 폼 */}
                  <div className="customer-message">
                    <Card>
                      <Card.Header>고객에게 보내는 메시지</Card.Header>
                      <Card.Body>
                        <Form.Group controlId="customerMessage">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="메시지 입력"
                            value={customerMessage}
                            onChange={(e) => setCustomerMessage(e.target.value)}
                          />
                        </Form.Group>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 mb-3">
              <InputGroup>
                <InputGroup.Text>결제마감일</InputGroup.Text>
                <Form.Control
                  type="datetime-local"
                  className="form-control"
                  value={paymentDeadline}
                  onChange={(e) => setPaymentDeadline(e.target.value)}
                />
              </InputGroup>
            </div>
            <div className="d-flex justify-content-end">
              {isEditing ? (
                <Button variant="primary" onClick={handleUpdateWeights}>
                  완료
                </Button>
              ) : (
                <Button variant="secondary" onClick={() => setIsEditing(true)}>
                  무게 수정
                </Button>
              )}
            </div>
            <div className="d-flex justify-content-between">
              <Button
                variant="primary"
                onClick={handleAddressUpdate}
                className="me-3"
              >
                주소 수정
              </Button>
              <Button
                variant="secondary"
                onClick={handleRepackageRequest}
                className="me-3"
              >
                재포장
              </Button>
              <Button variant="primary" onClick={handleUpdateEstimate}>
                전송
              </Button>
            </div>
          </Modal.Footer>
          <Modal
            show={showPrintModal}
            onHide={() => setShowPrintModal(false)}
            size="xl"
            centered
            dialogClassName="print-modal-wide"
          >
            <Modal.Header closeButton>
              <Modal.Title>재포장 클릭시 포장정보 인쇄</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div ref={printComponentRef} className="overflow-auto">
                <h2>재포장 포장정보</h2>
                <div style={{ textAlign: "center", marginBottom: "15px" }}>
                  <p>
                    <strong>주문번호:</strong> {orderNumber} |{" "}
                    <strong>스페이스코드:</strong> {orderDetails.user.id}
                  </p>
                </div>
                <div className="print-table-container">
                  <table className="table text-center table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">NO</th>
                        <th scope="col">운송장번호</th>
                        <th scope="col">창고비용</th>
                        <th scope="col">추가비용</th>
                        <th scope="col">필수포함</th>
                        <th scope="col">진공포장백</th>
                        <th scope="col">BOX</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails.products.map((product, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{product.tracking}</td>
                          <td>
                            ${product.overstoreFee} / ₩
                            {convertToKRW(product.overstoreFee)}
                          </td>
                          <td>
                            ${convertToUSD(product.Extra)} / ₩{product.Extra}
                          </td>
                          <td>{product.isUrgent ? "✓" : "-"}</td>
                          <td>{product.bag ? "✓" : "-"}</td>
                          <td>{product.boxed}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowPrintModal(false)}
              >
                취소
              </Button>
              <Button variant="primary" onClick={handlePrint}>
                인쇄
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  setShowPrintModal(false);
                  setShowRepackConfirmModal(true);
                }}
              >
                인쇄 없이 계속
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showRepackConfirmModal}
            onHide={() => setShowRepackConfirmModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>재포장 확인</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              재포장을 진행하시겠습니까? 이 작업은 취소할 수 없습니다.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowRepackConfirmModal(false)}
              >
                취소
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleRepackageClick();
                  setShowRepackConfirmModal(false);
                }}
              >
                확인
              </Button>
            </Modal.Footer>
          </Modal>
        </Modal>
      )}
      <Modal show={showImageModal} onHide={closeImageModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={imageToShow}
            alt="Enlarged"
            style={{ width: "100%", maxHeight: "80vh", objectFit: "contain" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

const RatingInfo = styled.div`
  width: 100%;
  margin: 20px 0;

  & > ul {
    margin-top: 10px;
    & > li {
      margin-top: 10px;
    }
  }
`;
const LoadingSection = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > img {
    width: 100%;
  }
`;
