import { AxiosManager } from "../axiosManager";
import { getAccessTokenFromLocalStorage } from "../common/constants";

export const reqGetUserById = async (payload: any) => {
  return await AxiosManager.get(`user/${payload.id}`);
};

export const reqGetAllUserById = async () => {
  const accessToken = getAccessTokenFromLocalStorage();
  if (!accessToken) {
    console.error("Access token is missing");
    throw new Error("Access token is missing");
  }

  return await AxiosManager.get("user/all", {
    headers: {
      Authorization: `Bearer ${accessToken}`, // 토큰을 Authorization 헤더에 추가
    },
  });
};

export const reqUpdateUserById = async (payload: any) => {
  return await AxiosManager.put(`/user/warehouse/update`, payload);
};

export const reqUpdateUserId = async (payload: any) => {
  return await AxiosManager.put(`/user/update-id`, payload);
};

export const reqGetUsersByIds = async (payload: any) => {
  return await AxiosManager.post(`user`, payload);
};

export async function reqUserGetOrderList(paylaod: { id: string }) {
  return await AxiosManager.get(`user/orderlist/${paylaod.id}`);
}

export async function reqUserGetShipList(payload: any) {
  return await AxiosManager.post(`user/shiplist`, payload);
}

export async function reqUserGetShipWaitShipping(payload: any) {
  return await AxiosManager.post(`user/shiplist/waitshipping`, payload);
}

export async function reqUserGetShipNeedModify(payload: any) {
  return await AxiosManager.post(`user/shiplist/needmodify`, payload);
}

export async function reqUserGetShipAllProduct(payload: any) {
  return await AxiosManager.post(`user/shiplist/allproduct`, payload);
}

export const reqUserGetAlarm = async () => {
  return await AxiosManager.post("user/alarm");
};

export const reqUserReadAlarm = async () => {
  return await AxiosManager.post("user/alarm/read");
};

export const reqCreateQuestionAndResponse = async (payload: any) => {
  return await AxiosManager.post("question-and-response", payload);
};

export function reqQuestionAndResponse() {
  return AxiosManager.get("question-and-response");
}

export const reqQuestionAndResponseByUserId = async (userId: string) => {
  return await AxiosManager.get(`/question-and-response/user/${userId}`);
};

export function reqSingleQuestionAndResponse(id: number) {
  return AxiosManager.get(`question-and-response/single/${id}`);
}

export const reqUpdateQuestionAndResponse = async (
  id: string,
  payload: any
) => {
  return await AxiosManager.patch(`question-and-response/${id}`, payload);
};

export const reqCreateNotice = async (payload: any) => {
  return await AxiosManager.post("notice/create", payload);
};

export async function reqModifyNotice(payload: any) {
  return await AxiosManager.post(`notice/modify`, payload);
}

export const reqDeleteNotice = async (id: number) => {
  return await AxiosManager.delete(`notice/delete/${id}`);
};

export const reqNoticeList = async (page: number) => {
  return await AxiosManager.get(`notice/list/${page}`);
};

export const reqNoticeListAdmin = async (page: number) => {
  return await AxiosManager.get(`notice/list/admin/${page}`);
};

export const reqBlogList = async () => {
  return await AxiosManager.get(`notice/event/list`);
};

export const reqToggleBlogLike = async (payload: any) => {
  return await AxiosManager.post(`notice/blog/like-toggle`, payload);
};

// 현재 블로그에 사용자가 좋아요를 눌렀던 적이 있는지 체크
export const reqBlogLikeByUser = async (payload: any) => {
  return await AxiosManager.get(`notice/blog/like-by-user`, {
    params: payload,
  });
};

export const reqViewNotice = async (id: number) => {
  return await AxiosManager.get(`notice/view/${id}`);
};

export const reqPlusViewCount = async (payload: any) => {
  return await AxiosManager.post(`notice/plus-view-count`, payload);
};

export const reqUpdateUserPoints = async (
  userId: string,
  pointsData: { pointsToAdd: number }
) => {
  return await AxiosManager.put(`/user/${userId}/points`, pointsData);
};

export const reqUpdateUserPointsUSD = async (
  userId: string,
  pointsData: { pointsToAdd: number }
) => {
  return await AxiosManager.put(`/user/${userId}/pointsUsd`, {
    ...pointsData,
    pointsToAdd: parseFloat(pointsData.pointsToAdd.toFixed(2)),
  });
};

export const reqEditUserAddress = async (payload: any) => {
  return await AxiosManager.post("/user/edit/address", payload);
};

export const reqPutUserAddres = async (payload: any) => {
  return await AxiosManager.put(`/user/address/${payload.id}`, payload);
};

export const reqUploadImage = async (payload: any) => {
  return await AxiosManager.post("/image/post", payload);
};

export const reqGetEveryUserServiceLog = async () => {
  const accessToken = getAccessTokenFromLocalStorage(); // 토큰 가져오기
  if (!accessToken) {
    throw new Error("Access token is missing");
  }

  return await AxiosManager.get("/user/service/log", {
    headers: {
      Authorization: `Bearer ${accessToken}`, // Authorization 헤더에 토큰 추가
    },
  });
};

export const reqUpdateUserPoint = async (payload: any) => {
  return await AxiosManager.put(`/user/${payload.userId}/point`, payload);
};

export const reqUpdateUserPointUSD = async (payload: any) => {
  return await AxiosManager.put(`/user/${payload.userId}/Upoint`, payload);
};

export const reqUpdateUserMembership = async (payload: any) => {
  return await AxiosManager.put(`/user/${payload.id}/membership`, payload);
};

export const reqPostPointlog = async (payload: any) => {
  return await AxiosManager.post(`point-logs`, payload);
};

export const reqDeleteUser = async (userId: string) => {
  return await AxiosManager.delete(`user/${userId}`);
};

export const reqGetLogsByUserId = async (userId: string) => {
  return await AxiosManager.get(`point-logs/${userId}`);
};

export const reqRegisteredUserCount = async () => {
  return await AxiosManager.get(`/user/count/registered`);
};

export const reqUploadExcelFile = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  return await AxiosManager.post("/user/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 이메일 전송
export const reqShopEmail = async (payload: any) => {
  return await AxiosManager.post("/email/shop/estimate", payload);
};

export const reqShipEmail = async (payload: any) => {
  return await AxiosManager.post("/email/ship/estimate", payload);
};

export const reqUpdateUserStatus = async (
  userId: string,
  statusData: { status: number }
) => {
  return await AxiosManager.put(`/user/${userId}/status`, statusData);
};

export const reqUpdateMarketingAgreement = async (
  userId: string,
  agreeMarketing: boolean
) => {
  return await AxiosManager.put(`/user/update-marketing-agreement`, {
    id: userId,
    agreeMarketing,
  });
};
