import { format, parseISO, subHours } from "date-fns";
import JsBarcode from "jsbarcode";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import Icon from "../../../components/Icon";
import {
  reqFilteredShipProductsByWarehouse,
  reqFilterShipProductsByDatetime,
  reqSearchShipProductsByTracking,
} from "../../../requests/ship";

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const LoadingText = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin: 0;
`;

// 모든 styled-components를 여기로 이동
const ResponsiveContainer = styled.div`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-x: auto;

  table {
    width: 100%;
    min-width: 800px;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      text-align: center;
      border: 1px solid #ddd;
      white-space: nowrap;

      @media (max-width: 768px) {
        padding: 4px;
        font-size: 14px;
      }
    }

    th {
      background-color: #f2f2f2;
      position: sticky;
      top: 0;
      z-index: 10;
    }

    td {
      button {
        white-space: nowrap;

        @media (max-width: 768px) {
          padding: 4px 8px;
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FilterItem = styled.div`
  flex: 1;
  min-width: 200px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PaginationControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    margin: 0 5px;

    @media (max-width: 768px) {
      padding: 5px 10px;
      font-size: 14px;
    }
  }
`;

const PrintButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const Spacer = styled.div`
  height: 20px; /* 원하는 간격 크기로 설정 */
`;

const Box = styled.div`
  display: flex;
  border: 1px solid lightgray;
  border-radius: 0.4rem;
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 5px;
  .input-reset {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.4rem;
    width: 100%;
    border: none;
    padding: 0.4rem 0.8rem;
  }
  #search-opt-dropdown {
    position: relative;
    flex: 1 1 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid lightgray;
  }
  #search-opt-input {
    flex: 1 1 0;
    margin-left: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  #search-input {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1.4;
    margin-left: 1px;
    border-radius: 4px;
  }
`;

const Input = styled.input`
  flex-grow: 2;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
`;

const SearchButton = styled.div`
  border: 1px solid var(--color-main-blue);
  padding: 0.4rem 1rem;
  align-items: center;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  cursor: pointer;
`;

const TabContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
`;

interface TabButtonProps {
  active: boolean;
}

const TabButton = styled.button<TabButtonProps>`
  padding: 10px 20px;
  border: none;
  background: ${(props) => (props.active ? "#007bff" : "#f8f9fa")};
  color: ${(props) => (props.active ? "#fff" : "#333")};
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${(props) => (props.active ? "#0056b3" : "#e9ecef")};
  }
`;

interface ShipRecord {
  id: string;
  tracking: string;
  userId: string;
  warehouseId: string;
  Warehous: boolean;
  createdAt: string;
  user: {
    name: string;
    membershipId: number;
  };
}

export default function AWHistory() {
  const [isLoading, setIsLoading] = useState(false);
  const [shipRecords, setShipRecords] = useState<ShipRecord[]>([]);
  const [originalRecords, setOriginalRecords] = useState<ShipRecord[]>([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("23:59");
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTracking, setSearchTracking] = useState("");
  const [activeTab, setActiveTab] = useState<"purchase" | "normal">("purchase");
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  // 탭 변경 핸들러
  const handleTabChange = (tab: "purchase" | "normal") => {
    setActiveTab(tab);
    setPage(1);
  };

  // 서버에서 기본 데이터 가져오기
  const fetchShipRecords = useCallback(async () => {
    try {
      setIsLoading(true);

      // activeTab에 따라 warehousType 값 설정
      const warehousType = activeTab === "purchase" ? "1" : "0";

      const response = await reqFilteredShipProductsByWarehouse(
        page,
        limit,
        warehousType
      );

      if (response && response.data) {
        const { data, total } = response.data;

        setShipRecords(Array.isArray(data) ? data : []);
        setOriginalRecords(Array.isArray(data) ? data : []);
        setTotalRecords(typeof total === "number" ? total : 0);
      } else {
        setShipRecords([]);
        setOriginalRecords([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("입고기록 조회 실패:", error);
      setShipRecords([]);
      setOriginalRecords([]);
      setTotalRecords(0);
    } finally {
      setIsLoading(false);
    }
  }, [page, limit, activeTab]);

  // 날짜 검색 핸들러
  const handleDateSearch = useCallback(() => {
    if (!startDate || !endDate) {
      fetchShipRecords();
      return;
    }

    setIsLoading(true);
    const warehousType = activeTab === "purchase" ? "1" : "0";

    // 한국 시간(KST)을 UTC로 변환 (9시간 차이)
    const convertToUTC = (date: string, time: string) => {
      const localDate = new Date(`${date}T${time}`);
      // KST는 UTC+9이므로, UTC로 변환하려면 9시간을 빼야 함
      return subHours(localDate, 9);
    };

    // 검색 시작 시간과 종료 시간을 UTC로 변환
    const utcStartDateTime = convertToUTC(startDate, startTime);
    const utcEndDateTime = convertToUTC(endDate, endTime);

    // UTC 형식으로 변환된 날짜와 시간
    const utcStartDate = format(utcStartDateTime, "yyyy-MM-dd");
    const utcStartTime = format(utcStartDateTime, "HH:mm");
    const utcEndDate = format(utcEndDateTime, "yyyy-MM-dd");
    const utcEndTime = format(utcEndDateTime, "HH:mm");

    reqFilterShipProductsByDatetime(
      page,
      limit,
      warehousType,
      utcStartDate,
      utcStartTime,
      utcEndDate,
      utcEndTime
    )
      .then((response) => {
        if (response && response.data) {
          const { data, total } = response.data;
          setShipRecords(Array.isArray(data) ? data : []);
          setOriginalRecords(Array.isArray(data) ? data : []);
          setTotalRecords(typeof total === "number" ? total : 0);
        } else {
          setShipRecords([]);
          setOriginalRecords([]);
          setTotalRecords(0);
        }
      })
      .catch((error) => {
        console.error("날짜 필터링 실패:", error);
        setShipRecords([]);
        setOriginalRecords([]);
        setTotalRecords(0);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    activeTab,
    endDate,
    endTime,
    fetchShipRecords,
    limit,
    page,
    startDate,
    startTime,
  ]);

  // 검색 버튼 클릭 핸들러 - 트래킹 번호 검색과 날짜 검색 모두 처리
  const handleSearch = useCallback(() => {
    console.log("[디버깅] handleSearch 실행:", {
      searchTracking,
      startDate,
      endDate,
    });
    // 1) 트래킹 번호 우선 검색
    if (searchTracking.trim()) {
      console.log("[디버깅] 트래킹 번호 검색 시작:", searchTracking);
      setIsLoading(true);
      const warehousType = activeTab === "purchase" ? "1" : "0";

      reqSearchShipProductsByTracking(
        1, // 검색 시 항상 첫 페이지부터
        limit,
        warehousType,
        searchTracking
      )
        .then((response) => {
          console.log("[디버깅] 트래킹 검색 성공:", response.data);
          if (response && response.data) {
            const { data, total } = response.data;
            setShipRecords(Array.isArray(data) ? data : []);
            setOriginalRecords(Array.isArray(data) ? data : []);
            setTotalRecords(typeof total === "number" ? total : 0);
            setPage(1);
          } else {
            setShipRecords([]);
            setOriginalRecords([]);
            setTotalRecords(0);
          }
        })
        .catch((error) => {
          console.error("[디버깅] 트래킹 번호 검색 실패:", error);
          setShipRecords([]);
          setOriginalRecords([]);
          setTotalRecords(0);
        })
        .finally(() => {
          console.log("[디버깅] 트래킹 검색 완료");
          setIsLoading(false);
        });
      return;
    }

    // 2) 트래킹이 없으면 날짜 검색 (또는 전체 데이터 로드)
    if (startDate && endDate) {
      console.log("[디버깅] 날짜 검색 시작:", { startDate, endDate });
      handleDateSearch();
    } else {
      console.log("[디버깅] 기본 데이터 로드");
      fetchShipRecords();
    }
  }, [
    searchTracking,
    startDate,
    endDate,
    handleDateSearch,
    fetchShipRecords,
    activeTab,
    limit,
  ]);

  // 엔터 키 처리 함수
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // 페이지, 탭 변경 시 데이터 로드 (검색 조건이 없을 때만)
  useEffect(() => {
    console.log("[디버깅] 페이지/탭 변경 useEffect 실행:", {
      page,
      limit,
      activeTab,
      searchTracking: searchTracking.trim(),
      hasDateRange: !!(startDate && endDate),
    });

    if (!searchTracking.trim() && (!startDate || !endDate)) {
      console.log("[디버깅] 조건 만족, fetchShipRecords 호출");
      fetchShipRecords();
    } else {
      console.log("[디버깅] 조건 불만족, fetchShipRecords 건너뜀");
    }
  }, [
    page,
    limit,
    activeTab,
    fetchShipRecords,
    searchTracking,
    startDate,
    endDate,
  ]);

  // 페이지 변경 핸들러
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  // 현재 페이지에 표시할 레코드 계산
  const currentRecords = originalRecords || [];

  // 총 페이지 수 계산
  const totalPages = Math.ceil(totalRecords / limit) || 1;

  // 체크박스 전체 선택/해제 핸들러
  const handleSelectAllChange = (isChecked: boolean) => {
    setSelectAll(isChecked);
    setSelectedRecords(isChecked ? shipRecords.map((record) => record.id) : []);
  };

  // 페이지당 항목 수 변경 핸들러
  const handleLimitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLimit(Number(event.target.value));
  };

  // 선택된 레코드 인쇄 핸들러
  const handlePrintSelected = () => {
    const selectedRecordsToPrint = shipRecords.filter((record) =>
      selectedRecords.includes(record.id)
    );

    const printWindow = window.open("", "", "width=800,height=600");
    if (!printWindow) {
      console.error(
        "Failed to open the print window. Check your browser popup settings."
      );
      return;
    }

    printWindow.document.write("<html><head><title>Print</title><style>");
    printWindow.document.write("@page { size: 8cm 6cm; margin: 0; }");
    printWindow.document.write(
      "body { margin: 0; display: flex; flex-direction: column; align-items: center; }"
    );
    printWindow.document.write(
      ".page { display: flex; justify-content: center; align-items: center; width: 8cm; height: 6cm; page-break-after: always; }"
    );
    printWindow.document.write(
      ".print-container { width: 8cm; height: 6cm; display: flex; flex-direction: column; justify-content: center; align-items: center; border: 1px solid #000; padding: 10px; box-sizing: border-box; }"
    );
    printWindow.document.write(
      "svg { width: 6cm; height: 2cm; margin-bottom: 10px; }"
    );
    printWindow.document.write(
      "p { margin: 0; padding: 2px 0; text-align: center; font-size: 1.2em; }"
    );
    printWindow.document.write("</style></head><body>");

    selectedRecordsToPrint.forEach((record) => {
      const svgBarcode = `<svg id="barcode-${record.id}"></svg>`;
      const lastEightChars = record.tracking.slice(-8);
      const firstFourChars = lastEightChars.slice(0, 4);
      const secondFourChars = lastEightChars.slice(4, 8);

      printWindow.document.write('<div class="page">');
      printWindow.document.write('<div class="print-container">');
      printWindow.document.write(svgBarcode);
      printWindow.document.write(
        `<p style="font-size: 1.5em; font-weight: bold;">${record.tracking}</p>`
      );
      printWindow.document.write(
        `<p style="font-size: 1em; font-weight: bold;">${record.userId} / ${
          record.user ? record.user.name : "Unknown User"
        }</p>`
      );
      printWindow.document.write(
        `<p style="font-size: 1em; font-weight: bold;">${record.warehouseId} / ${firstFourChars}-${secondFourChars}</p>`
      );
      printWindow.document.write("</div>");
      printWindow.document.write("</div>");
    });

    printWindow.document.write("</body></html>");
    printWindow.document.close();

    // 바코드 생성
    selectedRecordsToPrint.forEach((record) => {
      const barcodeElement = printWindow.document.getElementById(
        `barcode-${record.id}`
      );
      JsBarcode(barcodeElement, record.tracking, {
        format: "CODE128",
        displayValue: false,
        width: 1,
        height: 40,
        margin: 0,
      });
    });

    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  // 개별 레코드 선택/해제 핸들러
  const toggleRecordSelection = (id: string, isChecked: boolean) => {
    setSelectedRecords((prev) =>
      isChecked ? [...prev, id] : prev.filter((recordId) => recordId !== id)
    );
  };

  return (
    <>
      <ResponsiveContainer>
        <main id="main" className="main">
          <AdminHeadTitle
            subtitle1="창고 관리"
            subtitle2="입고 기록 라벨 출력"
            title="입고 기록 라벨 출력"
          />

          <TabContainer>
            <TabButton
              active={activeTab === "purchase"}
              onClick={() => handleTabChange("purchase")}
            >
              구매대행 입고
            </TabButton>
            <TabButton
              active={activeTab === "normal"}
              onClick={() => handleTabChange("normal")}
            >
              일반 입고
            </TabButton>
          </TabContainer>

          <FilterContainer>
            <FilterItem>
              <select
                className="form-select"
                aria-label="Default select example"
                value={limit}
                onChange={handleLimitChange}
              >
                <option value="10">10개씩 보기</option>
                <option value="50">50개씩 보기</option>
                <option value="100">100개씩 보기</option>
                <option value="999999">전체보기</option>
              </select>
            </FilterItem>
            <FilterItem>
              <div id="search-input" className="d-flex">
                <Input
                  type="text"
                  className="input-reset"
                  placeholder="Tracking Number"
                  value={searchTracking}
                  onChange={(e) => {
                    console.log(
                      "[디버깅] 트래킹 입력 변경:",
                      e.target.value.toUpperCase()
                    );
                    setSearchTracking(e.target.value.toUpperCase());
                  }}
                  onKeyPress={(e) => {
                    console.log("[디버깅] 키 입력:", e.key);
                    handleKeyPress(e);
                  }}
                  ref={searchInputRef}
                />
                <SearchButton
                  onClick={(e) => {
                    console.log("[디버깅] 검색 버튼 클릭");
                    handleSearch();
                  }}
                >
                  <Icon icon="search" color="var(--color-main-blue)" />
                </SearchButton>
              </div>
            </FilterItem>
          </FilterContainer>

          <FilterContainer>
            <FilterItem>
              <input
                type="date"
                className="form-control"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FilterItem>
            <FilterItem>
              <input
                type="time"
                className="form-control"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </FilterItem>
            <FilterItem>
              <input
                type="date"
                className="form-control"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FilterItem>
            <FilterItem>
              <input
                type="time"
                className="form-control"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </FilterItem>
            <FilterItem>
              <button className="btn btn-primary" onClick={handleSearch}>
                날짜 검색
              </button>
            </FilterItem>
          </FilterContainer>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <PrintButton onClick={handlePrintSelected}>출력</PrintButton>
          </div>

          {isLoading ? (
            <LoadingContainer>
              <Spinner animation="border" variant="primary" />
              <LoadingText>데이터를 불러오는 중입니다...</LoadingText>
            </LoadingContainer>
          ) : (
            <TableContainer>
              <table className="table table-hover text-center">
                <thead>
                  <tr>
                    <th scope="col">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={(e) =>
                          handleSelectAllChange(e.target.checked)
                        }
                      />
                    </th>
                    <th>NO</th>
                    <th scope="col">송장번호</th>
                    <th scope="col">스페이스코드</th>
                    <th scope="col">이름</th>
                    <th scope="col">저장위치</th>
                    <th scope="col">입고시간</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={7} className="text-center py-4">
                        <Spinner animation="border" variant="primary" />
                        <div className="mt-2">데이터를 불러오는 중...</div>
                      </td>
                    </tr>
                  ) : currentRecords && currentRecords.length > 0 ? (
                    currentRecords.map((record, index) => (
                      <tr
                        key={record.id}
                        onClick={() =>
                          toggleRecordSelection(
                            record.id,
                            !selectedRecords.includes(record.id)
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <th scope="row">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              toggleRecordSelection(record.id, e.target.checked)
                            }
                            checked={selectedRecords.includes(record.id)}
                            onClick={(e) => e.stopPropagation()} // Prevent the row click event from triggering
                          />
                        </th>
                        <td>{index + 1}</td>
                        <td>{record.tracking}</td>
                        <td>{record.userId}</td>
                        <td>
                          {record.user ? record.user.name : "Unknown User"}
                        </td>
                        <td>{record.warehouseId}</td>
                        <td>
                          {record.createdAt
                            ? format(
                                parseISO(record.createdAt),
                                "yyyy-MM-dd HH:mm:ss"
                              )
                            : "N/A"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} className="text-center py-4">
                        데이터가 없습니다.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TableContainer>
          )}

          <PaginationControlsContainer>
            {(originalRecords.length || 0) > 0 && (
              <div>
                <button
                  onClick={() => handlePageChange(1)}
                  disabled={page === 1}
                  className="pagination-button"
                >
                  <Icon icon="chevron-double-left" />
                </button>
                <button
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                  className="pagination-button"
                >
                  <Icon icon="chevron-left" />
                </button>
                <span className="pagination-info">
                  {page} / {totalPages}
                </span>
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === totalPages}
                  className="pagination-button"
                >
                  <Icon icon="chevron-right" />
                </button>
                <button
                  onClick={() => handlePageChange(totalPages)}
                  disabled={page === totalPages}
                  className="pagination-button"
                >
                  <Icon icon="chevron-double-right" />
                </button>
              </div>
            )}
          </PaginationControlsContainer>
        </main>
      </ResponsiveContainer>
    </>
  );
}
