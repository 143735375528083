import React, { useState } from "react";
import {
  Button,
  Collapse,
  Dropdown,
  FormControl,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  ADMIN_BADGE,
  CODE_COUNTRY_ITEMS,
  CODE_SHOPPING_ORDER_ITEMS,
} from "../../../common/constants";
import { MODAL_TYPE } from "../../../components/modal/GlobalModal";
import { modalOpen } from "../../../redux/modalSlice";
import { RootUserState } from "../../../redux/store";
import { reqAddAdminBadge } from "../../../requests/badge";
import {
  reqShopDetailpPost,
  reqShopPost,
  reqShopPosts,
} from "../../../requests/shop";
import {
  MBody,
  MButton,
  MFooter,
  MHeader,
  MPageTitle,
  MRow,
  MTwoButtonRow,
} from "../../../common/mobile-styled";
import Icon from "../../../components/Icon";
import { isMobile } from "react-device-detect";
interface ProductDetail {
  name: string;
  category: number;
  option1: string;
  option2: string;
  quantity: number;
  price: number;
  url: string;
  preorder: boolean;
  preorderStartAt?: string;
  preorderEndAt?: string;
  fancall: boolean;
  fancallName?: string;
  fancallBirthday?: string;
  fancallNumber?: string;
  fancallEmail?: string;
  fancallCountry?: number;
  fancallKakao?: string;
  fancallSnsLine?: string;
}

interface AdditionalInfo {
  failed: boolean;
}

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 750px;
  }
`;

const CShopSendorder = ({ closeCreateOrderModal }: any) => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const user = useSelector((state: RootUserState) => state.user);
  const [productDetails, setProductDetails] = useState<ProductDetail[]>([]);
  const [loading, setLoading] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>({
    failed: false,
  });
  const navigate = useNavigate();

  const handleNextStep = (nextStep: number) => {
    if (nextStep === 3) {
      const isAnyFieldMissing = products.some((product) => {
        const missing =
          product.name === "" ||
          product.category === 0 ||
          product.quantity === 0 ||
          product.price === 0 ||
          product.url === "";
        if (missing) {
          console.log("Missing field in product:", product);
        }
        return missing;
      });

      const isNotNaturalNumber = products.some((product) => {
        const notNatural =
          !Number.isInteger(Number(product.quantity)) ||
          product.quantity <= 0 ||
          !Number.isInteger(Number(product.price)) ||
          product.price <= 0;
        if (notNatural) {
          console.log("Non-natural number in product:", product);
        }
        return notNatural;
      });

      const hasInvalidCharacters = products.some((product) => {
        const invalidQuantity = /[^0-9]/.test(product.quantity.toString());
        const invalidPrice = /[^0-9]/.test(product.price.toString());
        if (invalidQuantity || invalidPrice) {
          console.log("Invalid character in numeric field:", product);
        }
        return invalidQuantity || invalidPrice;
      });

      if (isAnyFieldMissing) {
        Swal.fire(
          "Error!",
          "Please fill in all required fields for every product (Product name, Category, Quantity, Price and Site URL).",
          "error"
        );
        return;
      }

      if (isNotNaturalNumber) {
        Swal.fire(
          "Error!",
          "Quantity and Unit Price must be positive integers. Please correct the values.",
          "error"
        );
        return;
      }

      if (hasInvalidCharacters) {
        Swal.fire(
          "Error!",
          "Quantity and Unit Price should contain numbers only. Please remove any commas, dots or other characters.",
          "error"
        );
        return;
      }
    }

    console.log("Proceeding to next step:", nextStep);
    setStep(nextStep);
  };

  const deleteProduct = (index: number) => {
    if (products.length === 1) {
      Swal.fire("Error!", "At least one product must exist.", "error");
      return;
    }
    setProducts((currentProducts) =>
      currentProducts.filter((_, i) => i !== index)
    );
  };

  const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});

  const toggleItem = (index: number) => {
    setOpenItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const [products, setProducts] = useState([
    {
      id: Date.now().toString(),
      name: "",
      category: 0,
      option1: "",
      option2: "",
      quantity: 0,
      price: 0,
      url: "",
      preorder: false,
      fancall: false,
      preorderStartAt: "",
      preorderEndAt: "",
      fancallName: "",
      fancallBirthday: "",
      fancallNumber: "",
      fancallEmail: "",
      fancallCountry: 0,
      fancallKakao: "",
      fancallSnsLine: "",
    },
  ]);

  const addProduct = () => {
    setProducts([
      ...products,
      {
        id: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
        name: "",
        category: 0,
        option1: "",
        option2: "",
        quantity: 0,
        price: 0,
        url: "",
        preorder: false,
        fancall: false,
        preorderStartAt: "",
        preorderEndAt: "",
        fancallName: "",
        fancallBirthday: "",
        fancallNumber: "",
        fancallEmail: "",
        fancallCountry: 0,
        fancallKakao: "",
        fancallSnsLine: "",
      },
    ]);
  };

  const handleBankingDetailsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setAdditionalInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type, checked } = event.target;
    let newValue: any = type === "checkbox" ? checked : value;

    if (name === "price") {
      newValue = newValue.replace(/[^0-9]/g, "");
    }
    if (name === "quantity") {
      newValue = newValue.replace(/[^0-9]/g, "");
    }

    const updatedProducts = products.map((product, i) => {
      if (i === index) {
        if (name === "preorder" && newValue) {
          return { ...product, [name]: newValue, fancall: false };
        } else if (name === "fancall" && newValue) {
          return { ...product, [name]: newValue, preorder: false };
        } else {
          return { ...product, [name]: newValue };
        }
      }
      return product;
    });

    setProducts(updatedProducts);
  };

  const toggleSection = (index: number, section: "preorder" | "fancall") => {
    const newProducts = products.map((product, idx) => {
      if (idx === index) {
        const updatedSection = { [section]: !product[section] };

        if (updatedSection[section]) {
          if (section === "preorder") {
            return { ...product, ...updatedSection, fancall: false };
          } else {
            return { ...product, ...updatedSection, preorder: false };
          }
        } else {
          return { ...product, ...updatedSection };
        }
      }
      return product;
    });

    setProducts(newProducts);
  };

  const handleAdditionalInfoChange = (event: any) => {
    const { name, checked } = event.target;

    if (name === "totalCancellation") {
      setAdditionalInfo((prevInfo) => ({
        ...prevInfo,
        failed: checked,
      }));
    } else if (name === "partialCancellation") {
      setAdditionalInfo((prevInfo) => ({
        ...prevInfo,
        failed: !checked,
      }));
    } else {
      const { value, type } = event.target;
      let finalValue = value;

      if (type === "checkbox") {
        finalValue = checked;
      } else if (type === "radio") {
        finalValue = parseInt(value, 10);
      }

      setAdditionalInfo((prevInfo) => ({
        ...prevInfo,
        [name]: finalValue,
      }));
    }
  };

  const handleFancallCountrySelect = (countryId: number, index: number) => {
    const newProducts = [...products];
    newProducts[index].fancallCountry = countryId;
    setProducts(newProducts);
  };

  const filteredCountries = Object.entries(CODE_COUNTRY_ITEMS).filter(
    ([id, name]) => {
      if (Array.isArray(name)) {
        return name.some((n) =>
          n.toLowerCase().includes(countrySearchTerm.toLowerCase())
        );
      } else {
        return name.toLowerCase().includes(countrySearchTerm.toLowerCase());
      }
    }
  );

  const submitAllInfo = async () => {
    if (loading) return;

    setLoading(true);
    if (user.Check) {
      await Swal.fire({
        title: "Action Blocked",
        text: "Please wait until the space code changes.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setLoading(false);
      return;
    }

    const payload = {
      additionalInfo: {
        ...additionalInfo,
        userId: user.id,
        preorder: products.some((product) => product.preorder),
        fancall: products.some((product) => product.fancall),
      },
      productDetails: products.map((product) => ({
        name: product.name,
        category: product.category,
        option1: product.option1,
        option2: product.option2,
        quantity: product.quantity,
        price: product.price,
        url: product.url,
        preorder: product.preorder,
        fancall: product.fancall,
        preorderStartAt: product.preorder ? product.preorderStartAt : null,
        preorderEndAt: product.preorder ? product.preorderEndAt : null,
        fancallName: product.fancall ? product.fancallName : null,
        fancallBirthday: product.fancall ? product.fancallBirthday : null,
        fancallNumber: product.fancall ? product.fancallNumber : null,
        fancallEmail: product.fancall ? product.fancallEmail : null,
        fancallCountry: product.fancall ? product.fancallCountry : null,
        fancallKakao: product.fancall ? product.fancallKakao : null,
        fancallSnsLine: product.fancall ? product.fancallSnsLine : null,
        userId: user.id,
      })),
    };

    try {
      const response = await reqShopPosts(payload);
      console.log("정보 제출됨", response);

      const badgePayload = {
        badgeName: ADMIN_BADGE.SHOP_REQUEST_FORM,
      };
      await reqAddAdminBadge(badgePayload);
      dispatch(
        modalOpen({
          modalType: MODAL_TYPE.INFO,
          title: "Submission Success",
          payload: {
            text: "Your order has been successfully submitted. Purchase request details can be checked on All my orders, and ONTACT Korea will send you an estimate after checking it.",
          },
        })
      );
      closeCreateOrderModal();
      // navigate("/customer/shop/order");
      window.location.href = "/customer/shop/order";
    } catch (error) {
      console.error("정보 제출 중 오류 발생", error);
    } finally {
      setLoading(false);
    }
  };

  const categoryOptions = Object.entries(CODE_SHOPPING_ORDER_ITEMS).map(
    ([key, value]) => ({
      value: Number(key),
      label: value,
    })
  );

  const handleCategoryChange = (
    selectedOption: SingleValue<{ value: number; label: string }>,
    index: number
  ) => {
    const newProducts = [...products];
    newProducts[index].category = selectedOption ? selectedOption.value : 0;
    setProducts(newProducts);
  };

  // 스텝 사진 랜더링
  const [Image, setImage] = useState<any>(
    "https://kr.object.ncloudstorage.com/ontact-bucket/step1.PNG"
  );
  const [ImageGIF, setImageGIF] = useState<any>(
    "https://kr.object.ncloudstorage.com/ontact-bucket/step2.PNG"
  );
  const [starImage, setsatrImage] = useState<any>(
    "https://kr.object.ncloudstorage.com/ontact-bucket/step3.PNG"
  );
  const [whatImage, setwhatImage] = useState<any>(
    "https://kr.object.ncloudstorage.com/ontact-bucket/step4.PNG"
  );
  const renderStepModal = () => {
    switch (step) {
      case 4: // KRW 결제
        return (
          <Modal show={true} onHide={() => setStep(3)} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Step 4-1 Payment in KRW </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <div>
                                <h5>Failed order</h5>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        name="failed"
                                        onChange={handleAdditionalInfoChange}
                                        checked={additionalInfo.failed}
                                    /> Total cancellation
                                </div>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        name="failed"
                                        onChange={handleAdditionalInfoChange}
                                        checked={!additionalInfo.failed}
                                    /> Partial cancellation (purchase available items)
                                </div>
                            </div> */}
              <div>
                <h5>Refund methods</h5>
                {/* <div className="form-check">
                                    <input
                                        type="checkbox"
                                        name="refund"
                                        onChange={handleAdditionalInfoChange}
                                        checked={additionalInfo.refund}
                                    /> Regular refund(If you do not enter your refund account information, you will be refunded as a point)
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text">은행</span>
                                    <input type="text" className="form-control" placeholder="Bank Name" name="option1" value={additionalInfo.option1} onChange={handleBankingDetailsChange} />
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text">예금주</span>
                                    <input type="text" className="form-control" placeholder="Account Holder" name="option2" value={additionalInfo.option2} onChange={handleBankingDetailsChange} />
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text">계좌번호</span>
                                    <input type="text" className="form-control" placeholder="Account Number" name="url" value={additionalInfo.url} onChange={handleBankingDetailsChange} />
                                </div>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        name="refund"
                                        onChange={handleAdditionalInfoChange}
                                        checked={!additionalInfo.refund}
                                    /> Refund with points(+5% extra point)
                                </div> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={() => setStep(3)}>
                Back
              </Button>
              <Button variant="outline-success" onClick={submitAllInfo}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        );
      case 5: // USD 결제
        return (
          <Modal show={true} onHide={() => setStep(3)} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Step 4-2 Payment in USD</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <div>
                                <h5>Failed order</h5>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        name="failed"
                                        onChange={handleAdditionalInfoChange}
                                        checked={additionalInfo.failed}
                                    /> Total cancellation
                                </div>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        name="failed"
                                        onChange={handleAdditionalInfoChange}
                                        checked={!additionalInfo.failed}
                                    /> Partial cancellation (purchase available items)
                                </div>
                            </div> */}
              <div>
                <h5>Refund methods</h5>
                {/* <div className="form-check">
                                    <input
                                        type="checkbox"
                                        name="refund"
                                        onChange={handleAdditionalInfoChange}
                                        checked={additionalInfo.refund}
                                    /> Regular refund(If you do not enter your refund account information, you will be refunded as a point)
                                </div>
                                <div className="form-floating mb-3">
                                    Paypal Address <input type="text" name="account" onChange={handleAdditionalInfoChange} />
                                </div>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        name="refund"
                                        onChange={handleAdditionalInfoChange}
                                        checked={!additionalInfo.refund}
                                    /> Refund with points(+5% extra point)
                                </div> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={() => setStep(3)}>
                Back
              </Button>
              <Button variant="outline-success" onClick={submitAllInfo}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        );
      default:
        return null;
    }
  };

  return (
    <main id="main" className="main">
      <section>
        {/* Step 1 */}
        <StyledModal
          show={step === 1}
          onHide={() => {
            setStep(0);
            closeCreateOrderModal();
          }}
        >
          <Modal.Header closeButton>
            <div style={{ fontSize: "1.3rem" }}>Step 1: Guide</div>
          </Modal.Header>

          <MBody>
            <Tabs defaultActiveKey="getStarted">
              <Tab eventKey="getStarted" title="Get started !">
                <div className="image-container">
                  <img src={Image} alt="step1" className="responsive-image" />
                </div>
              </Tab>

              <Tab
                eventKey="inputInfo"
                title="Input information"
                className="font-bold-pay"
              >
                <div className="image-container">
                  <img
                    src={whatImage}
                    alt="step2"
                    className="responsive-image"
                  />
                </div>
              </Tab>

              <Tab eventKey="preview" title="Preview" className="font-bold-pay">
                <div className="image-container">
                  <img
                    src={starImage}
                    alt="step3"
                    className="responsive-image"
                  />
                </div>
              </Tab>

              <Tab eventKey="submit" title="Submit" className="font-bold-pay">
                <div className="image-container">
                  <img
                    src={ImageGIF}
                    alt="step4"
                    className="responsive-image"
                  />
                </div>
              </Tab>
            </Tabs>
          </MBody>

          <Modal.Footer>
            <MButton
              onClick={() => handleNextStep(2)}
              style={{
                backgroundColor: "var(--color-main-blue)",
                color: "var(--color-white)",
                width: isMobile ? "100%" : "25%",
              }}
            >
              Next
            </MButton>
          </Modal.Footer>
        </StyledModal>

        {/* Step 2*/}
        <Modal show={step === 2} onHide={() => setStep(0)} size="lg">
          <Modal.Header closeButton>
            <div style={{ fontSize: "1.3rem" }}>
              Step 2: Enter product information
            </div>
          </Modal.Header>
          <Modal.Body>
            {products.map((product, index) => (
              <form className="row" key={product.id}>
                <div className="col-12 mb-1">
                  <label className="form-label">Category *</label>
                  <Select
                    value={categoryOptions.find(
                      (option) => option.value === product.category
                    )}
                    onChange={(selectedOption) =>
                      handleCategoryChange(
                        selectedOption as SingleValue<{
                          value: number;
                          label: string;
                        }>,
                        index
                      )
                    }
                    options={categoryOptions}
                    placeholder="Select Category"
                  />
                </div>
                <div className="col-12 mb-1">
                  <label className="form-label">Product name *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={product.name}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
                <div className="col-6">
                  <label className="form-label">Option1</label>
                  <input
                    type="text"
                    className="form-control"
                    name="option1"
                    value={product.option1}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
                <div className="col-6">
                  <label className="form-label">Option2</label>
                  <input
                    type="text"
                    className="form-control"
                    name="option2"
                    value={product.option2}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
                <div className="col-6">
                  <label className="form-label">Quantity *</label>
                  <input
                    className="form-control"
                    name="quantity"
                    value={product.quantity}
                    onChange={(e) => handleInputChange(index, e)}
                    onFocus={(e) => {
                      if (e.target.value === "0") e.target.value = "";
                    }}
                  />
                </div>
                <div className="col-6">
                  <label className="form-label">Unit Price(KRW) *</label>
                  <input
                    className="form-control"
                    name="price"
                    value={`￦${product.price}`}
                    onChange={(e) => handleInputChange(index, e)}
                    onFocus={(e) => {
                      if (e.target.value === "￦0") e.target.value = "";
                    }}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">Site url *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="url"
                    value={product.url}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>

                <div className="col-12" style={{ marginTop: "0.5rem" }}>
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    {/* Pre order */}
                    <div
                      className="accordion-item"
                      style={{ padding: "0.5rem 0" }}
                    >
                      <h4 className="accordion-header">
                        {/* <div
                          className="form-check"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                            alignItems: "center",
                            fontSize: "1.1rem",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`preorder-${product.id}`}
                            name="preorder"
                            checked={product.preorder}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`preorder-${product.id}`}
                            style={{ fontWeight: "normal", width: "100%" }}
                          >
                            Pre Order
                          </label>

                          <Step2Dropdown>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              onClick={() => toggleSection(index, "preorder")}
                              aria-expanded={product.preorder}
                              aria-controls={`flush-collapseOne-${index}`}
                              style={{ padding: "0" }}
                            ></button>
                          </Step2Dropdown>
                        </div> */}
                      </h4>
                      <Collapse in={product.preorder}>
                        <div
                          id={`flush-collapseOne-${index}`}
                          className="accordion-body"
                          style={{ padding: "0" }}
                        >
                          {/* <div
                            className="accordion-body"
                            style={{ padding: "0", margin: "1rem 2rem" }}
                          >
                            <div style={{ marginBottom: "0.5rem" }}>
                              Start date ~ End date
                            </div>
                            <div className="input-group mb-3">
                              <input
                                type="date"
                                className="form-control"
                                name="preorderStartAt"
                                value={product.preorderStartAt || ""}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="Start date"
                                aria-label="Start date"
                              />
                              <span className="input-group-text">~</span>
                              <input
                                type="date"
                                className="form-control"
                                name="preorderEndAt"
                                value={product.preorderEndAt || ""}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="End date"
                                aria-label="End date"
                              />
                            </div>
                          </div> */}
                        </div>
                      </Collapse>
                    </div>

                    {/* Fan Call */}
                    <div
                      className="accordion-item"
                      style={{ padding: "0.5rem 0" }}
                    >
                      <h4 className="accordion-header">
                        <div
                          className="form-check"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                            alignItems: "center",
                            fontSize: "1.1rem",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`fancall-${product.id}`}
                            name="fancall"
                            checked={product.fancall}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`fancall-${product.id}`}
                            style={{ fontWeight: "normal", width: "100%" }}
                          >
                            Fan Call
                          </label>

                          <Step2Dropdown>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              onClick={() => toggleSection(index, "fancall")}
                              aria-expanded={product.fancall}
                              aria-controls={`flush-collapseTwo-${index}`}
                              style={{ padding: "0" }}
                            ></button>
                          </Step2Dropdown>
                        </div>
                      </h4>
                      <Collapse in={product.fancall}>
                        <div
                          id={`flush-collapseTwo-${index}`}
                          className="accordion-body"
                        >
                          <div className="accordion-body">
                            {/* Name Input */}
                            <div className="input-group mb-3">
                              <span className="input-group-text">Name</span>
                              <input
                                type="text"
                                aria-label="First name"
                                className="form-control"
                                name="fancallName"
                                value={product.fancallName || ""}
                                onChange={(e) => handleInputChange(index, e)}
                              />
                            </div>
                            {/* Birthday Input */}
                            <div className="input-group mb-3">
                              <span className="input-group-text">Birthday</span>
                              <input
                                type="date"
                                className="form-control"
                                name="fancallBirthday"
                                value={product.fancallBirthday || ""}
                                onChange={(e) => handleInputChange(index, e)}
                              />
                            </div>

                            {/* Phone Number Input */}
                            <div className="input-group mb-3">
                              <span className="input-group-text">Phone</span>
                              <input
                                type="text"
                                className="form-control"
                                name="fancallNumber"
                                value={product.fancallNumber || ""}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="Phone Number"
                                aria-label="Phone Number"
                              />
                            </div>

                            {/* Email Input */}
                            <div className="input-group mb-3">
                              <span className="input-group-text">E-mail</span>
                              <input
                                type="email"
                                className="form-control"
                                name="fancallEmail"
                                value={product.fancallEmail || ""}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="E-mail"
                                aria-label="E-mail"
                              />
                            </div>

                            {/* Country Input */}
                            <div className="input-group mb-3">
                              <label
                                className="input-group-text"
                                htmlFor="dropdown-basic-fancallCountry"
                              >
                                Country
                              </label>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic-fancallCountry"
                                >
                                  {products[index].fancallCountry
                                    ? CODE_COUNTRY_ITEMS[
                                        products[index].fancallCountry
                                      ]
                                    : "Select Country"}
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{
                                    maxHeight: "300px",
                                    overflowY: "auto",
                                  }}
                                >
                                  <FormControl
                                    autoFocus
                                    className="mx-2 my-1 w-auto"
                                    placeholder="Search country..."
                                    onChange={(e) =>
                                      setCountrySearchTerm(e.target.value)
                                    }
                                    value={countrySearchTerm}
                                  />
                                  {filteredCountries.map(([id, name]) => (
                                    <Dropdown.Item
                                      key={id}
                                      onClick={() =>
                                        handleFancallCountrySelect(
                                          Number(id),
                                          index
                                        )
                                      }
                                    >
                                      {name}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            {/* KakaoTalk ID Input */}
                            <div className="input-group mb-3">
                              <span className="input-group-text">
                                KakaoTalk ID
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                name="fancallKakao"
                                value={product.fancallKakao || ""}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="Kakao"
                                aria-label="Kakao"
                              />
                            </div>

                            {/* Line ID Input */}
                            <div className="input-group mb-3">
                              <span className="input-group-text">Line ID</span>
                              <input
                                type="text"
                                className="form-control"
                                name="fancallSnsLine"
                                value={product.fancallSnsLine || ""}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="SnsLine"
                                aria-label="SnsLine"
                              />
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>

                  <div className="col-12" style={{ marginTop: "1rem" }}>
                    <button
                      type="button"
                      style={{ width: "100%", marginBottom: "1rem" }}
                      className="btn btn-danger"
                      onClick={() => deleteProduct(index)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </form>
            ))}
            <Button
              style={{ width: "100%", marginBottom: "1rem" }}
              onClick={addProduct}
            >
              Add Product
            </Button>
          </Modal.Body>

          {isMobile ? (
            <MFooter
              style={{ padding: "1rem", borderTop: "1px solid lightgray" }}
            >
              <MTwoButtonRow>
                <MButton
                  onClick={() => handleNextStep(1)}
                  style={{
                    backgroundColor: "var(--color-gray)",
                    color: "var(--color-white)",
                  }}
                >
                  BACK
                </MButton>
                <MButton
                  onClick={() => handleNextStep(3)}
                  style={{
                    backgroundColor: "var(--color-main-blue)",
                    color: "var(--color-white)",
                  }}
                >
                  NEXT
                </MButton>
              </MTwoButtonRow>
            </MFooter>
          ) : (
            <Modal.Footer>
              <Button
                variant="outline-secondary"
                onClick={() => handleNextStep(1)}
              >
                BACK
              </Button>
              <Button
                variant="outline-success"
                onClick={() => handleNextStep(3)}
              >
                NEXT
              </Button>
            </Modal.Footer>
          )}
        </Modal>

        {/* Step 3 */}
        <Modal show={step === 3} onHide={() => setStep(0)} size="lg">
          <Modal.Header closeButton>
            <div style={{ fontSize: "1.3rem" }}>
              Step 3: Preview Input Information
            </div>
          </Modal.Header>

          <Modal.Body>
            <div className="accordion" id="accordionPreview">
              {products.map((product, index) => (
                <div className="accordion-item" key={product.id}>
                  <div
                    className="accordion-header"
                    id={`heading${index}`}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="outline-light"
                      onClick={() => toggleItem(index)}
                      aria-expanded={openItems[index] ?? true}
                      aria-controls={`collapse${index}`}
                      style={{
                        padding: ".5rem",
                        backgroundColor: "transparent",
                      }}
                    >
                      <Icon icon="caret-down-fill"></Icon>
                    </Button>
                    <div style={{ fontWeight: "bold" }}>No. {index + 1}</div>
                  </div>

                  <Collapse in={openItems[index] ?? true}>
                    <div
                      id={`collapse${index}`}
                      className="accordion-collapse collapse"
                    >
                      <div
                        className="accordion-body"
                        style={{ padding: "0", margin: "0 2.2rem" }}
                      >
                        {isMobile ? (
                          <>
                            <div>
                              <MRow>
                                <div className="label">Category</div>
                                <div className="data">
                                  {CODE_SHOPPING_ORDER_ITEMS[product.category]}
                                </div>
                              </MRow>
                              <MRow>
                                <div className="label out-text">
                                  Product name
                                </div>
                                <div className="data">{product.name}</div>
                              </MRow>
                              <MRow>
                                <div className="label">Option 1</div>
                                <div className="data">
                                  {product.option1 || "-"}
                                </div>
                              </MRow>
                              <MRow>
                                <div className="label">Option 2</div>
                                <div className="data">
                                  {product.option2 || "-"}
                                </div>
                              </MRow>
                              <MRow>
                                <div className="label">Quantity</div>
                                <div className="data">{product.quantity}</div>
                              </MRow>
                              <MRow>
                                <div className="label">Unit price</div>
                                <div className="data">￦{product.price}</div>
                              </MRow>
                              <MRow>
                                <div className="label">Hyperlink</div>
                                <div className="data">
                                  <a
                                    href={product.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {product.url}
                                  </a>
                                </div>
                              </MRow>
                              <MRow>
                                <div className="label">Pre Order</div>
                                <div className="data">
                                  {product.preorder
                                    ? `${product.preorderStartAt} V ${product.preorderEndAt}`
                                    : "No"}
                                </div>
                              </MRow>
                              {product.fancall && (
                                <MRow>
                                  <div className="label">Fan Call</div>
                                  <div className="data">
                                    <ul>
                                      <li>Name: {product.fancallName}</li>
                                      <li>Birth: {product.fancallBirthday}</li>
                                      <li>Contact: {product.fancallNumber}</li>
                                      <li>Email: {product.fancallEmail}</li>
                                      <li>
                                        Country:{" "}
                                        {
                                          CODE_COUNTRY_ITEMS[
                                            product.fancallCountry
                                          ]
                                        }
                                      </li>
                                      <li>Kakao: {product.fancallKakao}</li>
                                      <li>Line: {product.fancallSnsLine}</li>
                                    </ul>
                                  </div>
                                </MRow>
                              )}
                            </div>
                          </>
                        ) : (
                          <table className="table">
                            <tbody>
                              <tr>
                                <th>Category</th>
                                <td>
                                  {CODE_SHOPPING_ORDER_ITEMS[product.category]}
                                </td>
                              </tr>
                              <tr>
                                <th className="out-text">Product name</th>
                                <td>{product.name}</td>
                              </tr>
                              <tr>
                                <th className="out-text">Option 1</th>
                                <td>{product.option1 || "-"}</td>
                              </tr>
                              <tr>
                                <th className="out-text">Option 2</th>
                                <td>{product.option2 || "-"}</td>
                              </tr>
                              <tr>
                                <th>Quantity</th>
                                <td>{product.quantity}</td>
                              </tr>
                              <tr>
                                <th>Unit price</th>
                                <td>￦{product.price}</td>
                              </tr>
                              <tr>
                                <th>Hyperlink</th>
                                <td className="out-text">
                                  <a
                                    href={product.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {product.url}
                                  </a>
                                </td>
                              </tr>
                              {/* <tr>
                                <th>Pre Order</th>
                                <td>
                                  {product.preorder
                                    ? `${product.preorderStartAt} V ${product.preorderEndAt}`
                                    : "No"}
                                </td>
                              </tr> */}
                              {product.fancall && (
                                <tr>
                                  <th>Fan Call</th>
                                  <td>
                                    <ul>
                                      <li>Name: {product.fancallName}</li>
                                      <li>Birth: {product.fancallBirthday}</li>
                                      <li>Contact: {product.fancallNumber}</li>
                                      <li>Email: {product.fancallEmail}</li>
                                      <li>
                                        Country:{" "}
                                        {
                                          CODE_COUNTRY_ITEMS[
                                            product.fancallCountry
                                          ]
                                        }
                                      </li>
                                      <li>Kakao: {product.fancallKakao}</li>
                                      <li>Line: {product.fancallSnsLine}</li>
                                    </ul>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </Collapse>
                </div>
              ))}

              <div className="form-check" style={{ marginTop: "1rem" }}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="totalCancellation"
                  onChange={handleAdditionalInfoChange}
                  checked={products.length === 1 || additionalInfo.failed}
                />
                Total cancellation
              </div>
              {products.length > 1 && (
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="partialCancellation"
                    onChange={handleAdditionalInfoChange}
                    checked={!additionalInfo.failed}
                  />{" "}
                  Partial cancellation (purchase available items)
                </div>
              )}
            </div>
          </Modal.Body>

          {isMobile ? (
            <MFooter
              style={{ padding: "1rem", borderTop: "1px solid lightgray" }}
            >
              <MTwoButtonRow>
                <MButton
                  onClick={() => setStep(2)}
                  style={{
                    backgroundColor: "var(--color-gray)",
                    color: "var(--color-white)",
                  }}
                >
                  Back
                </MButton>
                <MButton
                  onClick={submitAllInfo}
                  style={{
                    backgroundColor: user.Check
                      ? "var(--color-main-blue-hover)"
                      : "var(--color-main-blue)",
                    color: "var(--color-white)",
                  }}
                >
                  Next
                </MButton>
              </MTwoButtonRow>
            </MFooter>
          ) : (
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={() => setStep(2)}>
                Back
              </Button>
              <Button onClick={submitAllInfo} disabled={loading}>
                Submit
              </Button>
              {/* <Button variant="outline-success" onClick={() => {
                            setAdditionalInfo(prev => ({ ...prev, PaymentMethod: 1 }));
                            setStep(4);
                        }}>KRW 결제</Button>
                        <Button variant="outline-success" disabled onClick={() => {
                            setAdditionalInfo(prev => ({ ...prev, PaymentMethod: 2 }));
                            setStep(5);
                        }}>USD 결제</Button> */}
            </Modal.Footer>
          )}
        </Modal>

        {renderStepModal()}
      </section>
    </main>
  );
};

const Step2Dropdown = styled.div`
  & > button:focus {
    box-shadow: none !important;
  }
`;

export default CShopSendorder;
